import React, { useEffect, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import "./dynamicTextarea.css";

export default function DynamicTextarea({
  value,
  onChange,
  style,
  placeHolder,
  readOnly,
  textAreaWrapperStyle,
  onHeightChange,
  hasErrors,
  handleClick,
}) {
  const textareaRef = useRef();

  useEffect(() => {
    if (textareaRef.current && value) {
      const scrollHeight1 = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${scrollHeight1}px`;
    }
  }, [value]);
  return (
    <div
      className="textarea-wrapper"
      style={textAreaWrapperStyle}
      onClick={handleClick}
    >
      <p className="textarea-label">{placeHolder}</p>
      <TextareaAutosize
        ref={textareaRef}
        cacheMeasurements={true}
        className="dynamic-textarea"
        style={style}
        value={value}
        onChange={onChange}
        //placeholder={placeHolder}
        readOnly={readOnly}
        onHeightChange={onHeightChange}
      />
      {hasErrors && (
        <p className="input-error-message">
          {hasErrors.influenceValidationError
            ? hasErrors.influenceValidationError
            : "Obavezno polje"}
        </p>
      )}
    </div>
  );
}
