import React from "react";

const MapaProcesaListPrint = React.forwardRef((props, ref) => {
  const poslovniProcessTableTitles = [
    { id: 1, title: "Sifra pr.", width: "10%" },
    { id: 2, title: "Poslovni proces", width: "25%" },
    { id: 3, title: "Vlasnik procesa", width: "25%" },
    { id: 4, title: "Odobrio", width: "20%" },
    { id: 5, title: "Datum izmene", width: "20%" },
  ];
  return (
    <div
      ref={ref}
      style={{
        width: "98%",

        marginTop: "10px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <ul
        style={{
          display: "flex",
          color: "black",
          height: "50px",
          fontWeight: "bold",
          border: "1px solid black",
        }}
      >
        {poslovniProcessTableTitles.map((el, index) => {
          return (
            <li
              key={el.id}
              style={{
                width: el.width,
                borderRight: index !== 4 ? "1px solid black" : "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#F0F0F0",
              }}
            >
              {el.title}
            </li>
          );
        })}
      </ul>
      {props.list && (
        <ul>
          {props.list.map((mapa, index) => {
            return (
              <li key={mapa.id}>
                <ul
                  style={{
                    display: "flex",
                    color: "black",
                    border: "1px solid black",
                    pageBreakInside: "avoid",
                  }}
                >
                  <li
                    key={`mapalistitem$1`}
                    style={{
                      width: poslovniProcessTableTitles[0].width,
                      padding: "5px",
                      borderRight: "var(--mainBorder)",
                      borderTop: "var(--mainBorder)",
                    }}
                  >
                    {mapa.business_process_list.code}
                  </li>
                  <li
                    key={`mapalistitem$2`}
                    style={{
                      width: poslovniProcessTableTitles[1].width,
                      padding: "5px",
                      borderRight: "var(--mainBorder)",
                      borderTop: "var(--mainBorder)",
                    }}
                  >
                    {mapa.business_process_list.name}
                  </li>
                  <li
                    key={`mapalistitem$3`}
                    style={{
                      width: poslovniProcessTableTitles[2].width,
                      padding: "5px",
                      borderRight: "var(--mainBorder)",
                      borderTop: "var(--mainBorder)",
                    }}
                  >
                    <ul>
                      {mapa.process.business_process_owners.map((owner) => {
                        return (
                          <li key={owner.id}>
                            <span style={{ marginRight: "7px" }}>
                              {owner.first_name}
                            </span>
                            <span>{owner.last_name}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                  <li
                    key={`mapalistitem$4`}
                    style={{
                      width: poslovniProcessTableTitles[3].width,
                      padding: "5px",
                      borderRight: "var(--mainBorder)",
                      borderTop: "var(--mainBorder)",
                    }}
                  ></li>
                  <li
                    style={{
                      width: poslovniProcessTableTitles[4].width,
                      padding: "5px",

                      borderTop: "var(--mainBorder)",
                    }}
                  >
                    datum
                  </li>
                </ul>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
});

export default MapaProcesaListPrint;
