import React, { useEffect, useState } from "react";
import { baseRequest, deleteUrl } from "../../../axiosconfig";

import DropdownWrapper from "../../elements/dropdownWrapper";
import DropDown from "../../elements/dropDown";
import CenterDropdownWrapper from "../../elements/CenterDropdownWrapper";
import DynamicTextarea from "../../elements/DynamicTextarea";
import MapeProcesaAktivnostiList from "../../mapeProcesaAktovnostiList";

import PoslovniProcessOwnerTable from "../../poslovniProcesiOwnerTable";
import MapeDokumentacija from "../../mapeDokumentacija";
import Loading from "../../loading";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import ProcedureList from "../../procedureList";
import NewProcedura from "../../newProcedura";
import NewProcedureRow from "../../newProcedureRow";
import { v4 as uuidv4 } from "uuid";
import DeleteMapaPopup from "../../deleteMapaPopup";
import axios from "axios";

import { formatDesignations } from "../singleMapaProcesa";
import { formatProcesses } from "../singleMapaProcesa";
import NewRisk from "../NewRisk";
import RiskList from "../RiskList";

export default function NewMapa() {
  const [processes, setProcesses] = useState([]);
  const [organization_units, setOrganization_units] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [designationsList, setDesignationsList] = useState();
  const [loading, setLoading] = useState(false);
  const [mapa, setMapa] = useState();
  //   const [mapaList, setMapaList] = useState({});
  const [newActivity, setNewActivity] = useState();
  const [newProcedure, setNewProcedure] = useState();
  const [newProcedureRow, setNewProcedureRow] = useState();
  const [activeProcedure, setActiveProcedure] = useState();
  const [mapaValidationErrors, setMapaValidationErrors] = useState();
  const [newActivityValidationErrors, setNewActivityValidationErrors] =
    useState();
  const [newProcedureValidationErrors, setNewProcedureValidationErrors] =
    useState();
  const [newProcedureRowValidationsError, setNewProcedureRowValidationsError] =
    useState();
  const [mapaToDelete, setMapaToDelete] = useState();
  const [activityToDelete, setActivityToDelete] = useState();
  const [procedureToDelete, setProcedureToDelete] = useState();
  const [newRisk, setNewRisk] = useState();
  const [riskForms, setRiskForms] = useState();
  const [newRiskValidationError, setNewRiskValidationerror] = useState();

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const wrapperStyle = { borderBottom: "none" };
  const wrapperTitleStyle = { borderRight: "1px solid var(--mainColor)" };

  const requiredFields = [
    "description_action",
    "description_input",
    "description_output",
    "process_goal",
  ];

  const formatListToMap = (list) => {
    const map = {};
    list.forEach((item) => {
      map[item.id] = item;
    });
    return map;
  };

  const calculateProcess = (id, list) => {
    return list.find((proc) => {
      return proc.id === id;
    });
  };

  const calculateUnitProcesses = (id, level, list) => {
    return list.filter((item) => {
      return (
        item.organization_unit_id === id && item.level === level && !item.del
      );
    });
  };

  useEffect(() => {
    setLoading(true);
    const urls = [
      "/organization_unit/0",
      "/employee/id/0/level/0",
      "/designations/0/0",
      "/business_processes",
    ];

    Promise.all(
      urls.map((url) => baseRequest.get(url, { withCredentials: true }))
    )
      .then((res) => {
        const responseObj = {};
        res.forEach((item) => {
          if (item.data.data) {
            if (item.data.data[0].first_name) {
              responseObj["designations"] = formatDesignations(item.data.data);
            } else {
              responseObj["organization_units"] = item.data.data;
            }
          } else if (item.data.business_processes) {
            responseObj["business_processes"] = item.data.business_processes;
          } else if (item.data.designations) {
            responseObj["designationsList"] = item.data.designations;
          } else if (item.data.process_maps) {
            responseObj["process_maps"] = item.data.process_maps;
          }
        });

        const orgUnits = responseObj["business_processes"]
          .map((process) => {
            if (process.level >= 0 && !process.del) {
              return {
                id: process.organization_unit_id,
                name: process.name,
                level: process.level,
              };
            }
          })
          .filter((item) => item)
          .map((item) => {
            const unit = responseObj["organization_units"].find(
              (unit) =>
                unit.id === item.id && unit.level === item.level && !unit.del
            );
            return unit;
          });

        setOrganization_units(orgUnits);
        setDesignations(responseObj["designations"]);
        setProcesses(formatProcesses(responseObj["business_processes"]));
        setDesignationsList(responseObj["designationsList"]);

        if (params.id) {
          setMapa({ id: params.id, adding: true });
        } else {
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleChangeOrganizationUnit = (value) => {
    setMapa((mapa) => {
      return {
        ...mapa,

        process: {
          organization_unit_id: value.value.id,
          org_unit_name: value.value.name,
          level: value.value.level,
        },
      };
    });
  };

  const handleChangeProcess = (value) => {
    setLoading(true);
    baseRequest
      .put(
        "/process_map",
        {
          id: mapa.id,
          business_process_list_id: value.value.id,
        },
        { withCredentials: true }
      )
      .then((res) => {
        setLoading(false);
        setMapa((mapa) => {
          return {
            ...mapa,
            process: { ...mapa.process, ...value.value },
            business_process_list_id: value.value.id,
          };
        });
      })
      .catch((error) => {
        console.log(error);
      });

    // calculateProcessOwner(value.value.business_process_owners, designations);
  };

  const calculateProcessOwner = (owners, des) => {
    const procOwners = owners.map((own) => {
      const person = des.find((des) => des.id === own.employee_id);
      return person;
    });

    setMapa((mapa) => {
      return { ...mapa, procOwners: procOwners };
    });
  };

  const handleAddNewActivitiItem = () => {
    // setMapa((map) => {
    //   if (map.activities) {
    //     return {
    //       ...map,
    //       activities: [
    //         ...map.activities,
    //         { name: "", description: "", description_addition: "" },
    //       ],
    //     };
    //   } else {
    //     return {
    //       ...map,
    //       activities: [{ name: "", description: "", description_addition: "" }],
    //     };
    //   }
    // });
    if (newActivityValidationErrors) {
      setNewActivityValidationErrors((errors) => {
        const { no_activity, ...rest } = errors;
        return rest;
      });
    }
    setLoading(true);
    baseRequest
      .post(
        "/activity",
        { process_map_id: mapa.id, procedure_id: null },
        { withCredentials: true }
      )
      .then((res) => {
        setLoading(false);
        setNewActivity({
          ...res.data.data,
          name: "",
          description: "",
          adding: true,
        });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleChangeActivitiName = (id) => {
    return function (e) {
      setMapa((mapa) => {
        return {
          ...mapa,
          activities: mapa.activities.map((activiti, index) => {
            if (index === id) {
              return { ...activiti, name: e.target.value };
            } else {
              return activiti;
            }
          }),
        };
      });
    };
  };

  const handleChangeNewActivity = (e, key) => {
    if (e.target.value.trimStart()) {
      if (newActivityValidationErrors) {
        setNewActivityValidationErrors((errors) => {
          const { [key]: prop, ...rest } = errors;
          return rest;
        });
      }
    }

    setNewActivity((activity) => {
      return { ...activity, [key]: e.target.value.trimStart() };
    });
  };

  const handleChangeActivitiDescription = (id) => {
    return function (e) {
      setMapa((mapa) => {
        return {
          ...mapa,
          activities: mapa.activities.map((activiti, index) => {
            if (index === id) {
              return { ...activiti, description: e.target.value };
            } else {
              return activiti;
            }
          }),
        };
      });
    };
  };

  const handleUpdateActivity = (activity) => {
    setLoading(true);
    baseRequest
      .put("/activity", activity, { withCredentials: true })
      .then((res) => {
        setLoading(false);

        setMapa((mapa) => {
          const newActivities = mapa.activities.map((act) => {
            if (act.id === activity.id) {
              return res.data.data;
            }
            return act;
          });
          return {
            ...mapa,
            activities: newActivities,
          };
        });
        // setMapaList((mapalist) => {
        //   const newActivities = mapa.activities.map((act) => {
        //     if (act.id === activity.id) {
        //       return res.data.data;
        //     }
        //     return act;
        //   });

        //   return {
        //     ...mapalist,
        //     [mapa.id]: {
        //       ...mapa,
        //       activities: newActivities,
        //     },
        //   };
        // });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleSaveNewActivity = () => {
    setLoading(true);

    if (validateMapaActivity(newActivity)) {
      return;
    }

    baseRequest
      .put("/activity", newActivity, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        setMapa((mapa) => {
          //const { adding, edit, ...rest } = mapa;
          return {
            ...mapa,
            activities: mapa.activities
              ? [...mapa.activities, res.data.data]
              : [res.data.data],
          };
        });
        // setMapaList((mapalist) => {
        //   //  const { adding, edit, ...rest } = mapa;
        //   return {
        //     ...mapalist,
        //     [mapa.id]: {
        //       ...mapa,
        //       activities: mapa.activities
        //         ? [...mapa.activities, res.data.data]
        //         : [res.data.data],
        //     },
        //   };
        // });
        setNewActivity(null);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleSetEditActivity = (id) => {
    setMapa((mapa) => {
      const newActivities = mapa.activities.map((activity) => {
        if (activity.id === id) {
          return { ...activity, editing: true };
        }
        return activity;
      });
      return { ...mapa, activities: newActivities };
    });
  };

  const handleChangeActivity = (e, id, key) => {
    setMapa((mapa) => {
      const newActivities = mapa.activities.map((activity) => {
        if (activity.id === id) {
          return { ...activity, [key]: e.target.value.trimStart() };
        }
        return activity;
      });
      return { ...mapa, activities: newActivities };
    });
  };

  const handleSaveActivity = (id) => {
    setMapa((mapa) => {
      const newActivities = mapa.activities.map((activity) => {
        if (activity.id === id) {
          const { editing, ...rest } = activity;
          return rest;
        }
        return activity;
      });
      return { ...mapa, activities: newActivities };
    });
  };

  const handleChange = (key) => {
    return function (e) {
      if (
        e.target.value.trimStart() &&
        mapaValidationErrors &&
        Object.keys(mapaValidationErrors).length
      ) {
        const { [key]: error, ...rest } = mapaValidationErrors;
        setMapaValidationErrors(rest);
      }
      setMapa((mapa) => {
        return { ...mapa, [key]: e.target.value.trimStart() };
      });
    };
  };

  const handleSelectCorellationProcess = ({ value }) => {
    setMapa((mapa) => {
      return { ...mapa, correlation_to_other_processes: value.name };
    });
  };

  const clearCorrelation = () => {
    setMapa((mapa) => {
      return { ...mapa, correlation_to_other_processes: null };
    });
  };

  const handleChangeResponsibleDesignations = (id) => {
    return function (value) {
      const responsibles = value.map((item) => {
        return { designation_id: item.value.id };
      });
      setMapa((mapa) => {
        return {
          ...mapa,

          activities: mapa.activities.map((el, index) => {
            if (index === id) {
              return {
                ...el,
                designations: responsibles,
                selectedResponsibilityDesignations: value,
              };
            }
            return el;
          }),
        };
      });
    };
  };

  const handleChangeDynamicDescription = (id) => {
    return function (e) {
      setMapa((mapa) => {
        return {
          ...mapa,

          activities: mapa.activities.map((el, index) => {
            if (index === id) {
              return { ...el, description_addition: e.target.value };
            }
            return el;
          }),
        };
      });
    };
  };

  const handleChangeDocumentPath = (id) => {
    return function (value) {
      setMapa((mapa) => {
        return {
          ...mapa,

          activities: mapa.activities.map((el, index) => {
            if (index === id) {
              return { ...el, documents: [value] };
            }
            return el;
          }),
        };
      });
    };
  };

  const handleSubmitMapa = () => {
    // setLoading(true);
    // baseRequest
    //   .post("/process_map", mapa, { withCredentials: true })
    //   .then((res) => {
    //     setLoading(false);
    //     setMapaList((list) => {
    //       return { ...list, [res.data.data.id]: { ...res.data.data, ...mapa } };
    //     });
    //     setMapa((mapa) => {
    //       return { ...mapa, id: res.data.data.id };
    //     });
    //   })
    //   .catch((err) => console.log(err));
    if (validateMapa()) {
      return;
    }

    // validateMapaActivity(newActivity);
    //const length = Object.keys(mapaList).length;

    // const id = length ? length + 1 : 1;
    setLoading(true);
    baseRequest
      .put("/process_map", mapa, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        navigate(`/admin/mape-procesa/${res.data.data.id}`);
        // if (mapa.adding) {
        // //   setMapa((mapa) => {
        // //     const { adding, ...rest } = mapa;
        // //     return { ...rest, ...res.data.data };
        // //   });
        //   //   setMapaList((mapaList) => {
        //   //     const { adding, ...rest } = mapa;
        //   //     return {
        //   //       ...mapaList,
        //   //       [res.data.data.id]: { ...rest, ...res.data.data },
        //   //     };
        //   //   });
        // } else {
        // //   setMapa((mapa) => {
        // //     const { edit, ...rest } = mapa;
        // //     return { ...rest, ...res.data.data };
        // //   });
        //   //   setMapaList((mapaList) => {
        //   //     const { edit, ...rest } = mapa;
        //   //     return {
        //   //       ...mapaList,
        //   //       [res.data.data.id]: { ...rest, ...res.data.data },
        //   //     };
        //   //   });
        // }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });

    // setMapaList((list) => {
    //   return { ...list, [id]: mapa };
    // });
    // setMapa((mapa) => {
    //   return { ...mapa, id };
    // });
  };

  const addNewMapa = () => {
    setLoading(true);
    baseRequest
      .post("/process_map", {}, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        const newMapa = { ...res.data.data, adding: true };
        setMapa(newMapa);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const calculateProcedureCode = (pr, mapa) => {
    const index = pr
      .filter(
        (proc) =>
          proc.business_process_id ===
          mapa.business_process_list.business_process_id
      )
      .findIndex(
        (el) =>
          el.business_process_id ===
          mapa.business_process_list.business_process_id
      );
    const procedureIndex = mapa.procedures.length
      ? mapa.procedures.length + 1
      : 1;
    return `${mapa.business_process_list.code}-${index + 1}-${procedureIndex}`;
  };

  const handleAddNewProcedure = () => {
    setLoading(true);
    baseRequest
      .post(
        "/procedure",
        { process_map_id: mapa.id },
        { withCredentials: true }
      )
      .then((res) => {
        setLoading(false);
        setNewProcedure({
          ...res.data.data,
          name: "",
          code: calculateProcedureCode(processes, mapa),
        });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleChangeNewProceduraName = (e) => {
    if (e.target.value.trimStart()) {
      setNewProcedureValidationErrors(null);
    }
    setNewProcedure((procedure) => {
      return { ...procedure, name: e.target.value.trimStart() };
    });
  };

  const handleSaveNewProcedure = () => {
    if (validateNewProcedure(newProcedure)) {
      return;
    }
    setLoading(true);
    baseRequest
      .put("/procedure", newProcedure, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        setMapa((mapa) => {
          return {
            ...mapa,
            procedures: mapa.procedures
              ? [...mapa.procedures, res.data.data]
              : [res.data.data],
          };
        });
        // setMapaList((mapalist) => {
        //   return {
        //     ...mapalist,
        //     [mapa.id]: {
        //       ...mapalist[mapa.id],
        //       procedures: mapalist[mapa.id].procedures
        //         ? [...mapalist[mapa.id].procedures, res.data.data]
        //         : [res.data.data],
        //     },
        //   };
        // });
        setActiveProcedure(res.data.data);
        setNewProcedure(null);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleAddNewProcedureRow = () => {
    setLoading(true);
    baseRequest
      .post(
        "/activity",
        {
          process_map_id: mapa.id,

          procedure_id: activeProcedure.id,
        },
        { withCredentials: true }
      )
      .then((res) => {
        setLoading(false);
        setNewProcedureRow({ ...res.data.data, name: "", description: "" });
        // setActiveProcedure((procedure) => {
        //   return { ...procedure, activities: procedure.activities ? [...procedure.activities, { ...res.data.data, name: "", description: "" }] };
        // });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleDeleteNewProcedure = () => {
    setNewProcedureValidationErrors(null);
    setNewProcedure(null);
  };

  const handleChangeNewProcedureRow = (e, key) => {
    if (e.target.value.trimStart()) {
      if (newProcedureRowValidationsError) {
        setNewProcedureRowValidationsError((errors) => {
          const { [key]: prop, ...rest } = errors;
          return rest;
        });
      }
    }
    setNewProcedureRow((row) => {
      return { ...row, [key]: e.target.value.trimStart() };
    });
  };

  const handleChangeProcedureRow = (e, key, row) => {
    setActiveProcedure((procedure) => {
      const newActivities = procedure.activities.map((act) => {
        if (act.id === row.id) {
          return { ...act, [key]: e.target.value };
        }
        return act;
      });
      return { ...procedure, activities: newActivities };
    });
  };

  const handleSelectProceduraRowResponsibles = (row) => {
    return function (value) {
      setActiveProcedure((procedure) => {
        const newActivities = procedure.activities.map((act) => {
          if (act.id === row.id) {
            return { ...act, activities_designations: value };
          }
          return act;
        });
        return { ...procedure, activities: newActivities };
      });
    };
  };

  const handleChangeProcedureRowDueDate = (row) => {
    return function (date) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      month = month >= 10 ? month : `0${month}`;
      let currentDate = `${year}-${month}-${day}`;
      setActiveProcedure((procedure) => {
        const newActivities = procedure.activities.map((act) => {
          if (act.id === row.id) {
            return { ...act, due_date: currentDate };
          }
          return act;
        });
        return { ...procedure, activities: newActivities };
      });
    };
  };

  const handleSetEditProcedure = (proc) => {
    setActiveProcedure(proc);
    setMapa((mapa) => {
      const newProceduresList = mapa.procedures.map((procedure) => {
        if (procedure.id === proc.id) {
          return { ...procedure, editing: true };
        }
        return procedure;
      });
      return { ...mapa, procedures: newProceduresList };
    });
  };

  const handleSetEditProcedureRow = (row) => {
    setActiveProcedure((procedure) => {
      const newActivities = procedure.activities.map((act) => {
        if (act.id === row.id) {
          return { ...act, editing: true };
        }
        return act;
      });
      return { ...procedure, activities: newActivities };
    });
  };

  const handleChangeProcedureName = (e, id) => {
    setMapa((mapa) => {
      const newProceduresList = mapa.procedures.map((procedure) => {
        if (procedure.id === id) {
          return { ...procedure, name: e.target.value.trimStart() };
        }
        return procedure;
      });
      return { ...mapa, procedures: newProceduresList };
    });
  };

  const handleSaveProcedure = (id) => {
    setMapa((mapa) => {
      const newProceduresList = mapa.procedures.map((procedure) => {
        if (procedure.id === id) {
          const { editing, ...rest } = procedure;
          return rest;
        }
        return procedure;
      });
      return { ...mapa, procedures: newProceduresList };
    });
  };

  const handleUpdateProcedure = (procedure) => {
    setLoading(true);
    baseRequest
      .put("/procedure", procedure, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        setMapa((mapa) => {
          const newProcedures = mapa.procedures.map((pr) => {
            if (pr.id === procedure.id) {
              return res.data.data;
            }
            return pr;
          });
          return {
            ...mapa,
            procedures: newProcedures,
          };
        });
        // setMapaList((mapalist) => {
        //   const newProcedures = mapa.procedures.map((pr) => {
        //     if (pr.id === procedure.id) {
        //       return res.data.data;
        //     }
        //     return pr;
        //   });
        //   return {
        //     ...mapalist,
        //     [mapa.id]: {
        //       ...mapalist[mapa.id],
        //       procedures: newProcedures,
        //     },
        //   };
        // });
        if (activeProcedure.id === procedure.id) {
          setActiveProcedure(res.data.data);
        }
      });
  };

  const handleDeleteNewActivity = () => {
    setNewActivityValidationErrors(null);
    setNewActivity(null);
  };

  function handleSelectDueDate(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    month = month >= 10 ? month : `0${month}`;
    let currentDate = `${year}-${month}-${day}`;
    setNewProcedureRow((formular) => {
      return {
        ...formular,
        ["due_date"]: currentDate,
      };
    });
  }

  const handleSelectNewProcedureResponsibleDesignations = (value) => {
    if (value.length && newProcedureRowValidationsError) {
      setNewProcedureRowValidationsError((errors) => {
        const { designations, ...rest } = errors;

        return rest;
      });
    }
    setNewProcedureRow((row) => {
      return { ...row, activities_designations: value };
    });
  };

  const handleAddLink = (value) => {
    setNewProcedureRow((row) => {
      return { ...row, link: value };
    });
  };

  const handleChangeLink = (e, key) => {
    setNewProcedureRow((row) => {
      return {
        ...row,
        link: { ...row.link, [key]: e.target.value.trimStart() },
      };
    });
  };

  const handleSaveNewProcedureRow = () => {
    if (validateNewProcedureRow(newProcedureRow)) {
      return;
    }

    const formattedDesignations = newProcedureRow.activities_designations.map(
      (item) => {
        return { designation_id: item.value.id };
      }
    );
    setLoading(true);

    baseRequest
      .put(
        "/activity",
        { ...newProcedureRow, designations: formattedDesignations },
        { withCredentials: true }
      )
      .then((res) => {
        setLoading(false);

        setActiveProcedure((procedure) => {
          return {
            ...procedure,
            activities: procedure.activities
              ? [...procedure.activities, res.data.data]
              : [res.data.data],
          };
        });
        setMapa((mapa) => {
          const newProceduresList = mapa.procedures.map((procedure) => {
            if (procedure.id === activeProcedure.id) {
              return {
                ...procedure,
                activities: procedure.activities
                  ? [...procedure.activities, newProcedureRow]
                  : [newProcedureRow],
              };
            }
            return procedure;
          });
          return {
            ...mapa,
            procedures: newProceduresList,
          };
        });

        // setMapaList((mapalist) => {
        //   const newProceduresList = mapa.procedures.map((procedure) => {
        //     if (procedure.id === activeProcedure.id) {
        //       return {
        //         ...procedure,
        //         activities: procedure.activities
        //           ? [...procedure.activities, newProcedureRow]
        //           : [newProcedureRow],
        //       };
        //     }
        //     return procedure;
        //   });
        //   return {
        //     ...mapalist,
        //     [mapa.id]: { ...mapa, procedures: newProceduresList },
        //   };
        // });
        setNewProcedureRow(null);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleDeleteNewProcedureRow = () => {
    setNewProcedureRowValidationsError(null);
    setNewProcedureRow(null);
  };

  const validateMapa = () => {
    const mapaValidationErrors = {};
    requiredFields.forEach((field) => {
      if (!mapa[field]) {
        mapaValidationErrors[field] = true;
      }
    });
    // if (!mapa.activities) {
    //   mapaValidationErrors.push({ activities: ["name", ""] });
    // }
    if (Object.keys(mapaValidationErrors).length) {
      setMapaValidationErrors(mapaValidationErrors);
      return true;
    }
    return false;
  };

  const validateMapaActivity = (activity) => {
    const activityValidationErrors = {};

    const fields = ["name", "description"];
    if (activity) {
      fields.forEach((field) => {
        if (activity[field].length === 0) {
          activityValidationErrors[field] = true;
        }
      });
      if (Object.keys(activityValidationErrors).length) {
        setNewActivityValidationErrors(activityValidationErrors);
        return true;
      }
    } else {
      setNewActivityValidationErrors({ no_activity: true });

      return true;
    }
    return false;
  };

  const validateNewProcedure = (procedure) => {
    if (!procedure?.name) {
      setNewProcedureValidationErrors(true);
      return true;
    }
    return false;
  };

  const validateProcedureRow = (row) => {
    const errors = {};
    const fields = ["name", "description", "activities_designations"];
    if (row) {
      fields.forEach((field) => {
        if (!row[field]) {
          errors[field] = true;
        }
      });
      if (Object.keys(errors).length) {
        return errors;
      }
    }
  };

  const validateNewProcedureRow = (row) => {
    if (validateProcedureRow(row)) {
      setNewProcedureRowValidationsError(validateProcedureRow(row));

      return true;
    }
    return false;
  };

  const handleAddNewMapaDocumentList = (isNewRow, row) => {
    if (isNewRow) {
      setNewProcedureRow((row) => {
        if (row?.documents && row?.documents.length > 0) {
          return {
            ...row,
            documents: [...row.documents, { id: row.documents.length + 1 }],
          };
        } else {
          return { ...row, documents: [{ id: 1 }] };
        }
      });
    } else {
      setActiveProcedure((procedure) => {
        const newActivities = procedure.activities.map((act) => {
          if (act.id === row.id) {
            return {
              ...act,
              activities_documents: act?.activities_documents.length
                ? [
                    ...act.activities_documents,
                    {
                      id: uuidv4(),
                      name: "",
                      path: "",
                    },
                  ]
                : [
                    {
                      id: uuidv4(),
                      name: "",
                      path: "",
                    },
                  ],
            };
          }
          return act;
        });
        return { ...procedure, activities: newActivities };
      });
    }
  };

  const handleChangeNewMapaDocument = (isNewRow, key, e, row) => {
    if (isNewRow) {
      setNewProcedureRow((row) => {
        return {
          ...row,
          documents: row.documents.map((document, index) => {
            if (index === row.documents.length - 1) {
              return { ...document, [key]: e.target.value.trimStart() };
            }
            return document;
          }),
        };
      });
    } else {
      setActiveProcedure((procedure) => {
        const newActivities = procedure.activities.map((act) => {
          if (act.id === row.id) {
            return {
              ...act,
              activities_documents: act?.activities_documents.length
                ? act.activities_documents.map((doc, index) => {
                    if (index === act.activities_documents.length - 1) {
                      return { ...doc, [key]: e.target.value.trimStart() };
                    }
                    return doc;
                  })
                : act.activities_documents.map((el) => {
                    return { ...el, [key]: e.target.value.trimStart() };
                  }),
            };
          }
          return act;
        });

        return { ...procedure, activities: newActivities };
      });
    }
  };

  const handleRejectMapaDukument = (isNewRow, row) => {
    if (isNewRow) {
      setNewProcedureRow((row) => {
        if (row?.documents.length > 1) {
          return {
            ...row,
            documents: row.documents.slice(0, row.documents.length - 1),
          };
        } else {
          const { documents, ...rest } = row;
          return rest;
        }
      });
    } else {
      setActiveProcedure((procedure) => {
        const newActivities = procedure.activities.map((act) => {
          if (act.id === row.id) {
            if (act?.activities_documents.length > 1) {
              return {
                ...act,
                activities_documents: act.activities_documents.slice(
                  0,
                  act.activities_documents.length - 1
                ),
              };
            } else {
              const { activities_documents, ...rest } = act;
              return rest;
            }
          }
          return act;
        });
        return { ...procedure, activities: newActivities };
      });
    }
  };

  const handleSaveNewMapaDocument = (isNewRow, row) => {
    if (isNewRow) {
      setNewProcedureRow((row) => {
        return {
          ...row,
          documents: row.documents.map((document, index) => {
            if (index === row.documents.length - 1) {
              return { ...document, saved: true };
            }
            return document;
          }),
        };
      });
    }
    // else {
    //   setActiveProcedure(procedure => {
    //     const newActivities = procedure.activities.map(act => {
    //       if(act.id === row.id) {
    //         return {...act, activities_documents: act.activities_documents.map((doc, index) => {
    //           if(index === act.activities_documents.length - 1) {
    //             return {...doc, saved:}
    //           }
    //         })}
    //       }
    //     })
    //   })
    // }
  };

  const handleSetEditMapa = () => {
    setMapa((mapa) => {
      return { ...mapa, edit: true };
    });
  };

  const handleUpdateProcedureRow = (row) => {
    const formattedDesignations = row.activities_designations.map((item) => {
      return {
        designation_id: item.value ? item.value.id : item.designation_id,
      };
    });
    setLoading(true);
    const { process_map_id, ...rest } = row;
    baseRequest
      .put(
        "/activity",
        {
          ...row,
          designations: formattedDesignations,
          documents: row.activities_documents,
        },
        { withCredentials: true }
      )
      .then((res) => {
        setLoading(false);

        setActiveProcedure((procedure) => {
          const newActivities = procedure.activities.map((act) => {
            if (act.id === row.id) {
              return res.data.data;
            }
            return act;
          });
          return {
            ...procedure,
            activities: newActivities,
          };
        });
        setMapa((mapa) => {
          const newProceduresList = mapa.procedures.map((procedure) => {
            if (procedure.id === activeProcedure.id) {
              return {
                ...procedure,
                activities: procedure.activities.map((act) => {
                  if (act.id === row.id) {
                    return res.data.data;
                  }
                  return act;
                }),
              };
            }
            return procedure;
          });
          return {
            ...mapa,
            procedures: newProceduresList,
          };
        });

        // setMapaList((mapalist) => {
        //   const newProceduresList = mapa.procedures.map((procedure) => {
        //     if (procedure.id === activeProcedure.id) {
        //       return {
        //         ...procedure,
        //         activities: procedure.activities.map((act) => {
        //           if (act.id === row.id) {
        //             return res.data.data;
        //           }
        //           return act;
        //         }),
        //       };
        //     }
        //     return procedure;
        //   });
        //   return {
        //     ...mapalist,
        //     [mapa.id]: { ...mapa, procedures: newProceduresList },
        //   };
        // });
        setNewProcedureRow(null);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const HandleDeleteMapa = () => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}process_map`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: { id: mapa.id },
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        setMapaToDelete(null);
        // const { [res.data.data.id]: deletedMapa, ...rest } = mapaList;
        // const activeMapa =
        //   Object.keys(rest).length > 0 ? rest[Object.keys(rest)[0]] : null;
        // setMapa(activeMapa);
        //  setMapaList(rest);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleDeleteActivity = () => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}activity`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: { id: activityToDelete.id },
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);

        if (!res.data.data.procedure_id) {
          setMapa((mapa) => {
            const newActivities = mapa.activities.filter((act) => {
              return act.id !== res.data.data.id;
            });
            // setMapaList((mapalist) => {
            //   return {
            //     ...mapalist,
            //     [mapa.id]: { ...mapalist[mapa.id], activities: newActivities },
            //   };
            // });
            return { ...mapa, activities: newActivities };
          });
        } else {
          setActiveProcedure((procedure) => {
            const newActivities = procedure.activities.filter(
              (act) => act.id !== res.data.data.id
            );

            setMapa((mapa) => {
              const newProcedures = mapa.procedures.map((proc) => {
                if (proc.id === res.data.data.procedure_id) {
                  return { ...proc, activities: newActivities };
                }
                return proc;
              });
              //   setMapaList((mapalist) => {
              //     return {
              //       ...mapalist,
              //       [res.data.data.process_map_id]: {
              //         ...mapalist[res.data.data.process_map_id],
              //         procedures: newProcedures,
              //       },
              //     };
              //   });
              return { ...mapa, procedures: newProcedures };
            });
            return { ...procedure, activities: newActivities };
          });
        }

        setActivityToDelete(null);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleDeleteProcedure = () => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}procedure`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: { id: procedureToDelete.id },
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);

        setMapa((mapa) => {
          const newProcedures = mapa.procedures.filter((act) => {
            return act.id !== res.data.data.id;
          });
          //   setMapaList((mapalist) => {
          //     return {
          //       ...mapalist,
          //       [mapa.id]: { ...mapalist[mapa.id], procedures: newProcedures },
          //     };
          //   });
          return { ...mapa, procedures: newProcedures };
        });
        setProcedureToDelete(null);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleChangeNewRiskName = (e) => {
    // if (e.target.value.trimStart()) {
    //   setNewProcedureValidationErrors(null);
    // }

    if (e.target.value.trimStart()) {
      setNewRiskValidationerror(null);
      setNewRisk((risk) => {
        return { ...risk, name: e.target.value.trimStart() };
      });
    }
  };

  const handleSaveNewRisk = () => {
    if (validateNewRisk(newRisk?.name)) {
      return;
    }
    setLoading(true);
    baseRequest
      .post("risk_form", newRisk, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        setRiskForms((forms) => {
          return forms ? [...forms, res.data.data] : [res.data.data];
        });
        setMapa((mapa) => {
          return {
            ...mapa,
            risk_forms: mapa.risk_forms
              ? [...mapa.risk_forms, res.data.data]
              : [res.data.data],
          };
        });
        setNewRisk(null);
      })

      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    // if (validateNewProcedure(newProcedure)) {
    //   return;
    // }
    // setLoading(true);
    // baseRequest
    //   .put("/procedure", newProcedure, { withCredentials: true })
    //   .then((res) => {
    //     setLoading(false);
    //     setMapa((mapa) => {
    //       return {
    //         ...mapa,
    //         procedures: mapa.procedures
    //           ? [...mapa.procedures, res.data.data]
    //           : [res.data.data],
    //       };
    //     });
    //     setMapaList((mapalist) => {
    //       return {
    //         ...mapalist,
    //         [mapa.id]: {
    //           ...mapalist[mapa.id],
    //           procedures: mapalist[mapa.id].procedures
    //             ? [...mapalist[mapa.id].procedures, res.data.data]
    //             : [res.data.data],
    //         },
    //       };
    //     });
    //     setActiveProcedure(res.data.data);
    //     setNewProcedure(null);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoading(false);
    //   });
  };

  const handleAddNewRisk = () => {
    setNewRisk({
      name: "",
      business_process_list_id: mapa.business_process_list_id,
      process_map_id: mapa.id,
    });
    // setLoading(true);
    // baseRequest
    //   .post(
    //     "/procedure",
    //     { process_map_id: mapa.id },
    //     { withCredentials: true }
    //   )
    //   .then((res) => {
    //     setLoading(false);
    //     setNewProcedure({
    //       ...res.data.data,
    //       name: "",
    //       code: calculateProcedureCode(processes, mapa),
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoading(false);
    //   });
  };

  const validateNewRisk = (name) => {
    if (!name) {
      setNewRiskValidationerror(true);
      return true;
    }
  };

  const handleSetEditRisk = (risk) => {
    setMapa((mapa) => {
      const newRiskList = mapa.risk_forms.map((form) => {
        if (form.id === risk.id) {
          return { ...risk, editing: true };
        }
        return risk;
      });
      return { ...mapa, risk_forms: newRiskList };
    });
  };

  return (
    <>
      <div
        className="processes-header"
        style={{ padding: "15px 0", justifyContent: "space-between" }}
      >
        <div className="back-arrow">
          <img src="/images/backarrow.png" />
          <Link to="/admin/mape-procesa">Nazad</Link>
        </div>
        <div className="sort-options-dropdown" style={{ width: "200px" }}>
          <div
            className="sort-options-dropdown-selected"
            onClick={() => {
              // setShowDropdown((state) => !state);
            }}
          >
            <span style={{ marginRight: "7px", paddingLeft: "10px" }}>
              Opcije
            </span>

            <img
              alt=""
              src="/images/sidebararrow.png"
              className="sidebar-navigation-item-arrow"
            ></img>
          </div>
          {/* {showDropdown && (
            <ul
              className="sort-options-dropdown-options"
              style={{ left: "0", zIndex: "100", flex: 1 }}
            >
              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setShowDropdown(false);
                }}
              >
                Izmenite
              </li>

              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setShowDropdown(false);
                }}
              >
                Odštampaj
              </li>
            </ul>
          )} */}
        </div>
        <div>
          {mapa && (
            <button className="add-btn-large" onClick={handleSetEditMapa}>
              <img
                alt=""
                src={
                  mapa.edit || mapa.adding
                    ? "/images/check2.png"
                    : "/images/edit.png"
                }
              ></img>
            </button>
          )}
        </div>
      </div>
      <div
        className="poslovni-procesi"
        style={{
          width: "auto",
          marginLeft: "50px",
          marginTop: "30px",
        }}
      >
        {mapa && (
          <div
            className="poslovni-procesi-tables"
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                border:
                  mapa.edit || mapa.adding
                    ? "2px solid var(--mainColor)"
                    : "none",
                marginBottom: "20px",
              }}
            >
              <DropdownWrapper
                title="PUN NAZIV ORGANIZACIJE"
                wrapperStyle={wrapperStyle}
                style={wrapperTitleStyle}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      backgroundColor: "#f0f8ff",
                      textAlign: "CENTER",
                      width: "60%",
                      height: "70px",
                      borderRight: "1px solid var(--mainColor)",
                      color: "var(--mainColor)",
                      textTransform: "uppercase",
                      fontWeight: "600",
                      fontSize: "14px",
                      padding: "1.6em 0",
                    }}
                  >
                    MAPA PROCESA
                  </div>
                  <div style={{ height: "70px", flex: "1" }}>
                    <div
                      style={{
                        height: "50%",
                        borderBottom: "1px solid var(--mainColor)",
                        color: "var(--mainColor)",
                        textTransform: "uppercase",
                        fontWeight: "600",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#f0f8ff",
                      }}
                    >
                      ŠIFRA PROCESA
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "var(--mainColor)",
                        height: "50%",
                      }}
                    >
                      {mapa?.process?.code || ""}
                    </div>
                  </div>
                </div>
              </DropdownWrapper>
              <DropdownWrapper
                title="VLASNIK PROCESA"
                wrapperStyle={wrapperStyle}
                style={wrapperTitleStyle}
              >
                {mapa?.process &&
                  mapa?.process?.business_process_owners &&
                  mapa?.process?.business_process_owners.length > 0 && (
                    <ul className="static-list">
                      {mapa.process.business_process_owners.map((el) => {
                        const worker = designations.find(
                          (item) => item.id === el.employee_id
                        ).name;
                        return <li key={el.id}>{worker}</li>;
                      })}
                    </ul>
                  )}
              </DropdownWrapper>

              <DropdownWrapper
                title="ORGANIZACIONA JEDINICA"
                style={wrapperTitleStyle}
              >
                <DropDown
                  list={organization_units}
                  onChange={handleChangeOrganizationUnit}
                  isMulti={false}
                  selectedValue={mapa?.process?.org_unit_name || ""}
                  disabled={!mapa.adding && !mapa.edit}
                />
              </DropdownWrapper>
            </div>
            <div
              style={{
                border:
                  mapa.edit || mapa.adding
                    ? "2px solid var(--mainColor)"
                    : "none",
                marginBottom: "20px",
              }}
            >
              <CenterDropdownWrapper
                title="NAZIV PROCESA"
                wrapperStyle={{ marginBottom: "0" }}
              >
                <DropDown
                  isMulti={false}
                  list={calculateUnitProcesses(
                    mapa?.process?.organization_unit_id,
                    mapa?.process?.level,
                    processes
                  )}
                  onChange={handleChangeProcess}
                  selectedValue={mapa?.process?.name || ""}
                  disabled={!mapa.adding && !mapa.edit}
                />
              </CenterDropdownWrapper>
            </div>

            <div
              style={{
                border:
                  mapa.edit || mapa.adding
                    ? "2px solid var(--mainColor)"
                    : "none",
                marginBottom: "20px",
              }}
            >
              <CenterDropdownWrapper
                title="CILJ PROCESA"
                wrapperStyle={{ marginBottom: "0" }}
              >
                <DynamicTextarea
                  onChange={handleChange("process_goal")}
                  value={mapa.process_goal ? mapa.process_goal : ""}
                  hasErrors={
                    mapaValidationErrors && mapaValidationErrors["process_goal"]
                  }
                  readOnly={!mapa.adding && !mapa.edit}
                />
              </CenterDropdownWrapper>
            </div>

            <div
              style={{
                marginTop: "40px",
                marginBottom: "20px",
              }}
            >
              {mapa.business_process_list_id && (
                <div
                  style={{
                    border:
                      mapa.edit || mapa.adding
                        ? "3px solid var(--mainColor)"
                        : "none",
                    marginBottom: "20px",
                  }}
                >
                  <CenterDropdownWrapper
                    title="Moguci rizici"
                    wrapperStyle={{ margin: "0", borderBottom: "none" }}
                  >
                    {mapa.risk_forms && mapa.risk_forms.length > 0 && (
                      <RiskList
                        list={mapa.risk_forms}
                        handleSetActiveProcedure={setActiveProcedure}
                        activeProcedure={activeProcedure}
                        handleSetEditProcedure={handleSetEditProcedure}
                        handleChangeProcedureName={handleChangeProcedureName}
                        handleSaveProcedure={handleSaveProcedure}
                        handleUpdateProcedure={handleUpdateProcedure}
                        setProcedureToDelete={setProcedureToDelete}
                        mapa={mapa}
                        processes={processes}
                        handleSetEditRisk={handleSetEditRisk}
                      />
                    )}
                  </CenterDropdownWrapper>

                  {newRisk && (
                    <NewRisk
                      handleChangeNewRiskName={handleChangeNewRiskName}
                      name={newRisk?.name}
                      handleSaveNewRisk={handleSaveNewRisk}
                      newRiskValidationError={newRiskValidationError}
                      newRisk={newRisk}
                      processes={processes}
                      mapa={mapa}
                    />
                  )}
                </div>
              )}
              {mapa.business_process_list_id && (
                <button
                  // disabled={!mapaList?.[mapa.id] || newProcedure}
                  onClick={handleAddNewRisk}
                  // className={
                  //   newProcedure || !mapaList?.[mapa?.id]
                  //     ? "add-button add-button-disabled"
                  //     : "add-button"
                  // }
                  className="add-button"
                >
                  <span style={{ marginRight: "5px" }}>+</span>
                  Dodajte rizik
                </button>
              )}
            </div>

            <div
              style={{
                border:
                  mapa.edit || mapa.adding
                    ? "2px solid var(--mainColor)"
                    : "none",
                marginBottom: "20px",
              }}
            >
              <CenterDropdownWrapper
                title="KRATAK OPIS PROCESA"
                wrapperStyle={{ marginBottom: "0" }}
              >
                <div className="text-area-wrapper">
                  <p className="text-area-title">ULAZ:</p>
                  <DynamicTextarea
                    style={{ borderBottom: "1px solid var(--mainColor)" }}
                    onChange={handleChange("description_input")}
                    value={mapa.description_input ? mapa.description_input : ""}
                    hasErrors={
                      mapaValidationErrors &&
                      mapaValidationErrors["description_input"]
                    }
                    readOnly={!mapa.adding && !mapa.edit}
                  />
                </div>
                <div className="text-area-wrapper">
                  <p className="text-area-title">AKTIVNOSTI:</p>
                  <DynamicTextarea
                    style={{ borderBottom: "1px solid var(--mainColor)" }}
                    onChange={handleChange("description_action")}
                    value={
                      mapa.description_action ? mapa.description_action : ""
                    }
                    hasErrors={
                      mapaValidationErrors &&
                      mapaValidationErrors["description_action"]
                    }
                    readOnly={!mapa.adding && !mapa.edit}
                  />
                </div>
                <div className="text-area-wrapper">
                  <p
                    className="text-area-title"
                    style={{ borderBottom: "none" }}
                  >
                    IZLAZ:
                  </p>
                  <DynamicTextarea
                    onChange={handleChange("description_output")}
                    value={
                      mapa.description_output ? mapa.description_output : ""
                    }
                    hasErrors={
                      mapaValidationErrors &&
                      mapaValidationErrors["description_output"]
                    }
                    readOnly={!mapa.adding && !mapa.edit}
                  />
                </div>
              </CenterDropdownWrapper>
            </div>
            <div
              style={{
                border:
                  mapa.edit || mapa.adding
                    ? "2px solid var(--mainColor)"
                    : "none",
                marginBottom: "20px",
              }}
            >
              <CenterDropdownWrapper
                title="VEZE SA DRUGIM POSLOVNIM PROCESIMA / PROCEDURAMA"
                wrapperStyle={{ marginBottom: "0" }}
                centerDropdownWrapperChildrenStyle={{ display: "flex" }}
              >
                {mapa?.correlation_to_other_processes && (
                  <button
                    onClick={clearCorrelation}
                    style={{
                      background: "transparent",
                      outline: "none",
                      border: "none",
                      color: "var(--mainColor)",
                      padding: "0 10px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    x
                  </button>
                )}
                <DropDown
                  list={processes.filter(
                    (proc) => proc.id !== mapa?.process?.id
                  )}
                  onChange={handleSelectCorellationProcess}
                  selectedValue={mapa?.correlation_to_other_processes || ""}
                  disabled={!mapa.adding && !mapa.edit}
                />
              </CenterDropdownWrapper>
            </div>
            <div
              style={{
                border:
                  mapa.edit || mapa.adding
                    ? "2px solid var(--mainColor)"
                    : "none",
                marginBottom: "20px",
              }}
            >
              <CenterDropdownWrapper
                title="RESURSI ZA OSTVARIVANJE POSLOVNOG PROCESA"
                wrapperStyle={{ marginBottom: "0" }}
              >
                <DynamicTextarea
                  onChange={handleChange("resources")}
                  value={mapa.resources ? mapa.resources : ""}
                  readOnly={!mapa.adding && !mapa.edit}
                />
              </CenterDropdownWrapper>
            </div>

            {mapa && (
              <MapeProcesaAktivnostiList
                handleAddNewActivitiItem={handleAddNewActivitiItem}
                activitiesList={mapa.activities}
                handleChangeActivitiName={handleChangeActivitiName}
                handleChangeActivitiDescription={
                  handleChangeActivitiDescription
                }
                newActivity={newActivity}
                handleChangeNewActivity={handleChangeNewActivity}
                handleSaveNewActivity={handleSaveNewActivity}
                // handleDeleteNewActivity={handleDeleteNewActivity}
                handleSetEditActivity={handleSetEditActivity}
                handleSaveActivity={handleSaveActivity}
                handleChangeActivity={handleChangeActivity}
                newActivityValidationErrors={newActivityValidationErrors}
                mapa={mapa}
                handleUpdateActivity={handleUpdateActivity}
                setActivityToDelete={setActivityToDelete}
                handleDeleteNewActivity={handleDeleteNewActivity}
              />
            )}

            {mapaValidationErrors &&
              Object.keys(mapaValidationErrors).length > 0 && (
                <div className="errormessage">Popunite obavezna polja</div>
              )}

            <button
              className="add-poslovni-proces-btn"
              onClick={handleSubmitMapa}
              style={{ marginBottom: "20px" }}
            >
              <img alt="" src="/images/save.png" />
              <span>Sačuvajte mapu procesa</span>
            </button>
            <div
              style={{
                marginTop: "40px",
              }}
            >
              <div
                style={{
                  border: "none",
                  marginBottom: "20px",
                }}
              >
                <CenterDropdownWrapper
                  title="ŠIFRE I NAZIV PROCEDURA"
                  wrapperStyle={{ margin: "0", borderBottom: "none" }}
                >
                  {mapa.procedures && (
                    <ProcedureList
                      proceduresList={mapa.procedures}
                      handleSetActiveProcedure={setActiveProcedure}
                      activeProcedure={activeProcedure}
                      handleSetEditProcedure={handleSetEditProcedure}
                      handleChangeProcedureName={handleChangeProcedureName}
                      handleSaveProcedure={handleSaveProcedure}
                      handleUpdateProcedure={handleUpdateProcedure}
                      setProcedureToDelete={setProcedureToDelete}
                    />
                  )}
                </CenterDropdownWrapper>

                {newProcedure && (
                  <NewProcedura
                    handleChangeNewProceduraName={handleChangeNewProceduraName}
                    name={newProcedure?.name}
                    handleSaveNewProcedure={handleSaveNewProcedure}
                    code={newProcedure?.code}
                    handleDeleteNewProcedure={handleDeleteNewProcedure}
                    newProcedureValidationErrors={newProcedureValidationErrors}
                    setProcedureToDelete={setProcedureToDelete}
                    newProcedure={newProcedure}
                  />
                )}
              </div>
              <button
                // disabled={!mapaList?.[mapa.id] || newProcedure}
                onClick={handleAddNewProcedure}
                className="add-button"
              >
                <span style={{ marginRight: "5px" }}>+</span>
                Dodajte proceduru
              </button>
            </div>

            <div
              style={{
                border: "none",
                margin: "20px 0",
              }}
            >
              <PoslovniProcessOwnerTable />
            </div>

            {mapa.procedures && activeProcedure && (
              <>
                <div
                  style={{
                    width: "70%",
                    color: "var(--mainColor)",
                    textAlign: "center",
                    margin: "50px auto 30px auto",
                  }}
                >
                  DOKUMENTACIJA O SISTEMU (VERTIKALNI PREGLED) - PROCEDURE -
                  <span
                    style={{
                      marginLeft: "5px",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    {activeProcedure?.name || ""}
                  </span>
                </div>
                <MapeDokumentacija
                  list={activeProcedure?.activities}
                  designations={designationsList}
                  handleChangeResponsibleDesignations={
                    handleChangeResponsibleDesignations
                  }
                  handleSelectDueDate={handleSelectDueDate}
                  handleChangeDynamicDescription={
                    handleChangeDynamicDescription
                  }
                  handleChangeDocumentPath={handleChangeDocumentPath}
                  handleSetEditProcedureRow={handleSetEditProcedureRow}
                  handleUpdateProcedureRow={handleUpdateProcedureRow}
                  handleChangeProcedureRow={handleChangeProcedureRow}
                  handleSelectProceduraRowResponsibles={
                    handleSelectProceduraRowResponsibles
                  }
                  handleChangeProcedureRowDueDate={
                    handleChangeProcedureRowDueDate
                  }
                  handleChangeNewMapaDocument={handleChangeNewMapaDocument}
                  handleAddNewMapaDocumentList={handleAddNewMapaDocumentList}
                  handleRejectMapaDukument={handleRejectMapaDukument}
                  setActivityToDelete={setActivityToDelete}
                />
                {newProcedureRow && (
                  <NewProcedureRow
                    name={newProcedureRow?.name}
                    designations={designationsList}
                    description={newProcedureRow?.description}
                    handleChangeNewProcedureRow={handleChangeNewProcedureRow}
                    handleSelectDueDate={handleSelectDueDate}
                    due_date={newProcedureRow?.due_date}
                    handleSelectNewProcedureResponsibleDesignations={
                      handleSelectNewProcedureResponsibleDesignations
                    }
                    responsibles={newProcedureRow?.activities_designations}
                    handleAddLink={handleAddLink}
                    handleChangeLink={handleChangeLink}
                    links={newProcedureRow?.documents}
                    handleSaveNewProcedureRow={handleSaveNewProcedureRow}
                    handleDeleteNewProcedureRow={handleDeleteNewProcedureRow}
                    newProcedureRowValidationsError={
                      newProcedureRowValidationsError
                    }
                    handleAddNewMapaDocumentList={handleAddNewMapaDocumentList}
                    handleRejectMapaDukument={handleRejectMapaDukument}
                    handleChangeNewMapaDocument={handleChangeNewMapaDocument}
                    handleSaveNewMapaDocument={handleSaveNewMapaDocument}
                    setActivityToDelete={setActivityToDelete}
                    newProcedureRow={newProcedureRow}
                  />
                )}
                <button
                  style={{ padding: "20px 0" }}
                  className={
                    newProcedureRow
                      ? "add-button add-button-disabled"
                      : "add-button"
                  }
                  disabled={newProcedureRow}
                  onClick={handleAddNewProcedureRow}
                >
                  <span style={{ marginRight: "5px" }}>+</span>
                  Dodajte red
                </button>
              </>
            )}
          </div>
        )}
        {mapaToDelete && (
          <div className="loading">
            <DeleteMapaPopup
              setItemToDelete={setMapaToDelete}
              loading={loading}
              handleItemDelete={HandleDeleteMapa}
            />
          </div>
        )}
        {activityToDelete && (
          <div className="loading">
            <DeleteMapaPopup
              setItemToDelete={setActivityToDelete}
              loading={loading}
              handleItemDelete={handleDeleteActivity}
            />
          </div>
        )}
        {procedureToDelete && (
          <div className="loading">
            <DeleteMapaPopup
              setItemToDelete={setProcedureToDelete}
              loading={loading}
              handleItemDelete={handleDeleteProcedure}
            />
          </div>
        )}
        <Loading isLoading={loading} />
      </div>
    </>
  );
}
