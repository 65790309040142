import React, { useState, useEffect, useRef, useContext } from "react";
import { baseRequest, deleteUrl } from "../../../axiosconfig";
import DropDown from "../../elements/dropDown";
import AdminFormulariRizikaList from "../adminFormulariRizikaList";
import { formatDesignations, formatProcesses } from "../../mapeProcesa";
import MapeProcesaList from "../mapeProcesaList";
import Loading from "../../loading";
import { useNavigate } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import MapaProcesaListPrint from "./mapaListPrint";
import { UserContext } from "../../../userContext";
import axios from "axios";
import DeleteMapaPopup from "../../deleteMapaPopup";

function AdminMapeProcesa(props) {
  const orgUnitsDropdownstyle = {
    borderRight: "1px solid var(--mainColor)",
    width: "50%",
  };
  const processesDropdownStyle = {
    borderRight: "1px solid var(--mainColor)",
    width: "30%",
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const [organizationUnits, setOrganizationUnits] = useState([]);
  const [activeUnit, setActiveUnit] = useState({ id: "all", name: "ALL" });
  const [activeProcess, setActiveProcess] = useState({
    id: "all",
    name: "ALL",
  });
  const [designations, setDesignations] = useState();
  const [designationsList, setDesignationsList] = useState();
  const [processes, setProcesses] = useState();
  const [mapaList, setMapaList] = useState();
  const [mapa, setMapa] = useState();
  const [designationsCopy, setDesignationsCopy] = useState();
  const [enableEdit, setEnableEdit] = useState(false);
  const [filteredMaps, setFilteredMaps] = useState();
  const [selectedProcess, setSelectedProcess] = useState();
  const [loading, setLoading] = useState(false);
  const [mapaToDelete, setMapaToDelete] = useState();

  const handleSelectUnit = ({ value }) => {
    setActiveUnit(value);
    setActiveProcess({ id: "all", name: "ALL" });
  };

  const calculateProcess = (id, list) => {
    const process = list.find((proc) => {
      return proc.id === id && !proc.del;
    });

    return process;
  };

  const navigate = useNavigate();

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { user } = useContext(UserContext);

  useEffect(() => {
    setLoading(true);
    const urls = [
      "/organization_unit/0",
      "/employee/id/0/level/0",
      "/designations/0/0",
      "/business_processes",
      "process_maps",
    ];

    Promise.all(
      urls.map((url) => baseRequest.get(url, { withCredentials: true }))
    )
      .then((res) => {
        const responseObj = {};
        res.forEach((item) => {
          if (item.data.data) {
            if (item.data.data[0].first_name) {
              responseObj["designations"] = formatDesignations(item.data.data);
            } else {
              responseObj["organization_units"] = item.data.data;
            }
          } else if (item.data.business_processes) {
            console.log(item.data.business_processes);
            responseObj["business_processes"] = item.data.business_processes;
          } else if (item.data.designations) {
            responseObj["designationsList"] = item.data.designations;
          } else if (item.data.process_maps) {
            responseObj["process_maps"] = item.data.process_maps;
          }
        });

        setOrganizationUnits(
          responseObj["business_processes"].filter((el) => !el.del)
        );
        setDesignations(responseObj["designations"]);

        setProcesses(formatProcesses(responseObj["business_processes"]));
        setDesignationsList(responseObj["designationsList"]);

        const actualMapas = responseObj["process_maps"].filter(
          (mapa) => mapa.business_process_list_id !== null && !mapa.del
        );

        const mapasWithProcess = actualMapas
          .map((mapa) => {
            const process = calculateProcess(
              mapa.business_process_list_id,
              formatProcesses(responseObj["business_processes"])
            );
            if (process) {
              return {
                ...mapa,
                process: process,
              };
            } else {
              return undefined;
            }
          })
          .filter((item) => item);

        setMapaList(mapasWithProcess);

        const firstMapa = mapasWithProcess[0];
        setMapa(firstMapa);

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const addNewMapa = () => {
    setLoading(true);
    baseRequest
      .post("/process_map", {}, { withCredentials: true })
      .then((res) => {
        setLoading(false);

        // const newMapa = { ...res.data.data, adding: true };
        // setMapa(newMapa);
        navigate(`/admin/mape-procesa/${res.data.data.id}`);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const filterProcesses = (unit, proc) => {
    const filteredProcesses = proc.filter((proc) => {
      return proc.business_process_id === unit.id;
    });

    return filteredProcesses;
  };

  const filterMaps = (list, unit, process) => {
    const filteredMapsByUnit =
      unit.name === "ALL"
        ? list
        : list.filter(
            (process) =>
              process.business_process_list.business_process_id === unit.id
          );
    if (process) {
      const filteredMapsByProcesses =
        process.name === "ALL"
          ? filteredMapsByUnit
          : filteredMapsByUnit.filter(
              (pr) => pr.business_process_list_id === process.id
            );
      return filteredMapsByProcesses;
    }

    return filteredMapsByUnit;
  };

  const selectProcess = ({ value }) => {
    setActiveProcess(value);
  };

  const canAddMapa = (user, mapas, processList) => {
    if (user.role_id === 1) {
      if (!processList.length) {
        return false;
      } else if (!mapas.length && processList.length) {
        return true;
      } else if (mapas.length && processList.length) {
        const result = processList.filter((process) => {
          const matchedMapa = mapas.find((mapa) => {
            return (
              mapa.process.business_process_id ===
                process.business_process_id && mapa.process.id === process.id
            );
          });
          return matchedMapa;
        });
        let difference = processList.filter(
          (process) => !result.find((item) => item.id === process.id)
        );
        if (difference.length) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      const userMapas = mapas.filter(
        (el) =>
          el.process.organization_unit_id === user.organization_unit_id &&
          el.process.level === user.level
      );

      const userProcesses = processList.filter(
        (proc) =>
          proc.organization_unit_id === user.organization_unit_id &&
          proc.level === user.level
      );

      if (!userProcesses.length) {
        return false;
      } else if (!userMapas.length && userProcesses.length) {
        return true;
      } else if (userMapas.length && userProcesses.length) {
        const result = userProcesses.filter((process) => {
          const matchedMapa = userMapas.find((mapa) => {
            return mapa.process.id === process.id;
          });
          return matchedMapa;
        });
        let difference = userProcesses.filter(
          (process) => !result.find((item) => item.id === process.id)
        );

        if (difference.length) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const HandleDeleteMapa = () => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}process_map`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: { id: mapaToDelete.id },
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        setMapaToDelete(null);
        setMapaList((list) => {
          const newMapas = list.filter((mapa) => mapa.id !== res.data.data.id);
          return newMapas;
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div>
      <div className="processes-header">
        <DropDown
          dropDownStyle={orgUnitsDropdownstyle}
          height={"49px"}
          list={organizationUnits}
          onChange={handleSelectUnit}
          selectedValue={activeUnit?.name}
          isFilter={true}
        />
        <DropDown
          dropDownStyle={processesDropdownStyle}
          height={"49px"}
          list={
            processes && activeUnit
              ? filterProcesses(activeUnit, processes)
              : []
          }
          onChange={selectProcess}
          selectedValue={activeProcess?.name}
          isFilter={true}
        />

        {/* <div className="sort-options-dropdown" style={{ flex: 1 }}>
          <div
            className="sort-options-dropdown-selected"
            onClick={() => {
              setShowDropdown((state) => !state);
            }}
          >
            <span style={{ marginRight: "7px", paddingLeft: "10px" }}>
              Opcije
            </span>

            <img
              alt=""
              src="/images/sidebararrow.png"
              className="sidebar-navigation-item-arrow"
            ></img>
          </div>
          {showDropdown && (
            <ul
              className="sort-options-dropdown-options"
              style={{ left: "0", zIndex: "100", flex: 1 }}
            >
              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setShowDropdown(false);
                }}
              >
                Izmenite
              </li>

              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  handlePrint();
                }}
              >
                Odštampaj
              </li>
            </ul>
          )}
        </div> */}
        <div style={{ marginRight: "10px", marginLeft: "10px" }}>
          {user && mapaList && (
            <button className="add-btn-large" onClick={handlePrint}>
              <img alt="" src="/images/printer.png"></img>
            </button>
          )}
        </div>
        {user &&
          mapaList &&
          processes &&
          canAddMapa(user, mapaList, processes) && (
            <div>
              <button className="add-btn-large" onClick={addNewMapa}>
                <img alt="" src="/images/plus.png"></img>
              </button>
            </div>
          )}
      </div>
      {mapaList && (
        <MapeProcesaList
          activeUnit={activeUnit}
          list={filterMaps(mapaList, activeUnit, activeProcess)}
          setMapaToDelete={setMapaToDelete}
        />
      )}

      {mapaList && (
        <div style={{ display: "none" }}>
          <MapaProcesaListPrint
            ref={componentRef}
            list={filterMaps(mapaList, activeUnit, activeProcess)}
          />
        </div>
      )}
      {mapaToDelete && (
        <div className="loading">
          <DeleteMapaPopup
            setItemToDelete={setMapaToDelete}
            loading={loading}
            handleItemDelete={HandleDeleteMapa}
          />
        </div>
      )}

      <Loading isLoading={loading} />
    </div>
  );
}

export default AdminMapeProcesa;
