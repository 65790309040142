import React from "react";

import "./procedurelist.css";
import DynamicTextarea from "../elements/DynamicTextarea";

export default function ProcedureList({
  proceduresList = [],
  handleSetActiveProcedure,
  activeProcedure,
  handleSetEditProcedure,
  handleChangeProcedureName,
  handleSaveProcedure,
  handleUpdateProcedure,
  setProcedureToDelete,
  mapa,
  processes,
}) {
  const handleProcedureClick = (procedure) => {
    handleSetActiveProcedure(procedure);
  };

  const calculateProcedureCode = (pr, mapa) => {
    const index = pr
      .filter(
        (proc) =>
          proc.business_process_id ===
          mapa.business_process_list.business_process_id
      )
      .findIndex(
        (el) =>
          el.business_process_id ===
          mapa.business_process_list.business_process_id
      );
    const procedureIndex = mapa.procedures.length
      ? mapa.procedures.length + 1
      : 1;
    return `${mapa.business_process_list.code}-${index + 1}-${procedureIndex}`;
  };

  return (
    <ul style={{ borderBottom: "var(--mainBorder)" }}>
      {proceduresList?.length &&
        proceduresList.map((item, index) => {
          return (
            <li
              key={item.id}
              style={{
                display: "flex",
                width: "100%",
              }}
              className="procedure-list-item"
            >
              <div
                style={{
                  borderRight: "1px solid var(--mainColor)",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px",
                  color: "var(--mainColor)",
                  width: "100px",
                  minWidth: "100px",
                }}
              >
                {item.code}
              </div>
              {item.editing && (
                <DynamicTextarea
                  readOnly={!item.editing}
                  style={{
                    paddingTop: "24px",
                    borderRight: "1px solid var(--mainColor)",
                  }}
                  onChange={(e) => handleChangeProcedureName(e, item.id)}
                  value={item.name}
                />
              )}
              {!item.editing && (
                <div
                  className="procedure-item-value"
                  onClick={() => handleProcedureClick(item)}
                >
                  <span
                    className={
                      activeProcedure && activeProcedure.id === item.id
                        ? "procedure-item-value-link-active"
                        : "procedure-item-value-link"
                    }
                  >
                    {item.name}
                  </span>
                </div>
              )}
              <div style={{ width: "10%" }}>
                <div className="options-block">
                  <button
                    type="button"
                    className="employee-item-options-button"
                    onClick={() => {
                      item.editing
                        ? handleUpdateProcedure(item)
                        : handleSetEditProcedure(item);
                    }}
                  >
                    <img
                      src={
                        item.editing ? "/images/check.png" : "/images/edit.png"
                      }
                      alt="im"
                    />
                  </button>
                  <button
                    type="button"
                    className="employee-item-options-button"
                    onClick={() => {
                      setProcedureToDelete(item);
                    }}
                  >
                    <img src="/images/trash.png" />
                  </button>
                </div>
              </div>
            </li>
          );
        })}
    </ul>
  );
}
