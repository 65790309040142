import React from "react";
import DynamicTextarea from "../elements/DynamicTextarea";

function NewProcedura({
  handleChangeNewProceduraName,
  name,
  handleSaveNewProcedure,
  code,
  handleDeleteNewProcedure,
  newProcedureValidationErrors,
  setProcedureToDelete,
  newProcedure,
  processes,
  mapa,
}) {
  return (
    <div className="new-procedure-list-item">
      <div
        style={{
          borderRight: "1px solid var(--mainColor)",
          display: "flex",
          alignItems: "center",
          padding: "5px",
          color: "var(--mainColor)",
          width: "100px",
          minWidth: "100px",
        }}
      >
        {code}
      </div>
      <DynamicTextarea
        style={{
          paddingTop: "24px",
          borderRight: "1px solid var(--mainColor)",
        }}
        onChange={handleChangeNewProceduraName}
        //   handleChangeActivitiName={handleChangeActivitiName(index)}
        //   handleChangeActivitiDescription={handleChangeActivitiDescription(
        //     index
        //   )}
        value={name}
        hasErrors={newProcedureValidationErrors}
      />
      <div style={{ width: "10%" }}>
        <div className="options-block">
          <button
            type="button"
            className="employee-item-options-button"
            onClick={handleSaveNewProcedure}
          >
            <img src="/images/check.png" alt="im" />
          </button>
          <button
            type="button"
            className="employee-item-options-button"
            onClick={() => {
              setProcedureToDelete(newProcedure);
            }}
          >
            <img src="/images/trash.png" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewProcedura;
