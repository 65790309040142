import React, { useContext } from "react";
import "./adminformularilistitem.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../userContext";

function AdminFormulariListItem({ item, setFormularToDelete }) {
  const navigate = useNavigate();

  const { user } = useContext(UserContext);

  const handleShowFormular = (id) => {
    navigate(`/admin/formulari-rizika/${id}`);
  };

  const hasEditPermission = (user, org_unit, level) => {
    if (user.role_id === 1) {
      return true;
    } else if (
      user.role_id > 1 &&
      user.organization_unit_id === org_unit &&
      user.level === level
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ul className="admin-formulari-list-item">
      <li className="admin-formulari-list-item-cell" style={{ width: "10%" }}>
        {item?.business_process_list?.code}
      </li>
      <li className="admin-formulari-list-item-cell" style={{ width: "20%" }}>
        {item?.business_process_list?.name}
      </li>
      <li
        className="admin-formulari-list-item-cell"
        style={{
          width: "15%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        {item?.business_process_list?.business_process_owners?.map((owner) => {
          return (
            <div key={owner.id}>
              <span>
                {owner.first_name} <span>{owner.last_name}</span>
              </span>
            </div>
          );
        })}
      </li>
      <li className="admin-formulari-list-item-cell" style={{ width: "20%" }}>
        {item?.name}
      </li>
      <li
        className="admin-formulari-list-item-cell"
        style={{ width: "10%" }}
      ></li>
      <li className="admin-formulari-list-item-cell" style={{ width: "12%" }}>
        {item.updated_at.split("T")[0]}
      </li>
      <li className="options-buttons-block" style={{ flex: "1" }}>
        <button
          className="options-buttons-block-button"
          onClick={() => handleShowFormular(item.id)}
        >
          <img src="/images/showpassword.png" />
        </button>

        {hasEditPermission(user, item.organization_unit_id, item.level) && (
          <button
            className="options-buttons-block-button"
            onClick={() => setFormularToDelete(item)}
          >
            <img src="/images/trash.png" />
          </button>
        )}
      </li>
    </ul>
  );
}

export default AdminFormulariListItem;
