import React, { useState, useEffect } from "react";
import "./autorizacija.css";
import AuthorizationEmployeeItem from "../authorizationEmployeeItem";
import NewUser from "../newUser";
import { baseRequest, deleteUrl } from "../../../axiosconfig";
import Loading from "../../loading";
import DropDown from "../../elements/dropDown";
import axios from "axios";

function Autorizacija(props) {
  const employeesListTitles = [
    { id: 1, title: "Organizaciona jedinica*", width: "20%" },
    { id: 2, title: "Zaposleni*", width: "20%" },

    { id: 3, title: "Rola*", width: "19%" },
    { id: 4, title: "Imejl adresa", width: "18%" },
    { id: 5, title: "Lozinka", width: "18%" },
  ];

  const sortOptions = [
    { id: 1, name: "Svi" },
    { id: 2, name: "A -Z" },
    { id: 3, name: "Z - A" },
  ];

  const [usersList, setUsersList] = useState();
  const [newUser, setNewUser] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSortDropdown, setShowSortDropdown] = useState(false);
  const [users, setUsers] = useState(null);

  const [loading, setLoading] = useState(false);
  const [designations, setDesignations] = useState();
  const [organization_units, setOrganizationUnits] = useState();
  const [sortOption, setSortOption] = useState({ id: 1, name: "Svi" });
  const [roles, setRoles] = useState();
  const [userValidationErrors, setUserValidationErrors] = useState();

  function formatDesignations(list) {
    return list.map((item) => {
      return {
        id: item.id,
        del: item.del,
        level: item.level,
        parent_id: item.parent_id,
        name: item.designation
          ? `${item.first_name} ${item.last_name} - ${item.designation.name}`
          : `${item.first_name} ${item.last_name} - ${item.designation_name}`,
        designation_name: item.designation
          ? item.designation.name
          : item.designation_name,
      };
    });
  }

  const filterActualUnits = (list) => {
    return list.filter((unit) => {
      return !unit.del && unit.has_designations === true;
    });
  };

  useEffect(() => {
    setLoading(true);
    const urls = [
      "/employee/id/0/level/0",
      "/organization_unit/0",
      "/roles",
      "/users",
    ];
    Promise.all(
      urls.map((url) => baseRequest.get(url, { withCredentials: true }))
    )
      .then((res) => {
        setLoading(false);

        const responseObj = {};
        res.forEach((item) => {
          if (item.data.data) {
            if (item.data.data[0]?.first_name) {
              responseObj["designations"] = formatDesignations(
                item.data.data
              ).filter((des) => !des.del);
            } else {
              responseObj["organization_units"] = filterActualUnits(
                item.data.data
              );
            }
          } else if (item.data.roles) {
            responseObj["roles"] = item.data.roles;
          } else if (item.data.users) {
            responseObj["users"] = item.data.users.filter(
              (user) => user.employee_id
            );
          }
        });

        setRoles(responseObj["roles"]);
        setOrganizationUnits(responseObj["organization_units"]);
        setDesignations(responseObj["designations"]);
        setUsersList(responseObj["users"]);
        setUsersList(
          responseObj["users"].map((user) => {
            const des = responseObj["designations"].find(
              (worker) => worker.id === user.employee_id
            );

            const unit = responseObj["organization_units"].find(
              (un) => un.id === des.parent_id && un.level === des.level
            );
            return {
              ...user,
              designation: des,
              unit: unit,
              filteredDesignations: responseObj["designations"].filter(
                (el) => el.parent_id === unit.id && el.level === unit.level
              ),
            };
          })
        );
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const handleAddNewUser = () => {
    setNewUser({ adding: true });
  };

  const handleSelectOrganizationUnit = ({ value }) => {
    if (userValidationErrors) {
      const { organization_unit_id, ...rest } = userValidationErrors;
      setUserValidationErrors(rest);
    }
    setNewUser((newUser) => {
      return { ...newUser, organization_unit: value, designation: null };
    });
  };

  const handleSelectDesignation = ({ value }) => {
    if (userValidationErrors) {
      const { employee_id, ...rest } = userValidationErrors;
      setUserValidationErrors(rest);
    }
    setNewUser((newUser) => {
      return { ...newUser, designation: value };
    });
  };

  const handleSelectRole = ({ value }) => {
    if (userValidationErrors) {
      const { role_id, ...rest } = userValidationErrors;
      setUserValidationErrors(rest);
    }
    setNewUser((newUser) => {
      return { ...newUser, role: value };
    });
  };

  const handleChangeRoleInput = (e, key) => {
    if (userValidationErrors) {
      const { [key]: something, ...rest } = userValidationErrors;
      setUserValidationErrors(rest);
    }
    setNewUser((newUser) => {
      return { ...newUser, [key]: e.target.value.trimStart() };
    });
  };

  //saving new user

  const handleSaveNewUser = (user) => {
    const payLoad = {
      level: user?.organization_unit?.level,
      organization_unit_id: user?.organization_unit?.id,
      employee_id: user?.designation?.id,
      email: user?.email,
      password: user?.password,
      role_id: user?.role?.id,
    };

    if (validateUser(payLoad)) {
      return;
    }

    setLoading(true);
    baseRequest
      .post("/user", payLoad, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        const des = designations.find(
          (worker) => worker.id === res.data.data.employee_id
        );

        const unit = organization_units.find(
          (un) => un.id === des.parent_id && un.level === des.level
        );

        const filteredDesignations = designations.filter(
          (el) => el.parent_id === unit.id && el.level === unit.level
        );
        setUsersList((list) => {
          return [
            ...list,
            { ...res.data.data, unit, designation: des, filteredDesignations },
          ];
        });
        setNewUser(null);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.message) {
          setUserValidationErrors((errors) => {
            return { ...errors, ...error.response.data.errors };
          });
          // setUserValidationErrors((errors) => {
          //   if (errors && errors.length) {
          //     const newErrors = errors.map((er) => {
          //       if (typeof er !== "string") {
          //         return { ...er, message: "Email postoji" };
          //       } else {
          //         return er;
          //       }
          //     });
          //     return newErrors;
          //   } else {
          //     return [{ message: "Email postoji" }];
          //   }
          // });
        }
        console.log(error);
      });
  };

  //delete new user

  const handleDeleteNewUser = () => {
    setNewUser(null);
    setUserValidationErrors(null);
  };

  const filterDesignations = (list, id, level) => {
    const filteredList = list.filter(
      (des) => (des.parent_id === id) & (des.level === level)
    );

    const filteredListWithDisabled = filteredList.map((worker) => {
      const match = usersList.find((us) => us.employee_id === worker.id);
      if (match) {
        return { ...worker, isDisabled: true };
      }
      return worker;
    });

    if (usersList) {
      return filteredListWithDisabled;
    } else {
      return filteredList;
    }
  };

  const handleSetEditUser = (id) => {
    setUsersList((list) => {
      const newUsers = list.map((user) => {
        if (user.id === id) {
          return { ...user, editing: true };
        }
        return user;
      });
      return newUsers;
    });
  };

  const handleSelectUserUnit = (id) => {
    return function (unit) {
      setUsersList((list) => {
        const newUsers = list.map((user) => {
          if (user.id === id) {
            return {
              ...user,
              unit: unit.value,
              designation: null,
              filteredDesignations: designations.filter(
                (des) =>
                  des.parent_id === unit.value.id &&
                  des.level === unit.value.level
              ),
            };
          }
          return user;
        });
        return newUsers;
      });
    };
  };

  const handleSelectUserDesignation = (id) => {
    return function (designation) {
      setUsersList((list) => {
        const newUsers = list.map((user) => {
          if (user.id === id) {
            return {
              ...user,

              designation: designation.value,
            };
          }
          return user;
        });
        return newUsers;
      });
    };
  };

  const handleChangeUserRole = (id) => {
    return function (value) {
      setUsersList((list) => {
        const newUsers = list.map((user) => {
          if (user.id === id) {
            return {
              ...user,

              role: value.value,
            };
          }
          return user;
        });
        return newUsers;
      });
    };
  };

  const handleChangeUser = (e, id) => {
    if (userValidationErrors) {
      const { email, ...rest } = userValidationErrors;
      setUserValidationErrors(rest);
    }

    setUsersList((list) => {
      const newUsers = list.map((user) => {
        if (user.id === id) {
          return {
            ...user,

            email: e.target.value.trimStart(),
          };
        }
        return user;
      });
      return newUsers;
    });
  };

  const handleUpdateUser = (payload) => {
    const load = {
      id: payload.id,
      employee_id: payload.designation
        ? payload.designation.id
        : payload.employee_id,
      email: payload.email,
      password: payload.password ? payload.password : "",
      role_id: payload.role ? payload.role.id : payload.role_id,
      organization_unit_id: payload.organization_unit_id,
      level: payload.level,
    };
    if (validateUser(load, true)) {
      return;
    }
    setLoading(true);
    baseRequest
      .put("/user", load, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        setUsersList((list) => {
          const newUsers = list.map((user) => {
            if (user.id === res.data.data.id) {
              const { editing, ...rest } = user;
              return { ...rest, ...res.data.data };
            }
            return user;
          });
          return newUsers;
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response.data.message) {
          setUserValidationErrors((errors) => {
            return { ...errors, ...error.response.data.errors };
          });
        }
      });
  };

  const handleDeleteUser = (user) => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}user`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: user,
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        setUsersList((list) => {
          const newUsers = list.filter((listUser) => {
            return listUser.id !== user.id;
          });
          return newUsers;
        });
        setUserValidationErrors(null);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const validateUser = (user, updating = false) => {
    const { level, ...rest } = user;
    const userErrors = updating
      ? Object.keys(rest).filter((key) => {
          if (key !== "password") {
            return !rest[key];
          }
        })
      : Object.keys(rest).filter((key) => {
          return !rest[key];
        });

    const errors = {};

    if (userErrors.length > 0) {
      userErrors.forEach((er) => {
        errors[er] = "Obavezno polje";
      });
      setUserValidationErrors(errors);
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <div
        className="processes-header"
        style={{ justifyContent: "space-between", height: "51px" }}
      >
        <div className="org-unit-title">Autorizacija</div>
        {/* <div className="sort-options-dropdown" style={{ width: "auto" }}>
          <div style={{ flex: "1" }}>Sortiraj po:</div>
          <DropDown
            dropDownStyle={{ width: "auto" }}
            height={"49px"}
            list={sortOptions}
            selectedValue={sortOption.name}
            containerValue={{ alignItems: "baseline", fontWeight: "bold" }}
            paddingLeft={"5px"}
            menu={{ width: "100px" }}
          />
        </div> */}

        {/* <div className="sort-options-dropdown" style={{ width: "200px" }}>
          <div
            className="sort-options-dropdown-selected"
            onClick={() => {
              setShowDropdown((state) => !state);
            }}
          >
            <span style={{ marginRight: "7px", paddingLeft: "10px" }}>
              Filtriraj po:
            </span>

            <img
              alt=""
              src="/images/sidebararrow.png"
              className="sidebar-navigation-item-arrow"
            ></img>
          </div>

          {showDropdown && (
            <ul
              className="sort-options-dropdown-options"
              style={{ left: "0", zIndex: "100", flex: 1 }}
            >
              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setShowDropdown(false);
                }}
              >
                Svi
              </li>

              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setShowDropdown(false);
                }}
              >
                Rukovodilac
              </li>
              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setShowDropdown(false);
                }}
              >
                Zaposleni
              </li>
            </ul>
          )}
        </div> */}
      </div>
      <div>
        <ul className="authorization-titles-list">
          {employeesListTitles.map((item) => {
            return (
              <li
                key={item.id}
                className=" authorization-titles-list-item"
                style={{ width: item.width }}
              >
                {item.title}
              </li>
            );
          })}
        </ul>
        {usersList && usersList.length > 0 && (
          <ul className="autorization-employees-list">
            {usersList.map((user) => {
              return (
                <AuthorizationEmployeeItem
                  key={user.id}
                  user={user}
                  designations={designations}
                  organization_units={organization_units}
                  roles={roles}
                  handleSetEditUser={handleSetEditUser}
                  handleSelectUserUnit={handleSelectUserUnit}
                  handleSelectUserDesignation={handleSelectUserDesignation}
                  handleChangeUserRole={handleChangeUserRole}
                  handleChangeUser={handleChangeUser}
                  handleUpdateUser={handleUpdateUser}
                  handleDeleteUser={handleDeleteUser}
                  userValidationErrors={userValidationErrors}
                />
              );
            })}
          </ul>
        )}
        <div className="new-user-block">
          {newUser && (
            <NewUser
              organization_units={organization_units}
              designations={filterDesignations(
                designations,
                newUser?.organization_unit?.id,
                newUser?.organization_unit?.level
              )}
              roles={roles}
              handleSelectOrganizationUnit={handleSelectOrganizationUnit}
              handleSelectDesignation={handleSelectDesignation}
              handleSelectRole={handleSelectRole}
              handleChangeRoleInput={handleChangeRoleInput}
              handleSaveNewUser={handleSaveNewUser}
              handleDeleteNewUser={handleDeleteNewUser}
              newUser={newUser}
              userValidationErrors={userValidationErrors}
            />
          )}
          <button
            onClick={handleAddNewUser}
            className={
              designations
                ? "add-new-user-btn"
                : "add-new-user-btn add-new-user-btn-disabled"
            }
            disabled={!designations}
          >
            <span style={{ marginRight: "3px" }}>+</span>
            <span>Dodajte korisnika</span>
          </button>
        </div>
      </div>
      <Loading isLoading={loading} />
    </div>
  );
}

export default Autorizacija;
