import React, { useState, useEffect, useRef } from "react";
import { baseRequest } from "../../../axiosconfig";

import "./registririzika.css";
import DropDown from "../../elements/dropDown";
import Loading from "../../loading";
import { RegistriRizikaList } from "../registriRizikaList";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import RegistriRizikaPrint from "./registriRizikaPrint";

function RegistriRizika() {
  const orgUnitsDropdownstyle = {
    borderRight: "1px solid var(--mainColor)",
    width: "50%",
  };
  const processesDropdownStyle = {
    borderRight: "1px solid var(--mainColor)",
    width: "30%",
  };

  const componentRef = useRef();

  const headerTitles = [
    { id: 0, title: "R.BR", width: "70px" },
    { id: 1, title: "VLASNIK PROCESA", width: "170px" },
    { id: 2, title: "BROJ", width: "70px" },
    { id: 3, title: "FUNKCIJA/VLASNIK RIZIKA", width: "220px" },
    { id: 4, title: "IME RIZIKA", width: "170px" },
    { id: 5, title: "OPIS RIZIKA", width: "370px" },
    { id: 6, title: "GRUPA RIZIKA", width: "170px" },
    { id: 7, title: "VEROVATNOĆA (1-5)", width: "200px" },
    { id: 8, title: "UTICAJ (1-5)", width: "170px" },
    {
      id: 9,
      title: "UKUPNA VREDNOST ",
      subtitle: "(verovatnoća + uticaj)",
      width: "220px",
    },
    { id: 10, title: "NAČIN UPRAVLJANJA RIZIKOM", width: "220px" },
    {
      id: 11,
      title: "OPIS KONTROLA/MERA ZA",
      subtitle: "UPRAVLJANJE RIZICIMA",
      width: "220px",
    },
    {
      id: 12,
      title: "PROCENJENI UTICAJ  ",
      subtitle: "KONTROLA U %",
      width: "220px",
    },
    { id: 13, title: "UTICAJ KONTROLA (0-5)", width: "220px" },
    { id: 14, title: "PREOSTALI RIZIK", width: "220px" },
  ];

  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [organizationUnits, setOrganizationUnits] = useState([]);
  const [riskGroups, setRiskGroups] = useState();
  const [activeUnit, setActiveUnit] = useState({
    name: "ALL",
    id: "all",
  });

  const [filteredProcesses, setFilteredProcesses] = useState();
  const [selectedProcess, setSelectedProcess] = useState({
    name: "ALL",
    id: "all",
  });
  const [riskForms, setRiskForms] = useState();
  const [businessProcessList, setBusinessProcessList] = useState();

  const [filteredRiskForms, setFilteredRiskForms] = useState();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function formatDesignations(list) {
    return list.map((item) => {
      return {
        id: item.id,
        name: item.designation
          ? `${item.first_name} ${item.last_name} - ${item.designation.name}`
          : `${item.first_name} ${item.last_name}`,
        designation_name: item.designation ? item.designation.name : "",
      };
    });
  }

  const formatListToMap = (list) => {
    const map = {};
    list.forEach((item) => {
      map[item.id] = item;
    });
    return map;
  };

  const calculateProcessOwnersNames = (list, des) => {
    if (list.length) {
      if (list[0].label) {
        return list;
      } else {
        const names = list.map((owner) => {
          const worker = des.find((des) => {
            return des.id === owner.employee_id;
          });
          if (worker)
            return {
              value: worker,
              label: worker?.name,
              isDisabled: undefined,
            };
        });

        return names.filter((name) => name);
      }
    }
  };

  const handleSelectUnit = ({ value }) => {
    setActiveUnit(value);
    setSelectedProcess({ id: "all", name: "ALL" });
  };

  const handleSelectProcess = ({ value }) => {
    setSelectedProcess(value);
  };

  useEffect(() => {
    setLoading(true);
    const urls = [
      "/risk_forms",
      "/employee/id/0/level/0",
      "/organization_unit/0",
      "/business_processes",
      "/risk_groups",
    ];
    Promise.all(
      urls.map((url) => baseRequest.get(url, { withCredentials: true }))
    )
      .then((res) => {
        setLoading(false);

        const responseObj = {};
        res.forEach((item) => {
          if (item.data.data) {
            if (item.data.data[0].first_name) {
              responseObj["designations"] = formatDesignations(item.data.data);
            } else {
              responseObj["organization_units"] = item.data.data;
            }
          } else if (item.data.business_processes) {
            responseObj["business_processes"] = item.data.business_processes;
          } else if (item.data.risk_forms) {
            responseObj["risk_forms"] = item.data.risk_forms;
          } else if (item.data.risk_groups) {
            responseObj["risk_groups"] = item.data.risk_groups;
          }
        });
        if (responseObj.risk_forms.length) {
          const actualRiskForms = responseObj.risk_forms.filter(
            (el) => !el.del && el.description
          );
          setRiskForms(actualRiskForms);
          const orgUnitsIds = [
            ...new Set(
              actualRiskForms.map(
                (form) => form.business_process_list.business_process_id
              )
            ),
          ];

          const processesIds = [
            ...new Set(
              actualRiskForms.map((form) => form.business_process_list_id)
            ),
          ];

          const actualUnits = orgUnitsIds.map((id) => {
            return formatListToMap(responseObj["business_processes"])[id];
          });
          const processes = [];
          processesIds.map((id) => {
            actualUnits.forEach((unit) => {
              unit.business_process_lists.forEach((list) => {
                if (list.id === id) {
                  processes.push(list);
                }
              });
            });
          });

          //setRiskForms(actualRiskForms);
          setOrganizationUnits(actualUnits);
          setBusinessProcessList(processes);
          setRiskGroups(
            responseObj["risk_groups"].map((item, index) => {
              return { ...item, id: index + 1 };
            })
          );
        } else {
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const filterProcesses = (unit) => {
    if (unit.name === "ALL") {
      return [];
    }
    return unit.business_process_lists;
  };

  return (
    <div>
      <div
        className="processes-header"
        style={{
          position: "fixed",
          top: "calc(100px + 2em)",
          left: "calc(300px + 5%)",
          right: "5%",
          zIndex: "50",
        }}
      >
        <DropDown
          dropDownStyle={orgUnitsDropdownstyle}
          height={"49px"}
          list={organizationUnits}
          onChange={handleSelectUnit}
          selectedValue={activeUnit?.name}
          isFilter={true}
        />
        <DropDown
          dropDownStyle={processesDropdownStyle}
          height={"49px"}
          list={filterProcesses(activeUnit)}
          onChange={handleSelectProcess}
          selectedValue={selectedProcess?.name}
          isFilter={true}
        />
        <div style={{ marginRight: "10px", marginLeft: "10px" }}>
          {riskForms && (
            <button className="add-btn-large" onClick={handlePrint}>
              <img alt="" src="/images/printer.png"></img>
            </button>
          )}
        </div>
        {/* 
        <div className="sort-options-dropdown" style={{ flex: 1 }}>
          <div
            className="sort-options-dropdown-selected"
            onClick={() => {
              setShowDropdown((state) => !state);
            }}
          >
            <span style={{ marginRight: "7px", paddingLeft: "10px" }}>
              Opcije
            </span>

            <img
              alt=""
              src="/images/sidebararrow.png"
              className="sidebar-navigation-item-arrow"
            ></img>
          </div>
          {showDropdown && (
            <ul
              className="sort-options-dropdown-options"
              style={{ left: "0", zIndex: "100", flex: 1 }}
            >
              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setShowDropdown(false);
                }}
              >
                Izmenite
              </li>

              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  handlePrint();
                }}
              >
                Odštampaj
              </li>
            </ul>
          )}
        </div> */}
      </div>
      <div className="registri-rizika">
        <ul className="registri-header">
          {headerTitles.map((item) => {
            if (!item.subtitle) {
              return (
                <li
                  key={item.id}
                  className="registri-header-cell"
                  style={{ width: item.width ? item.width : "auto" }}
                >
                  {item.title}
                </li>
              );
            } else {
              return (
                <li
                  key={item.id}
                  className="registri-header-cell-subtitle"
                  style={{ width: item.width ? item.width : "auto" }}
                >
                  <span className="registri-header-cell-title">
                    {item.title}
                  </span>
                  <span className="registri-header-cell-subtitle-span">
                    {item.subtitle}
                  </span>
                </li>
              );
            }
          })}
        </ul>
        {riskForms && (
          <RegistriRizikaList
            riskForms={riskForms}
            activeUnit={activeUnit}
            selectedProcess={selectedProcess}
            riskGroups={riskGroups}
          />
        )}
        {riskForms && (
          <div style={{ display: "none" }}>
            <RegistriRizikaPrint
              ref={componentRef}
              riskForms={riskForms}
              activeUnit={activeUnit}
              selectedProcess={selectedProcess}
              riskGroups={riskGroups}
            />
          </div>
        )}
      </div>
      <Loading isLoading={loading} />
    </div>
  );
}

export default RegistriRizika;
