import React from "react";
import AdminMapeProcesa from "../../components/admin/mapeProcesa";
import { Outlet } from "react-router-dom";

function AdminMapeProcesaPage(props) {
  return (
    <div className="admin-page-child-wrapper">
      <AdminMapeProcesa />
    </div>
  );
}

export default AdminMapeProcesaPage;
