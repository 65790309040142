import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseRequest } from "../../axiosconfig";
import Loading from "../loading";

export default function Home({ loading, isLoggedin }) {
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedin) {
      navigate("/admin/struktura-preduzeca");
    }
  }, [isLoggedin]);

  if (loading) {
    return (
      <div className="loading">
        <Loading isLoading={loading} />
      </div>
    );
  } else {
    return null;
  }
}
