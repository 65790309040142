import React, { useEffect, useState, useRef, useContext } from "react";
import { baseRequest, deleteUrl } from "../../../axiosconfig";
import "./businessprocesses.css";
import DropdownWrapper from "../../elements/dropdownWrapper";

import PoslovniProcessTable from "../../poslovniProcessTable";
import PoslovniProcessOwnerTable from "../../poslovniProcesiOwnerTable";

import DropDown from "../../elements/dropDown";
import Loading from "../../loading";
import { useNavigate } from "react-router-dom";
import NewPoslovniProces from "../../newPoslovniProces/indesx";
import axios from "axios";
import NavigatePopup from "../../navigatePopup";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import TestPrintPoslovniProcesi from "../../poslovniProcessTable/testprintprocesi";
import { UserContext } from "../../../userContext";

export const hasProcessPermission = (user, unit_id, level) => {
  if (user.role_id === 1) {
    return true;
  } else if (
    user.role_id > 1 &&
    user.organization_unit_id === unit_id &&
    user.level === level
  ) {
    return true;
  } else {
    return false;
  }
};

export default function AdminBusinessProcesses() {
  const [organization_units, setOrganization_units] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeUnit, setActiveUnit] = useState(null);
  const [activeProcess, setActiveProcess] = useState();
  const [businessProcessList, setBusinessProcessList] = useState();
  const [newUnit, setNewUnit] = useState();
  const [newProcess, setNewProcess] = useState();
  const [errorMessages, setErrorMessages] = useState();
  const [errorObject, setErrorObject] = useState();
  const [showNavigatePopup, setShowNavigatePopup] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filterOrganizationUnits, setFilterOrganizationUnits] = useState();

  const dropdownStyle = { width: "60%", borderRight: "var(--mainBorder)" };

  const navigate = useNavigate();
  const componentRef = useRef();

  const { user } = useContext(UserContext);

  const calculateProcessOwnersNames = (list, des) => {
    if (list.length) {
      if (list[0].label) {
        return list;
      } else {
        const names = list.map((owner) => {
          const worker = des.find((des) => {
            return des.id === owner.employee_id;
          });
          if (worker)
            return {
              value: { ...owner, name: worker.name },
              label: worker?.name,
              isDisabled: undefined,
            };
        });

        return names.filter((name) => name);
      }
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    setLoading(true);
    const urls = [
      "/employee/id/0/level/0",
      "/organization_unit/0",
      "/business_processes",
    ];
    Promise.all(
      urls.map((url) => baseRequest.get(url, { withCredentials: true }))
    )
      .then((res) => {
        setLoading(false);

        const responseObj = {};
        res.forEach((item) => {
          if (item.data.data) {
            if (item.data.data[0].first_name) {
              responseObj["designations"] = formatDesignations(item.data.data);
            } else {
              responseObj["organization_units"] = item.data.data;
            }
          } else {
            responseObj["business_processes"] = item.data.business_processes;
          }
        });
        if (responseObj.business_processes.length) {
          const actualUnits = responseObj.business_processes.filter(
            (el) => !el.del
          );
          setDesignations(responseObj["designations"]);
          setOrganization_units(responseObj["organization_units"]);
          const unit = actualUnits.length ? actualUnits[0] : null;

          if (unit) {
            setActiveUnit({
              ...unit,
              business_process_lists: unit.business_process_lists.map(
                (item) => {
                  return {
                    ...item,
                    business_process_owners: calculateProcessOwnersNames(
                      item.business_process_owners,
                      responseObj["designations"]
                    ),
                  };
                }
              ),
            });
          } else {
            setActiveUnit({});
          }

          setBusinessProcessList(
            formatListToMap(responseObj["business_processes"])
          );
        } else {
          setDesignations(responseObj["designations"]);
          setOrganization_units(responseObj["organization_units"]);

          baseRequest
            .post("/business_process", {}, { withCredentials: true })
            .then((res) => {
              setLoading(false);
              setActiveUnit({
                ...res.data.data,
                business_process_lists: [],
              });
            });
          setBusinessProcessList({});
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const formatListToMap = (list) => {
    const map = {};
    list.forEach((item) => {
      map[item.id] = item;
    });
    return map;
  };

  const handleSetEditPoslovniProcesItem = (payload) => {
    if (activeProcess || newProcess) {
      return;
    }
    setActiveUnit((unit) => {
      const newProcesses = unit.business_process_lists.map((item) => {
        if (payload.id === item.id) {
          return { ...item, editing: true };
        }
        return item;
      });
      return { ...unit, business_process_lists: newProcesses };
    });
    setActiveProcess(
      activeUnit.business_process_lists.find(
        (process) => payload.id === process.id
      )
    );
  };

  const handleChangeActiveProcessName = (e, id) => {
    if (errorObject?.code) {
      setErrorObject((errors) => {
        const { name, ...rest } = errors;
        return rest;
      });
    }
    setActiveUnit((unit) => {
      const newProcesses = unit.business_process_lists.map((item) => {
        if (id === item.id) {
          return { ...item, name: e.target.value.trimStart() };
        }
        return item;
      });
      return { ...unit, business_process_lists: newProcesses };
    });
    setActiveProcess((process) => {
      return { ...process, name: e.target.value.trimStart() };
    });
  };

  const handleCretaeNewProcess = (payload) => {
    if (validateProcess(payload)) {
      return;
    }
    setLoading(true);
    const processOwners = payload.business_process_owners.map((item) => {
      return { employee_id: item.value.id };
    });

    baseRequest
      .post(
        "/business_process_list",
        { ...payload, business_process_owners: processOwners },
        { withCredentials: true }
      )
      .then((res) => {
        setLoading(false);
        setErrorMessages(null);
        setErrorObject(null);
        setActiveUnit((unit) => {
          return {
            ...unit,
            business_process_lists: [
              ...unit.business_process_lists,
              {
                ...res.data.data,
                business_process_owners: calculateProcessOwnersNames(
                  res.data.data.business_process_owners,
                  designations
                ),
              },
            ],
          };
        });

        setBusinessProcessList((list) => {
          return {
            ...list,
            [res.data.data.business_process_id]: {
              ...list[res.data.data.business_process_id],
              business_process_lists: list[res.data.data.business_process_id]
                .business_process_lists
                ? [
                    ...list[res.data.data.business_process_id]
                      .business_process_lists,
                    res.data.data,
                  ]
                : [res.data.data],
            },
          };
        });

        setNewProcess(null);
      });
  };

  function formatDesignations(list) {
    return list.map((item) => {
      return {
        id: item.id,
        parent_id: item.parent_id,
        level: item.level,
        designation_id: item.designation_id,
        name: item.designation
          ? `${item.first_name} ${item.last_name} - ${item.designation.name}`
          : `${item.first_name} ${item.last_name} - ${item.designation_name}`,
        designation_name: item.designation
          ? item.designation.name
          : item.designation_name,
      };
    });
  }

  const handleAddNewUnit = () => {
    setLoading(true);
    baseRequest
      .post("/business_process", {}, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        setNewUnit(res.data.data);
        setActiveProcess(null);
        setNewProcess(null);
        setActiveUnit({ ...res.data.data, business_process_lists: [] });
      });
  };

  const handleSelectUnit = ({ value }) => {
    setLoading(true);
    const body = {
      id: activeUnit.id,
      level: value.level,
      organization_unit_id: value.id,
      manager_employee_id: activeUnit.manager_employee_id
        ? activeUnit.manager_employee_id
        : 0,
    };

    baseRequest
      .put("/business_process", body, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        setActiveUnit((unit) => {
          return {
            ...unit,
            ...res.data.data,
          };
        });

        const acUnit = {
          ...activeUnit,
          ...res.data.data,
        };

        setBusinessProcessList((list) => {
          return { ...list, [acUnit.id]: acUnit };
        });
      });
  };

  const handleSetNewProcess = () => {
    setNewProcess({
      redniBroj: activeUnit?.business_process_lists.filter((el) => !el.del)
        .length
        ? activeUnit?.business_process_lists.filter((el) => !el.del).length + 1
        : 1,
      code: ``,
      name: "",
      business_process_owners: [],
      business_process_id: activeUnit.id,
      adding: true,
    });
  };

  const handleSelectDesignation = ({ value }) => {
    if (
      !Object.keys(activeUnit).length ||
      activeUnit.organization_unit_id === 0
    ) {
      return;
    }

    const payload = { ...activeUnit, manager_employee_id: value.id };
    setLoading(true);

    baseRequest
      .put("business_process", payload, { withCredentials: true })
      .then((res) => {
        setLoading(false);

        setActiveUnit((unit) => {
          return { ...unit, ...res.data.data };
        });
        setBusinessProcessList((list) => {
          return {
            ...list,
            [res.data.data.id]: { ...activeUnit, ...res.data.data },
          };
        });
      });
  };

  const handleChangeNewProcess = (e) => {
    if (errorObject?.name) {
      setErrorObject((errors) => {
        const { name, ...rest } = errors;
        return rest;
      });
    }
    setNewProcess((process) => {
      return { ...process, name: e.target.value.trimStart() };
    });
  };

  const handleSelectNewProcessOwners = (value) => {
    setNewProcess((process) => {
      return { ...process, business_process_owners: value };
    });

    if (value.length && errorObject?.business_process_owners) {
      setErrorObject((errors) => {
        const { business_process_owners, ...rest } = errors;
        return rest;
      });
    }
  };

  const handleSelectProcessOwner = (id) => {
    return function (value) {
      if (value.length && errorObject?.business_process_owners) {
        setErrorObject((errors) => {
          const { business_process_owners, ...rest } = errors;
          return rest;
        });
      }
      setActiveUnit((unit) => {
        const newProcesses = unit.business_process_lists.map((process) => {
          if (process.id === id) {
            return {
              ...process,
              business_process_owners: value,
              editingOwners: true,
            };
          }
          return process;
        });
        return {
          ...unit,
          business_process_lists: newProcesses,
        };
      });
    };
  };

  const handleUpdateProcess = (payload) => {
    setLoading(true);
    if (validateProcess(payload)) {
      setLoading(false);
      return;
    }
    const formattedOwners = payload.business_process_owners.map((owner) => {
      return {
        ...owner.value,
        employee_id: owner.value.employee_id
          ? owner.value.employee_id
          : owner.value.id,
      };
    });

    baseRequest
      .put(
        "/business_process_list",
        { ...payload, business_process_owners: formattedOwners },
        { withCredentials: true }
      )
      .then((res) => {
        setErrorObject(null);
        setErrorMessages(null);
        const newOwners = calculateProcessOwnersNames(
          res.data.data.business_process_owners,
          designations
        );
        setActiveUnit((unit) => {
          return {
            ...unit,
            business_process_lists: unit.business_process_lists.map((pr) => {
              if (pr.id === res.data.data.id) {
                return { ...res.data.data, business_process_owners: newOwners };
              }
              return pr;
            }),
          };
        });
        setBusinessProcessList((list) => {
          return {
            ...list,
            [res.data.data.business_process_id]: {
              ...list[res.data.data.business_process_id],
              business_process_lists: list[
                res.data.data.business_process_id
              ].business_process_lists.map((pr) => {
                if (pr.id === res.data.data.id) {
                  return res.data.data;
                }
                return pr;
              }),
            },
          };
        });
        setActiveProcess(null);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleDeleteNewProcess = () => {
    setErrorMessages(null);
    setErrorObject(null);
    setNewProcess(null);
  };

  const handleDisableOrganizationUnits = (units) => {
    if (businessProcessList) {
      const newUnits = units.map((unit) => {
        const a = Object.values(businessProcessList).find(
          (val) =>
            val.organization_unit_id === unit.id &&
            val.level === unit.level &&
            !val.del
        );
        if (a || hasProcessPermission(user, unit.id, unit.level) === false) {
          return { ...unit, isDisabled: true };
        }
        return unit;
      });

      return newUnits;
    } else {
      return units;
    }
  };

  const allUnitsDisabled = (units) => {
    return handleDisableOrganizationUnits(units).every((un) => un.isDisabled);
  };

  const validateProcess = (process) => {
    if (
      !process.name ||
      !process.business_process_owners.length ||
      !process.code
    ) {
      if (!process.name) {
        setErrorObject((errors) => {
          return { ...errors, name: true };
        });
      }
      if (!process.code) {
        setErrorObject((errors) => {
          return { ...errors, code: true };
        });
      }
      if (!process.business_process_owners.length) {
        setErrorObject((errors) => {
          return { ...errors, business_process_owners: true };
        });
      }
      setErrorMessages("Popunite sva obavezna polja");
      return true;
    }
  };

  const handleDeleteProcess = (id) => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}business_process_list`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: { id: id },
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        setActiveUnit((unit) => {
          const newProcesses = unit.business_process_lists.map((process) => {
            if (process.id === res.data.data.id) {
              return res.data.data;
            }
            return process;
          });
          return { ...unit, business_process_lists: newProcesses };
        });
        setBusinessProcessList((list) => {
          const newProcesses = list[activeUnit.id].business_process_lists.map(
            (process) => {
              if (process.id === res.data.data.id) {
                return res.data.data;
              }
              return process;
            }
          );

          return {
            ...list,
            [activeUnit.id]: {
              ...list[activeUnit.id],
              business_process_lists: newProcesses,
            },
          };
        });
        if (newProcess) {
          const newProcessRednibroj = activeUnit.business_process_lists.filter(
            (proc) => !proc?.del
          ).length
            ? activeUnit.business_process_lists.filter((proc) => !proc?.del)
                .length + 1
            : 1;

          setNewProcess((process) => {
            return { ...process, redniBroj: newProcessRednibroj - 1 };
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleDeleteUnit = (id) => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}business_process`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: { id: id },
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);

        setBusinessProcessList((list) => {
          const { activeUnitsNumber, activeUnits } = findActiveUnits(list);
          if (id === activeUnit.id) {
            if (
              activeUnitsNumber === 1 &&
              activeUnit.organization_unit_id !== 0
            ) {
              setActiveUnit(null);
              setActiveProcess(null);
            } else {
              setActiveUnit(activeUnits[0]);
            }
          }

          return { ...list, [id]: res.data.data };
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleNavigate = () => {
    navigate("/register/formulari-rizika");
  };

  const findActiveUnits = (list) => {
    const activeUnits = Object.values(list).filter((item) => item.del === 0);

    return { activeUnitsNumber: activeUnits.length, activeUnits };
  };

  const handleChangeProcessCode = (e, id) => {
    if (errorObject?.code) {
      setErrorObject((errors) => {
        const { code, ...rest } = errors;
        return rest;
      });
    }
    setActiveUnit((unit) => {
      const newProcesses = unit.business_process_lists.map((item) => {
        if (id === item.id) {
          return { ...item, code: e.target.value.trimStart() };
        }
        return item;
      });
      return { ...unit, business_process_lists: newProcesses };
    });
    setActiveProcess((process) => {
      return { ...process, name: e.target.value.trimStart() };
    });
  };
  const handleChangeNewProcessCode = (e) => {
    if (errorObject?.code) {
      setErrorObject((errors) => {
        const { code, ...rest } = errors;
        return rest;
      });
    }
    setNewProcess((process) => {
      return { ...process, code: e.target.value.trimStart() };
    });
  };

  const filterUnitDesignations = (list, id, level) => {
    return list.filter((item) => {
      return item.parent_id === id && item.level === level;
    });
  };

  const calculateDesignationName = (list, id) => {
    const worker = list.find((item) => item.id === id);
    return worker.name;
  };

  const handleSelectActiveFilterUnit = ({ value }) => {
    const newValue = {
      ...value,
      business_process_lists: value.business_process_lists.map((process) => {
        return {
          ...process,
          business_process_owners: calculateProcessOwnersNames(
            process.business_process_owners,
            designations
          ),
        };
      }),
    };

    setActiveUnit(newValue);
  };

  return (
    <div>
      <div className="processes-header">
        <DropDown
          dropDownStyle={dropdownStyle}
          height={"49px"}
          list={
            businessProcessList
              ? Object.keys(businessProcessList)
                  .map((pr) => {
                    if (
                      businessProcessList[pr].level > -1 &&
                      !businessProcessList[pr].del
                    ) {
                      return businessProcessList[pr];
                    }
                  })
                  .filter((item) => item)
              : []
          }
          onChange={handleSelectActiveFilterUnit}
          selectedValue={activeUnit?.name}
        />
        {/* <div className="sort-options-dropdown" style={{ flex: 1 }}>
          <div
            className="sort-options-dropdown-selected"
            onClick={() => {
              setShowDropdown((state) => !state);
            }}
          >
            <span style={{ marginRight: "7px", paddingLeft: "10px" }}>
              Opcije
            </span>

            <img
              alt=""
              src="/images/sidebararrow.png"
              className="sidebar-navigation-item-arrow"
            ></img>
          </div>
          {showDropdown && (
            <ul
              className="sort-options-dropdown-options"
              style={{ left: "0", zIndex: "100", flex: 1 }}
            >
              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setShowDropdown(false);
                }}
              >
                Izmenite
              </li>

              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  handlePrint();
                }}
              >
                Odštampaj
              </li>
            </ul>
          )}
        </div> */}
        <div style={{ marginRight: "10px", marginLeft: "10px" }}>
          {user && (
            <button className="add-btn-large" onClick={handlePrint}>
              <img alt="" src="/images/printer.png"></img>
            </button>
          )}
        </div>
        <div>
          <button
            className={
              (activeUnit && activeUnit.level === -1) ||
              allUnitsDisabled(organization_units)
                ? "add-btn-large add-btn-large-disabled"
                : " add-btn-large"
            }
            onClick={handleAddNewUnit}
            disabled={
              (activeUnit && activeUnit.level === -1) ||
              allUnitsDisabled(organization_units)
            }
          >
            <img alt="" src="/images/plus.png"></img>
          </button>
        </div>
      </div>

      {activeUnit && activeUnit.id && (
        <div
          className="poslovni-procesi-tables"
          style={{ width: "auto", margin: "20px 0 0 50px" }}
        >
          <DropdownWrapper
            title="organizaciona jedinica"
            wrapperStyle={{ borderBottom: "none" }}
            style={{ borderRight: "1px solid var(--mainColor)" }}
          >
            <DropDown
              list={handleDisableOrganizationUnits(organization_units)}
              onChange={handleSelectUnit}
              selectedValue={activeUnit?.name}
            />
          </DropdownWrapper>
          <DropdownWrapper
            title="Rukovodilac organizacione jedinice"
            style={{ borderRight: "1px solid var(--mainColor)" }}
          >
            <DropDown
              list={filterUnitDesignations(
                designations,
                activeUnit.organization_unit_id,
                activeUnit.level
              )}
              onChange={handleSelectDesignation}
              selectedValue={
                activeUnit?.employee
                  ? calculateDesignationName(
                      designations,
                      activeUnit.employee.id
                    )
                  : ""
              }
            />
          </DropdownWrapper>
          <div>
            <PoslovniProcessTable
              //ref={componentRef}
              designations={filterUnitDesignations(
                designations,
                activeUnit.organization_unit_id,
                activeUnit.level
              )}
              list={activeUnit.business_process_lists}
              handleDeleteProcess={handleDeleteProcess}
              handleSelectProcessOwner={handleSelectProcessOwner}
              handleSetEditPoslovniProcesItem={handleSetEditPoslovniProcesItem}
              handleChangeActiveProcessName={handleChangeActiveProcessName}
              handleUpdateProcess={handleUpdateProcess}
              errorObject={errorObject}
              activeProcess={activeProcess}
              handleChangeProcessCode={handleChangeProcessCode}
              newProcess={newProcess}
              activeUnit={activeUnit}
            />
            {newProcess && (
              <NewPoslovniProces
                process={newProcess}
                designations={designations}
                handleChangeNewProcess={handleChangeNewProcess}
                handleSelectNewProcessOwners={handleSelectNewProcessOwners}
                handleCretaeNewProcess={handleCretaeNewProcess}
                handleDeleteNewProcess={handleDeleteNewProcess}
                errorObject={errorObject}
                handleChangeNewProcessCode={handleChangeNewProcessCode}
              />
            )}
            {hasProcessPermission(
              user,
              activeUnit.organization_unit_id,
              activeUnit.level
            ) && (
              <button
                className={
                  activeUnit.organization_unit_id === 0 ||
                  newProcess ||
                  activeProcess
                    ? "add-poslovni-proces-btn add-poslovni-proces-btn-disabled"
                    : "add-poslovni-proces-btn"
                }
                onClick={handleSetNewProcess}
                disabled={
                  activeUnit.organization_unit_id === 0 ||
                  newProcess ||
                  activeProcess
                }
                style={{ marginBottom: "20px" }}
              >
                <img src="/images/plus.png" />
                <span>Dodajte poslovni proces</span>
              </button>
            )}
          </div>
          <div style={{ paddingBottom: "3em", marginTop: "30px" }}>
            <PoslovniProcessOwnerTable />
            {hasProcessPermission(
              user,
              activeUnit.organization_unit_id,
              activeUnit.level
            ) && (
              <button
                className={
                  !(!activeUnit || !Object.keys(activeUnit).length)
                    ? "add-poslovni-proces-btn"
                    : "add-poslovni-proces-btn add-poslovni-proces-btn-disabled"
                }
                onClick={() => handleDeleteUnit(activeUnit.id)}
                disabled={!activeUnit || !Object.keys(activeUnit).length}
              >
                <img src="/images/trash.png" />
                <span>Izbrisite organizacionu jedinicu</span>
              </button>
            )}
          </div>
          <div style={{ display: "none" }}>
            <TestPrintPoslovniProcesi
              ref={componentRef}
              list={activeUnit.business_process_lists}
              activeUnit={activeUnit}
              designationName={
                activeUnit && activeUnit.employee
                  ? calculateDesignationName(
                      designations,
                      activeUnit.employee.id
                    )
                  : ""
              }
            />
          </div>
        </div>
      )}
      {showNavigatePopup && (
        <>
          <div className="loading"></div>
          <NavigatePopup
            setShowNavigatePopup={setShowNavigatePopup}
            handleNavigate={handleNavigate}
          />
        </>
      )}
      <Loading isLoading={loading} />
    </div>
  );
}
