import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "./App.css";
import Header from "./components/header";
import Startpage from "./components/startpage";
import { baseRequest } from "./axiosconfig";

import AddEmployeesListPage from "./pages/addEmployeesListPage";
import LoginPage from "./pages/loginPage";
import PoslovniProcesiPage from "./pages/poslovniProcesi";
import FormulareRizikaPage from "./pages/formulareRizikaPage";
import ForgottenPasswordPage from "./pages/forgottenPasswordPage";
import Home from "./components/Home";
import MapeProcesaPage from "./pages/mapeProcesaPage";
import Footer from "./components/Footer";
import SuccessRegistrationPage from "./pages/successRegistrationPage";
import NewStrukturaPreduzeca from "./components/newStrucuraPreduzeca";
import Loading from "./components/loading";
import ContactPage from "./pages/contactPage";
import AdminPage from "./pages/adminPage";
import NotificationsPage from "./pages/notificationsPage";
import FukPage from "./pages/fukPage";
import AdminBusinessProcessesPage from "./pages/adminBusinessProcessesPage";
import AdminStrukturaPreduzecaPage from "./pages/adminStrukturaPreduzecaPage";
import AdminAuthorizationPage from "./pages/adminAuthorizationPage";
import AdminFormulariRizikaPage from "./pages/adminFormulariRizikaPage";
import RegistriRizikaPage from "./pages/registriRizikaPage";
import AdminEmployeesPage from "./pages/adminEmployeesPage";
import AdminMapeProcesaPage from "./pages/adminMapeProcesaPage";
import AdminSingeMapaProcesaPage from "./pages/adminSingleMapaProcesaPage";
import { UnsavedDataContext } from "./context";
import AdminNewMapaProcesaPage from "./pages/adminNewMapaProcesaPage";
import RegisterStrukturaPreduzeca from "./components/registerStrukturaPreduzeca";
import AdminFormulariRizikaSinglePage from "./pages/adminFormulariRizikaSinglePage";
import AdminNewFormularRizikaPage from "./pages/adminNewFormularRizikaPage";
import { useContext } from "react";
import UnsavedDataPopup from "./components/admin/unsavedDataPopup";
import { UserContext } from "./userContext";
import ResetPasswordPage from "./pages/resetPasswordPage";

function App() {
  const [loading, setLoading] = useState(false);
  const [isLoggedin, setIsloggedin] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const { unsavedData, showNavigatePopup, setShowNavigatePopup, navigateUrl } =
    useContext(UnsavedDataContext);

  const { setUser } = useContext(UserContext);

  useEffect(() => {
    setLoading(true);
    if (
      location.pathname.includes("/password-reset") &&
      location.search.includes("email")
    ) {
      setLoading(false);
      navigate(location.pathname + location.search);
      return;
    }
    baseRequest
      .post("/login", {}, { withCredentials: true })
      .then((res) => {
        baseRequest.get("/user", { withCredentials: true }).then((res) => {
          setUser(res.data.data);
          setIsloggedin(true);
          setLoading(false);
          if (location.pathname === "/") {
            navigate("/admin/struktura-preduzeca");
          } else {
            navigate(location.pathname);
          }
        });
      })
      .catch((err) => {
        navigate("/login");
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <div className="main-container">
      <Header
        isLoggedin={isLoggedin}
        setIsloggedin={setIsloggedin}
        setLoading={setLoading}
      />

      <Routes>
        <Route
          path="/"
          element={<Home loading={loading} isLoggedin={isLoggedin} />}
        />
        <Route
          path="/register"
          element={
            <Startpage isLoggedin={isLoggedin} setIsloggedin={setIsloggedin} />
          }
        ></Route>
        <Route
          path="/login"
          element={
            <LoginPage isLoggedin={isLoggedin} setIsloggedin={setIsloggedin} />
          }
        ></Route>
        <Route
          path="/forgotten-password"
          element={<ForgottenPasswordPage />}
        ></Route>
        <Route
          path="/password-reset/:token"
          element={<ResetPasswordPage />}
        ></Route>
        <Route
          path="/register/struktura-preduzeca"
          element={<NewStrukturaPreduzeca />}
        />
        <Route
          path="/register/spisak-zaposlenih"
          element={<AddEmployeesListPage />}
        />
        <Route
          path="/register/poslovni-procesi"
          element={<PoslovniProcesiPage />}
        />
        <Route
          path="/register/formulari-rizika"
          element={<FormulareRizikaPage />}
        />
        <Route
          path="/register/formulari-rizika/:id"
          element={<FormulareRizikaPage />}
        />

        <Route path="/register/mape-procesa" element={<MapeProcesaPage />} />
        <Route
          path="/register/mape-procesa/:id"
          element={<MapeProcesaPage />}
        />
        <Route
          path="/register/success-registration"
          element={<SuccessRegistrationPage />}
        />
        <Route path="/contact" element={<ContactPage />} />
        <Route
          path="/admin"
          element={
            <AdminPage
              isLoggedin={isLoggedin}
              setIsloggedin={setIsloggedin}
              setLoading={setLoading}
            />
          }
        >
          <Route path="contact" element={<ContactPage />} />
          <Route path="notifications" element={<NotificationsPage />} />
          <Route path="fuk" element={<FukPage />} />
          <Route
            path="poslovni-procesi"
            element={<AdminBusinessProcessesPage />}
          />
          <Route
            path="struktura-preduzeca"
            element={<AdminStrukturaPreduzecaPage />}
          />
          <Route path="autorizacija" element={<AdminAuthorizationPage />} />
          <Route
            path="formulari-rizika"
            element={<AdminFormulariRizikaPage />}
          />
          <Route
            path="formulari-rizika/:id"
            element={<AdminFormulariRizikaSinglePage />}
          />
          <Route path="registri-rizika" element={<RegistriRizikaPage />} />
          <Route
            path="lista-zaposlenih/:id/:level"
            element={<AdminEmployeesPage />}
          />
          <Route path="mape-procesa" element={<AdminMapeProcesaPage />} />
          <Route
            path="mape-procesa/:id"
            element={<AdminSingeMapaProcesaPage />}
          />
          <Route
            path="mape-procesa/new/:id"
            element={<AdminNewMapaProcesaPage />}
          />
          <Route
            path="formulari-rizika/new"
            element={<AdminNewFormularRizikaPage />}
          />
        </Route>
      </Routes>
      <Footer />
      {loading && (
        <div className="loading">
          <Loading isLoading={loading} />
        </div>
      )}

      {showNavigatePopup && (
        <div className="loading">
          <UnsavedDataPopup
            url={navigateUrl}
            setShowNavigatePopup={setShowNavigatePopup}
          />
        </div>
      )}
    </div>
  );
}

export default App;
