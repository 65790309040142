import React, { useContext, useState } from "react";
import "./loginForm.css";
import { Formik, Field, Form } from "formik";

import ValidationError from "../validationError";
import { baseRequest, csrfUrl } from "../../axiosconfig";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../loading";
import { UserContext } from "../../userContext";

export default function LoginForm({ setIsloggedin }) {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [conditions, setConditions] = useState(false);

  const { setUser } = useContext(UserContext);

  const navigate = useNavigate();

  const handleShowPassword = () => {
    setShowPassword((prevState) => {
      return !prevState;
    });
  };

  return (
    <div className="login-form-wrapper">
      <Formik
        initialValues={{
          password: "",
          email: "",
        }}
        validateOnChange={false}
        validateOnBlur={false}
        // validationSchema={registerOrganizationSchema}
        onSubmit={async (values, { setFieldError }) => {
          setLoading(true);
          axios
            .get(`${csrfUrl}sanctum/csrf-cookie`, {
              withCredentials: true,
            })
            .then((res) => {
              baseRequest
                .post(
                  "/login",
                  {
                    password: values.password,
                    email: values.email,
                  },
                  {
                    withCredentials: true,
                  }
                )
                .then((res) => {
                  baseRequest
                    .get("/user", { withCredentials: true })
                    .then((res) => {
                      setUser(res.data.data);
                      setIsloggedin(true);
                      setLoading(false);
                      navigate("/admin/struktura-preduzeca");
                    });
                })
                .catch((error) => {
                  setLoading(false);
                  const errors = error.response.data.errors;
                  Object.keys(errors).forEach((error) => {
                    setFieldError(error, errors[error]);
                  });
                });
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        }}
      >
        {({ errors, touched }) => (
          <Form className="login-form">
            <div className="login-form-field-wrapper">
              <label htmlFor="email" className="login-form-field-label">
                Imejl-adresa:*
              </label>
              <Field name="email" type="text" className="login-textfield" />
              {errors.email && <ValidationError error={errors.email} />}
            </div>
            <div
              className="login-form-field-wrapper"
              style={{ marginBottom: "10px" }}
            >
              <label htmlFor="password" className="login-form-field-label">
                Vaša lozinka:*
              </label>
              <Field
                name="password"
                type={showPassword ? "text" : "password"}
                className="login-textfield"
              />
              <button
                className="show-password-button"
                type="button"
                onClick={handleShowPassword}
              >
                <img src="/images/showpassword.png" />
                {showPassword && <span className="slash"></span>}
              </button>
              {errors.password && <ValidationError error={errors.password} />}
            </div>
            <div
              className="login-form-link-wrapper"
              style={{ marginBottom: "20px" }}
            >
              <Link to="/forgotten-password" className="login-form-link">
                Zaboravljena lozinka / Obnova lozinke
              </Link>
            </div>
            <div className="login-form-link-wrapper">
              <div
                className={!conditions ? "checkbox" : "checkbox checked"}
                onClick={() => setConditions((conditions) => !conditions)}
              >
                {conditions && <img src="/images/check.png" />}
              </div>

              <span
                className="text"
                style={{ marginBottom: "0", marginRight: "5px" }}
              >
                Slažem se sa
              </span>
              <Link
                to="/"
                className="login-form-link"
                style={{ fontWeight: "700" }}
              >
                uslovima korišćenja.
              </Link>
            </div>
            <div className="login-form-link-wrapper">
              <span
                className="text"
                style={{ marginBottom: "0", marginRight: "5px" }}
              >
                Nemate nalog? Registrujte se
              </span>
              <Link
                to="/register"
                className="login-form-link"
                style={{ fontWeight: "700" }}
              >
                ovde.
              </Link>
            </div>

            <button type="submit" className="login-form-button">
              Prijavite se
            </button>
          </Form>
        )}
      </Formik>

      <Loading isLoading={loading} />
    </div>
  );
}
