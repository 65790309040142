import React from "react";
import Button from "../elements/button";
import "./successregistration.css";
import { useNavigate } from "react-router-dom";

export default function SuccessRegistration() {
  const navigate = useNavigate();

  return (
    <div className="success-registration">
      <div className="success-popup">
        <div className="success-popup-message">
          <p>Uspešno ste se registrovali.</p>
          <p className="bold">Dobrodošli!</p>
        </div>
        <Button title="Prijavite se" style={{ width: "150px" }} onClick={() => navigate("/login")} />
      </div>
    </div>
  );
}
