import React from "react";
import "./poslovniProcesiUnitsList.css";

export default function PoslovniProcesiUnitsList({
  processList,
  setActiveUnit,
  handleAddNewUnit,
  activeOrgUnit,
  designations,
  organization_units,
  handleDeleteUnit,
  calculateProcessOwnersNames,
  allUnitsDisabled,
  setNewProcess,
  setActiveProcess,
}) {
  return (
    <div className="poslovni-procesi-unit-list">
      <button
        className="add-position-button"
        onClick={handleAddNewUnit}
        disabled={
          (activeOrgUnit && activeOrgUnit.level === -1) ||
          allUnitsDisabled(organization_units)
        }
      >
        <span>
          <img className="plus-circle-icon" src="/images/pluscircle.png" />
        </span>
        <span
          className={
            (activeOrgUnit && activeOrgUnit.level === -1) ||
            allUnitsDisabled(organization_units)
              ? "add-unit-btn-disabled"
              : "add-unit-btn"
          }
        >
          Dodajte organizacionu jedinicu
        </span>
      </button>
      {activeOrgUnit && !activeOrgUnit.del && (
        <div>
          <h3 className="poslovni-procesi-unit-list-title">
            {activeOrgUnit?.name || "Organizaciona jedinica"}
            <span>
              <img
                src="/images/arrow.png"
                style={{ width: "20px", marginRight: "10px" }}
              />
            </span>
          </h3>
        </div>
      )}

      {processList && Object.keys(processList).length > 0 && (
        <ul>
          {Object.keys(processList).map((key) => {
            if (+key !== activeOrgUnit?.id && !processList[key].del)
              return (
                <li key={key} className="poslovni-procesi-unit-list-item">
                  <p
                    className="unit-name"
                    style={{ width: "90%" }}
                    onClick={() => {
                      setActiveUnit({
                        ...processList[key],

                        business_process_lists: processList[key]
                          .business_process_lists
                          ? processList[key]?.business_process_lists.map(
                              (item) => {
                                return {
                                  ...item,
                                  business_process_owners:
                                    calculateProcessOwnersNames(
                                      item.business_process_owners,
                                      designations
                                    ),
                                };
                              }
                            )
                          : [],
                      });
                      setNewProcess(null);
                      setActiveProcess(null);
                    }}
                  >
                    {processList[key]?.name}
                  </p>
                  <span
                    className="poslovni-procesi-unit-list-delete-button"
                    onClick={() => handleDeleteUnit(key)}
                  >
                    <img src="/images/trash.png" />
                  </span>
                </li>
              );
          })}
        </ul>
      )}
    </div>
  );
}
