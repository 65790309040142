import React, { useState } from "react";

import DropDown from "../../elements/dropDown";
import AdminFormulariRizikaList from "../adminFormulariRizikaList";
import Loading from "../../loading";
import { Link, useNavigate } from "react-router-dom";
import { baseRequest } from "../../../axiosconfig";

function AdminFormulariRizika(props) {
  const orgUnitsDropdownstyle = {
    borderRight: "1px solid var(--mainColor)",
    width: "50%",
  };
  const processesDropdownStyle = {
    borderRight: "1px solid var(--mainColor)",
    width: "30%",
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const [organizationUnits, setOrganizationUnits] = useState([]);
  const [activeUnit, setActiveUnit] = useState({ id: "all", name: "ALL" });
  const [designationsCopy, setDesignationsCopy] = useState();
  const [enableEdit, setEnableEdit] = useState(false);
  const [filteredProcesses, setFilteredProcesses] = useState();
  const [selectedProcess, setSelectedProcess] = useState({
    id: "all",
    name: "ALL",
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSelectUnit = ({ value }) => {
    setActiveUnit({
      ...value,

      business_process_lists: value.business_process_lists
        ? value?.business_process_lists.map((item) => {
            return {
              ...item,
              business_process_owners: calculateProcessOwnersNames(
                item.business_process_owners,
                designationsCopy
              ),
            };
          })
        : [],
    });
    setSelectedProcess({ id: "all", name: "ALL" });
  };

  const handleSelectProcess = ({ value }) => {
    setSelectedProcess(value);
  };

  // const filterProcesses = (unit, proc) => {
  //   const filteredProcesses = proc.filter((proc) => {
  //     return proc.business_process_id === unit.id;
  //   });

  //   return filteredProcesses;
  // };

  const calculateProcessOwnersNames = (list, des) => {
    if (list.length) {
      if (list[0].label) {
        return list;
      } else {
        const names = list.map((owner) => {
          const worker = des.find((des) => {
            return des.id === owner.employee_id;
          });
          if (worker)
            return {
              value: worker,
              label: worker?.name,
              isDisabled: undefined,
            };
        });

        return names.filter((name) => name);
      }
    }
  };

  const createNewFormular = () => {
    navigate("/admin/formulari-rizika/new");
  };

  const filterProcesses = (unit) => {
    if (unit.name === "ALL") {
      return [];
    }
    return unit.business_process_lists;
  };

  return (
    <div>
      <div className="processes-header">
        <DropDown
          dropDownStyle={orgUnitsDropdownstyle}
          height={"49px"}
          list={organizationUnits}
          onChange={handleSelectUnit}
          selectedValue={activeUnit?.name}
          isFilter={true}
        />
        <DropDown
          dropDownStyle={processesDropdownStyle}
          height={"49px"}
          list={filterProcesses(activeUnit)}
          onChange={handleSelectProcess}
          selectedValue={selectedProcess?.name}
          isFilter={true}
        />
        <div className="sort-options-dropdown" style={{ flex: 1 }}>
          <div
            className="sort-options-dropdown-selected"
            onClick={() => {
              setShowDropdown((state) => !state);
            }}
          >
            <span style={{ marginRight: "7px", paddingLeft: "10px" }}>
              Opcije
            </span>

            <img
              alt=""
              src="/images/sidebararrow.png"
              className="sidebar-navigation-item-arrow"
            ></img>
          </div>
          {/* {showDropdown && (
            <ul
              className="sort-options-dropdown-options"
              style={{ left: "0", zIndex: "100", flex: 1 }}
            >
              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setShowDropdown(false);
                }}
              >
                Izmenite
              </li>

              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setShowDropdown(false);
                }}
              >
                Odštampaj
              </li>
            </ul>
          )} */}
        </div>
        {/* <div>
          <button className="add-btn-large" onClick={createNewFormular}>
            <img alt="" src="/images/plus.png"></img>
          </button>
        </div> */}
      </div>
      <AdminFormulariRizikaList
        setOrganizationUnits={setOrganizationUnits}
        activeUnit={activeUnit}
        setActiveUnit={setActiveUnit}
        setDesignationsCopy={setDesignationsCopy}
        enableEdit={enableEdit}
        setFilteredProcesses={setFilteredProcesses}
        selectedProcess={selectedProcess}
        loading={loading}
        setLoading={setLoading}
      />
      <Loading isLoading={loading} />
    </div>
  );
}

export default AdminFormulariRizika;
