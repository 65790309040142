import React, { useState, useEffect, useRef } from "react";

import DropDown from "../elements/dropDown";
import DynamicTextarea from "../elements/DynamicTextarea";
import NoInputCalendar from "../DatePicker/noinputdatepicker";
import "./mapedokumentacijaitem.css";
import LinkPopup from "../LinkPopup";

export default function MapeDokumentacijaItem({
  designations,
  dueDate,
  responsibles,
  editing,
  links,
  handleRejectMapaDukument,
  handleSetEditProcedureRow,
  handleUpdateProcedureRow,
  row,
  handleChangeProcedureRow,
  handleSelectProceduraRowResponsibles,
  handleChangeProcedureRowDueDate,
  handleChangeNewMapaDocument,
  handleAddNewMapaDocumentList,
  setActivityToDelete,
  mapa,
  list,
  validationsErrors,
}) {
  const [openLinkPopup, setOpenLinkPopup] = useState(false);

  // const formatDateToObject = (date) => {
  //   const dateStringArray = date.split(" ");
  //   const dateWithoutTime = dateStringArray[0].split("-");

  //   return new Date(
  //     dateWithoutTime[0],
  //     +dateWithoutTime[1] - 1,
  //     dateWithoutTime[2]
  //   );
  // };

  const taRef1 = useRef();
  const taRef2 = useRef();

  useEffect(() => {
    if (taRef1.current && taRef2.current) {
      const scrollHeight1 = taRef1.current.scrollHeight;

      const height2 = +taRef2.current.style.height.split("p")[0];

      if (scrollHeight1 > height2) {
        taRef2.current.style.height = `${scrollHeight1}px`;
      }
      taRef1.current.style.height = `${scrollHeight1}px`;
      const scrollHeight2 = taRef2.current.scrollHeight;

      const height1 = +taRef1.current.style.height.split("p")[0];

      if (scrollHeight2 > height1) {
        taRef1.current.style.height = `${scrollHeight2}px`;
      }
      taRef2.current.style.height = `${scrollHeight2}px`;
    }
  }, [row]);

  return (
    <ul
      className="poslovni-procesi-table-list"
      style={{
        borderTop: editing ? "2px solid var(--mainColor)" : "var(--mainBorder)",
        borderBottom: editing ? "2px solid var(--mainColor)" : "none",
        borderLeft: editing ? "2px solid var(--mainColor)" : "none",
        borderRight: editing ? "2px solid var(--mainColor)" : "none",
      }}
    >
      <li
        className="poslovni-procesi-table-item"
        style={{ width: "21%", alignItems: "flex-start", height: "auto" }}
      >
        <textarea
          className="dynamic-textarea-auto"
          ref={taRef1}
          rows="1"
          onChange={(e) => {
            handleChangeProcedureRow(e, "name", row);
          }}
          value={row.name ? row.name : ""}
          readOnly={!editing}
        />
        {row.editing && validationsErrors && validationsErrors["name"] && (
          <div className="input-error-message">Obavezno polje</div>
        )}
      </li>
      <li
        className="poslovni-procesi-table-item"
        style={{
          width: "30%",
          alignItems: "flex-start",
        }}
      >
        <textarea
          className="dynamic-textarea-auto"
          ref={taRef2}
          rows="1"
          onChange={(e) => {
            handleChangeProcedureRow(e, "description", row);
          }}
          value={row.description}
          readOnly={!editing}
        />
        {row.editing &&
          validationsErrors &&
          validationsErrors["description"] && (
            <div className="input-error-message">Obavezno polje</div>
          )}
      </li>
      <li
        className="poslovni-procesi-table-item"
        style={{ width: "30%", display: "flex", alignItems: "flex-start" }}
      >
        <div
          style={{
            width: "55%",
            borderRight: "1px solid var(--mainColor)",
            height: "100%",
            display: "flex",
            alignItems: "flex-start",
            paddingTop: "2px",
          }}
        >
          <DropDown
            isMulti={true}
            list={designations}
            onChange={handleSelectProceduraRowResponsibles(row)}
            selectedValues={responsibles}
            fontSize={12}
            paddingLeft={0}
            flexDirection="column"
            whiteSpace="wrap !important"
            valuePadding="0"
            disabled={!editing}
            control={{ alignItems: "flex-start" }}
          />
          {row.editing &&
            validationsErrors &&
            validationsErrors["activities_designations"] && (
              <div className="input-error-message">Obavezno polje</div>
            )}
        </div>
        <div
          style={{
            width: "45%",
            position: "relative",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          {/* <p
            style={{
              position: "absolute",
              top: "10px",
              left: "5px",

              fontSize: "16px",
            }}
          >
            {dueDate ? dueDate.split(" ")[0] : ""}
          </p>
          <NoInputCalendar
            handleSelectDate={handleChangeProcedureRowDueDate(row)}
            parentStartDate={dueDate ? formatDateToObject(dueDate) : ""}
            disabled={!editing}
          /> */}
          <textarea
            className="dynamic-textarea-auto"
            rows="1"
            onChange={(e) => {
              handleChangeProcedureRow(e, "due_date", row);
            }}
            value={row.due_date}
            readOnly={!editing}
          />
        </div>
      </li>
      <li
        className="poslovni-procesi-table-item"
        style={{
          width: "15%",
          position: "relative",
          alignItems: "flex-start",
        }}
      >
        <ul
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {links &&
            links.length > 0 &&
            links.map((link) => {
              return (
                <a
                  className="document-path-link"
                  key={link.id}
                  href={link?.path}
                  target="_blank"
                >
                  {link?.name}
                </a>
              );
            })}
        </ul>

        <div
          style={{
            display: "flex",
            paddingTop: "12px",
            paddingRight: "5px",
            flex: "1",
          }}
        >
          <button className="mapedokumentacijaitem-button">
            <img alt="" src="/images/doc.png" />
          </button>
          <button
            disabled={!editing}
            className="mapedokumentacijaitem-button"
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setOpenLinkPopup((state) => !state);
              handleAddNewMapaDocumentList(false, row);
            }}
          >
            <img alt="" src="/images/link.png" />
          </button>
        </div>
      </li>
      <li className="poslovni-procesi-table-item" style={{ width: "4%" }}>
        <div className="new-procedure-options-block">
          <button
            type="button"
            className="employee-item-options-button"
            style={{ width: "40%" }}
            onClick={() => {
              if (editing) {
                handleUpdateProcedureRow(row);
              } else {
                if (
                  !list.some((el) => el.editing) &&
                  !mapa.activities.some((el) => el.editing)
                ) {
                  handleSetEditProcedureRow(row);
                }
              }
            }}
          >
            <img
              src={editing ? "/images/check.png" : "/images/edit.png"}
              alt="im"
            />
          </button>
          <button
            type="button"
            className="employee-item-options-button"
            style={{ width: "40%" }}
            onClick={() => {
              setActivityToDelete(row);
            }}
          >
            <img src="/images/trash.png" />
          </button>
        </div>
      </li>
      {openLinkPopup && (
        <>
          <div className="loading"></div>
          <LinkPopup
            //  handleAddLink={handleAddLink}
            setOpenLinkPopup={setOpenLinkPopup}
            handleChangeNewMapaDocument={handleChangeNewMapaDocument}
            link={row.activities_documents[row.activities_documents.length - 1]}
            row={row}
            isNewRow={false}
            handleSaveNewMapaDocument={() => {}}
            handleRejectMapaDukument={handleRejectMapaDukument}
          />
        </>
      )}
    </ul>
  );
}
