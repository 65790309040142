import { Routes, Route, Link, Outlet } from "react-router-dom";
import Sidebar from "../../components/admin/sidebar";

const AdminPage = ({ isLoggedin, setIsloggedin, setLoading }) => {
  return (
    <div className="admin-page">
      <Sidebar
        isLoggedin={isLoggedin}
        setIsloggedin={setIsloggedin}
        setLoading={setLoading}
      />

      <Outlet />
    </div>
  );
};

export default AdminPage;
