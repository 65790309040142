import React from "react";
import AdminBusinessProcesses from "../../components/admin/businessProcesses";

function AdminBusinessProcessesPage(props) {
  return (
    <div className="admin-page-child-wrapper">
      <AdminBusinessProcesses />
    </div>
  );
}

export default AdminBusinessProcessesPage;
