import React from "react";
import Select, { components } from "react-select";
import "./dropdown.css";

export default function DropDown({
  list,
  selectedValue,
  selectedValues,
  dropDownStyle,
  withIcon,
  isFilter,
  ...props
}) {
  const customStyles = {
    option: (provided, { isDisabled }) => ({
      ...provided,
      backgroundColor: "white",
      cursor: "pointer",
      color: isDisabled ? "#eee" : "var(--mainColor)",
    }),
    container: (provided, state) => ({
      ...provided,
      top: 0,
      color: "var(--mainColor)",
      fontSize: 14,
      borderRadius: 0,
      bottom: 0,
      height: "100%",
    }),

    control: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,

      borderWidth: state.isFocused ? 0 : 0,
      // borderLeftWidth: "1px !important",
      //borderLeftColor: "var(--mainColor) !important",
      boxShadow: "0 !important",
      alignItems: props?.control?.alignItems
        ? props?.control?.alignItems
        : "center",
      height: "100% ",
      outline: "none",
      backgroundColor: "white",
      width: "100%",
      padding: 0,
      cursor: "pointer",
      borderRadius: 0,
      minHeight: props.height || 60,
    }),

    indicatorSeparator: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,

      display: "none",
    }),

    dropdownIndicator: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      paddingRight: 0,
      paddingLeft: 0,
      color: "var(--mainColor)",
    }),

    clearIndicator: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      paddingRight: 0,
      paddingLeft: 0,
      color: "var(--mainColor)",
    }),

    valueContainer: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,

      backgroundColor: "white",
      color: "var(--mainColor)",
      width: "100%",
      paddingRight: 0,
      paddingLeft: props.paddingLeft,
      margin: 0,
      borderColor: state.isFocused ? "var(--mainColor)" : "var(--mainColor)",
      borderWidth: state.isFocused ? 1 : 1,
      flexDirection: "column",
      alignItems: props?.containerValue?.alignItems
        ? props.containerValue.alignItems
        : "flex-start",
      fontWeight: props?.containerValue?.fontWeight
        ? props.containerValue.fontWeight
        : "regular",
    }),

    singleValue: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      backgroundColor: "white",
      color: "var(--mainColor)",
      whiteSpace: "wrap",
      overflow: "visible",
    }),

    input: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
    }),

    multiValue: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      backgroundColor: "white",
      color: "var(--mainColor) !important",
      flexDirection: props.flexDirection,
    }),

    indicatorsContainer: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
    }),

    multiValueLabel: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      backgroundColor: "white",
      color: "var(--mainColor) !important",
      fontSize: props.fontSize || 16,
      whiteSpace: props.whiteSpace,
      padding: props.valuePadding,
      textAlign: "left",
    }),

    menu: (provided) => ({
      ...provided,
      border: "1px solid var(--mainColor)",
      backgroundColor: "white",
      width: props?.menu?.width ? props?.menu?.width : "100%",
      // top: "10% !important",
      borderRadius: 0,
      boxShadow: "none",
    }),
  };

  function formatList(list = []) {
    if (isFilter) {
      const filterList = list.map((item) => {
        return {
          value: item,
          label: item.name,
          isDisabled: item.isDisabled,
          icon: item?.icon,
        };
      });
      return [
        { value: { id: "all", name: "ALL" }, label: "ALL" },
        ...filterList,
      ];
    } else {
      return list
        .filter((el) => el)
        .map((item) => {
          return {
            value: item,
            label: item.name,
            isDisabled: item.isDisabled,
            icon: item?.icon,
          };
        });
    }
  }

  const { Option } = components;
  const IconOption = (props) => (
    <Option {...props}>
      <img
        src={props.data.icon}
        style={{ width: 14, marginRight: 5 }}
        alt={props.data.label}
      />
      {props.data.label}
    </Option>
  );

  return (
    <div className="searchable-dropdown" style={dropDownStyle}>
      <Select
        menuPosition="fixed"
        options={formatList(list)}
        isMulti={props.isMulti}
        styles={customStyles}
        placeholder=""
        onChange={(value) => {
          props.onChange(value);
        }}
        value={!props.isMulti ? { label: selectedValue } : selectedValues}
        isDisabled={props.disabled}
        isOptionDisabled={(option) => option.isDisabled}
        components={withIcon ? { Option: IconOption } : ""}
      />
    </div>
  );
}
