import React from "react";
import "./mapedokumentacija.css";
import MapeDokumentacijaItem from "../mapeDokumentacijaItem";

export default function MapeDokumentacija({
  list,
  designations,
  handleChangeResponsibleDesignations,
  handleChangeDynamicDescription,
  handleSelectDueDate,
  handleChangeDocumentPath,
  handleSetEditProcedureRow,
  handleUpdateProcedureRow,
  handleChangeProcedureRow,
  handleSelectProceduraRowResponsibles,
  handleRejectMapaDukument,
  handleChangeProcedureRowDueDate,
  handleChangeNewMapaDocument,
  handleAddNewMapaDocumentList,
  setActivityToDelete,
  mapa,
  validationsErrors,
}) {
  const mapedokumentacijaTitles = [
    { id: 1, title: "DIJAGRAM TOKA", width: "21%" },
    { id: 2, title: "OPIS AKTIVNOSTI", width: "30%" },
    { id: 3, title: "IZVRŠENJE", width: "30%" },
    { id: 4, title: "PROPRATNI DOKUMENTI", width: "19%" },
  ];

  const formatDesignations = (list) => {
    if (list) {
      if (list.some((el) => el.value)) {
        return list;
      } else {
        return list.map((item) => {
          const worker = designations.find((des) => {
            return des.id === item.designation_id;
          });

          return { value: worker, label: worker.name };
        });
      }
    }
  };

  return (
    <div className="table">
      <ul className="table-header" style={{ borderBottom: "none" }}>
        {mapedokumentacijaTitles.map((title, index) => {
          if (index === 2) {
            return (
              <li
                key={title.id}
                style={{
                  width: "30%",
                  borderRight: "1px solid var(--mainColor)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  color: "var(--mainColor)",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    fontWeight: "900",
                    textAlign: "center",
                    borderBottom: "1px solid var(--mainColor)",
                    height: "50%",
                    paddingTop: "20px",
                  }}
                >
                  IZVRŠENJE
                </div>
                <div style={{ display: "flex", flex: "1" }}>
                  <div
                    style={{
                      borderRight: "1px solid var(--mainColor)",
                      width: "55%",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    ODGOVORNOST
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "45%",
                    }}
                  >
                    ROK
                  </div>
                </div>
              </li>
            );
          }
          return (
            <li
              className="table-header-item"
              key={title.id}
              style={{ width: title.width }}
            >
              <p className="table-header-title">{title.title}</p>
            </li>
          );
        })}
      </ul>
      <div className="poslovli-proseci-table-list">
        {list &&
          list.length > 0 &&
          list.map((item, index) => {
            return (
              <MapeDokumentacijaItem
                key={index}
                name={item.name}
                description={item.description}
                designations={designations}
                handleChangeResponsibleDesignations={handleChangeResponsibleDesignations(
                  index
                )}
                selectedResponsibilityDesignations={
                  item.selectedResponsibilityDesignations
                }
                handleSelectDueDate={handleSelectDueDate}
                dueDate={item.due_date}
                handleChangeDynamicDescription={handleChangeDynamicDescription(
                  index
                )}
                handleChangeDocumentPath={handleChangeDocumentPath(index)}
                link={item.link}
                responsibles={formatDesignations(item?.activities_designations)}
                index={index}
                editing={item?.editing}
                links={item.activities_documents}
                handleSetEditProcedureRow={handleSetEditProcedureRow}
                row={item}
                handleUpdateProcedureRow={handleUpdateProcedureRow}
                handleChangeProcedureRow={handleChangeProcedureRow}
                handleSelectProceduraRowResponsibles={
                  handleSelectProceduraRowResponsibles
                }
                handleChangeProcedureRowDueDate={
                  handleChangeProcedureRowDueDate
                }
                handleChangeNewMapaDocument={handleChangeNewMapaDocument}
                handleAddNewMapaDocumentList={handleAddNewMapaDocumentList}
                handleRejectMapaDukument={handleRejectMapaDukument}
                setActivityToDelete={setActivityToDelete}
                mapa={mapa}
                list={list}
                validationsErrors={validationsErrors}
              />
            );
          })}
      </div>
    </div>
  );
}
