import React, { useState } from "react";

import { Formik, Field, Form } from "formik";

import ValidationError from "../validationError";
import { baseRequest, csrfUrl } from "../../axiosconfig";
import "./forgottenPassword.css";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../loading";

export default function ForgottenPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [sentEmail, setSentEmail] = useState(false);

  const navigate = useNavigate();

  return (
    <div className="login-form-wrapper">
      {!sentEmail && (
        <>
          <div className="text-regular">
            <p style={{ fontWeight: "700" }}>
              Zaboravljena lozinka / Obnova lozinke{" "}
            </p>

            <p>
              Unesite Vašu imejl-adresu i poslaćemo Vam link za obnovu Vaše
              lozinke.
            </p>
          </div>
          <Formik
            initialValues={{
              email: "",
            }}
            validateOnChange={false}
            // validationSchema={registerOrganizationSchema}
            onSubmit={async (values, { setFieldError }) => {
              setLoading(true);
              axios
                .get(`${csrfUrl}sanctum/csrf-cookie`, {
                  withCredentials: true,
                })
                .then((res) => {
                  baseRequest
                    .post(
                      "/forgot-password",
                      {
                        email: values.email,
                      },
                      {
                        withCredentials: true,
                      }
                    )
                    .then((res) => {
                      setLoading(false);
                      setSentEmail(true);
                    })
                    .catch((error) => {
                      setLoading(false);
                      const errors = error.response.data.errors;
                      Object.keys(errors).forEach((error) => {
                        setFieldError(error, errors[error]);
                      });
                    });
                });
            }}
          >
            {({ errors, touched }) => (
              <Form className="login-form">
                <div className="login-form-field-wrapper">
                  <label htmlFor="email" className="login-form-field-label">
                    Imejl-adresa:*
                  </label>
                  <Field name="email" type="text" className="login-textfield" />
                  {errors.email && <ValidationError error={errors.email} />}
                </div>

                <button type="submit" className="login-form-button">
                  Pošalji
                </button>
              </Form>
            )}
          </Formik>
          <Loading isLoading={loading} />
        </>
      )}
      {sentEmail && (
        <div className="text-regular">
          <p style={{ fontWeight: "700" }}>
            Zaboravljena lozinka / Obnova lozinke{" "}
          </p>

          <p>Poslali smo link za promenu šifre na vaš email.</p>
        </div>
      )}
    </div>
  );
}
