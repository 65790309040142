import { forwardRef } from "react";
import RegistriRizikaRowPrint from "./registriRizikaRowPrint";

const RegistriRizikaPrint = forwardRef((props, ref) => {
  const headerTitles = [
    { id: 0, title: "R.BR", width: "70px" },
    { id: 1, title: "VLASNIK PROCESA", width: "170px" },
    { id: 2, title: "BROJ", width: "70px" },
    { id: 3, title: "FUNKCIJA/VLASNIK RIZIKA", width: "220px" },
    { id: 4, title: "IME RIZIKA", width: "170px" },
    { id: 5, title: "OPIS RIZIKA", width: "370px" },
    { id: 6, title: "GRUPA RIZIKA", width: "170px" },
    { id: 7, title: "VEROVATNOĆA (1-5)", width: "200px" },
    { id: 8, title: "UTICAJ (1-5)", width: "170px" },
    {
      id: 9,
      title: "UKUPNA VREDNOST ",
      subtitle: "(verovatnoća + uticaj)",
      width: "220px",
    },
    { id: 10, title: "NAČIN UPRAVLJANJA RIZIKOM", width: "220px" },
    {
      id: 11,
      title: "OPIS KONTROLA/MERA ZA",
      subtitle: "UPRAVLJANJE RIZICIMA",
      width: "220px",
    },
    {
      id: 12,
      title: "PROCENJENI UTICAJ  ",
      subtitle: "KONTROLA U %",
      width: "220px",
    },
    { id: 13, title: "UTICAJ KONTROLA (0-5)", width: "220px" },
    { id: 14, title: "PREOSTALI RIZIK", width: "220px" },
  ];

  const filterForms = (list, unit, process) => {
    const filteredMapsByUnit =
      unit.name === "ALL"
        ? list
        : list.filter(
            (process) =>
              process.business_process_list.business_process_id === unit.id
          );

    if (process) {
      const filteredMapsByProcesses =
        process.name === "ALL"
          ? filteredMapsByUnit
          : filteredMapsByUnit.filter(
              (pr) => pr.business_process_list_id === process.id
            );

      return filteredMapsByProcesses;
    }

    return filteredMapsByUnit;
  };

  return (
    <div
      ref={ref}
      style={{ marginTop: "30px", marginRight: "20px", marginLeft: "20px" }}
    >
      <ul
        style={{
          display: "flex",
          backgroundColor: "#F0F0F0",
          border: "1px solid black",
          color: "black",
          fontWeight: "bold",
          pageBreakAfter: "auto",
          height: "100px",
        }}
      >
        {headerTitles.map((item, index, array) => {
          if (!item.subtitle) {
            return (
              <li
                key={item.id}
                style={{
                  width: item.width ? item.width : "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  borderRight:
                    index !== array.length - 1 ? "1px solid black" : "none",
                  fontSize: "8px",
                }}
              >
                {item.title}
              </li>
            );
          } else {
            return (
              <li
                key={item.id}
                className="registri-header-cell-subtitle"
                style={{
                  width: item.width ? item.width : "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  borderRight: "1px solid black",
                  fontSize: "8px",
                }}
              >
                <span
                  className="registri-header-cell-title"
                  style={{ fontSize: "8px", color: "black" }}
                >
                  {item.title}
                </span>
                <span
                  className="registri-header-cell-subtitle-span"
                  style={{ fontSize: "8px", color: "black" }}
                >
                  {item.subtitle}
                </span>
              </li>
            );
          }
        })}
      </ul>
      <ul>
        {filterForms(
          props.riskForms,
          props.activeUnit,
          props.selectedProcess
        ).map((form, index) => {
          if (!form.del)
            return (
              <RegistriRizikaRowPrint
                key={form.id}
                titles={headerTitles}
                row={form}
                redniBroj={index + 1}
                riskGroups={props.riskGroups}
              />
            );
        })}
      </ul>
    </div>
  );
});

export default RegistriRizikaPrint;
