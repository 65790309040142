import React, { useEffect, useState } from "react";

import EmployeesListItem from "../../employeesListItem";
import Button from "../../elements/button";
import { baseRequest, deleteUrl } from "../../../axiosconfig";
import Loading from "../../loading";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import NewEmployee from "../../newEmployee";
import DeleteEmployeePopup from "../../deleteEmployeePopup";
import axios from "axios";
import EmployeelistErrorPopup from "../../employeeListErrorPopup";
import DropDown from "../../elements/dropDown";
import { useContext } from "react";
import { UnsavedDataContext } from "../../../context";
import { UserContext } from "../../../userContext";

export default function AdminEmployeesList() {
  const [newEmployee, setNewEmployee] = useState();
  const [employeesList, setEmployeesList] = useState([]);
  const [errorMessages, setErrorMessages] = useState("");
  const [organization_units, setOrganization_units] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [employeeToDelete, setEmployeeToDelete] = useState();
  const [activeEmployee, setActiveEmployee] = useState();
  const [loading, setLoading] = useState(false);
  const [emptyFieldsError, setEmptyFieldsError] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [sortOption, setSortOption] = useState({ id: "all", name: "ALL" });

  const [showDesignationDescription, setShowDesignationDescription] = useState({
    isOpen: "false",
    id: null,
  });
  const params = useParams();
  const navigate = useNavigate();

  const { setUnsavedData } = useContext(UnsavedDataContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (newEmployee || activeEmployee) {
      setUnsavedData(true);
    } else if (!newEmployee || !activeEmployee) {
      setUnsavedData(false);
    }
  }, [newEmployee, activeEmployee]);

  const employeesListTitles = [
    { id: 1, title: "Ime*" },
    { id: 2, title: "Prezime*" },
    { id: 3, title: "Organizaciona jedinica*" },
    { id: 4, title: "Naziv radnog mesta*" },
    { id: 5, title: "Opis radnog mesta" },
    { id: 6, title: "Opcije" },
  ];

  useEffect(() => {
    setLoading(true);
    const getEmployeeUrl =
      params.id === "0"
        ? "/employee/id/0/level/0"
        : `/employee/id/${params.id}/level/${params.level}`;

    baseRequest
      .get("/organization_unit/0", { withCredentials: true })
      .then((res) => {
        setOrganization_units(res.data.data);
      })
      .catch((error) => console.log(error));
    baseRequest
      .get(`/designations/0/0`, { withCredentials: true })
      .then((res) => {
        setDesignations(res.data.designations);
      })
      .catch((error) => console.log(error));
    baseRequest
      .get(getEmployeeUrl, { withCredentials: true })
      .then((res) => {
        setEmployeesList(res.data.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const addEmployeeToList = () => {
    setNewEmployee({
      first_name: "",
      last_name: "",
      organization_unit_name: "",
      designation_name: "",
      designation_id: "",
      description: "",
      adding: true,
      level: "",
      parent_id: "",
    });

    setActiveEmployee({
      activeUnit: {
        first_name: "",
        last_name: "",
        organization_unit: "",
        designation_name: "",
        designation_id: "",
        designation_description: "",
        adding: true,
        parent_id: "",
      },
      prevState: {
        first_name: "",
        last_name: "",
        organization_unit: "",
        designation_name: "",
        designation_id: "",
        designation_description: "",
        adding: true,
        parent_id: "",
      },
    });
  };

  const handleCreateEmployee = (payload) => {
    if (validateAllFields(payload)) {
      return true;
    }
    setLoading(true);
    baseRequest
      .post("/employee", payload, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        setEmployeesList((list) => {
          return [...list, res.data.data];
        });
        setNewEmployee(null);
        setActiveEmployee(null);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  function handleChangeEmployee(e, field, id) {
    setEmptyFieldsError(null);
    setActiveEmployee((employee) => {
      return {
        ...employee,
        activeUnit: {
          ...employee.activeUnit,
          [field]: e.target.value.trimStart(),
        },
      };
    });
    setEmployeesList((employees) => {
      const newEmployees = employees.map((employee) => {
        if (employee.id === id) {
          return { ...employee, [field]: e.target.value.trimStart() };
        }
        return employee;
      });
      return [...newEmployees];
    });
  }

  const handleChangeNewEmployee = (e, field) => {
    if (emptyFieldsError) {
      setEmptyFieldsError((list) => {
        return {
          ...list,
          emptyFields: list.emptyFields.filter((item) => item !== field),
        };
      });
    }

    setNewEmployee((employee) => {
      return { ...employee, [field]: e.target.value.trimStart() };
    });
    setActiveEmployee((employee) => {
      return {
        ...employee,
        activeUnit: {
          ...employee.activeUnit,
          [field]: e.target.value.trimStart(),
        },
      };
    });
  };

  function handleSetEditEmployee(payload) {
    if (validate()) {
      return;
    }
    setActiveEmployee({
      activeUnit: { ...payload, typing: true },
      prevState: payload,
    });
    setEmployeesList((employees) => {
      const newEmployees = employees.map((employee) => {
        if (employee.id === payload.id) {
          return { ...employee, typing: true };
        }
        const { typing, ...rest } = employee;
        return rest;
      });
      return [...newEmployees];
    });
  }

  const handleUpdateEmployee = (payload) => {
    if (payload.typing) {
      if (validateAllFields(payload)) {
        return;
      }
      setLoading(true);
      baseRequest
        .put("/employee", payload, { withCredentials: true })
        .then((res) => {
          setEmployeesList((employees) => {
            const newEmployees = employees.map((employee) => {
              if (employee.id === payload.id) {
                return res.data.data;
              }
              return employee;
            });
            return [...newEmployees];
          });
          setLoading(false);
          setActiveEmployee(null);
          setErrorMessages(null);
        });
    }
    if (payload.adding) {
      handleCreateEmployee(payload);
    }
  };

  const handleAddUnitToList = (employeeId, unit) => {
    setEmployeesList((employees) => {
      const newEmployees = employees.map((employee) => {
        if (employee.id === employeeId) {
          return {
            ...employee,
            organization_unit: unit.name,
            designation_name: "",
            designation_id: "",
            designation_description: "",
          };
        }
        return employee;
      });
      return [...newEmployees];
    });
  };

  const handleAddDesignationToList = (employeeId, designation) => {
    setEmployeesList((employees) => {
      const newEmployees = employees.map((employee) => {
        if (employee.id === employeeId) {
          return {
            ...employee,
            designation_name: designation.name,
            designation_id: designation.id,
            designation_description: designation.description,
          };
        }
        return employee;
      });
      return [...newEmployees];
    });
  };

  const disableAddButton = () => {
    return (
      employeesList.some((employee) => employee.typing || employee.editing) ||
      newEmployee
    );
  };

  const validateAllFields = (employee) => {
    setErrorMessages(null);
    const fieldsToValidate = {
      first_name: 1,
      last_name: 2,
      parent_id: 3,
      designation_id: 4,
    };
    const emptyFields = Object.keys(employee).filter((key) => {
      if (fieldsToValidate[key]) {
        if (!employee[key]) {
          return key;
        }
      }
    });

    setEmptyFieldsError(
      emptyFields.length ? { id: employee.id, emptyFields } : null
    );
    if (emptyFields.length) {
      return true;
    }
    return false;
  };

  const deleteEmployee = (payload) => {
    if (validate()) {
      return;
    }
    setEmployeeToDelete(payload);
  };

  const handleConfirmDeleteEmployee = (payload) => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}employee`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: payload,
        withCredentials: true,
      })
      .then((res) => {
        setEmployeesList((employees) => {
          const newEmployees = employees.map((employee) => {
            if (employee.id === res.data.data.id) {
              return { ...employee, del: 1 };
            }
            return employee;
          });
          return newEmployees;
        });
        setLoading(false);
        setErrorMessages("");
        setEmployeeToDelete(null);
      });
  };

  const handleSubmit = () => {
    if (employeesList.length && !disableAddButton()) {
      setLoading(true);
      baseRequest
        .post(
          "/employee",
          { employees: employeesList },
          { withCredentials: true }
        )
        .then((res) => {
          setLoading(false);
          navigate("/register/poslovni-procesi");
        });
    }
  };

  const calculateDisbledButton = () => {
    return !employeesList.length || disableAddButton() || errorMessages;
  };

  const handleSelectUnit = (employee) => {
    return function (value) {
      if (emptyFieldsError) {
        setEmptyFieldsError((list) => {
          return {
            ...list,
            emptyFields: list.emptyFields.filter(
              (item) => item !== "parent_id"
            ),
          };
        });
      }
      if (employee.adding) {
        setNewEmployee((obj) => {
          return {
            ...obj,
            organization_unit_name: value.value.name,
            parent_id: value.value.id,
            level: value.value.level,
            description: "",
            designation_id: "",
            designation_name: "",
          };
        });
        setActiveEmployee((obj) => {
          return {
            ...obj,
            activeUnit: {
              ...obj.activeUnit,
              organization_unit_name: value.value.name,
              parent_id: value.value.id,
              level: value.value.level,
              description: "",
              designation_id: "",
              designation_name: "",
            },
          };
        });
      }
      if (employee.typing) {
        setActiveEmployee((obj) => {
          return {
            ...obj,
            activeUnit: {
              ...obj.activeUnit,
              organization_unit_name: value.value.name,
              parent_id: value.value.id,
              level: value.value.level,
              description: "",
              designation_id: "",
              designation_name: "",
            },
          };
        });
        setEmployeesList((list) => {
          const newList = list.map((item) => {
            if (item.id === employee.id) {
              return {
                ...item,
                organization_unit_name: value.value.name,
                parent_id: value.value.id,
                level: value.value.level,
                description: "",
                designation_id: "",
                designation_name: "",
              };
            }
            return item;
          });
          return newList;
        });
      }
    };
  };

  const validate = () => {
    if (newEmployee || activeEmployee) {
      setErrorMessages("Imate nesacuvane podaci. Zelite da sacuvate?");
      return true;
    }
  };

  const resetActiveEmployee = () => {
    setEmployeesList((list) => {
      const newList = list.map((item) => {
        if (item.id === activeEmployee.prevState.id) {
          const { typing, ...rest } = activeEmployee.prevState;

          return rest;
        }
        return item;
      });
      return newList;
    });
    setActiveEmployee(null);
    setNewEmployee(null);
  };

  const handleSelectDesignation = (employee) => {
    return function ({ value }) {
      if (emptyFieldsError) {
        setEmptyFieldsError((list) => {
          return {
            ...list,
            emptyFields: list.emptyFields.filter(
              (item) => item !== "designation_id"
            ),
          };
        });
      }
      setEmployeesList((list) => {
        const newList = list.map((item) => {
          if (item.id === employee.id) {
            return {
              ...item,
              description: value.description,
              designation_id: value.id,
              designation_name: value.name,
            };
          }
          return item;
        });
        return newList;
      });
    };
  };

  const deleteNewEmployee = () => {
    setNewEmployee(null);
    setActiveEmployee(null);
    setEmptyFieldsError(null);
    setErrorMessages(null);
    setEmployeeToDelete(null);
  };

  const setFilterOrgUnit = ({ value }) => {
    // baseRequest.get(`/employee/id/${value.id}/level/${value.level}`);
    setLoading(true);
    if (value.id === "all") {
      baseRequest
        .get(`/employee/id/0/level/0`, {
          withCredentials: true,
        })
        .then((res) => {
          setLoading(false);

          setEmployeesList(res.data.data);
        });
      navigate(`/admin/lista-zaposlenih/0/0`);
    } else {
      baseRequest
        .get(`/employee/id/${value.id}/level/${value.level}`, {
          withCredentials: true,
        })
        .then((res) => {
          setLoading(false);

          setEmployeesList(res.data.data);
        });
      navigate(`/admin/lista-zaposlenih/${value.id}/${value.level}`);
    }

    // setSearchParams({
    //   org_unit_id: value.id,
    //   level: value.level ?? "",
    // });
    setSortOption(value);
  };

  const filterOrganisationUnits = (unit, list) => {
    if (unit.id === "all") {
      return list;
    }
    return list.filter((el) => el.parent_id === unit.id);
  };

  const hasEditEmployeePermission = (user, unit_id, level) => {
    if (user.role_id === 1) {
      return true;
    } else if (
      user.role_id === 2 &&
      user.organization_unit_id === unit_id &&
      user.level === level
    ) {
      return true;
    } else {
      return false;
    }
  };

  const hasAddEmployeePermission = (user) => {
    if (user.role_id === 1 || user.role_id === 2) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="employees-list-section" style={{ border: "none" }}>
        <div
          className="processes-header"
          style={{ justifyContent: "space-between", height: "51px" }}
        >
          <div className="sort-options-dropdown" style={{ width: "auto" }}>
            <div style={{ flex: "1" }}>Organizaciona jedinica:</div>
            <DropDown
              dropDownStyle={{ width: "auto" }}
              height={"49px"}
              list={organization_units.filter(
                (el) => el.has_designations === true
              )}
              selectedValue={sortOption.name}
              containerValue={{ alignItems: "baseline", fontWeight: "bold" }}
              paddingLeft={"5px"}
              menu={{ width: "200px" }}
              onChange={setFilterOrgUnit}
              isFilter={true}
            />
          </div>

          {/* <div className="sort-options-dropdown" style={{ width: "200px" }}>
            <div
              className="sort-options-dropdown-selected"
              onClick={() => {
                setShowDropdown((state) => !state);
              }}
            >
              <span style={{ marginRight: "7px", paddingLeft: "10px" }}>
                Filtriraj po:
              </span>

              <img
                src="/images/sidebararrow.png"
                className="sidebar-navigation-item-arrow"
              ></img>
            </div>

            {showDropdown && (
              <ul
                className="sort-options-dropdown-options"
                style={{ left: "0", zIndex: "100", flex: 1 }}
              >
                <li
                  className="sort-options-dropdown-options-item"
                  onClick={() => {
                    setShowDropdown(false);
                  }}
                >
                  Svi
                </li>

                <li
                  className="sort-options-dropdown-options-item"
                  onClick={() => {
                    setShowDropdown(false);
                  }}
                >
                  Rukovodilac
                </li>
                <li
                  className="sort-options-dropdown-options-item"
                  onClick={() => {
                    setShowDropdown(false);
                  }}
                >
                  Zaposleni
                </li>
              </ul>
            )}
          </div> */}
        </div>
        <ul
          className="employees-list-header"
          style={{
            display: "flex",
            alignItems: "center",
            borderRight: "1px solid  var(--secondaryColor)",
          }}
        >
          {employeesListTitles.map((item) => {
            return (
              <li key={item.id} className="employees-list-title">
                {item.title}
              </li>
            );
          })}
        </ul>
        <div className="employees-list-wrapper ">
          {employeesList.map((employee) => {
            if (!employee.del)
              return (
                <EmployeesListItem
                  key={employee.id}
                  employee={employee}
                  organization_units={organization_units}
                  designations={designations}
                  handleChangeEmployee={handleChangeEmployee}
                  handleSetEditEmployee={handleSetEditEmployee}
                  handleAddUnitToList={handleAddUnitToList}
                  handleAddDesignationToList={handleAddDesignationToList}
                  deleteEmployee={deleteEmployee}
                  errorMessages={errorMessages}
                  validateAllFields={validateAllFields}
                  handleUpdateEmployee={handleUpdateEmployee}
                  emptyFieldsError={emptyFieldsError}
                  handleSelectUnit={handleSelectUnit}
                  handleSelectDesignation={handleSelectDesignation}
                  activeEmployee={activeEmployee}
                  showDesignationDescription={showDesignationDescription}
                  setShowDesignationDescription={setShowDesignationDescription}
                  hasEditEmployeePermission={hasEditEmployeePermission}
                  user={user}
                />
              );
          })}
        </div>
        {newEmployee && (
          <NewEmployee
            employee={newEmployee}
            organization_units={organization_units}
            designations={designations}
            handleChangeNewEmployee={handleChangeNewEmployee}
            setNewEmployee={setNewEmployee}
            setActiveEmployee={setActiveEmployee}
            handleCreateEmployee={handleCreateEmployee}
            emptyFieldsError={emptyFieldsError}
            deleteNewEmployee={deleteNewEmployee}
            handleSelectUnit={handleSelectUnit}
            setEmptyFieldsError={setEmptyFieldsError}
            style={{
              borderTop:
                employeesList.length > 0
                  ? "none"
                  : "2px solid var(--mainColor)",
              border: "2px solid var(--mainColor)",
            }}
            sortOption={sortOption}
            hasEditEmployeePermission={hasEditEmployeePermission}
            user={user}
          />
        )}

        {hasAddEmployeePermission(user) && (
          <button
            className={
              !disableAddButton()
                ? "add-employee-button"
                : "add-employee-button add-employee-button-disabled"
            }
            style={{ marginLeft: "10px" }}
            onClick={addEmployeeToList}
            disabled={disableAddButton()}
          >
            <span>
              <img
                alt=""
                src="/images/plus.png"
                className={disableAddButton() ? "disabled-button" : ""}
              />
            </span>
            Dodajte zaposlenog
          </button>
        )}
      </div>

      {errorMessages && (
        <>
          <div className="loading"></div>
          <EmployeelistErrorPopup
            message={errorMessages}
            setErrorMessage={setErrorMessages}
            resetActiveEmployee={resetActiveEmployee}
            activeEmployee={activeEmployee}
            handleUpdateEmployee={handleUpdateEmployee}
            handleCreateEmployee={handleCreateEmployee}
            newEmployee={newEmployee}
          />
        </>
      )}
      {employeeToDelete && (
        <>
          <div className="loading"></div>
          <DeleteEmployeePopup
            employeeToDelete={employeeToDelete}
            setEmployeeToDelete={setEmployeeToDelete}
            handleConfirmDeleteEmployee={handleConfirmDeleteEmployee}
            loading={loading}
          />
        </>
      )}
      {loading && (
        <>
          <div className="loading"></div>
          <Loading isLoading={loading} />
        </>
      )}
    </>
  );
}
