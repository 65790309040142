import React, { useContext } from "react";

import DropDown from "../../elements/dropDown";
import DropdownWrapper from "../../elements/dropdownWrapper";
import FormulareRizikaUnitsList from "../../formulareRizikaUnitsList";
import Button from "../../elements/button";
import TextareaAutosize from "react-textarea-autosize";
import DynamicTextarea from "../../elements/DynamicTextarea";
import Loading from "../../loading";

import Calendar from "../../DatePicker";
import { useEffect, useState } from "react";
import { baseRequest, deleteUrl } from "../../../axiosconfig";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../userContext";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { FormularRizikaPrint } from "./rizikFormularaPrint";

export default function SingleFormularRizika() {
  const [designations, setDesignations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formular, setFormular] = useState({});
  const [processes, setProcesses] = useState([]);
  const [riskGroups, setRiskGroups] = useState([]);
  const [riskLevels, setRisksLevels] = useState([]);
  const [controlInfluence, setControlInfluence] = useState("");
  const [preostaliRizik, setPreostaliRizik] = useState("");
  const [formularList, setFormularList] = useState({});
  const [errors, setErrors] = useState();
  const [errorMessages, setErrorMessages] = useState();
  const [influenceValidationError, setInfluenceValidationError] = useState();

  const navigate = useNavigate();

  const params = useParams();

  const { user } = useContext(UserContext);

  const componentRef = useRef();

  const wrapperStyle = { borderBottom: "none" };
  const multiWrapperStyle = { borderBottom: "none", borderTop: "none" };
  const style = { borderRight: "1px solid var(--mainColor)" };
  const textAreaStyle = {
    borderBottom: "1px solid var(--mainColor)",
    paddingTop: "15px",
    boxSizing: "border-box",
  };
  const border = "1px solid var(--mainColor)";
  const disabledBackground = "#eee";

  const errorStyle = { border: "2px solid red", width: "100%" };

  const riskColors = {
    nizak: "#B0E0E6",
    srednji: "#FFD700",
    visok: "#FF5C5C",
  };

  function formatProcesses(list = []) {
    const newList = [];

    list.forEach((item) => {
      if (!item.del) {
        item.business_process_lists.forEach((element) => {
          if (!element.del) {
            newList.push(element);
          }
        });
      }
    });

    return newList;
  }

  const formatListToMap = (list) => {
    const map = {};
    list.forEach((item) => {
      map[item.id] = item;
    });
    return map;
  };

  const formatEmployees = (list, des) => {
    const workers = list.map((item) => {
      const worker = des.find((el) => el.id === item.employee_id);

      if (worker) {
        return { label: worker.name, value: worker, isDisabled: undefined };
      }
    });

    return workers;
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    setLoading(true);

    baseRequest
      .get("/employee/id/0/level/0", { withCredentials: true })
      .then((res) => {
        const designations = formatDesignations(res.data.data);
        setDesignations(designations);
        baseRequest
          .get("/risk_forms", { withCredentials: true })
          .then((response) => {
            const formattedFormularList = formatListToMap(
              response.data.risk_forms
            );
            setFormularList(formattedFormularList);
            const activeFormulars = response.data.risk_forms.filter(
              (formular) => !formular.del
            );
            if (activeFormulars.length) {
              if (params?.id) {
                setFormular({
                  ...formattedFormularList[params.id],
                  risk_interviewees: formatEmployees(
                    formattedFormularList[params.id].risk_interviewees,
                    designations
                  ),
                  risk_owners: formatEmployees(
                    formattedFormularList[params.id].risk_owners,
                    designations
                  ),
                  risk_correspondents: formatEmployees(
                    formattedFormularList[params.id].risk_correspondents,
                    designations
                  ),
                });
              } else {
                setFormular({
                  ...activeFormulars[0],
                  risk_interviewees: formatEmployees(
                    activeFormulars[0].risk_interviewees,
                    designations
                  ),
                  risk_owners: formatEmployees(
                    activeFormulars[0].risk_owners,
                    designations
                  ),
                  risk_correspondents: formatEmployees(
                    activeFormulars[0].risk_correspondents,
                    designations
                  ),
                });
              }
            } else {
              addNewFormular();
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    baseRequest
      .get("business_processes", { withCredentials: true })
      .then((res) => {
        setProcesses(res.data.business_processes);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    baseRequest
      .get("/risk_groups", { withCredentials: true })
      .then((res) => {
        setLoading(false);

        setRiskGroups(res.data.risk_groups);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
    baseRequest
      .get("/risk_levels", { withCredentials: true })
      .then((res) => {
        setLoading(false);

        setRisksLevels(formatRiskLevels(res.data.risk_levels));
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (formular.selectedProcess) {
      setFormular((formular) => {
        return { ...formular, ["process_code"]: formular.selectedProcess.code };
      });
    }
  }, [formular.selectedProcess]);

  useEffect(() => {
    const cInf = calculateControlInfluence(
      formular.probability_id,
      formular.precaution_influence_percentage
    );

    setControlInfluence(cInf);

    const tot = calculateTotalValue(
      formular.probability_id,
      formular.influence_id
    ).total;
    if (tot && controlInfluence) {
      const preostaliRizik = (tot - controlInfluence) * 10;
      const stringValue =
        preostaliRizik < 50
          ? "nizak"
          : preostaliRizik > 59
          ? "visok"
          : "srednji";
      setPreostaliRizik({ preostaliRizik: `${preostaliRizik}%`, stringValue });
    } else if (tot && controlInfluence === 0) {
      const preostaliRizik = (tot - controlInfluence) * 10;
      const stringValue =
        preostaliRizik < 50
          ? "nizak"
          : preostaliRizik > 59
          ? "visok"
          : "srednji";
      setPreostaliRizik({ preostaliRizik: `${preostaliRizik}%`, stringValue });
    } else {
      setPreostaliRizik("");
    }
  }, [formular, controlInfluence]);

  function formatDesignations(list) {
    return list.map((item) => {
      return {
        id: item.id,
        first_name: item.first_name,
        last_name: item.last_name,
        name: `${item.first_name} ${item.last_name} - ${item.designation_name}`,
      };
    });
  }

  const formatRiskLevels = (list) => {
    return list.map((item) => {
      return {
        name: `${item.id}. ${item.name}`,
        id: item.id,
        basic_name: item.name,
      };
    });
  };

  function setInterviewedPersons(value) {
    if (value.length) {
      validateField("risk_interviewees");
    }
    setFormular((formular) => {
      return { ...formular, risk_interviewees: value };
    });
  }

  function handleSelectInterviewDate(date) {
    if (date) {
      validateField("interview_date");
    }
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let currentDate = `${year}-${month}-${day}`;
    setFormular((formular) => {
      return {
        ...formular,
        ["interview_date"]: currentDate,
      };
    });
  }

  const handleSetProcessName = (value) => {
    if (value) {
      validateField("business_process_list_id");
    }
    const relativeFormulars = Object.values(formularList).filter(
      (element) => element.business_process_list_id === value.value.id
    );
    const formularNumber =
      relativeFormulars.length > 0 ? relativeFormulars.length + 1 : 1;
    setFormular((formular) => {
      return {
        ...formular,
        business_process_list: value.value,
        business_process_list_id: value.value.id,
        number: formularNumber,
      };
    });
  };

  const getProcessOwners = (list, designationsList) => {
    if (list && list.length) {
      return list.map((owner) => {
        const desName = designationsList.find(
          (des) => des.id === owner.employee_id
        ).name;

        return desName;
      });
    } else {
      return "";
    }
  };

  const selectRistOwners = (value) => {
    if (value.length) {
      validateField("risk_owners");
    }
    setFormular((formular) => {
      return { ...formular, ["risk_owners"]: value };
    });
  };

  const handleChange = (key, isNumeric) => {
    return function (e) {
      if (e.target.value.trimStart() && !isNumeric) {
        setFormular((formular) => {
          return { ...formular, [key]: e.target.value.trimStart() };
        });
        validateField(key);
      } else {
        const validNumber = new RegExp(/^\d*\.?\d*$/);
        const value = e.target.value.trimStart();

        if (validNumber.test(value)) {
          validateField(key);
          setInfluenceValidationError(null);
          setFormular((formular) => {
            return { ...formular, [key]: value === "" ? "" : +value };
          });
        } else if (formular[key]) {
          e.preventDefault();
        } else {
          setInfluenceValidationError({
            influenceValidationError: "Samo brojevi",
          });
        }
      }
    };
  };

  function setGroupRisk(value) {
    if (value) {
      validateField("risk_group_id");
    }
    setFormular((formular) => {
      return {
        ...formular,
        ["risk_group"]: value.value,
        risk_group_id: value.value.id,
      };
    });
  }

  function setManagementMethod(value) {
    if (value) {
      validateField("management_method");
    }
    setFormular((formular) => {
      return {
        ...formular,
        ["management_method"]: value.value.name,
        management_method_id: value.value.id,
      };
    });
  }

  function setRiskProbability(value) {
    if (value) {
      validateField("probability_id");
    }
    setFormular((formular) => {
      return {
        ...formular,
        ["probability_id"]: value.value.id,
        selectedRiskProbability: value.value,
      };
    });
  }

  function setRiskInfluence(value) {
    if (value) {
      validateField("influence_id");
    }
    setFormular((formular) => {
      return {
        ...formular,
        ["influence_id"]: value.value.id,
        selectedRiskEffect: value.value,
      };
    });
  }

  function handleSelectPrecautionDate(date) {
    if (date) {
      validateField("precaution_date");
    }
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let currentDate = `${year}-${month}-${day}`;
    setFormular((formular) => {
      return {
        ...formular,
        ["precaution_date"]: currentDate,
      };
    });
  }

  const calculateTotalValue = (probability, influence) => {
    if (probability && influence) {
      const percentage = (probability + influence) * 10;
      const stringValue =
        percentage < 50 ? "nizak" : percentage > 59 ? "visok" : "srednji";
      return {
        total: probability + influence,
        percentage: `${percentage}%`,
        stringValue,
      };
    }
    return "";
  };

  const calculateControlInfluence = (probability, estimatedInfluence) => {
    if (probability && estimatedInfluence) {
      const value = (probability * estimatedInfluence) / 100;

      if (Number.isInteger(value)) {
        return +value;
      } else {
        return value.toFixed(1);
      }
    } else if (probability && estimatedInfluence === 0) {
      const value = (probability * estimatedInfluence) / 100;

      if (Number.isInteger(value)) {
        return +value;
      } else {
        return +value.toFixed(1);
      }
    }

    return "";
  };

  function setCorrespondentEmployees(value) {
    if (value.length) {
      validateField("risk_correspondents");
    }
    setFormular((formular) => {
      return { ...formular, ["risk_correspondents"]: value };
    });
  }

  const handleSubmitFormular = () => {
    if (validateAllFields(formular)) {
      return;
    }
    const { risk_correspondents, risk_interviewees, risk_owners } = formular;
    const formatted_risk_correspondent_employees = risk_correspondents.map(
      (item) => {
        return { id: item.value.id };
      }
    );
    const formatted_risk_interviewee_employees = risk_interviewees.map(
      (item) => {
        return { id: item.value.id };
      }
    );

    const formatted_risk_owner_employees = risk_owners.map((item) => {
      return { id: item.value.id };
    });
    const formattedFormular = {
      ...formular,
      risk_correspondent_employees: formatted_risk_correspondent_employees,
      risk_interviewee_employees: formatted_risk_interviewee_employees,
      risk_owner_employees: formatted_risk_owner_employees,
    };
    setLoading(true);
    if (formular.id) {
      baseRequest
        .put("risk_form", formattedFormular, { withCredentials: true })
        .then((res) => {
          setLoading(false);
          setErrorMessages(null);
          setErrors(null);
          setFormularList((list) => {
            return {
              ...list,
              [res.data.data.id]: { ...res.data.data, ...formular },
            };
          });
          setFormular((formular) => {
            return { ...formular, id: res.data.data.id };
          });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      baseRequest
        .post("risk_form", formattedFormular, { withCredentials: true })
        .then((res) => {
          setLoading(false);
          setErrorMessages(null);
          setErrors(null);
          setFormularList((list) => {
            return {
              ...list,
              [res.data.data.id]: { ...res.data.data, ...formular },
            };
          });
          setFormular((formular) => {
            return { ...formular, id: res.data.data.id };
          });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };
  const addNewFormular = () => {
    setFormular({});
    setControlInfluence("");
    setPreostaliRizik("");
  };

  const formatDateToObject = (date) => {
    const dateStringArray = date.split("-");

    return new Date(
      dateStringArray[0],
      +dateStringArray[1] - 1,
      dateStringArray[2]
    );
  };

  const validateAllFields = (formular) => {
    const requiredFields = [
      "interview_date",
      "business_process_list_id",
      "name",
      "description",
      "cause",
      "effect",
      "risk_group_id",
      "probability_id",
      "influence_id",
      "management_method",

      "precaution_influence_percentage",
      "precaution_date",

      "risk_interviewees",
      "risk_owners",

      "business_process_list",
      "risk_group",
    ];
    const {
      created_at,
      del,
      id,
      is_archived,
      last_updated,
      number,
      tenant_id,
      updated_at,
      user_id_last_updated,
      ...rest
    } = formular;
    const errors = [];
    const isEmpty = Object.keys(formular).length === 0;
    if (isEmpty) {
      setErrorMessages("Popunite sva polja");
      return true;
    } else {
      requiredFields.forEach((field) => {
        if (rest[field] && Array.isArray(rest[field]) && !rest[field].length) {
          errors.push(field);
        } else if (
          !rest[field] &&
          field !== "precaution_influence_percentage"
        ) {
          errors.push(field);
        } else if (
          field === "precaution_influence_percentage" &&
          typeof formular[field] !== "number"
        ) {
          errors.push(field);
        }
      });
      if (errors.length) {
        setErrorMessages("Popunite sva polja");
        setErrors(errors);
        return true;
      }
      return false;
    }
  };

  const validateField = (field) => {
    if (errors && errors.includes(field)) {
      setErrors((errors) => {
        if (errors.length === 1) {
          setErrorMessages(null);
        }

        const filteredErrors = errors.filter((error) => error !== field);
        return filteredErrors;
      });
    }
  };

  const handleDeleteFormular = (id) => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}risk_form`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: { id: id },
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);

        setFormularList((list) => {
          return {
            ...list,
            [res.data.data.id]: { ...list[res.data.data.id], del: 1 },
          };
        });

        if (res.data.data.id === formular.id) {
          const activeFormulars = Object.values(formularList).filter(
            (value) => !value.del
          );
          if (activeFormulars.length > 1) {
            setFormular(
              activeFormulars.filter((form) => form.id !== res.data.data.id)[0]
            );
          } else {
            // addNewFormular();
          }
        }
        navigate("/admin/formulari-rizika");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const hasEditPermission = (user, org_unit, level) => {
    if (user.role_id === 1) {
      return true;
    } else if (
      user.role_id > 1 &&
      user.organization_unit_id === org_unit &&
      user.level === level
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div
        className="processes-header"
        style={{ padding: "15px 0", justifyContent: "space-between" }}
      >
        <div className="back-arrow">
          <img src="/images/backarrow.png" />
          <Link to="/admin/formulari-rizika">Nazad</Link>
        </div>
        <div className="sort-options-dropdown" style={{ width: "200px" }}>
          <div
            className="sort-options-dropdown-selected"
            onClick={() => {
              // setShowDropdown((state) => !state);
            }}
          >
            <span style={{ marginRight: "7px", paddingLeft: "10px" }}>
              Opcije
            </span>

            <img
              alt=""
              src="/images/sidebararrow.png"
              className="sidebar-navigation-item-arrow"
            ></img>
          </div>
          {/* {showDropdown && (
            <ul
              className="sort-options-dropdown-options"
              style={{ left: "0", zIndex: "100", flex: 1 }}
            >
              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setShowDropdown(false);
                }}
              >
                Izmenite
              </li>

              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setShowDropdown(false);
                }}
              >
                Odštampaj
              </li>
            </ul>
          )} */}
        </div>
        {/* <div>
          <button className="add-btn-large" onClick={createNewFormular}>
            <img alt="" src="/images/plus.png"></img>
          </button>
        </div> */}
        <div style={{ marginRight: "10px" }}>
          {user && processes && formular && (
            <button className="add-btn-large" onClick={handlePrint}>
              <img alt="" src="/images/printer.png"></img>
            </button>
          )}
        </div>
      </div>

      <div
        className="poslovni-procesi"
        style={{
          width: "auto",
          marginLeft: "50px",
          marginTop: "30px",
        }}
      >
        {Object.keys(formular).length > 0 && (
          <div
            className="poslovni-procesi-tables"
            style={{
              width: "100%",
            }}
          >
            <DropdownWrapper
              title="INTERVJUISANO LICE I NJEGOVA FUNKCIJA"
              wrapperStyle={wrapperStyle}
              style={style}
            >
              <DropDown
                list={designations}
                isMulti={true}
                disabled={
                  hasEditPermission(
                    user,
                    formular.organization_unit_id,
                    formular.level
                  ) === false
                }
                onChange={setInterviewedPersons}
                selectedValues={
                  formular?.risk_interviewees ? formular?.risk_interviewees : ""
                }
                dropDownStyle={
                  errors && errors.includes("risk_interviewees")
                    ? errorStyle
                    : {}
                }
              />
            </DropdownWrapper>
            <DropdownWrapper
              title="DATUM INTERVJUA"
              wrapperStyle={wrapperStyle}
              style={style}
            >
              <div
                style={
                  errors && errors.includes("interview_date") ? errorStyle : {}
                }
              >
                <Calendar
                  handleSelectDate={handleSelectInterviewDate}
                  parentStartDate={
                    formular.interview_date
                      ? formatDateToObject(formular.interview_date)
                      : ""
                  }
                  disabled={
                    hasEditPermission(
                      user,
                      formular.organization_unit_id,
                      formular.level
                    ) === false
                  }
                />
              </div>
            </DropdownWrapper>
            <DropdownWrapper
              title="IME PROCESA"
              wrapperStyle={wrapperStyle}
              style={style}
            >
              <DropDown
                isMulti={false}
                disabled={
                  // hasEditPermission(
                  //   user,
                  //   formular.organization_unit_id,
                  //   formular.level
                  // ) === false
                  true
                }
                list={formatProcesses(processes) || []}
                onChange={handleSetProcessName}
                selectedValue={formular?.business_process_list?.name || ""}
                dropDownStyle={
                  errors && errors.includes("business_process_list_id")
                    ? errorStyle
                    : {}
                }
              />
            </DropdownWrapper>
            <DropdownWrapper
              title="VLASNIK PROCESA"
              wrapperStyle={wrapperStyle}
              style={style}
            >
              {formular.business_process_list && (
                <ul className="static-list">
                  {getProcessOwners(
                    formular.business_process_list.business_process_owners,
                    designations
                  ).map((el) => {
                    return <li key={el}>{el}</li>;
                  })}
                </ul>
              )}
            </DropdownWrapper>
            <DropdownWrapper
              title="BROJ"
              wrapperStyle={wrapperStyle}
              style={style}
            >
              <div className="broj">{formular?.number}</div>
            </DropdownWrapper>
            <DropdownWrapper title="FUNKCIJA/VLASNIK RIZIKA" style={style}>
              <DropDown
                list={designations}
                isMulti={true}
                disabled={
                  hasEditPermission(
                    user,
                    formular.organization_unit_id,
                    formular.level
                  ) === false
                }
                onChange={selectRistOwners}
                selectedValues={
                  formular.risk_owners ? formular.risk_owners : ""
                }
                dropDownStyle={
                  errors && errors.includes("risk_owners") ? errorStyle : {}
                }
              />
            </DropdownWrapper>
            <div className="empty-cell"></div>
            <DropdownWrapper
              title="ime rizika"
              wrapperStyle={wrapperStyle}
              style={style}
            >
              <DynamicTextarea
                onChange={handleChange("name")}
                value={formular.name ? formular.name : ""}
                style={errors && errors.includes("name") ? errorStyle : {}}
                readOnly={
                  hasEditPermission(
                    user,
                    formular.organization_unit_id,
                    formular.level
                  ) === false
                }
              />
            </DropdownWrapper>
            <DropdownWrapper
              title="opis rizika"
              wrapperStyle={wrapperStyle}
              style={style}
            >
              <DynamicTextarea
                style={
                  errors && errors.includes("description")
                    ? errorStyle
                    : textAreaStyle
                }
                autoFocus
                placeHolder="*opis rizika"
                onChange={handleChange("description")}
                value={formular.description ? formular.description : ""}
                readOnly={
                  hasEditPermission(
                    user,
                    formular.organization_unit_id,
                    formular.level
                  ) === false
                }
              />
              <DynamicTextarea
                style={
                  errors && errors.includes("cause")
                    ? errorStyle
                    : textAreaStyle
                }
                autoFocus
                placeHolder="*uzroci"
                onChange={handleChange("cause")}
                value={formular.cause ? formular.cause : ""}
                readOnly={
                  hasEditPermission(
                    user,
                    formular.organization_unit_id,
                    formular.level
                  ) === false
                }
              />
              <DynamicTextarea
                placeHolder="*posledice"
                onChange={handleChange("effect")}
                value={formular.effect ? formular.effect : ""}
                style={
                  errors && errors.includes("effect")
                    ? errorStyle
                    : { paddingTop: "15px" }
                }
                readOnly={
                  hasEditPermission(
                    user,
                    formular.organization_unit_id,
                    formular.level
                  ) === false
                }
              />
            </DropdownWrapper>

            <DropdownWrapper
              title="grupa rizika"
              wrapperStyle={wrapperStyle}
              style={style}
            >
              <DropDown
                isMulti={false}
                list={riskGroups}
                onChange={setGroupRisk}
                selectedValue={
                  formular?.risk_group?.name ? formular.risk_group.name : ""
                }
                dropDownStyle={
                  errors && errors.includes("risk_group_id") ? errorStyle : {}
                }
                disabled={
                  hasEditPermission(
                    user,
                    formular.organization_unit_id,
                    formular.level
                  ) === false
                }
              />
            </DropdownWrapper>
            <DropdownWrapper
              title="verovatnoca (1-5)"
              wrapperStyle={wrapperStyle}
              style={style}
            >
              <DropDown
                isMulti={false}
                list={riskLevels}
                onChange={setRiskProbability}
                selectedValue={formular.probability_id}
                dropDownStyle={
                  errors && errors.includes("probability_id")
                    ? { ...errorStyle, height: "auto" }
                    : { height: "auto" }
                }
                disabled={
                  hasEditPermission(
                    user,
                    formular.organization_unit_id,
                    formular.level
                  ) === false
                }
              />
              <DynamicTextarea
                readOnly={true}
                style={{ borderTop: border }}
                value={
                  riskLevels && formular.probability_id
                    ? riskLevels.find(
                        (item) => item.id === formular.probability_id
                      ).basic_name
                    : ""
                }
              />
            </DropdownWrapper>

            <DropdownWrapper
              title="uticaj (1-5)"
              wrapperStyle={wrapperStyle}
              style={style}
            >
              <DropDown
                isMulti={false}
                list={riskLevels}
                onChange={setRiskInfluence}
                selectedValue={formular.influence_id}
                dropDownStyle={
                  errors && errors.includes("influence_id")
                    ? { ...errorStyle, height: "auto" }
                    : { height: "auto" }
                }
                disabled={
                  hasEditPermission(
                    user,
                    formular.organization_unit_id,
                    formular.level
                  ) === false
                }
              />
              <DynamicTextarea
                style={{ borderTop: border }}
                readOnly={true}
                value={
                  riskLevels && formular.influence_id
                    ? riskLevels.find(
                        (item) => item.id === formular.influence_id
                      ).basic_name
                    : ""
                }
              />
            </DropdownWrapper>

            <DropdownWrapper
              title="UKUPNA VREDNOST (verovatnoća + uticaj)"
              style={style}
            >
              <DynamicTextarea
                style={{
                  borderBottom: border,
                  backgroundColor: disabledBackground,
                  padding: "10px",
                }}
                readOnly={true}
                value={
                  formular.probability_id
                    ? calculateTotalValue(
                        formular.probability_id,
                        formular.influence_id
                      ).total
                    : ""
                }
              />
              <DynamicTextarea
                style={{
                  borderBottom: border,
                  backgroundColor: disabledBackground,
                  padding: "10px",
                }}
                readOnly={true}
                value={
                  formular.probability_id
                    ? calculateTotalValue(
                        formular.probability_id,
                        formular.influence_id
                      ).percentage
                    : ""
                }
              />
              <DynamicTextarea
                style={{
                  padding: "10px",
                  backgroundColor: calculateTotalValue(
                    formular.probability_id,
                    formular.influence_id
                  ).stringValue
                    ? riskColors[
                        calculateTotalValue(
                          formular.probability_id,
                          formular.influence_id
                        ).stringValue
                      ]
                    : disabledBackground,
                }}
                readOnly={true}
                value={
                  formular.probability_id
                    ? calculateTotalValue(
                        formular.probability_id,
                        formular.influence_id
                      ).stringValue
                    : ""
                }
              />
            </DropdownWrapper>
            <DropdownWrapper
              title="NAČIN UPRAVLJANJA RIZIKOM"
              wrapperStyle={multiWrapperStyle}
              style={style}
            >
              <DropDown
                isMulti={false}
                list={formular.risk_management_methods}
                onChange={setManagementMethod}
                selectedValue={
                  formular.management_method ? formular.management_method : ""
                }
                dropDownStyle={
                  errors && errors.includes("management_method")
                    ? errorStyle
                    : {}
                }
                disabled={
                  hasEditPermission(
                    user,
                    formular.organization_unit_id,
                    formular.level
                  ) === false
                }
              />
            </DropdownWrapper>
            <DropdownWrapper
              title="OPIS KONTROLA/MERA ZA UPRAVLJANJE RIZICIMA"
              wrapperStyle={wrapperStyle}
              style={style}
            >
              <DynamicTextarea
                onChange={handleChange(
                  "management_method_precaution_description"
                )}
                value={
                  formular.management_method_precaution_description
                    ? formular.management_method_precaution_description
                    : ""
                }
                style={
                  errors &&
                  errors.includes("management_method_precaution_description")
                    ? errorStyle
                    : {}
                }
                readOnly={
                  hasEditPermission(
                    user,
                    formular.organization_unit_id,
                    formular.level
                  ) === false
                }
              />
            </DropdownWrapper>
            <DropdownWrapper
              title="PROCENJENI UTICAJ KONTROLA U %"
              wrapperStyle={wrapperStyle}
              style={style}
            >
              <DynamicTextarea
                onChange={handleChange("precaution_influence_percentage", true)}
                hasErrors={influenceValidationError}
                value={
                  formular.precaution_influence_percentage
                    ? formular.precaution_influence_percentage
                    : formular.precaution_influence_percentage === 0
                    ? 0
                    : ""
                }
                style={
                  errors && errors.includes("precaution_influence_percentage")
                    ? errorStyle
                    : {}
                }
                readOnly={
                  hasEditPermission(
                    user,
                    formular.organization_unit_id,
                    formular.level
                  ) === false
                }
              />
            </DropdownWrapper>
            <DropdownWrapper
              title="UTICAJ KONTROLA (0-5)"
              wrapperStyle={{ borderBottom: "none" }}
              style={style}
            >
              <DynamicTextarea
                readOnly={true}
                value={
                  controlInfluence
                    ? controlInfluence
                    : controlInfluence === 0
                    ? "0"
                    : ""
                }
                style={{ backgroundColor: disabledBackground, height: "100%" }}
              />
            </DropdownWrapper>
            <DropdownWrapper title="PREOSTALI RIZIK" style={style}>
              <DynamicTextarea
                style={{
                  borderBottom: border,
                  backgroundColor: disabledBackground,
                  padding: "10px",
                }}
                readOnly={true}
                value={
                  preostaliRizik.preostaliRizik
                    ? preostaliRizik.preostaliRizik
                    : ""
                }
              />
              <DynamicTextarea
                style={{
                  backgroundColor: preostaliRizik.stringValue
                    ? riskColors[preostaliRizik.stringValue]
                    : disabledBackground,
                  padding: "10px",
                }}
                readOnly={true}
                value={
                  preostaliRizik.stringValue ? preostaliRizik.stringValue : ""
                }
              />
            </DropdownWrapper>
            <div className="empty-cell"></div>
            <DropdownWrapper
              title="ROK ZA IZVRŠENJE KONTROLNIH MERA"
              wrapperStyle={wrapperStyle}
              style={style}
            >
              <div
                style={
                  errors && errors.includes("precaution_date") ? errorStyle : {}
                }
              >
                <Calendar
                  handleSelectDate={handleSelectPrecautionDate}
                  parentStartDate={
                    formular.precaution_date
                      ? formatDateToObject(formular.precaution_date)
                      : ""
                  }
                  disabled={
                    hasEditPermission(
                      user,
                      formular.organization_unit_id,
                      formular.level
                    ) === false
                  }
                />
              </div>
            </DropdownWrapper>
            <DropdownWrapper
              title="ODGOVORNA OSOBA ZA SPROVOĐENJE MERA"
              wrapperStyle={wrapperStyle}
              style={style}
            >
              <DropDown
                isMulti={true}
                list={designations}
                onChange={setCorrespondentEmployees}
                selectedValues={
                  formular.risk_correspondents
                    ? formular.risk_correspondents
                    : ""
                }
                dropDownStyle={
                  errors && errors.includes("risk_correspondents")
                    ? errorStyle
                    : {}
                }
                disabled={
                  hasEditPermission(
                    user,
                    formular.organization_unit_id,
                    formular.level
                  ) === false
                }
              />
            </DropdownWrapper>
            <DropdownWrapper
              title="STATUS IZVRŠENJA MERA"
              wrapperStyle={wrapperStyle}
              style={style}
            >
              <DynamicTextarea
                onChange={handleChange("precaution_status")}
                value={
                  formular.precaution_status ? formular.precaution_status : ""
                }
                style={
                  errors && errors.includes("precaution_status")
                    ? errorStyle
                    : {}
                }
                readOnly={
                  hasEditPermission(
                    user,
                    formular.organization_unit_id,
                    formular.level
                  ) === false
                }
              />
            </DropdownWrapper>
            <DropdownWrapper
              title="POSLEDNJE IZMENE UNEO"
              style={style}
              wrapperStyle={wrapperStyle}
            >
              <DynamicTextarea
                style={{ backgroundColor: disabledBackground, padding: 0 }}
                readOnly={true}
              />
            </DropdownWrapper>
            <DropdownWrapper title="DATUM POSLEDNJE IZMENE" style={style}>
              <DynamicTextarea
                style={{ backgroundColor: disabledBackground, padding: 0 }}
                readOnly={true}
              />
            </DropdownWrapper>
            {errorMessages && (
              <div className="errormessage">{errorMessages}</div>
            )}
            <div
              style={{
                padding: "20px 0",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {hasEditPermission(
                user,
                formular.organization_unit_id,
                formular.level
              ) && (
                <button
                  className="add-poslovni-proces-btn"
                  onClick={handleSubmitFormular}
                >
                  <img src="/images/save.png" />
                  <span>Sačuvajte formular rizika</span>
                </button>
              )}
              {formular &&
                formular.id &&
                hasEditPermission(
                  user,
                  formular.organization_unit_id,
                  formular.level
                ) && (
                  <button
                    className="add-poslovni-proces-btn"
                    onClick={() => handleDeleteFormular(formular.id)}
                  >
                    <img src="/images/trash.png" />
                    <span>Izbrisite formular rizika</span>
                  </button>
                )}
            </div>
          </div>
        )}
        {formular && (
          <div style={{ display: "none" }}>
            <FormularRizikaPrint
              ref={componentRef}
              formular={formular}
              riskLevels={riskLevels}
              controlInfluence={controlInfluence}
              designations={designations}
            />
          </div>
        )}
        <Loading isLoading={loading} />
      </div>
    </>
  );
}
