import React from "react";
import "./loginPage.css";
import LoginForm from "../../components/loginForm";

export default function LoginPage({ isLoggedin, setIsloggedin }) {
  return (
    <div className="login-page">
      <LoginForm isLoggedin={isLoggedin} setIsloggedin={setIsloggedin} />
    </div>
  );
}
