import React from "react";
import RegistriRizikaRow from "../registriRizikaRow";

export const RegistriRizikaList = React.forwardRef((props, ref) => {
  const headerTitles = [
    { id: 0, title: "R.BR", width: "70px" },
    { id: 1, title: "VLASNIK PROCESA", width: "170px" },
    { id: 2, title: "BROJ", width: "70px" },
    { id: 3, title: "FUNKCIJA/VLASNIK RIZIKA", width: "220px" },
    { id: 4, title: "IME RIZIKA", width: "170px" },
    { id: 5, title: "OPIS RIZIKA", width: "370px" },
    { id: 6, title: "GRUPA RIZIKA", width: "170px" },
    { id: 7, title: "VEROVATNOĆA (1-5)", width: "200px" },
    { id: 8, title: "UTICAJ (1-5)", width: "170px" },
    {
      id: 9,
      title: "UKUPNA VREDNOST ",
      subtitle: "(verovatnoća + uticaj)",
      width: "220px",
    },
    { id: 10, title: "NAČIN UPRAVLJANJA RIZIKOM", width: "220px" },
    {
      id: 11,
      title: "OPIS KONTROLA/MERA ZA",
      subtitle: "UPRAVLJANJE RIZICIMA",
      width: "220px",
    },
    {
      id: 12,
      title: "PROCENJENI UTICAJ  ",
      subtitle: "KONTROLA U %",
      width: "220px",
    },
    { id: 13, title: "UTICAJ KONTROLA (0-5)", width: "220px" },
    { id: 14, title: "PREOSTALI RIZIK", width: "220px" },
  ];

  const filterForms = (list, unit, process) => {
    const filteredMapsByUnit =
      unit.name === "ALL"
        ? list
        : list.filter(
            (process) =>
              process.business_process_list.business_process_id === unit.id
          );

    if (process) {
      const filteredMapsByProcesses =
        process.name === "ALL"
          ? filteredMapsByUnit
          : filteredMapsByUnit.filter(
              (pr) => pr.business_process_list_id === process.id
            );

      return filteredMapsByProcesses;
    }

    return filteredMapsByUnit;
  };

  return (
    <ul>
      {filterForms(
        props.riskForms,
        props.activeUnit,
        props.selectedProcess
      ).map((form, index) => {
        if (!form.del)
          return (
            <RegistriRizikaRow
              key={form.id}
              titles={headerTitles}
              row={form}
              redniBroj={index + 1}
              riskGroups={props.riskGroups}
            />
          );
      })}
    </ul>
  );
});
