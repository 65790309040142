import React, { useState } from "react";
import "./notification.css";

function Notification({ notification }) {
  const { date, message } = notification;
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div className="notification">
      <span>{date}</span>
      <span className="notification-message">{message}</span>
      <div className="sort-options-dropdown">
        <div
          className="sort-options-dropdown-selected"
          onClick={() => {
            setShowDropdown((state) => !state);
          }}
        >
          <span style={{ marginRight: "7px" }}>Opcije</span>

          <img
            src="/images/sidebararrow.png"
            className="sidebar-navigation-item-arrow"
          ></img>
        </div>
        {showDropdown && (
          <ul
            className="sort-options-dropdown-options"
            style={{ left: "unset", zIndex: "100", top: "calc(100% + 16px)" }}
          >
            <li
              className="sort-options-dropdown-options-item"
              onClick={() => {
                setShowDropdown(false);
              }}
            >
              Pregledajte
            </li>
            <li
              className="sort-options-dropdown-options-item"
              onClick={() => {
                setShowDropdown(false);
              }}
            >
              Izmenite
            </li>
            <li
              className="sort-options-dropdown-options-item"
              onClick={() => {
                setShowDropdown(false);
              }}
            >
              Odobrite
            </li>
            <li
              className="sort-options-dropdown-options-item"
              onClick={() => {
                setShowDropdown(false);
              }}
            >
              Odbijte
            </li>
            <li
              className="sort-options-dropdown-options-item"
              onClick={() => {
                setShowDropdown(false);
              }}
            >
              Odštampaj
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}

export default Notification;
