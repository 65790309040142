import React, { useEffect, useState, useContext } from "react";
import "./sidebar.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { baseRequest } from "../../../axiosconfig";
import { UnsavedDataContext } from "../../../context";
import { UserContext } from "../../../userContext";

function Sidebar({ isLoggedin, setIsloggedin, setLoading }) {
  function handleLogout() {
    setLoading(true);
    baseRequest
      .post("/logout", {}, { withCredentials: true })
      .then((res) => {
        navigate("/login");
        setIsloggedin(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  const { user } = useContext(UserContext);

  const showItem = (user) => {
    return user.role_id === 1;
  };

  const { unsavedData, setShowNavigatePopup, setNavigateUrl } =
    useContext(UnsavedDataContext);

  const items = [
    {
      id: 1,
      name: "OBAVEŠTENJA",
      icon: "/images/bell.png",
      iconActive: "/images/bellactive.png",
      url: "/admin/notifications",
    },
    {
      id: 2,
      name: "fuk",
      icon: "/images/fuk.png",
      iconActive: "/images/fukactive.png",
      url: "/admin/fuk",
      multiple: true,
      items: [
        {
          id: 1,
          name: "Liste poslovnih procesa",
          url: "/admin/poslovni-procesi",
        },
        { id: 2, name: "Mape procesa", url: "/admin/mape-procesa" },
        { id: 3, name: "Formulari rizika", url: "/admin/formulari-rizika" },
        { id: 4, name: "Registri rizika", url: "/admin/registri-rizika" },
        { id: 5, name: "Grupe rizika" },
      ],
    },
    {
      id: 3,
      name: "organizacija",
      icon: "/images/globe1.png",
      iconActive: "/images/globe1active.png",
      multiple: true,
      items: [
        {
          id: 1,
          name: "Struktura preduzeća",
          url: "/admin/struktura-preduzeca",
        },
        { id: 2, name: "Lista zaposlenih", url: "/admin/lista-zaposlenih/0/0" },
      ],
    },
    {
      id: 4,
      name: "arhiva",
      icon: "/images/arhiv.png",
      iconActive: "/images/arhivactive.png",
      multiple: true,
      items: [
        { id: 1, name: "Liste poslovnih procesa" },
        { id: 2, name: "Mape procesa" },
        { id: 3, name: "Formulari rizika" },
      ],
    },
    {
      id: 5,
      name: "PODEŠAVANJA",
      icon: "/images/settings.png",
      iconActive: "/images/settingsactive.png",
      multiple: true,
      items: [
        {
          id: 1,
          name: "Autorizacija",
          url: "/admin/autorizacija",
          show: showItem(user),
        },
        { id: 2, name: "Kontaktirajte nas", url: "/admin/contact" },
        { id: 3, name: "Odjavite se", onClick: true },
      ],
    },
  ];
  const [activeNavigationItem, setActiveNavigationItem] = useState("");
  const [activeUrl, setActiveUrl] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    let url = location.pathname;

    if (location.pathname === "/admin/notifications") {
      setActiveUrl("/admin/notifications");
      setActiveNavigationItem("OBAVEŠTENJA");
      return;
    }

    if (params.id && location.pathname.includes("new")) {
      url = "/admin/mape-procesa";
    } else if (
      params.id &&
      !params.level &&
      !location.pathname.includes("new")
    ) {
      url = url.split(params.id)[0].slice(0, -1);
    } else if (!params.id && location.pathname.includes("new")) {
      url = "/admin/formulari-rizika";
    } else if (params.id && params.level) {
      url = "/admin/lista-zaposlenih/0/0";
    }

    setActiveUrl(url);

    const value = items.find((el) => {
      return el.multiple && el.items.find((it) => it.url === url);
    });
    setActiveNavigationItem(value.name);
  }, [location]);

  const handleClick = (item) => {
    if (!item.multiple) {
      navigate(item.url);
      setActiveNavigationItem(item.name);
    } else {
      setActiveNavigationItem((value) => {
        if (item.name === value) {
          return "";
        } else {
          return item.name;
        }
      });
    }
  };

  return (
    <div>
      <ul className="sidebar">
        {items.map((item) => {
          return (
            <div
              key={item.id}
              className={
                item.name === activeNavigationItem
                  ? "sidebar-navigation-item sidebar-navigation-item-active"
                  : "sidebar-navigation-item"
              }
            >
              <div
                // to={item.url}
                onClick={() => handleClick(item)}
                className={
                  item.name === activeNavigationItem
                    ? "sidebar-navigation-item-link sidebar-navigation-item-link-active"
                    : "sidebar-navigation-item-link"
                }
                style={{
                  borderBottom:
                    item.multiple && item.name === activeNavigationItem
                      ? "1px solid var(--mainColor)"
                      : "none",
                }}
              >
                <div className="flex-center">
                  <img
                    alt="icon"
                    src={
                      item.name === activeNavigationItem
                        ? item.iconActive
                        : item.icon
                    }
                    className={
                      item.name === activeNavigationItem
                        ? "sidebar-navigation-item-icon sidebar-navigation-link-active"
                        : "sidebar-navigation-item-icon"
                    }
                  ></img>
                  <span> {item.name}</span>
                </div>

                {item.multiple && (
                  <img
                    alt="icon"
                    src={
                      item.name === activeNavigationItem
                        ? "/images/sidebararrowup.png"
                        : "/images/sidebararrow.png"
                    }
                    className="sidebar-navigation-item-arrow"
                  ></img>
                )}
              </div>

              {item.multiple && item.name === activeNavigationItem && (
                <ul className="sidebar-navigation-subitems-list">
                  {item?.items &&
                    item.items.map((element) => {
                      if (
                        element.name !== "Autorizacija" ||
                        (element.name === "Autorizacija" && element.show)
                      )
                        return (
                          <li
                            onClick={
                              element.onClick
                                ? handleLogout
                                : () => {
                                    if (!unsavedData) {
                                      navigate(element.url);
                                    } else if (
                                      unsavedData &&
                                      location.pathname !== element.url
                                    ) {
                                      setShowNavigatePopup(true);
                                      setNavigateUrl(element.url);
                                    }
                                  }
                            }
                            key={element.id}
                            className="sidebar-navigation-subitem"
                          >
                            <span
                              style={{
                                borderBottom:
                                  element.url === activeUrl
                                    ? "1px solid var(--mainColor)"
                                    : "none",
                                fontWeight:
                                  element.url === activeUrl ? "bold" : "normal",
                              }}
                            >
                              {element.name}
                            </span>
                          </li>
                        );
                    })}
                </ul>
              )}
            </div>
          );
        })}
      </ul>
    </div>
  );
}

export default Sidebar;
