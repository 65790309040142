import React, { useContext } from "react";

import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { UserContext } from "../../../userContext";

export const FormularRizikaPrint = React.forwardRef((props, ref) => {
  const [preostaliRizik, setPreostaliRizik] = useState("");

  const { user } = useContext(UserContext);

  const disabledBackground = "#eee";

  const riskColors = {
    nizak: "#B0E0E6",
    srednji: "#FFD700",
    visok: "#FF5C5C",
  };

  useEffect(() => {
    const tot = calculateTotalValue(
      props.formular.probability_id,
      props.formular.influence_id
    ).total;
    if (tot && props.controlInfluence) {
      const preostaliRizik = (tot - props.controlInfluence) * 10;
      const stringValue =
        preostaliRizik < 50
          ? "nizak"
          : preostaliRizik > 59
          ? "visok"
          : "srednji";
      setPreostaliRizik({ preostaliRizik: `${preostaliRizik}%`, stringValue });
    } else if (tot && props.controlInfluence === 0) {
      const preostaliRizik = (tot - props.controlInfluence) * 10;
      const stringValue =
        preostaliRizik < 50
          ? "nizak"
          : preostaliRizik > 59
          ? "visok"
          : "srednji";
      setPreostaliRizik({ preostaliRizik: `${preostaliRizik}%`, stringValue });
    } else {
      setPreostaliRizik("");
    }
  }, [props.formular, props.controlInfluence]);

  const calculateTotalValue = (probability, influence) => {
    if (probability && influence) {
      const percentage = (probability + influence) * 10;
      const stringValue =
        percentage < 50 ? "nizak" : percentage > 59 ? "visok" : "srednji";
      return {
        total: probability + influence,
        percentage: `${percentage}%`,
        stringValue,
      };
    }
    return "";
  };

  const getProcessOwners = (list, designationsList) => {
    if (list && list.length) {
      return list.map((owner) => {
        const desName = designationsList.find(
          (des) => des.id === owner.employee_id
        ).name;

        return desName;
      });
    } else {
      return "";
    }
  };

  return (
    <>
      {Object.keys(props.formular).length > 0 && (
        <div ref={ref} style={{ width: "90%", margin: "20px auto 20px auto" }}>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              INTERVJUISANO LICE I NJEGOVA FUNKCIJA
            </p>
            {props.formular?.risk_interviewees &&
              props.formular?.risk_interviewees.length > 0 && (
                <ul style={{ width: "65%" }}>
                  {props.formular?.risk_interviewees.map((el) => {
                    return (
                      <li key={el.id} style={{ padding: "5px" }}>
                        {el.label}
                      </li>
                    );
                  })}
                </ul>
              )}
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              DATUM INTERVJUA
            </p>

            <p
              style={{
                width: "65%",
                fontSize: "15px",
                color: "black",
                padding: "5px",
              }}
            >
              {props.formular.interview_date
                ? props.formular.interview_date.split(" ")[0]
                : ""}
            </p>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              IME PROCESA
            </p>

            <p
              style={{
                width: "65%",
                fontSize: "15px",
                color: "black",
                padding: "5px",
              }}
            >
              {props.formular?.business_process_list?.name}
            </p>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              VLASNIK PROCESA
            </p>
            {props.formular.business_process_list && (
              <ul style={{ width: "65%", padding: "5px" }}>
                {getProcessOwners(
                  props.formular.business_process_list.business_process_owners,
                  props.designations
                ).map((el) => {
                  return <li key={el}>{el}</li>;
                })}
              </ul>
            )}
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              BROJ
            </p>

            <p
              style={{
                width: "65%",
                fontSize: "15px",
                color: "black",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {props.formular?.number}
            </p>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              FUNKCIJA/VLASNIK PROCESA
            </p>
            {props.formular.business_process_list && (
              <ul style={{ width: "65%", padding: "5px" }}>
                {props.formular.risk_owners.map((el) => {
                  return <li key={el.id}>{el.label}</li>;
                })}
              </ul>
            )}
          </div>

          <div className="empty-cell"></div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              IME RIZIKA
            </p>

            <p
              style={{
                width: "65%",
                fontSize: "15px",
                color: "black",
                padding: "5px",
              }}
            >
              {props.formular.name ? props.formular.name : ""}
            </p>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              OPIS RIZIKA
            </p>
            <div style={{ width: "65%" }}>
              <div style={{ width: "100%", position: "relative" }}>
                <span
                  style={{
                    position: "absolute",
                    color: "black",
                    fontSize: "12px",
                    fontWeight: "bold",
                    top: "2px",
                    left: "3px",
                  }}
                >
                  *opis rizika
                </span>
                <p
                  style={{
                    width: "100%",
                    fontSize: "15px",
                    color: "black",
                    padding: "15px 5px 5px 5px",
                    borderBottom: "1px solid black",
                    wordWrap: "break-word",
                  }}
                >
                  {props.formular.description ? props.formular.description : ""}
                </p>
              </div>
              <div style={{ width: "100%", position: "relative" }}>
                <span
                  style={{
                    position: "absolute",
                    color: "black",
                    fontSize: "12px",
                    fontWeight: "bold",
                    top: "2px",
                    left: "3px",
                  }}
                >
                  *uzroci
                </span>
                <p
                  style={{
                    width: "100%",
                    fontSize: "15px",
                    color: "black",
                    padding: "15px 5px 5px 5px",
                    borderBottom: "1px solid black",
                    wordWrap: "break-word",
                  }}
                >
                  {props.formular.cause ? props.formular.cause : ""}
                </p>
              </div>
              <div style={{ width: "100%", position: "relative" }}>
                <span
                  style={{
                    position: "absolute",
                    color: "black",
                    fontSize: "12px",
                    fontWeight: "bold",
                    top: "2px",
                    left: "3px",
                  }}
                >
                  *posledice
                </span>
                <p
                  style={{
                    width: "100%",
                    fontSize: "15px",
                    color: "black",
                    padding: "15px 5px 5px 5px",
                    borderBottom: "1px solid black",
                    wordWrap: "break-word",
                  }}
                >
                  {props.formular.effect ? props.formular.effect : ""}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              GRUPA RIZIKA
            </p>

            <p
              style={{
                width: "65%",
                fontSize: "15px",
                color: "black",
                padding: "5px",
              }}
            >
              {props.formular?.risk_group?.name
                ? props.formular.risk_group.name
                : ""}
            </p>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              VEROVATNOCA (1-5)
            </p>
            <div style={{ width: "65%" }}>
              <p
                style={{
                  width: "100%",
                  fontSize: "15px",
                  color: "black",
                  padding: "5px",
                }}
              >
                {props.formular.probability_id}
              </p>
              <p
                style={{
                  width: "100%",
                  fontSize: "15px",
                  color: "black",
                  padding: "5px",
                  borderTop: "1px solid black",
                }}
              >
                {props.riskLevels && props.formular.probability_id
                  ? props.riskLevels.find(
                      (item) => item.id === props.formular.probability_id
                    ).basic_name
                  : ""}
              </p>
            </div>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              UTICAJ (1-5)
            </p>
            <div style={{ width: "65%" }}>
              <p
                style={{
                  width: "100%",
                  fontSize: "15px",
                  color: "black",
                  padding: "5px",
                }}
              >
                {props.formular.influence_id}
              </p>
              <p
                style={{
                  width: "100%",
                  fontSize: "15px",
                  color: "black",
                  padding: "5px",
                  borderTop: "1px solid black",
                }}
              >
                {props.riskLevels && props.formular.influence_id
                  ? props.riskLevels.find(
                      (item) => item.id === props.formular.influence_id
                    ).basic_name
                  : ""}
              </p>
            </div>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              UKUPNA VREDNOST (verovatnoća + uticaj)
            </p>
            <div style={{ width: "65%" }}>
              <p
                style={{
                  width: "100%",
                  fontSize: "15px",
                  color: "black",
                  padding: "5px",
                }}
              >
                {props.formular.probability_id
                  ? calculateTotalValue(
                      props.formular.probability_id,
                      props.formular.influence_id
                    ).total
                  : ""}
              </p>
              <p
                style={{
                  width: "100%",
                  fontSize: "15px",
                  color: "black",
                  padding: "5px",
                  borderTop: "1px solid black",
                }}
              >
                {props.formular.probability_id
                  ? calculateTotalValue(
                      props.formular.probability_id,
                      props.formular.influence_id
                    ).percentage
                  : ""}
              </p>
              <p
                style={{
                  width: "100%",
                  fontSize: "15px",
                  color: "black",
                  padding: "5px",
                  backgroundColor: calculateTotalValue(
                    props.formular.probability_id,
                    props.formular.influence_id
                  ).stringValue
                    ? riskColors[
                        calculateTotalValue(
                          props.formular.probability_id,
                          props.formular.influence_id
                        ).stringValue
                      ]
                    : disabledBackground,
                }}
              >
                {props.formular.probability_id
                  ? calculateTotalValue(
                      props.formular.probability_id,
                      props.formular.influence_id
                    ).stringValue
                  : ""}
              </p>
            </div>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              NAČIN UPRAVLJANJA RIZIKOM
            </p>

            <p
              style={{
                width: "65%",
                fontSize: "15px",
                color: "black",
                padding: "5px",
              }}
            >
              {props.formular.management_method
                ? props.formular.management_method
                : ""}
            </p>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              OPIS KONTROLA/MERA ZA UPRAVLJANJE RIZICIMA
            </p>

            <p
              style={{
                width: "65%",
                fontSize: "15px",
                color: "black",
                padding: "5px",
                wordWrap: "break-word",
              }}
            >
              {props.formular.management_method_precaution_description
                ? props.formular.management_method_precaution_description
                : ""}
            </p>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              PROCENJENI UTICAJ KONTROLA U %
            </p>

            <p
              style={{
                width: "65%",
                fontSize: "15px",
                color: "black",
                padding: "5px",
              }}
            >
              {props.formular.precaution_influence_percentage
                ? props.formular.precaution_influence_percentage
                : props.formular.precaution_influence_percentage === 0
                ? 0
                : ""}
            </p>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              UTICAJ KONTROLA (0-5)
            </p>

            <p
              style={{
                width: "65%",
                fontSize: "15px",
                color: "black",
                padding: "5px",
              }}
            >
              {props.controlInfluence
                ? props.controlInfluence
                : props.controlInfluence === 0
                ? "0"
                : ""}
            </p>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              PREOSTALI RIZIK
            </p>
            <div style={{ width: "65%" }}>
              <p
                style={{
                  width: "100%",
                  fontSize: "15px",
                  color: "black",
                  padding: "5px",
                }}
              >
                {preostaliRizik.preostaliRizik
                  ? preostaliRizik.preostaliRizik
                  : ""}
              </p>
              <p
                style={{
                  width: "100%",
                  fontSize: "15px",
                  color: "black",
                  padding: "5px",
                  height: "calc(1.6em + 15px)",
                  borderTop: "1px solid black",
                  backgroundColor: preostaliRizik.stringValue
                    ? riskColors[preostaliRizik.stringValue]
                    : disabledBackground,
                }}
              >
                {preostaliRizik.stringValue ? preostaliRizik.stringValue : ""}
              </p>
            </div>
          </div>

          <div className="empty-cell"></div>

          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              ROK ZA IZVRŠENJE KONTROLNIH MERA
            </p>

            <p
              style={{
                width: "65%",
                fontSize: "15px",
                color: "black",
                padding: "5px",
              }}
            >
              {props.formular.precaution_date
                ? props.formular.precaution_date.split(" ")
                : ""}
            </p>
          </div>

          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              ODGOVORNA OSOBA ZA SPROVOĐENJE MERA
            </p>

            <p
              style={{
                width: "65%",
                fontSize: "15px",
                color: "black",
                padding: "5px",
              }}
            >
              {props?.formular?.risk_correspondents && (
                <ul style={{ width: "65%", padding: "5px" }}>
                  {props.formular.risk_correspondents.map((el) => {
                    return <li key={el.id}>{el.label}</li>;
                  })}
                </ul>
              )}
            </p>
          </div>

          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              STATUS IZVRŠENJA MERA
            </p>

            <p
              style={{
                width: "65%",
                fontSize: "15px",
                color: "black",
                padding: "5px",
              }}
            >
              {props.formular.precaution_status
                ? props.formular.precaution_status
                : ""}
            </p>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              POSLEDNJE IZMENE UNEO
            </p>

            <p
              style={{
                width: "65%",
                fontSize: "15px",
                color: "black",
                padding: "5px",
              }}
            ></p>
          </div>
          <div
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
              borderRight: "1px solid black",
              borderLeft: "1px solid black",
              display: "flex",
              pageBreakInside: "avoid",
            }}
          >
            <p
              style={{
                width: "35%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                padding: "1.6em 5px",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "600",
                fontSize: "15px",
                backgroundColor: "#F0F0F0",
                textAlign: "center",
              }}
            >
              DATUM POSLEDNJE IZMENE
            </p>

            <p
              style={{
                width: "65%",
                fontSize: "15px",
                color: "black",
                padding: "5px",
              }}
            ></p>
          </div>
        </div>
      )}
    </>
  );
});
