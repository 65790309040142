import React, { useEffect, useState } from "react";
import "./addEmployeesList.css";
import SelectOrganizationsDropdown from "../elements/nestedDropDown";
import { baseRequest } from "../../axiosconfig";
import SelectDesignationDropdown from "../selectDesignationDropdown";
import DropDown from "../elements/dropDown";
import DesignationDescription from "../admin/DesignationDescription";

export default function EmployeesListItem({
  employee,
  handleChangeEmployee,
  handleSetEditEmployee,
  handleSelectDesignation,
  handleAddDesignationToList,
  deleteEmployee,
  handleSelectUnit,
  organization_units,
  designations,
  handleUpdateEmployee,
  emptyFieldsError,
  hasEditEmployeePermission,
  activeEmployee,
  showDesignationDescription,
  user,
  setShowDesignationDescription,
}) {
  const [showOrganizationsDropdown, setshowOrganizationsDropdown] =
    useState(false);
  const [showDesignationsDropdown, setshowDesignationsDropdown] =
    useState(false);

  const filterUnits = (list) => {
    return list
      .filter((item) => {
        return item.del == 0;
      })
      .map((item) => {
        if (!item.has_designations) {
          return { ...item, isDisabled: true };
        }
        return item;
      });
  };

  const filterDesignations = (list, employee) => {
    if (employee.level === 0) {
      return list.filter((item) => {
        return (item.del == 0 || item.del === false) && item.level === 0;
      });
    } else {
      return list.filter((item) => {
        return (
          item.level === employee.level &&
          item.parent_id === employee.parent_id &&
          (item.del == 0 || item.del === false)
        );
      });
    }
  };

  return (
    <>
      <form
        className="employees-list"
        style={{
          borderBottom: employee.typing ? "2px solid var(--mainColor)" : "none",
          borderTop: employee.typing
            ? "2px solid var(--mainColor)"
            : "1px solid var(--secondaryColor)",
          borderLeft: "1px solid var(--secondaryColor)",
          borderRight: "1px solid var(--secondaryColor)",
        }}
      >
        <div
          className="employees-list-item"
          style={{
            border:
              emptyFieldsError?.id === employee.id &&
              emptyFieldsError?.emptyFields.includes("first_name")
                ? "3px solid red"
                : "none",
            borderRight:
              emptyFieldsError?.id === employee.id &&
              emptyFieldsError?.emptyFields.includes("first_name")
                ? "3px solid red"
                : "1px solid var(--secondaryColor)",
            borderLeft:
              emptyFieldsError?.id === employee.id &&
              emptyFieldsError?.emptyFields.includes("first_name")
                ? "3px solid red"
                : employee.typing
                ? "2px solid var(--mainColor)"
                : "none",
          }}
        >
          <input
            type="text"
            readOnly={!employee?.typing}
            className="employees-list-input"
            onChange={(e) => handleChangeEmployee(e, "first_name", employee.id)}
            value={employee.first_name}
          />
        </div>
        <div
          className="employees-list-item"
          style={{
            border:
              emptyFieldsError?.id === employee.id &&
              emptyFieldsError?.emptyFields.includes("last_name")
                ? "3px solid red"
                : "none",
            borderRight:
              emptyFieldsError?.id === employee.id &&
              emptyFieldsError?.emptyFields.includes("last_name")
                ? "3px solid red"
                : "1px solid var(--secondaryColor)",
          }}
        >
          <input
            type="text"
            readOnly={!employee.typing}
            className="employees-list-input"
            onChange={(e) => handleChangeEmployee(e, "last_name", employee.id)}
            value={employee.last_name}
          />
        </div>
        <div
          className="employees-list-item"
          style={{
            border:
              emptyFieldsError?.id === employee.id &&
              emptyFieldsError?.emptyFields.includes("parent_id")
                ? "3px solid red"
                : "none",
            borderRight:
              emptyFieldsError?.id === employee.id &&
              emptyFieldsError?.emptyFields.includes("parent_id")
                ? "3px solid red"
                : "1px solid var(--secondaryColor)",
          }}
          id="employees-dropdown"
          onClick={
            employee?.typing && organization_units.length > 0
              ? () => {
                  setshowOrganizationsDropdown((showDropdown) => !showDropdown);
                  setshowDesignationsDropdown(false);
                }
              : undefined
          }
        >
          {/* {employee.organization_unit && <p className="selected-unit">{employee.organization_unit}</p>} */}
          {/* <SelectOrganizationsDropdown
          handleSelectUnit={handleSelectUnit}
          showOrganizationsDropdown={showOrganizationsDropdown}
          organization_units={organization_units}
        />
        <div className="dropdown-arrow">
          <img src={showOrganizationsDropdown ? "/images/dropdownup.png" : "/images/dropdowndown.png"} />
        </div> */}
          <DropDown
            list={filterUnits(organization_units)}
            height={50}
            onChange={handleSelectUnit(employee)}
            selectedValue={
              employee.typing
                ? activeEmployee.activeUnit.organization_unit_name
                : employee.organization_unit_name
            }
            disabled={!employee?.typing}
            containerValue={{ alignItems: "center" }}
          />
        </div>
        <div
          className="employees-list-item"
          style={{
            border:
              emptyFieldsError?.id === employee.id &&
              emptyFieldsError?.emptyFields.includes("designation_id")
                ? "3px solid red"
                : "none",
            borderRight:
              emptyFieldsError?.id === employee.id &&
              emptyFieldsError?.emptyFields.includes("designation_id")
                ? "3px solid red"
                : "1px solid var(--secondaryColor)",
          }}
          id="employees-dropdown"
          onClick={
            employee?.typing && designations.length > 0
              ? () => {
                  setshowDesignationsDropdown((showDropdown) => !showDropdown);
                  setshowOrganizationsDropdown(false);
                }
              : undefined
          }
        >
          {/* <p className="selected-unit">{employee.designation_name}</p>
        <SelectDesignationDropdown
          designations={designations}
          showDesignationsDropdown={showDesignationsDropdown}
          handleSelectDesignation={handleSelectDesignation}
        />
        <div className="dropdown-arrow">
          <img src={showDesignationsDropdown ? "/images/dropdownup.png" : "/images/dropdowndown.png"} />
        </div> */}
          <DropDown
            list={filterDesignations(designations, employee)}
            height={50}
            onChange={handleSelectDesignation(employee)}
            selectedValue={
              employee?.designation?.name || employee.designation_name
            }
            disabled={!employee?.typing}
            containerValue={{ alignItems: "center" }}
          />
        </div>
        <div
          className="employees-list-item designation-description"
          style={{
            height: "auto",
            borderRight: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* {employee?.designation?.description || employee.description} */}
          {employee.description && (
            <button
              type="button"
              className="opis-radnog-mesta-btn"
              onClick={() =>
                setShowDesignationDescription({ id: employee.id, isOpen: true })
              }
            >
              Opis radnog mesta
            </button>
          )}
        </div>

        <div
          className="employees-list-item options-block"
          style={{
            borderRight: employee.typing
              ? "2px solid var(--mainColor)"
              : "1px solid var(--secondaryColor)",
            borderLeft: "1px solid var(--secondaryColor)",
          }}
        >
          {hasEditEmployeePermission(
            user,
            employee.parent_id,
            employee.level
          ) && (
            <>
              <button
                type="button"
                className="employee-item-options-button"
                onClick={
                  employee?.typing
                    ? () => {
                        // setshowOrganizationsDropdown(false);
                        //setshowDesignationsDropdown(false);
                        handleUpdateEmployee(employee);
                      }
                    : () => handleSetEditEmployee(employee)
                }
              >
                <img
                  alt=""
                  src={
                    employee?.typing ? "/images/check1.jpg" : "/images/edit.png"
                  }
                />
              </button>
              <button
                type="button"
                className="employee-item-options-button"
                onClick={() => {
                  deleteEmployee(employee);
                }}
              >
                <img src="/images/trash.png" />
              </button>
            </>
          )}
        </div>
      </form>
      {showDesignationDescription.isOpen &&
        showDesignationDescription.id === employee.id && (
          <div className="loading">
            <DesignationDescription
              description={employee.description}
              setShowDescription={setShowDesignationDescription}
            />
          </div>
        )}
    </>
  );
}
