import React from "react";

import DynamicTextarea from "../../elements/DynamicTextarea";
import { useNavigate } from "react-router-dom";

export default function RiskList({
  list = [],
  handleSetActiveProcedure,
  activeRisk,
  handleSetEditProcedure,
  handleChangeProcedureName,
  handleSaveProcedure,
  handleUpdateProcedure,
  setProcedureToDelete,
  mapa,
  processes,
  handleSetEditRisk,
  handleChangeRiskName,
  handleUpdateRisk,
  riskValidationError,
  setRiskToDelete,
}) {
  const handleProcedureClick = (procedure) => {
    handleSetActiveProcedure(procedure);
  };

  const navigate = useNavigate();

  const handleShowFormular = (id) => {
    navigate(`/admin/formulari-rizika/${id}`);
  };

  const calculateProcedureCode = (pr, mapa) => {
    const index = pr
      .filter(
        (proc) =>
          proc.business_process_id ===
          mapa.business_process_list.business_process_id
      )
      .findIndex(
        (el) =>
          el.business_process_id ===
          mapa.business_process_list.business_process_id
      );
    const procedureIndex = mapa.procedures.length
      ? mapa.procedures.length + 1
      : 1;
    return `${mapa.business_process_list.code}-${index + 1}-${procedureIndex}`;
  };

  return (
    <ul style={{ borderBottom: "var(--mainBorder)" }}>
      {list?.length &&
        list.map((item, index, array) => {
          if (!item.del)
            return (
              <li
                key={item.id}
                style={{
                  display: "flex",
                  width: "100%",
                  borderTop: item.editing
                    ? "2px solid var(--mainColor)"
                    : "none",
                  borderLeft: item.editing
                    ? "2px solid var(--mainColor)"
                    : "none",
                  borderRight: item.editing
                    ? "2px solid var(--mainColor)"
                    : "none",
                  borderBottom: item.editing
                    ? "2px solid var(--mainColor)"
                    : index !== array.length - 1
                    ? "var(--mainBorder)"
                    : "none",
                }}
                className="procedure-list-item"
              >
                {item.editing && (
                  <DynamicTextarea
                    readOnly={!item.editing}
                    textAreaWrapperStyle={{
                      width: item.editing ? "calc(90% + 1px)" : "90%",
                    }}
                    style={{
                      paddingTop: "24px",
                      borderRight: "1px solid var(--mainColor)",
                    }}
                    onChange={handleChangeRiskName(item)}
                    value={item.name}
                    hasErrors={riskValidationError}
                  />
                )}
                {!item.editing && (
                  <div
                    className="procedure-item-value"
                    onClick={() => handleShowFormular(item.id)}
                    style={{
                      width: "90%",
                    }}
                  >
                    <span
                      className={
                        activeRisk && activeRisk.id === item.id
                          ? "procedure-item-value-link-active"
                          : "procedure-item-value-link"
                      }
                    >
                      {item.name}
                    </span>
                  </div>
                )}
                <div style={{ flex: "1" }}>
                  <div className="options-block">
                    <button
                      type="button"
                      className="employee-item-options-button"
                      onClick={() => {
                        item.editing
                          ? handleUpdateRisk(item)
                          : handleSetEditRisk(item);
                      }}
                    >
                      <img
                        src={
                          item.editing
                            ? "/images/check.png"
                            : "/images/edit.png"
                        }
                        alt="im"
                      />
                    </button>
                    <button
                      type="button"
                      className="employee-item-options-button"
                      onClick={() => {
                        setRiskToDelete(item);
                      }}
                    >
                      <img src="/images/trash.png" />
                    </button>
                  </div>
                </div>
              </li>
            );
        })}
    </ul>
  );
}
