import React from "react";

export default function MapeProcesaUnitList({
  addNewMapa,
  mapaList,
  setMapa,
  activeMapa,
  setActiveProcedure,
  handleSetEditMapa,
  handleSubmitMapa,
  setMapaToDelete,
}) {
  return (
    <div className="poslovni-procesi-unit-list">
      <button
        className={
          activeMapa && !mapaList[activeMapa?.id]
            ? "add-position-button add-position-button-disabled"
            : "add-position-button"
        }
        onClick={addNewMapa}
        disabled={activeMapa && !mapaList[activeMapa?.id]}
      >
        <span>
          <img className="plus-circle-icon" src="/images/pluscircle.png" />
        </span>
        <span>Dodajte mapu procesa</span>
      </button>
      <div className="active-mapa-procesa-wrapper">
        <h3
          className="poslovni-procesi-unit-list-title"
          style={{ border: "none", width: "80%" }}
        >
          {activeMapa && activeMapa?.process?.name
            ? activeMapa.process.name
            : activeMapa
            ? "Mapa Procesa"
            : ""}
        </h3>
        {activeMapa && activeMapa?.process?.name && (
          <div style={{ display: "flex" }}>
            <button
              className="edit-mapa-procesa-button"
              onClick={() => {
                if (activeMapa && (activeMapa.adding || activeMapa.edit)) {
                  handleSubmitMapa();
                } else {
                  handleSetEditMapa();
                }
              }}
            >
              <img
                alt=""
                src={
                  activeMapa && (activeMapa.adding || activeMapa.edit)
                    ? "/images/check.png"
                    : "/images/edit.png"
                }
              />
            </button>
            <button
              className="edit-mapa-procesa-button"
              onClick={() => setMapaToDelete(activeMapa)}
            >
              <img alt="" src="/images/trash.png" />
            </button>
          </div>
        )}
      </div>

      {Object.keys(mapaList).length > 0 && (
        <ul>
          {Object.keys(mapaList).map((key) => {
            if (key != activeMapa.id)
              return (
                <li
                  key={key}
                  className={
                    key == activeMapa.id
                      ? "poslovni-procesi-unit-list-item active-unit"
                      : "poslovni-procesi-unit-list-item"
                  }
                  onClick={() => {
                    setMapa(mapaList[key]);
                    setActiveProcedure(null);
                  }}
                >
                  {mapaList[key].process.name}
                </li>
              );
          })}
        </ul>
      )}
    </div>
  );
}
