import React from "react";
import "./sectionHeader.css";

export default function SectionHeader({ title, description }) {
  return (
    <div className="section-header-wrapper">
      <h1 className="section-header-title">{title}</h1>
      <p className="section-header-description">{description}</p>
    </div>
  );
}
