import React, { useRef, useEffect } from "react";

function DepartmentDesignation({
  position,
  validationError,
  handleChangeDesignationName,
  department,
  setShowDescription,
  handleUpdateDesignation,
  handleShowDescription,
  activeUnit,
  handleSetEditDesignation,
  handleDeleteDesignation,
  showDescription,
  hasDesignationPermission,
}) {
  const designationRef = useRef();

  useEffect(() => {
    if (designationRef.current) {
      designationRef.current.style.height = "100%";
      const scrollHeight = designationRef.current.scrollHeight;
      designationRef.current.style.height = scrollHeight + "px";
    }
  }, [position]);
  return (
    <li>
      <div
        className={
          !validationError?.level && validationError?.id === position.id
            ? "add-to-list-input-wrapper unit-error-border"
            : "add-to-list-input-wrapper"
        }
      >
        <textarea
          ref={designationRef}
          rows="1"
          readOnly={!position.typing && !position.editing}
          style={{ resize: "none", overflow: "hidden" }}
          type="text"
          className="add-to-list-input"
          value={position?.name}
          onChange={(e) =>
            handleChangeDesignationName(
              e,
              position.id,
              department.id,
              "departments",
              "name"
            )
          }
          // onKeyDown={(e) =>
          //   handleKeyDown(e, handleAddConfirmPositionToDepartment, department.id, position.id)
          // }
          // onBlur={() => {
          //   position.name
          //     ? handleAddConfirmPositionToDepartment(department.id, position.id)
          //     : handleDeletePositionFromDepartment(department.id, position.id);
          // }}
        ></textarea>

        <div className="edit-buttons-block">
          {position.editing && (
            <button
              onClick={() => {
                setShowDescription(null);
                handleUpdateDesignation({
                  ...position,
                  level: 1,
                });
              }}
            >
              <img src="/images/check1.jpg" />
            </button>
          )}
          {!position.editing && hasDesignationPermission && (
            <button
              onClick={() => {
                if (!activeUnit) {
                  handleShowDescription(position.id, 1, true);
                }

                handleSetEditDesignation(
                  position.id,
                  department.id,
                  "departments",
                  1
                );
              }}
            >
              <img src="/images/edit.png" />
            </button>
          )}
          {hasDesignationPermission && (
            <button
              onClick={() =>
                handleDeleteDesignation(
                  { id: position.id, level: 1 },
                  "designation"
                )
              }
            >
              <img src="/images/trash.png" />
            </button>
          )}
          {!position.adding && (
            <button
              onClick={() => {
                handleShowDescription(position.id, 1);
              }}
            >
              <img
                alt=""
                src={
                  showDescription && showDescription.id === position.id
                    ? "/images/iks.png"
                    : "/images/plus.png"
                }
              />
            </button>
          )}
        </div>
      </div>

      {showDescription && showDescription.id === position.id && (
        <textarea
          rows="4"
          cols="50"
          className="position-description"
          value={position.description || ""}
          readOnly={!position.editing}
          onChange={(e) =>
            handleChangeDesignationName(
              e,
              position.id,
              department.id,
              "departments",
              "description"
            )
          }
        />
      )}
    </li>
  );
}

export default DepartmentDesignation;
