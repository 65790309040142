import React from "react";
import Contact from "../../components/contact";

export default function ContactPage() {
  return (
    <div className="admin-page-child-wrapper">
      <Contact />
    </div>
  );
}
