import React, { useEffect, useState } from "react";
import { baseRequest, deleteUrl } from "../../../axiosconfig";
import "./adminformularirizikalist.css";

import DropdownWrapper from "../../elements/dropdownWrapper";
import PoslovniProcessTableItem from "../../poslovniProcessTableItem";
import PoslovniProcessTable from "../../poslovniProcessTable";
import PoslovniProcessOwnerTable from "../../poslovniProcesiOwnerTable";
//import PoslovniProcesiUnitsList from "../poslovniProcesiUnitsList";
import Button from "../../elements/button";
import DropDown from "../../elements/dropDown";
import Loading from "../../loading";
import { useNavigate } from "react-router-dom";
import NewPoslovniProces from "../../newPoslovniProces/indesx";
import axios from "axios";
import NavigatePopup from "../../navigatePopup";
import AdminFormulariListItem from "../adminFormulariListItem";
import DeleteMapaPopup from "../../deleteMapaPopup";

export default function AdminFormulariRizikaList({
  setOrganizationUnits,
  activeUnit,
  setActiveUnit,
  setDesignationsCopy,
  enableEdit,
  setFilteredProcesses,
  selectedProcess,
  loading,
  setLoading,
}) {
  const [organization_units, setOrganization_units] = useState([]);
  const [designations, setDesignations] = useState([]);

  //const [activeUnit, setActiveUnit] = useState(null);
  const [activeProcess, setActiveProcess] = useState();
  const [businessProcessList, setBusinessProcessList] = useState();
  const [newUnit, setNewUnit] = useState();
  const [newProcess, setNewProcess] = useState();
  const [errorMessages, setErrorMessages] = useState();
  const [errorObject, setErrorObject] = useState();
  const [showNavigatePopup, setShowNavigatePopup] = useState(false);
  const [riskForms, setRiskForms] = useState();
  const [filteredRiskForms, setFilteredRiskForms] = useState();
  const [formularToDelete, setFormularToDelete] = useState();

  const headerTitles = [
    { id: 1, title: "Šifra procesa:", width: "10%" },
    { id: 2, title: "Poslovni proces", width: "20%" },
    { id: 3, title: "Vlasnik procesa", width: "15%" },
    { id: 4, title: "Ime rizika", width: "20%" },
    { id: 5, title: "Odobrio", width: "10%" },
    { id: 6, title: "Datum izmene", width: "12%" },
  ];

  const navigate = useNavigate();

  const calculateProcessOwnersNames = (list, des) => {
    if (list.length) {
      if (list[0].label) {
        return list;
      } else {
        const names = list.map((owner) => {
          const worker = des.find((des) => {
            return des.id === owner.employee_id;
          });
          if (worker)
            return {
              value: worker,
              label: worker?.name,
              isDisabled: undefined,
            };
        });

        return names.filter((name) => name);
      }
    }
  };
  useEffect(() => {
    setLoading(true);
    const urls = [
      "/risk_forms",
      "/employee/id/0/level/0",
      "/organization_unit/0",
      "/business_processes",
    ];
    Promise.all(
      urls.map((url) => baseRequest.get(url, { withCredentials: true }))
    )
      .then((res) => {
        setLoading(false);

        const responseObj = {};
        res.forEach((item) => {
          if (item.data.data) {
            if (item.data.data[0].first_name) {
              responseObj["designations"] = formatDesignations(item.data.data);
            } else {
              responseObj["organization_units"] = item.data.data;
            }
          } else if (item.data.business_processes) {
            responseObj["business_processes"] = item.data.business_processes;
          } else if (item.data.risk_forms) {
            responseObj["risk_forms"] = item.data.risk_forms;
          }
        });
        if (responseObj.business_processes.length) {
          const actualUnits = responseObj.business_processes.filter(
            (el) => !el.del
          );
          setRiskForms(responseObj["risk_forms"].filter((form) => !form.del));
          setOrganizationUnits(actualUnits);
          setDesignations(responseObj["designations"]);
          setDesignationsCopy(responseObj["designations"]);
          setOrganization_units(responseObj["organization_units"]);
          const unit = actualUnits.length ? actualUnits[0] : null;

          // if (unit) {
          //   setActiveUnit({
          //     ...unit,
          //     business_process_lists: unit.business_process_lists.map(
          //       (item) => {
          //         return {
          //           ...item,
          //           business_process_owners: calculateProcessOwnersNames(
          //             item.business_process_owners,
          //             responseObj["designations"]
          //           ),
          //         };
          //       }
          //     ),
          //   });
          // } else {
          //   setActiveUnit({});
          // }

          setBusinessProcessList(
            formatListToMap(responseObj["business_processes"])
          );
        } else {
          //   setDesignations(responseObj["designations"]);
          //   setOrganization_units(responseObj["organization_units"]);
          //   baseRequest
          //     .post("/business_process", {}, { withCredentials: true })
          //     .then((res) => {
          //       setLoading(false);
          //       setActiveUnit({
          //         ...res.data.data,
          //         business_process_lists: [],
          //       });
          //     });
          //   setBusinessProcessList({});
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const filterForms = (list, unit, process) => {
    const filteredMapsByUnit =
      unit.name === "ALL"
        ? list
        : list.filter(
            (process) =>
              process.business_process_list.business_process_id === unit.id
          );
    if (process) {
      const filteredMapsByProcesses =
        process.name === "ALL"
          ? filteredMapsByUnit
          : filteredMapsByUnit.filter(
              (pr) => pr.business_process_list_id === process.id
            );
      return filteredMapsByProcesses;
    }

    return filteredMapsByUnit;
  };

  const formatListToMap = (list) => {
    const map = {};
    list.forEach((item) => {
      map[item.id] = item;
    });
    return map;
  };

  const handleSetEditPoslovniProcesItem = (payload) => {
    if (activeProcess || newProcess) {
      return;
    }
    setActiveUnit((unit) => {
      const newProcesses = unit.business_process_lists.map((item) => {
        if (payload.id === item.id) {
          return { ...item, editing: true };
        }
        return item;
      });
      return { ...unit, business_process_lists: newProcesses };
    });
    setActiveProcess(
      activeUnit.business_process_lists.find(
        (process) => payload.id === process.id
      )
    );
  };

  const handleChangeActiveProcessName = (e, id) => {
    if (errorObject?.code) {
      setErrorObject((errors) => {
        const { name, ...rest } = errors;
        return rest;
      });
    }
    setActiveUnit((unit) => {
      const newProcesses = unit.business_process_lists.map((item) => {
        if (id === item.id) {
          return { ...item, name: e.target.value.trimStart() };
        }
        return item;
      });
      return { ...unit, business_process_lists: newProcesses };
    });
    setActiveProcess((process) => {
      return { ...process, name: e.target.value.trimStart() };
    });
  };

  const handleCretaeNewProcess = (payload) => {
    if (validateProcess(payload)) {
      return;
    }
    setLoading(true);
    const processOwners = payload.business_process_owners.map((item) => {
      return { employee_id: item.value.id };
    });
    baseRequest
      .post(
        "/business_process_list",
        { ...payload, business_process_owners: processOwners },
        { withCredentials: true }
      )
      .then((res) => {
        setLoading(false);
        setErrorMessages(null);
        setErrorObject(null);
        setActiveUnit((unit) => {
          return {
            ...unit,
            business_process_lists: [
              ...unit.business_process_lists,
              {
                ...res.data.data,
                business_process_owners: calculateProcessOwnersNames(
                  res.data.data.business_process_owners,
                  designations
                ),
              },
            ],
          };
        });

        setBusinessProcessList((list) => {
          return {
            ...list,
            [res.data.data.business_process_id]: {
              ...list[res.data.data.business_process_id],
              business_process_lists: list[res.data.data.business_process_id]
                .business_process_lists
                ? [
                    ...list[res.data.data.business_process_id]
                      .business_process_lists,
                    res.data.data,
                  ]
                : [res.data.data],
            },
          };
        });

        setNewProcess(null);
      });
  };

  function formatDesignations(list) {
    return list.map((item) => {
      return {
        id: item.id,
        name: item.designation
          ? `${item.first_name} ${item.last_name} - ${item.designation.name}`
          : `${item.first_name} ${item.last_name}`,
        designation_name: item.designation ? item.designation.name : "",
      };
    });
  }

  const handleAddNewUnit = () => {
    setLoading(true);
    baseRequest
      .post("/business_process", {}, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        setNewUnit(res.data.data);
        setActiveProcess(null);
        setNewProcess(null);
        setActiveUnit({ ...res.data.data, business_process_lists: [] });
      });
  };

  const handleSelectUnit = ({ value }) => {
    setLoading(true);
    const body = {
      id: activeUnit.id,
      level: value.level,
      organization_unit_id: value.id,
      manager_employee_id: activeUnit.manager_employee_id
        ? activeUnit.manager_employee_id
        : 0,
    };

    baseRequest
      .put("/business_process", body, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        setActiveUnit((unit) => {
          return {
            ...unit,
            ...res.data.data,
          };
        });

        const acUnit = {
          ...activeUnit,
          ...res.data.data,
        };

        setBusinessProcessList((list) => {
          return { ...list, [acUnit.id]: acUnit };
        });
      });
  };

  const handleSetNewProcess = () => {
    // const keys = businessProcessList ? Object.keys(businessProcessList) : null;
    // const lastKey = keys ? keys.length : 1;

    // const redniBroj = calculateRedniBroj(activeUnit.business_process_lists);

    setNewProcess({
      redniBroj: activeUnit?.business_process_lists.filter((el) => !el.del)
        .length
        ? activeUnit?.business_process_lists.filter((el) => !el.del).length + 1
        : 1,
      code: ``,
      name: "",
      business_process_owners: [],
      business_process_id: activeUnit.id,
      adding: true,
    });
  };

  const handleSelectDesignation = ({ value }) => {
    if (
      !Object.keys(activeUnit).length ||
      activeUnit.organization_unit_id === 0
    ) {
      return;
    }

    const payload = { ...activeUnit, manager_employee_id: value.id };
    setLoading(true);

    baseRequest
      .put("business_process", payload, { withCredentials: true })
      .then((res) => {
        setLoading(false);

        setActiveUnit((unit) => {
          return { ...unit, ...res.data.data };
        });
        setBusinessProcessList((list) => {
          return {
            ...list,
            [res.data.data.id]: { ...activeUnit, ...res.data.data },
          };
        });
      });
  };

  const handleChangeNewProcess = (e) => {
    if (errorObject?.name) {
      setErrorObject((errors) => {
        const { name, ...rest } = errors;
        return rest;
      });
    }
    setNewProcess((process) => {
      return { ...process, name: e.target.value.trimStart() };
    });
  };

  const handleSelectNewProcessOwners = (value) => {
    setNewProcess((process) => {
      return { ...process, business_process_owners: value };
    });

    if (value.length && errorObject?.business_process_owners) {
      setErrorObject((errors) => {
        const { business_process_owners, ...rest } = errors;
        return rest;
      });
    }
  };

  const handleSelectProcessOwner = (id) => {
    return function (value) {
      if (value.length && errorObject?.business_process_owners) {
        setErrorObject((errors) => {
          const { business_process_owners, ...rest } = errors;
          return rest;
        });
      }
      setActiveUnit((unit) => {
        const newProcesses = unit.business_process_lists.map((process) => {
          if (process.id === id) {
            return {
              ...process,
              business_process_owners: value,
              editingOwners: true,
            };
          }
          return process;
        });
        return {
          ...unit,
          business_process_lists: newProcesses,
        };
      });
    };
  };

  const handleUpdateProcess = (payload) => {
    setLoading(true);
    if (validateProcess(payload)) {
      setLoading(false);
      return;
    }
    const formattedOwners = payload.business_process_owners.map((owner) => {
      return { ...owner.value, employee_id: owner.value.id };
    });

    const { business_process_owners, ...rest } = payload;

    baseRequest
      .put(
        "/business_process_list",
        { ...payload, business_process_owners: formattedOwners },
        { withCredentials: true }
      )
      .then((res) => {
        setErrorObject(null);
        setErrorMessages(null);
        const newOwners = calculateProcessOwnersNames(
          res.data.data.business_process_owners,
          designations
        );
        setActiveUnit((unit) => {
          return {
            ...unit,
            business_process_lists: unit.business_process_lists.map((pr) => {
              if (pr.id === res.data.data.id) {
                return { ...res.data.data, business_process_owners: newOwners };
              }
              return pr;
            }),
          };
        });
        setBusinessProcessList((list) => {
          return {
            ...list,
            [res.data.data.business_process_id]: {
              ...list[res.data.data.business_process_id],
              business_process_lists: list[
                res.data.data.business_process_id
              ].business_process_lists.map((pr) => {
                if (pr.id === res.data.data.id) {
                  return res.data.data;
                }
                return pr;
              }),
            },
          };
        });
        setActiveProcess(null);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleDeleteNewProcess = () => {
    setErrorMessages(null);
    setErrorObject(null);
    setNewProcess(null);
  };

  const handleDisableOrganizationUnits = (units) => {
    if (businessProcessList) {
      const newUnits = units.map((unit) => {
        const a = Object.values(businessProcessList).find(
          (val) =>
            val.organization_unit_id === unit.id &&
            val.level === unit.level &&
            !val.del
        );
        if (a) {
          return { ...unit, isDisabled: true };
        }
        return unit;
      });

      return newUnits;
    } else {
      return units;
    }
  };

  const allUnitsDisabled = (units) => {
    return handleDisableOrganizationUnits(units).every((un) => un.isDisabled);
  };

  const validateProcess = (process) => {
    if (
      !process.name ||
      !process.business_process_owners.length ||
      !process.code
    ) {
      if (!process.name) {
        setErrorObject((errors) => {
          return { ...errors, name: true };
        });
      }
      if (!process.code) {
        setErrorObject((errors) => {
          return { ...errors, code: true };
        });
      }
      if (!process.business_process_owners.length) {
        setErrorObject((errors) => {
          return { ...errors, business_process_owners: true };
        });
      }
      setErrorMessages("Popunite sva obavezna polja");
      return true;
    }
  };

  const handleDeleteProcess = (id) => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}business_process_list`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: { id: id },
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);
        setActiveUnit((unit) => {
          const newProcesses = unit.business_process_lists.map((process) => {
            if (process.id === res.data.data.id) {
              return res.data.data;
            }
            return process;
          });
          return { ...unit, business_process_lists: newProcesses };
        });
        setBusinessProcessList((list) => {
          const newProcesses = list[activeUnit.id].business_process_lists.map(
            (process) => {
              if (process.id === res.data.data.id) {
                return res.data.data;
              }
              return process;
            }
          );

          return {
            ...list,
            [activeUnit.id]: {
              ...list[activeUnit.id],
              business_process_lists: newProcesses,
            },
          };
        });
        if (newProcess) {
          const newProcessRednibroj = activeUnit.business_process_lists.filter(
            (proc) => !proc?.del
          ).length
            ? activeUnit.business_process_lists.filter((proc) => !proc?.del)
                .length + 1
            : 1;

          setNewProcess((process) => {
            return { ...process, redniBroj: newProcessRednibroj - 1 };
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleDeleteUnit = (id) => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}business_process`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: { id: id },
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);

        setBusinessProcessList((list) => {
          const { activeUnitsNumber, activeUnits } = findActiveUnits(list);
          if (id === activeUnit.id) {
            if (
              activeUnitsNumber === 1 &&
              activeUnit.organization_unit_id !== 0
            ) {
              setActiveUnit(null);
              setActiveProcess(null);
            } else {
              setActiveUnit(activeUnits[0]);
            }
          }

          return { ...list, [id]: res.data.data };
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleNavigate = () => {
    navigate("/register/formulari-rizika");
  };
  const handleNavigateToNextPage = () => {
    if (newProcess || activeProcess) {
      setShowNavigatePopup(true);
    } else {
      handleNavigate();
    }
  };

  const handleDisableNavigateButton = (list) => {
    if (list) {
      const hasProcesses = Object.values(businessProcessList).some((value) => {
        return (
          value.del === 0 &&
          value?.business_process_lists.length > 0 &&
          value?.business_process_lists.some((process) => process.del === 0)
        );
      });

      return hasProcesses;
    }
    return false;
  };

  const calculateRedniBroj = (list) => {
    if (list.length) {
      if (list.every((process) => process.del === 1)) {
        return 1;
      }
      if (
        !list.every((process) => process.del === 1) &&
        list[list.length - 1].del === 1
      ) {
        const activeProcesses = list.filter((el) => el.del === 0);
        const lastActiveProcess = activeProcesses[activeProcesses.length - 1];

        return +lastActiveProcess.code.split(".")[1] + 1;
      }
      if (
        !list.every((process) => process.del === 1) &&
        list[list.length - 1].del === 0
      ) {
        const activeProcesses = list.filter((el) => el.del === 0);
        const lastActiveProcess = activeProcesses[activeProcesses.length - 1];

        return +lastActiveProcess.code.split(".")[1] + 1;
      }
    } else {
      return 1;
    }
  };

  const findActiveUnits = (list) => {
    const activeUnits = Object.values(list).filter((item) => item.del === 0);

    return { activeUnitsNumber: activeUnits.length, activeUnits };
  };

  const handleChangeProcessCode = (e, id) => {
    if (errorObject?.code) {
      setErrorObject((errors) => {
        const { code, ...rest } = errors;
        return rest;
      });
    }
    setActiveUnit((unit) => {
      const newProcesses = unit.business_process_lists.map((item) => {
        if (id === item.id) {
          return { ...item, code: e.target.value.trimStart() };
        }
        return item;
      });
      return { ...unit, business_process_lists: newProcesses };
    });
    setActiveProcess((process) => {
      return { ...process, name: e.target.value.trimStart() };
    });
  };
  const handleChangeNewProcessCode = (e) => {
    if (errorObject?.code) {
      setErrorObject((errors) => {
        const { code, ...rest } = errors;
        return rest;
      });
    }
    setNewProcess((process) => {
      return { ...process, code: e.target.value.trimStart() };
    });
  };

  const handleDeleteFormular = () => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}risk_form`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: { id: formularToDelete.id },
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);

        setRiskForms((list) => {
          const newList = list.filter((el) => el.id !== res.data.data.id);
          return newList;
        });
        setFormularToDelete(null);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <ul className="admin-formulari-rizika-list-header">
        {headerTitles.map((item) => {
          return (
            <li
              key={item.id}
              className="admin-formulari-rizika-list-header-item"
              style={{ width: item.width ? `${item.width}` : "auto" }}
            >
              {item.title}
            </li>
          );
        })}
      </ul>

      <ul className="admin-formulari-rizika-list">
        {filterForms(riskForms, activeUnit, selectedProcess)?.map((form) => {
          if (!form.del)
            return (
              <AdminFormulariListItem
                key={form.id}
                item={form}
                setFormularToDelete={setFormularToDelete}
              />
            );
        })}
        {formularToDelete && (
          <div className="loading">
            <DeleteMapaPopup
              setItemToDelete={setFormularToDelete}
              loading={loading}
              handleItemDelete={handleDeleteFormular}
            />
          </div>
        )}
      </ul>
    </>
  );
}
