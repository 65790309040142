import React from "react";
import SectionHeader from "../../components/elements/sectionHeader";
import FormulareRizika from "../../components/formulareRizika";
import { Link } from "react-router-dom";

export default function FormulareRizikaPage() {
  return (
    <div className="page">
      <Link to="/admin/formulari-rizika">vrati se</Link>
      <SectionHeader
        title="Formulari rizika"
        description="Molimo vas da ne ulazite u proces registracije bez pomoći stručnog lica. Naš tim Vam stoji na raspolaganju."
      />

      <FormulareRizika />
    </div>
  );
}
