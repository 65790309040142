import React, { useState } from "react";

import Button from "../elements/button";
import Loading from "../loading";

export default function ErrorMessagePopup({
  setErrorMessage,
  message,
  setActiveUnit,
  handleUpdateDesignation,
  activeUnit,
  handleCreateDesignation,
  handleCreateUnit,
  loading,
  handleUpdateUnit,
  validationError,
  setValidationError,

  ...props
}) {
  console.log(activeUnit);

  return (
    <div className="link-popup">
      <p style={{ marginBottom: "20px" }}>{message}</p>
      <div className="link-popup-buttons">
        <Button
          onClick={() => {
            if (activeUnit?.activeUnit.edit && !activeUnit?.activeUnit.key) {
              handleUpdateUnit(activeUnit.activeUnit, activeUnit.level);
            } else if (
              activeUnit?.activeUnit.editing &&
              activeUnit?.activeUnit.key === "designation"
            ) {
              handleUpdateDesignation(activeUnit.activeUnit);
            } else if (
              activeUnit?.activeUnit.adding &&
              activeUnit?.activeUnit?.key.includes("designation")
            ) {
              handleCreateDesignation(activeUnit.activeUnit);
            } else if (
              activeUnit?.activeUnit.adding &&
              !activeUnit?.activeUnit?.key.includes("designation")
            ) {
              handleCreateUnit(activeUnit.activeUnit);
            } else {
              props.handleConfirm(activeUnit.activeUnit);
            }
          }}
          title="Da"
          style={{
            backgroundColor: "white",
            color: "var(--mainColor)",
            border: "1px solid var(--mainColor)",
            fontWeight: "700",
            width: "120px",
          }}
        />
        <Button
          title="Ne"
          style={{ width: "120px" }}
          onClick={() => {
            setErrorMessage(null);
            setValidationError(null);
            props.handler(null);
          }}
        />
      </div>
      <Loading isLoading={loading} />
    </div>
  );
}
