import React from "react";

function RegistriRizikaRowPrint({ row, titles, redniBroj, riskGroups }) {
  const probabilityMap = {
    1: "Nizak",
    2: "Srednji",
    3: "Srednji",
    4: "Visok",
    5: "Visok",
  };

  const riskColors = {
    nizak: "#B0E0E6",
    srednji: "#FFD700",
    visok: "#FF5C5C",
  };

  const calculateTotalValue = (probability, influence) => {
    if (probability && influence) {
      const percentage = (probability + influence) * 10;
      const stringValue =
        percentage < 50 ? "nizak" : percentage > 59 ? "visok" : "srednji";
      return {
        total: probability + influence,
        percentage: `${percentage}%`,
        stringValue,
      };
    }
    return "";
  };

  const calculateControlInfluence = (probability, estimatedInfluence) => {
    if (probability && estimatedInfluence) {
      const value = (probability * estimatedInfluence) / 100;

      if (Number.isInteger(value)) {
        return value;
      } else {
        return value.toFixed(1);
      }
    } else if (probability && estimatedInfluence === 0) {
      const value = (probability * estimatedInfluence) / 100;

      if (Number.isInteger(value)) {
        return value;
      } else {
        return value.toFixed(1);
      }
    } else {
      return "";
    }
  };

  const calculatePreostaliRizik = () => {
    const cInf = calculateControlInfluence(
      row.probability_id,
      row.precaution_influence_percentage
    );

    const tot = calculateTotalValue(row.probability_id, row.influence_id).total;
    if ((tot && cInf) || (tot && cInf === 0)) {
      const preostaliRizik = (tot - cInf) * 10;
      const stringValue =
        preostaliRizik < 50
          ? "nizak"
          : preostaliRizik > 59
          ? "visok"
          : "srednji";
      return { preostaliRizik: `${preostaliRizik}%`, stringValue };
    } else {
      return;
    }
  };

  return (
    <ul
      style={{
        display: "flex",
        border: "1px solid black",
        fontSize: "8px",
        color: "black",
        width: "100%",
        pageBreakInside: "avoid",
      }}
    >
      <li
        style={{
          width: "70px",
          borderRight: "1px solid black",

          textAlign: "center",
          paddingTop: "5px",
        }}
      >
        {redniBroj}
      </li>
      <li
        style={{
          width: "170px",

          borderRight: "1px solid black",
          textAlign: "center",
          paddingTop: "5px",
        }}
      >
        {row.business_process_list?.business_process_owners.map((owner) => {
          return (
            <p key={owner.id} style={{ marginBottom: "5px" }}>
              <p>{owner.first_name}</p>
              <p>{owner.last_name}</p>
            </p>
          );
        })}
      </li>
      <li
        style={{
          width: "70px",
          fontSize: "7px",
          borderRight: "1px solid black",

          textAlign: "center",
          paddingTop: "5px",
        }}
      >
        {row.number}
      </li>
      <li
        style={{
          width: "220px",
          borderRight: "1px solid black",
          textAlign: "center",
          paddingTop: "5px",
        }}
      >
        {row.risk_owners.map((owner) => {
          return (
            <p key={owner.id} style={{ marginBottom: "7px" }}>
              <p>{owner.first_name}</p>
              <p>{owner.last_name}</p>
            </p>
          );
        })}
      </li>
      <li
        style={{
          borderRight: "1px solid black",
          textAlign: "center",
          paddingTop: "5px",
          width: "170px",
        }}
      >
        {row.name}
      </li>
      <li
        style={{
          width: "370px",
          borderRight: "1px solid black",
          paddingTop: "5px",
          paddingBottom: "5px",
          wordWrap: "break-word",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            marginBottom: "5px",
            paddingLeft: "5px",

            wordWrap: "break-word",
          }}
        >
          <span style={{ marginRight: "5px", fontWeight: "bold" }}>
            Opis rizika:
          </span>
          <span>{row.description}</span>
        </div>
        <div
          style={{
            marginBottom: "5px",
            paddingLeft: "5px",

            wordWrap: "break-word",
          }}
        >
          <span style={{ marginRight: "5px", fontWeight: "bold" }}>Uzrok:</span>
          <span>{row.cause}</span>
        </div>
        <div style={{ paddingLeft: "5px", wordWrap: "break-word" }}>
          <span style={{ marginRight: "5px", fontWeight: "bold" }}>
            Posledice:
          </span>
          <span>{row.effect}</span>
        </div>
      </li>
      <li
        style={{
          width: "170px",
          borderRight: "1px solid black",
          paddingTop: "5px",
          textAlign: "center",
        }}
      >
        {row?.risk_group ? row?.risk_group.name : "grupa rizika"}
      </li>
      <li
        style={{
          width: "200px",
          borderRight: "1px solid black",
          paddingTop: "5px",
          textAlign: "center",
        }}
      >
        {`${row.probability_id}. ${probabilityMap[row.probability_id]} `}
      </li>
      <li
        style={{
          width: "170px",
          borderRight: "1px solid red",
          paddingTop: "5px",
          textAlign: "center",
        }}
      >
        {`${row.probability_id}. ${probabilityMap[row.influence_id]}`}
      </li>
      <li
        style={{
          width: "220px",
          borderRight: "1px solid black",
          textAlign: "center",
        }}
      >
        <div
          style={{
            borderBottom: "1px solid black",
            textAlign: "center",
            height: "33%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {calculateTotalValue(row.probability_id, row.influence_id).total}
        </div>
        <div
          style={{
            borderBottom: "1px solid black",
            textAlign: "center",
            height: "33%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {calculateTotalValue(row.probability_id, row.influence_id).percentage}
        </div>
        <div
          style={{
            backgroundColor:
              riskColors[
                calculateTotalValue(row.probability_id, row.influence_id)
                  ?.stringValue
              ],
            textAlign: "center",
            height: "33%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {
            calculateTotalValue(row.probability_id, row.influence_id)
              ?.stringValue
          }
        </div>
      </li>
      <li
        style={{
          width: titles.find((title) => title.id === 10).width,
          borderRight: "1px solid black",
          paddingTop: "5px",
          textAlign: "center",
        }}
      >
        {row.management_method}
      </li>
      <li
        style={{
          width: titles.find((title) => title.id === 11).width,
          borderRight: "1px solid black",
          paddingTop: "5px",
          textAlign: "center",
        }}
      >
        {row.management_method_precaution_description}
      </li>
      <li
        style={{
          width: titles.find((title) => title.id === 12).width,
          borderRight: "1px solid black",
          paddingTop: "5px",
          textAlign: "center",
          wordWrap: "break-word",
        }}
      >
        {row.precaution_influence_percentage}%
      </li>
      <li
        style={{
          width: titles.find((title) => title.id === 13).width,
          borderRight: "1px solid black",
          paddingTop: "5px",
          textAlign: "center",
        }}
      >
        {calculateControlInfluence(
          row.probability_id,
          row.precaution_influence_percentage
        )}
      </li>
      <li
        style={{
          width: titles.find((title) => title.id === 14).width,
          display: "flex",
        }}
      >
        <div
          style={{
            width: "50%",
            borderRight: "1px solid black",
            height: "100%",
            paddingTop: "5px",
            textAlign: "center",
          }}
        >
          {calculatePreostaliRizik()?.preostaliRizik}
        </div>
        <div
          style={{
            width: "50%",
            paddingTop: "5px",
            textAlign: "center",
            height: "100%",
            backgroundColor: riskColors[calculatePreostaliRizik()?.stringValue],
          }}
        >
          {calculatePreostaliRizik()?.stringValue}
        </div>
      </li>
    </ul>
  );
}

export default RegistriRizikaRowPrint;
