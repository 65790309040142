import React from "react";
import SingleFormularRizika from "../../components/admin/singleFormularRizika";

function AdminFormulariRizikaSinglePage(props) {
  return (
    <div className="admin-page-child-wrapper">
      <SingleFormularRizika />
    </div>
  );
}

export default AdminFormulariRizikaSinglePage;
