import React from "react";

const TestPrintPoslovniProcesi = React.forwardRef((props, ref) => {
  const poslovniProcessTableTitles = [
    { id: 1, title: "R.BR", width: "7%" },
    { id: 2, title: "ŠIFRA PROCESA", width: "16%" },
    { id: 3, title: "POSLOVNI PROCES", width: "38%" },
    { id: 4, title: "VLASNIK POSLOVNOG PROCESA", width: "39%" },
  ];
  return (
    <div ref={ref} style={{ width: "95%", margin: "20px auto " }}>
      <div
        style={{
          borderTop: "1px solid black",
          borderBottom: "1px solid black",
          borderRight: "1px solid black",
          borderLeft: "1px solid black",
          display: "flex",
        }}
      >
        <p
          style={{
            width: "30%",
            borderRight: "1px solid black",
            display: "flex",
            justifyContent: "center",
            padding: "1.6em 0",
            color: "black",

            fontWeight: "bold",
            fontSize: "14px",
            backgroundColor: "#F0F0F0",
          }}
        >
          ORGANIZACIONA JEDINICA
        </p>
        <p
          style={{
            width: "70%",
            display: "flex",
            alignItems: "center",
            paddingLeft: "10px",
          }}
        >
          {props.activeUnit?.name || ""}
        </p>
      </div>
      <div
        style={{
          borderBottom: "1px solid black",
          borderRight: "1px solid black",
          borderLeft: "1px solid black",
          display: "flex",
        }}
      >
        <p
          style={{
            width: "30%",
            borderRight: "1px solid black",
            display: "flex",
            justifyContent: "center",
            padding: "1.6em 0",
            color: "black",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: "14px",
            backgroundColor: "#F0F0F0",
            textAlign: "center",
          }}
        >
          RUKOVODILAC ORGANIZACIONE JEDINICE
        </p>
        <p
          style={{
            width: "70%",
            display: "flex",
            alignItems: "center",
            paddingLeft: "10px",
          }}
        >
          {props.designationName ? props.designationName : ""}
        </p>
      </div>
      <div
        style={{
          marginTop: "20px",
          width: "100%",
        }}
      >
        <ul
          style={{
            display: "flex",
            color: "black",
            height: "50px",
            fontWeight: "bold",
            border: "1px solid black",
          }}
        >
          {poslovniProcessTableTitles.map((el, index) => {
            return (
              <li
                key={el.id}
                style={{
                  width: el.width,
                  borderRight: index !== 3 ? "1px solid black" : "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F0F0F0",
                  textAlign: "center",
                  fontSize: "14px",
                }}
              >
                {el.title}
              </li>
            );
          })}
        </ul>
        {props.list && (
          <ul>
            {props.list.map((process) => {
              return (
                <li key={process.id} style={{ pageBreakInside: "avoid" }}>
                  <ul
                    style={{
                      display: "flex",
                      color: "black",
                      border: "1px solid black",
                    }}
                  >
                    <li
                      style={{
                        width: poslovniProcessTableTitles[0].width,
                        padding: "5px",
                        borderRight: "1px solid black",
                      }}
                    ></li>
                    <li
                      style={{
                        width: poslovniProcessTableTitles[1].width,
                        padding: "5px",
                        borderRight: "1px solid black",
                      }}
                    >
                      {process.code}
                    </li>
                    <li
                      style={{
                        width: poslovniProcessTableTitles[2].width,
                        padding: "5px",
                        borderRight: "1px solid black",
                      }}
                    >
                      {process.name}
                    </li>
                    <li
                      style={{
                        width: poslovniProcessTableTitles[3].width,
                        padding: "5px",
                      }}
                    >
                      <ul>
                        {process.business_process_owners.map((own) => {
                          return <li>{own.value.name}</li>;
                        })}
                      </ul>
                    </li>
                  </ul>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div
        style={{
          marginTop: "40px",
          pageBreakInside: "avoid",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "40px",
            border: "1px solid black",
            backgroundColor: "#F0F0F0",
          }}
        >
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          ></p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            PRIPREMA
          </p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              alignItems: "center",
              fontSize: "16px",
            }}
          >
            KONTROLIŠE
          </p>
          <p
            style={{
              width: "25%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            ODOBRAVA
          </p>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "40px",
            borderTop: "none",
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            IME I PREZIME
          </p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          ></p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              alignItems: "center",
              fontSize: "16px",
            }}
          ></p>
          <p
            style={{
              width: "25%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "600",
              fontSize: "14px",
            }}
          ></p>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "40px",
            borderTop: "none",
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            POTPIS
          </p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          ></p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              alignItems: "center",
              fontSize: "16px",
            }}
          ></p>
          <p
            style={{
              width: "25%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "600",
              fontSize: "14px",
            }}
          ></p>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "40px",
            borderTop: "none",
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            DATUM
          </p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          ></p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              alignItems: "center",
              fontSize: "16px",
            }}
          ></p>
          <p
            style={{
              width: "25%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "600",
              fontSize: "14px",
            }}
          ></p>
        </div>
      </div>
    </div>
  );
});

export default TestPrintPoslovniProcesi;
