import React from "react";
import "./authorizationemployeeitem.css";

import DropDown from "../../elements/dropDown";

function AuthorizationEmployeeItem({
  user,
  organization_units,
  handleChangeUser,
  roles,
  handleSetEditUser,
  handleSelectUserUnit,
  handleSelectUserDesignation,
  handleChangeUserRole,
  handleUpdateUser,
  handleDeleteUser,
  userValidationErrors,
}) {
  const options = roles
    ? [
        { id: 1, icon: "/images/bigupravaicon.png" },
        { id: 2, icon: "/images/odsekicon.png" },
        { id: 3, icon: "/images/smallzaposleniicon.png" },
      ].map((item) => {
        const match = roles.find((r) => r.id === item.id);
        return { ...item, ...match };
      })
    : [];

  const height = "49px";

  const calculateUserRole = (worker, rolesList) => {
    if (worker) {
      if (worker.role) {
        return rolesList.find((el) => el.id === worker.role.id).name;
      } else {
        return rolesList.find((el) => el.id === worker.role_id).name;
      }
    } else return "";
  };

  return (
    <div className="authorization-employee-item">
      <div
        className="authorization-employee-item-cell"
        style={{ width: "20%" }}
      >
        <DropDown
          height={height}
          onChange={handleSelectUserUnit(user.id)}
          list={organization_units}
          selectedValue={user?.unit.name}
          disabled={true}
        />
        {user.editing &&
          userValidationErrors &&
          userValidationErrors["organization_unit_id"] && (
            <p
              style={{
                position: "absolute",
                left: "4px",
                bottom: "0",
                fontSize: "11px",
                color: "red",
              }}
            >
              {userValidationErrors["organization_unit_id"]}
            </p>
          )}
      </div>
      <div
        className="authorization-employee-item-cell"
        style={{ width: "20%" }}
      >
        <DropDown
          height={height}
          list={user.filteredDesignations}
          selectedValue={user?.designation ? user.designation.name : ""}
          onChange={handleSelectUserDesignation(user.id)}
          disabled={true}
        />
        {user.editing &&
          userValidationErrors &&
          userValidationErrors["employee_id"] && (
            <p
              style={{
                position: "absolute",
                left: "4px",
                bottom: "0",
                fontSize: "11px",
                color: "red",
              }}
            >
              {userValidationErrors["employee_id"]}
            </p>
          )}
      </div>
      <div
        className="authorization-employee-item-cell"
        style={{ width: "19%" }}
      >
        <DropDown
          list={options}
          height={height}
          selectedValue={calculateUserRole(user, options)}
          withIcon={true}
          disabled={!user.editing}
          onChange={handleChangeUserRole(user.id)}
        />
        {user.editing &&
          userValidationErrors &&
          userValidationErrors["role_id"] && (
            <p
              style={{
                position: "absolute",
                left: "4px",
                bottom: "0",
                fontSize: "11px",
                color: "red",
              }}
            >
              {userValidationErrors["role_id"]}
            </p>
          )}
      </div>
      <div
        className="authorization-employee-item-cell"
        style={{ width: "18%" }}
      >
        <input
          className="authorization-employee-item-input"
          value={user.email}
          disabled={!user.editing}
          onChange={(e) => {
            handleChangeUser(e, user.id);
          }}
        />
        {user.editing &&
          userValidationErrors &&
          userValidationErrors["email"] &&
          !Array.isArray(userValidationErrors["email"]) && (
            <p
              style={{
                position: "absolute",
                left: "4px",
                bottom: "0",
                fontSize: "11px",
                color: "red",
              }}
            >
              {userValidationErrors["email"]}
            </p>
          )}
        {user.editing &&
          userValidationErrors &&
          userValidationErrors["email"] &&
          Array.isArray(userValidationErrors["email"]) && (
            <ul>
              {userValidationErrors["email"].map((er) => {
                return (
                  <li
                    key={er}
                    style={{
                      position: "absolute",
                      left: "4px",
                      bottom: "0",
                      fontSize: "11px",
                      color: "red",
                    }}
                  >
                    {er}
                  </li>
                );
              })}
            </ul>
          )}
      </div>
      <div
        className="authorization-employee-item-cell"
        style={{ width: "18%" }}
      >
        <input
          className="authorization-employee-item-input"
          value={user.password ? user.password : ""}
          disabled={true}
        />
      </div>
      <div className="authorization-employee-item-cell" style={{ width: "5%" }}>
        <div className=" options-block">
          <button
            type="button"
            className="employee-item-options-button"
            onClick={
              user?.editing
                ? () => {
                    handleUpdateUser(user);
                  }
                : () => handleSetEditUser(user.id)
            }
          >
            <img
              alt=""
              src={user?.editing ? "/images/check1.jpg" : "/images/edit.png"}
            />
          </button>
          <button
            type="button"
            className="employee-item-options-button"
            onClick={() => {
              handleDeleteUser(user);
            }}
          >
            <img src="/images/trash.png" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default AuthorizationEmployeeItem;
