import React, { useState } from "react";

import Button from "../elements/button";
import Loading from "../loading";

export default function DeleteMapaPopup({
  itemToDelete,
  setItemToDelete,
  loading,
  setLoading,
  handleItemDelete,
}) {
  return (
    <div className="link-popup">
      <p style={{ marginBottom: "20px" }}>Sigurno zelite da obrisete?</p>
      <div className="link-popup-buttons">
        <Button
          onClick={() => handleItemDelete()}
          title="Da"
          style={{
            backgroundColor: "white",
            color: "var(--mainColor)",
            border: "1px solid var(--mainColor)",
            fontWeight: "700",
            width: "120px",
          }}
        />
        <Button
          title="Ne"
          style={{ width: "120px" }}
          onClick={() => {
            setItemToDelete(null);
          }}
        />
      </div>
      <Loading isLoading={loading} />
    </div>
  );
}
