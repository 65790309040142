import React from "react";
import Notifications from "../../components/admin/notifications";

function NotificationsPage(props) {
  return (
    <div className="admin-page-child-wrapper">
      <Notifications />
    </div>
  );
}

export default NotificationsPage;
