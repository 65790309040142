import { createContext, useState } from "react";

const UnsavedDataContext = createContext(undefined);

function UnsavedDataProvider({ children }) {
  const [unsavedData, setUnsavedData] = useState(false);
  const [showNavigatePopup, setShowNavigatePopup] = useState(false);
  const [navigateUrl, setNavigateUrl] = useState();

  return (
    <UnsavedDataContext.Provider
      value={{
        unsavedData,
        setUnsavedData,
        showNavigatePopup,
        setShowNavigatePopup,
        navigateUrl,
        setNavigateUrl,
      }}
    >
      {children}
    </UnsavedDataContext.Provider>
  );
}

export { UnsavedDataProvider, UnsavedDataContext };
