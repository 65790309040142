import React from "react";
import RegistriRizika from "../../components/admin/registriRizika";

function RegistriRizikaPage(props) {
  return (
    <div
      className="admin-page-child-wrapper"
      style={{ position: "relative", overflowX: "scroll" }}
    >
      <RegistriRizika />
    </div>
  );
}

export default RegistriRizikaPage;
