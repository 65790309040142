import React from "react";
import SectionHeader from "../../components/elements/sectionHeader";
import PoslovniProcesi from "../../components/poslovniProcesi";

export default function PoslovniProcesiPage() {
  return (
    <div className="page">
      <SectionHeader
        title="Poslovni procesi"
        description="Molimo vas da ne ulazite u proces registracije bez pomoći stručnog lica. Naš tim Vam stoji na raspolaganju."
      />
      <PoslovniProcesi />
    </div>
  );
}
