import React from "react";
import "./poslovniProcessTable.css";
import PoslovniProcessTableItem from "../poslovniProcessTableItem";
const PoslovniProcessTable = React.forwardRef((props, ref) => {
  const poslovniProcessTableTitles = [
    { id: 1, title: "R.BR", width: "7%" },
    { id: 2, title: "ŠIFRA PROCESA", width: "15%" },
    { id: 3, title: "Poslovni Proces", width: "35%" },
    { id: 4, title: "Vlasnik Poslovnog Procesa", width: "35%" },
    { id: 5, title: "", width: "8%" },
  ];

  return (
    <div
      className="table"
      style={{ borderBottom: "var(--mainBorder)" }}
      ref={ref}
    >
      <ul className="table-header">
        {poslovniProcessTableTitles.map((title) => {
          return (
            <li
              className="table-header-item"
              key={title.id}
              style={{ width: title.width }}
            >
              <p className="table-header-title">{title.title}</p>
            </li>
          );
        })}
      </ul>
      {props.list && props.list.filter((el) => !el.del).length > 0 && (
        <div className="poslovli-proseci-table-list">
          {props.list
            .filter((el) => !el.del)
            .map((process, index) => {
              return (
                <PoslovniProcessTableItem
                  key={process.id}
                  process={process}
                  redniBroj={index + 1}
                  designations={props.designations}
                  handleChangeProcessDescription={
                    props.handleChangeProcessDescription
                  }
                  handleSelectProcessOwner={props.handleSelectProcessOwner}
                  handleSetEditPoslovniProcesItem={
                    props.handleSetEditPoslovniProcesItem
                  }
                  handleChangeActiveProcessName={
                    props.handleChangeActiveProcessName
                  }
                  handleUpdateProcess={props.handleUpdateProcess}
                  errorObject={props.errorObject}
                  activeProcess={props.activeProcess}
                  handleDeleteProcess={props.handleDeleteProcess}
                  handleChangeProcessCode={props.handleChangeProcessCode}
                  newProcess={props.newProcess}
                  activeUnit={props.activeUnit}
                />
              );
            })}
        </div>
      )}
    </div>
  );
});

export default PoslovniProcessTable;
// {
//   list,
//   designations,
//   handleChangeProcessDescription,
//   handleSelectProcessOwner,
//   handleSetEditPoslovniProcesItem,
//   handleDeleteProcess,
//   handleChangeActiveProcessName,
//   handleUpdateProcess,
//   errorObject,
//   activeProcess,
//   handleChangeProcessCode,
//   newProcess,
// })
