import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./App.css";
import { UnsavedDataProvider } from "./context";
import { UserContextProvider } from "./userContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <UserContextProvider>
      <UnsavedDataProvider>
        <App />
      </UnsavedDataProvider>
    </UserContextProvider>
  </BrowserRouter>
);
