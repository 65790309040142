import React from "react";
import NewMapa from "../../components/admin/newMapa";

function AdminNewMapaProcesaPage(props) {
  return (
    <div className="admin-page-child-wrapper">
      <NewMapa />
    </div>
  );
}

export default AdminNewMapaProcesaPage;
