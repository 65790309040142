import React, { useState } from "react";

import Button from "../elements/button";
import Loading from "../loading";

export default function EmployeelistErrorPopup({
  setErrorMessage,
  message,
  setActiveUnit,
  handleUpdateDesignation,
  activeEmployee,
  handleCreateDesignation,
  handleCreateUnit,
  loading,
  handleUpdateUnit,
  handleCreateEmployee,
  newEmployee,
  handleUpdateEmployee,
  resetActiveEmployee,
  ...props
}) {
  console.log(activeEmployee);
  return (
    <div className="link-popup">
      <p style={{ marginBottom: "20px" }}>{message}</p>
      <div className="link-popup-buttons">
        <Button
          onClick={() => {
            if (activeEmployee.activeUnit.adding) {
              handleCreateEmployee(newEmployee);
            }
            if (activeEmployee.activeUnit.typing) {
              handleUpdateEmployee(activeEmployee.activeUnit);
            }
          }}
          title="Da"
          style={{
            backgroundColor: "white",
            color: "var(--mainColor)",
            border: "1px solid var(--mainColor)",
            fontWeight: "700",
            width: "120px",
          }}
        />
        <Button
          title="Ne"
          style={{ width: "120px" }}
          onClick={() => {
            setErrorMessage(null);
            if (activeEmployee.activeUnit.typing) {
              resetActiveEmployee(activeEmployee.prevState);
            }
          }}
        />
      </div>
      <Loading isLoading={loading} />
    </div>
  );
}
