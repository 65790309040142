import React from "react";
import SuccessRegistration from "../../components/successRegistration";

export default function SuccessRegistrationPage() {
  return (
    <div>
      <SuccessRegistration />
    </div>
  );
}
