import React from "react";
import DropdownWrapper from "../../elements/dropdownWrapper";

const MapaProcesaPrint = React.forwardRef((props, ref) => {
  const wrapperTitleStyle = { borderRight: "1px solid var(--mainColor)" };

  return (
    <div
      ref={ref}
      className="poslovni-procesi-tables"
      style={{
        width: "85%",
        margin: "30px auto 10px auto",
        color: "black",
      }}
    >
      <div
        style={{
          marginBottom: "20px",
          pageBreakInside: "avoid",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            border: "1px solid black",
          }}
        >
          <div
            style={{
              backgroundColor: "#F0F0F0",
              textAlign: "center",
              width: "30%",
              height: "70px",
              borderRight: "1px solid black",

              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "15px",
              padding: "1.6em 0",
            }}
          >
            PUN NAZIV ORGANIZACIJE
          </div>

          <div
            style={{
              backgroundColor: "#F0F0F0",
              textAlign: "center",
              width: "50%",
              height: "70px",
              // borderRight: "1px solid var(--mainColor)",

              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "15px",
              padding: "1.6em 0",
            }}
          >
            MAPA PROCESA
          </div>
          <div
            style={{
              height: "70px",
              flex: "1",
              borderLeft: "1px solid black",
            }}
          >
            <div
              style={{
                height: "50%",
                borderBottom: "1px solid black",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#F0F0F0",
              }}
            >
              ŠIFRA PROCESA
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "black",
                height: "50%",
              }}
            >
              {props.mapa?.process?.code || ""}
            </div>
          </div>
        </div>
        <div
          style={{
            borderTop: "none",
            //borderBottom: "1px solid black",
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
            display: "flex",
          }}
        >
          <p
            style={{
              width: "30%",
              borderRight: "1px solid black",
              display: "flex",
              justifyContent: "center",
              padding: "1.6em 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "600",
              fontSize: "15px",
              backgroundColor: "#F0F0F0",
            }}
          >
            VLASNIK PROCESA
          </p>
          {props.mapa?.business_process_list &&
            props.mapa?.business_process_list?.business_process_owners &&
            props.mapa?.business_process_list?.business_process_owners.length >
              0 && (
              <ul style={{ width: "70%" }}>
                {props.mapa?.business_process_list?.business_process_owners.map(
                  (el) => {
                    return (
                      <li
                        key={el.id}
                        style={{ padding: "5px" }}
                      >{`${el.first_name} ${el.last_name}`}</li>
                    );
                  }
                )}
              </ul>
            )}
        </div>
        <div
          style={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
            display: "flex",
          }}
        >
          <p
            style={{
              width: "30%",
              borderRight: "1px solid black",
              display: "flex",
              justifyContent: "center",
              padding: "1.6em 0",
              color: "black",

              fontWeight: "bold",
              fontSize: "14px",
              backgroundColor: "#F0F0F0",
            }}
          >
            ORGANIZACIONA JEDINICA
          </p>
          <p style={{ width: "70%", padding: "5px" }}>
            {props.mapa?.business_process_list?.organization_unit_name || ""}
          </p>
        </div>
      </div>

      <div
        style={{
          marginBottom: "20px",
          pageBreakInside: "avoid",
        }}
      >
        <div
          style={{
            border: "1px solid black",
            height: "40px",
            backgroundColor: "#F0F0F0",
          }}
        >
          <p
            style={{
              width: "100%",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            NAZIV PROCESA
          </p>
        </div>
        <div
          style={{
            borderTop: "none",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",

            padding: "10px",
            pageBreakBefore: "auto",
            borderBottom: "  1px solid black",
          }}
        >
          <span>{props.mapa?.business_process_list?.name || ""}</span>
        </div>
      </div>
      <div
        style={{
          marginBottom: "20px",
          pageBreakInside: "avoid",
        }}
      >
        <div
          style={{
            border: "1px solid black",
            height: "40px",
            backgroundColor: "#F0F0F0",
          }}
        >
          <p
            style={{
              width: "100%",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            CILJ PROCESA
          </p>
        </div>
        <div
          style={{
            borderTop: "none",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",

            padding: "10px",
            pageBreakBefore: "auto",
            borderBottom: "  1px solid black",
          }}
        >
          <span>{props.mapa.process_goal ? props.mapa.process_goal : ""}</span>
        </div>
      </div>
      <div
        style={{
          marginTop: "20px",
          marginBottom: "20px",
          pageBreakInside: "auto",
        }}
      >
        <div
          style={{
            border: "1px solid black",
            height: "40px",
            backgroundColor: "#F0F0F0",
          }}
        >
          <p
            style={{
              width: "100%",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            MOGUCI RIZICI
          </p>
        </div>
        {props.mapa.risk_forms && props.mapa.risk_forms.length > 0 && (
          <ul>
            {props.mapa.risk_forms.map((form) => {
              if (!form.del)
                return (
                  <div
                    style={{
                      borderTop: "1px solid black",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",

                      padding: "10px",
                      pageBreakBefore: "auto",
                      borderBottom: "  1px solid black",
                    }}
                  >
                    <span className={"procedure-item-value-link"}>
                      {form.name}
                    </span>
                  </div>
                );
            })}
          </ul>
        )}
      </div>
      {props.mapa.activities && (
        <div
          style={{
            marginBottom: "30px",
            marginTop: "40px",
          }}
        >
          <div style={{}}>
            <div
              style={{
                border: "1px solid black",
                display: "flex",
                backgroundColor: "#F0F0F0",
                pageBreakAfter: "auto",
              }}
            >
              <p
                style={{
                  width: "35%",
                  borderRight: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px 0",
                  color: "black",
                  textTransform: "uppercase",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                AKTIVNOSTI U PROCESU
              </p>
              <p
                style={{
                  width: "65%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px 0",
                  color: "black",
                  textTransform: "uppercase",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                OPIS AKTIVNOSTI
              </p>
            </div>
            <ul>
              {props.mapa.activities.length > 0 &&
                props.mapa.activities.map((activity, index, array) => {
                  return (
                    <li
                      key={activity.id}
                      style={{
                        display: "flex",
                        width: "100%",
                        borderTop: "1px solid black",
                        borderRight: "1px solid black",
                        borderBottom: "1px solid black",

                        borderLeft: "1px solid black",
                        color: "black",
                        pageBreakInside: "avoid",
                      }}
                    >
                      <div
                        style={{
                          width: "35%",
                          padding: "10px",
                          borderRight: "1px solid black",
                        }}
                      >
                        {activity.name}
                      </div>
                      <div style={{ width: "65%", padding: "10px" }}>
                        {activity.description}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      )}

      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "30px",
            border: "1px solid black",
            fontWeight: "bold",
            justifyContent: "center",
            color: "black",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#F0F0F0",
            pageBreakAfter: "auto",
          }}
        >
          KRATAK OPIS PROCESA
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            minHeight: "40px",
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
            pageBreakInside: "avoid",
          }}
        >
          <p
            style={{
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRight: "1px solid black",
            }}
          >
            ULAZ:
          </p>
          <p
            style={{
              width: "75%",
              paddingLeft: "10px",
            }}
          >
            {props.mapa.description_input ? props.mapa.description_input : ""}
          </p>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            minHeight: "40px",
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
            pageBreakInside: "avoid",
          }}
        >
          <p
            style={{
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRight: "1px solid black",
            }}
          >
            AKTIVNOSTI:
          </p>
          <p
            style={{
              width: "75%",
              paddingLeft: "10px",
            }}
          >
            {props.mapa.description_action ? props.mapa.description_action : ""}
          </p>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            minHeight: "40px",
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
            pageBreakInside: "avoid",
          }}
        >
          <p
            style={{
              width: "25%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRight: "1px solid black",
            }}
          >
            IZLAZ:
          </p>
          <p
            style={{
              width: "75%",
              paddingLeft: "10px",
            }}
          >
            {props.mapa.description_output ? props.mapa.description_output : ""}
          </p>
        </div>
      </div>
      <div
        style={{
          border: "none",
          marginBottom: "20px",
          pageBreakInside: "avoid",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "30px",
            border: "1px solid black",
            fontWeight: "bold",
            justifyContent: "center",
            color: "black",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#F0F0F0",
          }}
        >
          VEZE SA DRUGIM POSLOVNIM PROCESIMA / PROCEDURAMA
        </div>
        <p
          style={{
            width: "100%",
            paddingLeft: "10px",
            borderTop: "none",
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
            color: "black",
          }}
        >
          {props.mapa?.correlation_to_other_processes || ""}
        </p>
      </div>
      <div
        style={{
          border: "none",
          marginBottom: "10px",
          pageBreakInside: "avoid",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "30px",
            border: "1px solid black",
            fontWeight: "bold",
            justifyContent: "center",
            color: "black",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#F0F0F0",
          }}
        >
          RESURSI ZA OSTVARIVANJE POSLOVNOG PROCESA
        </div>
        <p
          style={{
            width: "100%",
            paddingLeft: "10px",
            borderTop: "none",
            borderBottom: "1px solid black",
            borderLeft: "1px solid black",
            borderRight: "1px solid black",
            color: "black",
          }}
        >
          {props.mapa.resources ? props.mapa.resources : ""}
        </p>
      </div>
      <div
        style={{
          marginTop: "40px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "30px",
            border: "1px solid black",
            fontWeight: "bold",
            justifyContent: "center",
            color: "black",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#F0F0F0",
            pageBreakAfter: "auto",
          }}
        >
          ŠIFRE I NAZIV PROCEDURA
        </div>
        {props.mapa.procedures && props.mapa.procedures.length > 0 && (
          <ul>
            {props.mapa.procedures.map((procedure) => {
              return (
                <li
                  key={procedure.id}
                  style={{
                    display: "flex",
                    width: "100%",
                    borderTop: "none",
                    borderBottom: "1px solid black",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    pageBreakInside: "avoid",
                  }}
                >
                  <div
                    style={{
                      borderRight: "1px solid black",
                      display: "flex",
                      alignItems: "center",
                      padding: "3px",
                      color: "black",
                      width: "100px",
                      minWidth: "100px",
                    }}
                  >
                    {procedure.code}
                  </div>
                  <div style={{ padding: "3px" }}>
                    <span>{procedure.name}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div
        style={{
          marginTop: "40px",
          pageBreakInside: "avoid",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "40px",
            border: "1px solid black",
            backgroundColor: "#F0F0F0",
          }}
        >
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          ></p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            PRIPREMA
          </p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              alignItems: "center",
              fontSize: "16px",
            }}
          >
            KONTROLIŠE
          </p>
          <p
            style={{
              width: "25%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            ODOBRAVA
          </p>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "40px",
            borderTop: "none",
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            IME I PREZIME
          </p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          ></p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              alignItems: "center",
              fontSize: "16px",
            }}
          ></p>
          <p
            style={{
              width: "25%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "600",
              fontSize: "14px",
            }}
          ></p>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "40px",
            borderTop: "none",
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            POTPIS
          </p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          ></p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              alignItems: "center",
              fontSize: "16px",
            }}
          ></p>
          <p
            style={{
              width: "25%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "600",
              fontSize: "14px",
            }}
          ></p>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "40px",
            borderTop: "none",
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            DATUM
          </p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          ></p>
          <p
            style={{
              width: "25%",
              borderRight: "1px solid black",
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "bold",
              alignItems: "center",
              fontSize: "16px",
            }}
          ></p>
          <p
            style={{
              width: "25%",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
              color: "black",
              textTransform: "uppercase",
              fontWeight: "600",
              fontSize: "14px",
            }}
          ></p>
        </div>
      </div>
      {props.mapa.procedures && (
        <ul style={{ pageBreakBefore: "auto" }}>
          {props.mapa.procedures.map((procedure) => {
            if (procedure.activities && procedure.activities.length > 0)
              return (
                <li style={{ marginTop: "35px", color: "black" }}>
                  <div style={{ marginBottom: "10px" }}>
                    DOKUMENTACIJA O SISTEMU (VERTIKALNI PREGLED) - PROCEDURE
                    <span
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      {procedure.name}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "70px",
                      border: "1px solid black",
                      backgroundColor: "#F0F0F0",
                      pageBreakInside: "avoid",
                    }}
                  >
                    <p
                      style={{
                        width: "25%",
                        borderRight: "1px solid black",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        padding: "10px 0",
                        color: "black",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      DIJAGRAM TOKA
                    </p>
                    <p
                      style={{
                        width: "25%",
                        borderRight: "1px solid black",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        padding: "10px 0",
                        color: "black",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      OPIS AKTIVNOSTI
                    </p>
                    <div
                      style={{
                        width: "35%",
                        borderRight: "1px solid black",

                        justifyContent: "center",

                        color: "black",
                        textTransform: "uppercase",
                        fontWeight: "bold",

                        fontSize: "15px",
                      }}
                    >
                      <div
                        style={{
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderBottom: "1px solid black",
                        }}
                      >
                        IZVRŠENJE
                      </div>
                      <div style={{ display: "flex", height: "35px" }}>
                        <p
                          style={{
                            borderRight: "1px solid black",
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "60%",
                          }}
                        >
                          ODGOVORNOST
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "40%",
                          }}
                        >
                          ROK
                        </p>
                      </div>
                    </div>
                    <p
                      style={{
                        width: "15%",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        color: "black",
                        textTransform: "uppercase",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      PROPRATNI DOKUMENT
                    </p>
                  </div>
                  {procedure.activities.map((activity) => {
                    return (
                      <ul
                        style={{
                          borderTop: "none",
                          borderLeft: "1px solid black",
                          borderBottom: "1px solid black",
                          borderRight: "1px solid black",
                          display: "flex",
                          pageBreakInside: "avoid",
                        }}
                      >
                        <li
                          style={{
                            width: "25%",
                            borderRight: "1px solid black",
                            padding: "5px",
                          }}
                        >
                          {activity.name}
                        </li>
                        <li
                          style={{
                            width: "25%",
                            borderRight: "1px solid black",
                            padding: "5px",
                          }}
                        >
                          {activity.description}
                        </li>
                        <li
                          style={{
                            width: "35%",
                            display: "flex",
                            borderRight: "1px solid black",
                          }}
                        >
                          <ul
                            style={{
                              width: "60%",
                              borderRight: "1px solid black",
                            }}
                          >
                            {activity?.activities_designations &&
                              activity?.activities_designations?.length > 0 &&
                              activity?.activities_designations.map((des) => {
                                return (
                                  <li style={{ padding: "3px" }}>{des.name}</li>
                                );
                              })}
                          </ul>

                          <div
                            style={{
                              width: "40%",
                              fontSize: "15px",
                              padding: "3px",
                            }}
                          >
                            {activity.due_date}
                          </div>
                        </li>
                        <li>
                          {activity?.activities_documents &&
                            activity?.activities_documents.length > 0 && (
                              <ul>
                                {activity?.activities_documents.map((doc) => {
                                  return (
                                    <li style={{ padding: "3px" }}>
                                      {doc.name}
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                        </li>
                      </ul>
                    );
                  })}
                </li>
              );
          })}
        </ul>
      )}
    </div>
  );
});

export default MapaProcesaPrint;
