import React, { useState } from "react";

import { Formik, Field, Form } from "formik";

import ValidationError from "../validationError";
import { baseRequest, csrfUrl } from "../../axiosconfig";

import axios from "axios";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Loading from "../loading";

export default function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [sentEmail, setSentEmail] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const handleShowRepeatPassword = () => {
    setShowRepeatPassword((state) => !state);
  };

  const handleShowPassword = () => {
    setShowPassword((state) => !state);
  };

  return (
    <div className="login-form-wrapper">
      {!sentEmail && (
        <>
          <div className="text-regular">
            <p style={{ fontWeight: "700" }}>Unesite novu sifru</p>
          </div>
          <Formik
            initialValues={{
              password: "",

              password_confirmation: "",
            }}
            validateOnChange={false}
            // validationSchema={registerOrganizationSchema}
            onSubmit={async (values, { setFieldError }) => {
              setLoading(true);
              axios
                .get(`${csrfUrl}sanctum/csrf-cookie`, {
                  withCredentials: true,
                })
                .then((res) => {
                  baseRequest
                    .post(
                      "/reset-password",
                      {
                        password: values.password,
                        email: searchParams ? searchParams?.get("email") : "",
                        token: params?.token || "",
                        password_confirmation: values.password_confirmation,
                      },
                      {
                        withCredentials: true,
                      }
                    )
                    .then((res) => {
                      setLoading(false);
                      setSentEmail(true);
                    })
                    .catch((error) => {
                      setLoading(false);
                      const errors = error.response.data.errors;
                      Object.keys(errors).forEach((error) => {
                        setFieldError(error, errors[error]);
                      });
                    });
                });
            }}
          >
            {({ errors, touched }) => (
              <Form className="login-form">
                <div className="login-form-field-wrapper">
                  <label htmlFor="password" className="login-form-field-label">
                    Lozinka:*
                  </label>
                  <Field
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="login-textfield"
                  />
                  <button
                    className="show-password-button"
                    type="button"
                    onClick={handleShowPassword}
                  >
                    <img src="/images/showpassword.png" />
                    {showPassword && <span className="slash"></span>}
                  </button>
                  {errors.password && (
                    <ValidationError error={errors.password} />
                  )}
                </div>
                <div className="login-form-field-wrapper">
                  <label
                    htmlFor="password_confirmation"
                    className="login-form-field-label"
                  >
                    Ponovite lozinku:*
                  </label>
                  <Field
                    name="password_confirmation"
                    type={showRepeatPassword ? "text" : "password"}
                    className="login-textfield"
                  />
                  <button
                    className="show-password-button"
                    type="button"
                    onClick={handleShowRepeatPassword}
                  >
                    <img src="/images/showpassword.png" />
                    {showRepeatPassword && <span className="slash"></span>}
                  </button>
                  {errors.password_confirmation && (
                    <ValidationError error={errors.passwordConfirmation} />
                  )}
                </div>

                <button type="submit" className="login-form-button">
                  Pošalji
                </button>
              </Form>
            )}
          </Formik>
          <Loading isLoading={loading} />
        </>
      )}
      {sentEmail && (
        <div className="text-regular">
          <p style={{ fontWeight: "700" }}>Uspesno ste obnovili lozinku</p>

          <p>Ulogujte se s novom lozinkom.</p>
        </div>
      )}
    </div>
  );
}
