import React from "react";
import "./dropdownWrapper.css";

export default function DropdownWrapper({ title, style, wrapperStyle, children }) {
  return (
    <div className="dropdown-wrapper" style={wrapperStyle}>
      <div className="dropdown-title" style={style || undefined}>
        {title}
      </div>
      <div className="dropdown-wrapper-children">{children}</div>
    </div>
  );
}
