import React from "react";
import ResetPassword from "../../components/resetPassword";

export default function ResetPasswordPage() {
  return (
    <div className="login-page">
      <ResetPassword />
    </div>
  );
}
