import React from "react";
import "./button.css";

export default function Button({
  title,
  disabledColor,
  onClick,
  style,
  disabled,
}) {
  return (
    <button
      className={disabledColor ? "submit-btn btn-disabled" : "submit-btn"}
      onClick={onClick}
      style={style}
      disabled={disabled}
    >
      {title}
    </button>
  );
}
