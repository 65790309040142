import React from "react";
import PoslovniProcesiOwnerItem from "../poslovniProcesiOwnerItem";

export default function PoslovniProcessOwnerTable({}) {
  const poslovniProcessTableTitles = [
    { id: 1, title: "", width: "25%" },
    { id: 2, title: "priprema", width: "25%" },
    { id: 3, title: "KONTROLIŠE", width: "25%" },
    { id: 4, title: "odobrava", width: "25%" },
  ];

  return (
    <div className="table" style={{ marginTop: "0" }}>
      <ul className="table-header">
        {poslovniProcessTableTitles.map((title) => {
          return (
            <li
              className="table-header-item"
              key={title.id}
              style={{ width: title.width }}
            >
              <p className="table-header-title">{title.title}</p>
            </li>
          );
        })}
      </ul>
      <div className="poslovli-proseci-table-list">
        <PoslovniProcesiOwnerItem title="ime i prezime" />
        <PoslovniProcesiOwnerItem title="potpis" />
        <PoslovniProcesiOwnerItem title="datum" />
      </div>
    </div>
  );
}
