import React from "react";

import Button from "../elements/button";

export default function NavigatePopup({
  setShowNavigatePopup,
  handleNavigate,
}) {
  return (
    <div className="link-popup">
      <p style={{ marginBottom: "20px", textAlign: "center" }}>
        Imate nesacuvane podatke. Zelite da napustite stranu?
      </p>
      <div className="link-popup-buttons">
        <Button
          onClick={() => {
            handleNavigate();
          }}
          title="Da"
          style={{
            backgroundColor: "white",
            color: "var(--mainColor)",
            border: "1px solid var(--mainColor)",
            fontWeight: "700",
            width: "120px",
          }}
        />
        <Button
          title="Ne"
          style={{ width: "120px" }}
          onClick={() => {
            setShowNavigatePopup(false);
          }}
        />
      </div>
    </div>
  );
}
