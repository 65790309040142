import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../userContext";

function MapeProcesaRow({ row, titles, setMapaToDelete }) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const handleShowMapa = (id) => {
    navigate(`/admin/mape-procesa/${id}`);
  };

  const hasMapaPermission = (user, org_unit, level) => {
    if (user.role_id === 1) {
      return true;
    } else if (
      user.role_id > 1 &&
      user.organization_unit_id === org_unit &&
      user.level === level
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <ul className="registri-rizika-row">
      <li
        className="registri-rizika-row-item"
        style={{ width: titles.find((title) => title.id === 0).width }}
      >
        {row.business_process_list.code}
      </li>
      <li
        className="registri-rizika-row-item"
        style={{
          width: titles.find((title) => title.id === 1).width,
        }}
      >
        {row.business_process_list.name}
      </li>
      <li
        className="registri-rizika-row-item"
        style={{
          width: titles.find((title) => title.id === 2).width,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {row.process.business_process_owners.map((owner) => {
          return (
            <p key={owner.id}>
              <span style={{ marginRight: "7px" }}>{owner.first_name}</span>
              <span>{owner.last_name}</span>
            </p>
          );
        })}
      </li>
      <li
        className="registri-rizika-row-item"
        style={{ width: titles.find((title) => title.id === 3).width }}
      ></li>
      <li
        className="registri-rizika-row-item"
        style={{
          width: titles.find((title) => title.id === 4).width,
        }}
      >
        {row.verified_at.split(" ")[0]}
      </li>

      <li
        className="options-buttons-block"
        style={{
          width: titles.find((title) => title.id === 5).width,
        }}
      >
        <button
          className="options-buttons-block-button"
          onClick={() => handleShowMapa(row.id)}
        >
          <img alt="" src="/images/showpassword.png" />
        </button>

        {user &&
          hasMapaPermission(
            user,
            row.process.organization_unit_id,
            row.process.level
          ) && (
            <button
              className="options-buttons-block-button"
              onClick={() => setMapaToDelete(row)}
            >
              <img alt="" src="/images/trash.png" />
            </button>
          )}
      </li>
    </ul>
  );
}

export default MapeProcesaRow;
