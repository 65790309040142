import React from "react";
import "./designationdescription.css";

function DesignationDescription({ description, setShowDescription }) {
  return (
    <>
      <div className="designation-description-popup">
        <p>{description}</p>
      </div>
      <span
        className="close-designation-description-popup "
        onClick={() => setShowDescription({ id: null, isOpen: false })}
      >
        <img alt="" src="/images/iks.png" />
      </span>
    </>
  );
}

export default DesignationDescription;
