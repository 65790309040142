import React, { useState } from "react";
import "./poslovniProcesiOwnerItem.css";

export default function PoslovniProcesiOwnerItem({ title }) {
  return (
    <ul className="poslovni-procesi-table-list">
      <li className="poslovni-procesi-table-item owner-item-title" style={{ width: "25%" }}>
        {title}
      </li>
      <li className="poslovni-procesi-table-item owner-item-disabled" style={{ width: "25%" }}></li>
      <li className="poslovni-procesi-table-item owner-item-disabled" style={{ width: "25%" }}></li>
      <li className="poslovni-procesi-table-item owner-item-disabled" style={{ width: "25%" }}></li>
    </ul>
  );
}
