import React from "react";
import "./loading.css";

export default function Loading({ isLoading }) {
  if (isLoading) {
    return (
      <div className="loading">
        <div className="loading-circle">
          <img src="/images/spinner.png" />
        </div>
      </div>
    );
  }
  return null;
}
