import React from "react";
import SectionHeader from "../../components/elements/sectionHeader";
import AddEmployeesList from "../../components/employeesListItem";
import EmployeesListItem from "../../components/employeesListItem";
import EmployeesList from "../../components/employeesList";

export default function AddEmployeesListPage() {
  return (
    <div className="page">
      <SectionHeader
        title="Spisak zaposlenih"
        description="Molimo vas da ne ulazite u proces registracije bez pomoći stručnog lica. Naš tim Vam stoji na raspolaganju."
      />
      <EmployeesList />
    </div>
  );
}
