import React, { useContext, useState } from "react";
import "./startpageform.css";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import ValidationError from "../validationError";
import { baseRequest, csrfUrl } from "../../axiosconfig";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../loading";
import { useEffect } from "react";
import { UserContext } from "../../userContext";

export default function Startpageform({ isLoggedin, setIsloggedin }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setUser } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedin) {
      navigate("/admin/struktura-preduzeca");
    }
  }, [isLoggedin]);

  const handleShowPassword = () => {
    setShowPassword((prevState) => {
      return !prevState;
    });
  };

  const handleShowRepeatPassword = () => {
    setShowRepeatPassword((prevState) => {
      return !prevState;
    });
  };

  return (
    <div className="startpage-form-wrapper">
      <Formik
        initialValues={{
          name: "",

          password: "",
          email: "",
          super_admin_password: "",
          password_confirmation: "",
        }}
        validateOnChange={false}
        validateOnBlur={false}
        // validationSchema={registerOrganizationSchema}
        onSubmit={async (values, { setFieldError }) => {
          setLoading(true);
          axios
            .get(`${csrfUrl}sanctum/csrf-cookie`, {
              withCredentials: true,
            })
            .then((res) => {
              baseRequest
                .post(
                  "/register",
                  {
                    super_admin_password: values.super_admin_password,
                    name: values.name,
                    password: values.password,
                    password_confirmation: values.password_confirmation,
                    email: values.email,
                  },
                  {
                    withCredentials: true,
                  }
                )
                .then((res) => {
                  setIsloggedin(true);
                  setLoading(false);
                  baseRequest
                    .get("/user", { withCredentials: true })
                    .then((res) => {
                      setUser(res.data.data);
                      setLoading(false);
                      navigate("/admin/struktura-preduzeca");
                    });
                })
                .catch((error) => {
                  setLoading(false);
                  const errors = error.response.data.errors;
                  Object.keys(errors).forEach((error) => {
                    setFieldError(error, errors[error]);
                  });
                });
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }}
      >
        {({ errors, touched }) => (
          <Form className="startpage-form">
            <div className="form-field-wrapper">
              <label
                htmlFor="organization-password"
                className="form-field-label"
              >
                Lozinka za registraciju organizacije:*
              </label>
              <Field
                name="super_admin_password"
                type="password"
                className={
                  !errors.super_admin_password
                    ? "textfield"
                    : "textfield error-border"
                }
              />
              {errors.super_admin_password && (
                <ValidationError error={errors.super_admin_password} />
              )}
              {/* <button className="show-password-button" type="button" onClick={handleShowPassword}>
                <img src="/images/showpassword.png" />
              </button> */}
            </div>
            <div className="form-field-wrapper">
              <label htmlFor="name" className="form-field-label">
                Pun naziv organizacije:
              </label>
              <Field name="name" type="text" className="textfield" />
            </div>
            <div className="form-field-wrapper">
              <label htmlFor="email" className="form-field-label">
                Imejl-adresa Super Admina:*
              </label>
              <Field
                name="email"
                type="text"
                className={
                  !errors.email ? "textfield" : "textfield error-border"
                }
              />
              {errors.email && <ValidationError error={errors.email} />}
            </div>
            <div className="form-field-wrapper">
              <label htmlFor="password" className="form-field-label">
                Lozinka Super Admina:*
              </label>
              <Field
                name="password"
                type={showPassword ? "text" : "password"}
                className={
                  !errors.password ? "textfield" : "textfield error-border"
                }
              />

              <button
                className="show-password-button"
                type="button"
                onClick={handleShowPassword}
              >
                <img src="/images/showpassword.png" />
                {showPassword && <span className="slash"></span>}
              </button>
              {errors.password && <ValidationError error={errors.password} />}
            </div>
            <div className="form-field-wrapper">
              <label
                htmlFor="password_confirmation"
                className="form-field-label"
              >
                Ponovi lozinku Super Admina:*
              </label>
              <Field
                name="password_confirmation"
                type={showRepeatPassword ? "text" : "password"}
                className="textfield"
              />
              <button
                className="show-password-button"
                type="button"
                onClick={handleShowRepeatPassword}
              >
                <img src="/images/showpassword.png" />
                {showRepeatPassword && <span className="slash"></span>}
              </button>
            </div>
            <button type="submit" className="startpage-form-button">
              Dalje
            </button>
          </Form>
        )}
      </Formik>
      <div className="text-small" style={{ marginTop: "0.5em" }}>
        *Obavezna polja
      </div>
      <Loading isLoading={loading} />
    </div>
  );
}
