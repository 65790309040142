import React from "react";
import NewFormularRizika from "../../components/admin/newFormular";

function AdminNewFormularRizikaPage(props) {
  return (
    <div className="admin-page-child-wrapper">
      <NewFormularRizika />
    </div>
  );
}

export default AdminNewFormularRizikaPage;
