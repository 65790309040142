import React from "react";
import AdminStrukturaPreduzeca from "../../components/admin/adminStrukturaPreduzeca";

function AdminStrukturaPreduzecaPage(props) {
  return (
    <div className="admin-page-child-wrapper">
      <AdminStrukturaPreduzeca />
    </div>
  );
}

export default AdminStrukturaPreduzecaPage;
