import React from "react";
import MapeProcesaRow from "../mapeProcesaRow";

const MapeProcesaList = React.forwardRef((props, ref) => {
  const headerTitles = [
    { id: 0, title: "Sifra procesa", width: "10%" },
    { id: 1, title: "Poslovni proces", width: "23%" },
    { id: 2, title: "Vlasnik procesa", width: "23%" },
    { id: 3, title: "Odobrio", width: "20%" },
    { id: 4, title: "Datum izmene", width: "15%" },
    { id: 5, title: "", width: "9%" },
  ];

  return (
    <div ref={ref}>
      <ul
        className="registri-header "
        style={{
          borderLeft: "var(--mainBorder)",
          borderRight: "var(--mainBorder)",
          borderBottom: "var(--mainBorder)",
        }}
      >
        {headerTitles.map((item) => {
          if (!item.subtitle) {
            return (
              <li
                key={item.id}
                className="registri-header-cell"
                style={{ width: item.width ? item.width : "auto" }}
              >
                {item.title}
              </li>
            );
          } else {
            return (
              <li
                key={item.id}
                className="registri-header-cell-subtitle"
                style={{ width: item.width ? item.width : "auto" }}
              >
                <span className="registri-header-cell-title">{item.title}</span>
                <span className="registri-header-cell-subtitle-span">
                  {item.subtitle}
                </span>
              </li>
            );
          }
        })}
      </ul>
      <ul
        style={{
          borderLeft: "var(--mainBorder)",
          borderRight: "var(--mainBorder)",
        }}
      >
        {props.list &&
          props.list.map((mapa) => {
            return (
              <MapeProcesaRow
                key={mapa.id}
                row={mapa}
                titles={headerTitles}
                setMapaToDelete={props.setMapaToDelete}
              />
            );
          })}
      </ul>
    </div>
  );
});

export default MapeProcesaList;
