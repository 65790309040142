import React from "react";
import "./contact.css";
import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <div className="contact">
      <div className="contact-block">
        <div style={{ marginBottom: "20px" }}>
          <h3 style={{ color: "var(--mainColor)", fontWeight: "900", fontSize: "30px", marginBottom: "20px" }}>
            FUK Srbija
          </h3>
          <p>Ulica i broj,</p>
          <p>11000 Beograd,</p>
          <p>Srbija</p>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <p>Tel. +00 00000 0000</p>
          <p>Fax. +00 00000 0000</p>
        </div>
        <div>
          <p style={{ color: "var(--mainColor)", fontWeight: "900", textDecoration: "underline" }}>info@fuk.rs</p>
        </div>
      </div>
    </div>
  );
}
