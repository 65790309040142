import React from "react";
import "./centerdropdownwrapper.css";

export default function CenterDropdownWrapper({
  title,
  style,
  wrapperStyle,
  children,
  title1,
  title2,
  isMultiple = false,
  centerDropdownWrapperChildrenStyle,
}) {
  return (
    <div className="center-dropdown-wrapper" style={wrapperStyle}>
      <div className="center-dropdown-title" style={style || undefined}>
        {isMultiple ? (
          <>
            <p style={{ width: "30%" }}>{title1}</p>
            <p style={{ width: "70%" }}>{title2}</p>
          </>
        ) : (
          <p>{title}</p>
        )}
      </div>
      <div
        className="center-dropdown-wrapper-children"
        style={centerDropdownWrapperChildrenStyle}
      >
        {children}
      </div>
    </div>
  );
}
