import React, { useState, useRef, useEffect } from "react";
import "./datepicker.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default function Calendar({
  handleSelectDate,
  parentStartDate,
  style,
  disabled,
}) {
  const [startDate, setStartDate] = useState(null);
  const [openCalendar, setOpenCalendar] = useState(false);
  const calendarRef = useRef();

  // useEffect(() => {
  //   if (startDate) {
  //     console.log(startDate);
  //     handleSelectDate(startDate);
  //   }
  // }, [startDate]);

  // useEffect(()=> {

  // }, [resetCalendar])

  const days = ["Nd", "Po", "Ut", "Sr", "Ct", "Pe", "Sb"];
  const months = [
    "Januar",
    "Februar",
    "Mart",
    "April",
    "Maj",
    "Jun",
    "Jul",
    "August",
    "Septembar",
    "Oktobar",
    "Novembar",
    "Decembar",
  ];

  const locale = {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "dd/mm/yyyy",
    },
  };

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.setOpen(openCalendar);
    }
  }, [openCalendar]);

  return (
    <div className="calendar-wrapper">
      <DatePicker
        ref={calendarRef}
        selected={parentStartDate}
        onChange={(date) => handleSelectDate(date)}
        locale={locale}
        style={style}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        disabled={disabled}
      />
      <div
        className="calendar-button"
        onClick={
          !disabled
            ? () => {
                setOpenCalendar((state) => !state);
              }
            : () => {}
        }
      >
        <img src="/images/dropdowndown.png" />
      </div>
    </div>
  );
}
