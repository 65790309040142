import React, { useState } from "react";
import "./linkpopup.css";
import Button from "../elements/button";

export default function LinkPopup({
  handleAddLink,
  setOpenLinkPopup,
  row,
  link,
  handleRejectMapaDukument,
  handleChangeNewMapaDocument,
  handleSaveNewMapaDocument,
  isNewRow,
}) {
  return (
    <div className="link-popup">
      <div className="link-popup-input-wrapper">
        <p className="link-popup-label">Naziv linka:</p>
        <input
          className="link-popup-input"
          onChange={(e) =>
            handleChangeNewMapaDocument(isNewRow, "name", e, row)
          }
          value={link?.name ? link.name : ""}
        />
      </div>
      <div className="link-popup-input-wrapper">
        <p className="link-popup-label">URL:</p>
        <input
          placeholder="https://example.com"
          className="link-popup-input"
          onChange={(e) =>
            handleChangeNewMapaDocument(isNewRow, "path", e, row)
          }
          value={link?.path ? link.path : ""}
        />
      </div>
      <div className="link-popup-buttons">
        <Button
          onClick={() => {
            handleRejectMapaDukument(isNewRow, row);
            setOpenLinkPopup(false);
            // handleAddLink(link);
          }}
          title="Odbaci"
          style={{
            backgroundColor: "white",
            color: "var(--mainColor)",
            border: "1px solid var(--mainColor)",
            fontWeight: "700",
            width: "120px",
          }}
        />
        <Button
          title="Dodaj link"
          style={{ width: "120px" }}
          onClick={() => {
            handleSaveNewMapaDocument(true);
            setOpenLinkPopup(false);
          }}
        />
      </div>
    </div>
  );
}
