import React from "react";
import AdminEmployeesList from "../../components/admin/employees";

function AdminEmployeesPage(props) {
  return (
    <div className="admin-page-child-wrapper">
      <AdminEmployeesList />
    </div>
  );
}

export default AdminEmployeesPage;
