import React, { useEffect, useState, useRef } from "react";
import "./newposlovniproces.css";

import DropDown from "../elements/dropDown";

export default function NewPoslovniProces({
  process,
  designations,
  handleChangeNewProcess,
  handleSelectNewProcessOwners,
  handleCretaeNewProcess,
  handleDeleteNewProcess,
  errorObject,
  handleChangeNewProcessCode,
}) {
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "100%";
      const scrollHeight = inputRef.current.scrollHeight;
      inputRef.current.style.height = scrollHeight + "px";
    }
  }, [process.name]);

  return (
    <ul
      className="poslovni-procesi-table-list"
      style={{
        borderLeft: "1px solid var(--mainColor)",
        borderRight: "1px solid var(--mainColor)",
        borderBottom: "1px solid var(--mainColor)",
        borderTop: "none",
      }}
    >
      <li
        className="poslovni-procesi-table-item"
        style={{
          width: "7%",
          paddingTop: "26px",
          paddingBottom: "5px",
          alignItems: "flex-start",
        }}
      >
        {process.redniBroj}
      </li>
      <li
        className={
          !errorObject?.code
            ? "poslovni-procesi-table-item"
            : "poslovni-procesi-table-item error-border"
        }
        style={{ width: "15%" }}
      >
        <textarea
          rows={1}
          className="poslovni-procesi-table-input"
          onChange={(e) => handleChangeNewProcessCode(e)}
          value={process.code || ""}
        />
      </li>
      <li
        className={
          !errorObject?.name
            ? "poslovni-procesi-table-item"
            : "poslovni-procesi-table-item error-border"
        }
        style={{ width: "35%" }}
      >
        <textarea
          ref={inputRef}
          rows={1}
          className="poslovni-procesi-table-input"
          onChange={(e) => handleChangeNewProcess(e)}
          value={process.name || ""}
          style={{ paddingTop: "23px" }}
        />
      </li>
      <li
        className={
          !errorObject?.business_process_owners
            ? "poslovni-procesi-table-item"
            : "poslovni-procesi-table-item error-border"
        }
        style={{ width: "35%", paddingTop: "18px" }}
        // onClick={() => setshowDesignationsDropdown((showDropdown) => !showDropdown)}
      >
        {/* {process?.owner && <p className="selected-unit  ">{process.owner.name}</p>}
        <SelectDesignationDropdown
          designations={designations}
          style={{ width: "100%" }}
          showDesignation={true}
          pstyle={{ textAlign: "left", paddingLeft: "5px", width: "80%" }}
          showDesignationsDropdown={showDesignationsDropdown}
          handleSelectDesignation={selectOwner}
        />
        <div className="dropdown-arrow">
          <img src={showDesignationsDropdown ? "/images/dropdownup.png" : "/images/dropdowndown.png"} />
        </div> */}
        <DropDown
          isMulti={true}
          list={designations}
          onChange={handleSelectNewProcessOwners}
          selectedValues={process.business_process_owners}
        />
      </li>
      <li className="poslovni-procesi-table-item" style={{ width: "8%" }}>
        <div className="employees-list-item options-block">
          <button
            type="button"
            className="employee-item-options-button"
            onClick={
              process?.adding
                ? () => {
                    // setshowOrganizationsDropdown(false);
                    //setshowDesignationsDropdown(false);
                    handleCretaeNewProcess(process);
                  }
                : () => {}
            }
          >
            <img
              src={process.adding ? "/images/check.png" : "/images/edit.png"}
            />
          </button>
          <button
            type="button"
            className="employee-item-options-button"
            onClick={() => {
              handleDeleteNewProcess();
            }}
          >
            <img src="/images/trash.png" />
          </button>
        </div>
      </li>
    </ul>
  );
}
