import React, { useContext, useEffect, useRef } from "react";
import "./poslovniProcessTableItem.css";

import DropDown from "../elements/dropDown";
import { UserContext } from "../../userContext";
import { hasProcessPermission } from "../admin/businessProcesses";

export default function PoslovniProcessTableItem({
  process,
  designations,
  handleSelectProcessOwner,
  handleSetEditPoslovniProcesItem,
  handleChangeProcessCode,
  handleChangeActiveProcessName,
  handleUpdateProcess,
  errorObject,
  activeProcess,
  handleDeleteProcess,
  redniBroj,
  activeUnit,
}) {
  const inputRef = useRef();

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "100%";
      const scrollHeight = inputRef.current.scrollHeight;

      inputRef.current.style.height = scrollHeight + "px";
    }
  }, [process.name]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "100%";
      const scrollHeight = inputRef.current.scrollHeight;

      inputRef.current.style.height = scrollHeight + "px";
    }
  }, [process.business_process_owners]);

  return (
    <ul className="poslovni-procesi-table-list">
      <li
        className="poslovni-procesi-table-item"
        style={{ width: "7%", alignItems: "flex-start", paddingTop: "26px" }}
      >
        {redniBroj}
      </li>
      <li
        className={
          errorObject?.code && activeProcess?.id === process.id
            ? "poslovni-procesi-table-item error-border"
            : "poslovni-procesi-table-item"
        }
        style={{ width: "15%" }}
      >
        <textarea
          rows={1}
          className="poslovni-procesi-table-input"
          onChange={(e) => handleChangeProcessCode(e, process.id)}
          value={process.code}
          disabled={!process.editing}
        />
      </li>
      <li
        className={
          errorObject?.name && activeProcess?.id === process.id
            ? "poslovni-procesi-table-item error-border"
            : "poslovni-procesi-table-item"
        }
        style={{ width: "35%" }}
      >
        <textarea
          ref={inputRef}
          rows={1}
          className="poslovni-procesi-table-input"
          onChange={(e) => handleChangeActiveProcessName(e, process.id)}
          value={process.name}
          disabled={!process.editing}
          style={{ paddingTop: "23px" }}
        />
      </li>
      <li
        className={
          errorObject?.business_process_owners &&
          activeProcess?.id === process.id
            ? "poslovni-procesi-table-item error-border"
            : "poslovni-procesi-table-item"
        }
        style={{ width: "35%", paddingTop: "16px" }}
      >
        <DropDown
          isMulti={true}
          list={designations}
          onChange={handleSelectProcessOwner(process.id)}
          selectedValues={process.business_process_owners}
          disabled={!process.editing}
        />
      </li>
      <li className="poslovni-procesi-table-item" style={{ width: "8%" }}>
        {hasProcessPermission(
          user,
          activeUnit.organization_unit_id,
          activeUnit.level
        ) && (
          <div className="employees-list-item options-block">
            <button
              type="button"
              className="employee-item-options-button"
              onClick={
                process?.editing
                  ? () => {
                      handleUpdateProcess(process);
                    }
                  : () => handleSetEditPoslovniProcesItem(process)
              }
            >
              <img
                src={process.editing ? "/images/check.png" : "/images/edit.png"}
                alt="im"
              />
            </button>
            <button
              type="button"
              className="employee-item-options-button"
              onClick={() => {
                handleDeleteProcess(process.id);
              }}
            >
              <img src="/images/trash.png" />
            </button>
          </div>
        )}
      </li>
    </ul>
  );
}
