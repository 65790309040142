import React, { useState } from "react";
import "./notifications.css";
import Notification from "../notification";

function Notifications(props) {
  const [activeSortValue, setActiveSortValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const notificationsList = [
    {
      id: 1,
      date: "03.03.2023",
      message: "Korisnik marko markovic je izmenio listu poslovnih procesa",
    },
    {
      id: 2,
      date: "03.03.2023",
      message: "Korisnik marko markovic je izmenio listu poslovnih procesa",
    },
    {
      id: 3,
      date: "03.03.2023",
      message: "Korisnik marko markovic je izmenio listu poslovnih procesa",
    },
  ];
  return (
    <div className="notifications">
      <div className="notifications-header">
        <div className="sort-options-dropdown">
          <div
            className="sort-options-dropdown-selected"
            onClick={() => {
              setShowDropdown((state) => !state);
            }}
          >
            <span>Sortiraj po:</span>
            <span style={{ fontWeight: "bold", margin: "0 10px 0 5px" }}>
              {activeSortValue}
            </span>

            <img
              src="/images/sidebararrow.png"
              className="sidebar-navigation-item-arrow"
            ></img>
          </div>
          {showDropdown && (
            <ul className="sort-options-dropdown-options">
              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setActiveSortValue("Najnovije");
                  setShowDropdown(false);
                }}
              >
                Najnovije
              </li>
              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setActiveSortValue("Najstarije");
                  setShowDropdown(false);
                }}
              >
                Najstarije
              </li>
              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setActiveSortValue("Najbolje");
                  setShowDropdown(false);
                }}
              >
                Najbolje
              </li>
            </ul>
          )}
        </div>
      </div>
      <ul>
        {notificationsList.map((notification) => {
          return (
            <Notification key={notification.id} notification={notification} />
          );
        })}
      </ul>
    </div>
  );
}

export default Notifications;
