import React, { useEffect, useRef } from "react";
import Division from "../Division";
import DepartmentDesignation from "../DepartmentDesignation";
import { useContext } from "react";
import { UserContext } from "../../userContext";
import { hasDesignationPermission } from "../admin/adminStrukturaPreduzeca";

export default function Department({
  department,
  divisions,
  subdivisions,
  handleCreateDesignation,
  handleDeleteUnit,
  handleDeleteNewUnit,
  value,
  handleAddNewUnit,
  setValue,
  handleCreateUnit,
  handleKeyDown,
  handleSetEditUnit,
  handleChangeUnitName,
  handleUpdateUnit,
  handleSetEditDesignation,
  handleChangeDesignationName,
  handleUpdateDesignation,
  handleDeleteDesignation,
  handleSetUnitOpen,
  handleChangeNewUnit,
  validationError,
  activeUnit,
  handleShowDescription,
  showDescription,
  setShowDescription,
  hasOrganizationUnitPermission,
}) {
  const odelenjeRef = useRef();
  const radnoMestoRef = useRef();
  const designationRef = useRef();
  const newOdelenjeRef = useRef();
  const { user } = useContext(UserContext);

  function objectNotEmpty(object = {}) {
    return Object.keys(object).length > 0;
  }

  useEffect(() => {
    if (odelenjeRef.current) {
      odelenjeRef.current.style.height = "100%";
      const scrollHeight = odelenjeRef.current.scrollHeight;
      odelenjeRef.current.style.height = scrollHeight + "px";
    }
    if (designationRef.current) {
      designationRef.current.style.height = "100%";
      const scrollHeight = designationRef.current.scrollHeight;
      designationRef.current.style.height = scrollHeight + "px";
    }
  }, [department]);

  useEffect(() => {
    if (radnoMestoRef.current && value.key === "department_designation") {
      radnoMestoRef.current.style.height = "100%";
      const scrollHeight = radnoMestoRef.current.scrollHeight;
      radnoMestoRef.current.style.height = scrollHeight + "px";
    }

    if (newOdelenjeRef.current && value.key === "division") {
      newOdelenjeRef.current.style.height = "100%";
      const scrollHeight = newOdelenjeRef.current.scrollHeight;
      newOdelenjeRef.current.style.height = scrollHeight + "px";
    }
  }, [value]);

  return (
    <div>
      <div className="organization-wrapper">
        <div
          className={
            department.id === validationError?.id &&
            validationError?.level === 1
              ? "input-wrapper unit-error-border"
              : "input-wrapper"
          }
        >
          <img className="department-icon" src="/images/departmenticon.png" />
          <textarea
            ref={odelenjeRef}
            rows="1"
            readOnly={!department.editing && !department.typing}
            type="text"
            className="department-title-input"
            value={department.name}
            onChange={(e) =>
              handleChangeUnitName(e, department.id, "departments")
            }
            style={{ resize: "none", overflow: "hidden" }}
          ></textarea>

          {hasOrganizationUnitPermission && (
            <div
              className="edit-department-buttons-block"
              style={{ top: "unset", bottom: "12px" }}
            >
              {department?.editing && (
                <button onClick={() => handleUpdateUnit(department, 1)}>
                  <img src="/images/check1.jpg" />
                </button>
              )}
              {!department?.editing && (
                <button
                  onClick={() =>
                    handleSetEditUnit(department.id, "departments", 1)
                  }
                >
                  <img src="/images/edit.png" />
                </button>
              )}
              <button
                onClick={() =>
                  handleDeleteUnit({ id: department.id, level: 1 }, "unit")
                }
              >
                <img src="/images/trash.png" />
              </button>
            </div>
          )}
          <div
            className="unfold-list-arrow"
            onClick={() => handleSetUnitOpen(department.id, "departments")}
          >
            <img
              alt=""
              src={
                department.isOpen
                  ? "/images/dropdownup.png"
                  : "/images/dropdowndown.png"
              }
            ></img>
          </div>
        </div>

        {objectNotEmpty(department.designations) && department.isOpen && (
          <ul className="positions-list">
            {Object.keys(department.designations).map((key) => {
              const position = department.designations[key];
              if (!position.del)
                return (
                  <DepartmentDesignation
                    key={`designation${position.id}`}
                    position={position}
                    validationError={validationError}
                    handleChangeDesignationName={handleChangeDesignationName}
                    department={department}
                    setShowDescription={setShowDescription}
                    handleUpdateDesignation={handleUpdateDesignation}
                    handleShowDescription={handleShowDescription}
                    activeUnit={activeUnit}
                    handleSetEditDesignation={handleSetEditDesignation}
                    handleDeleteDesignation={handleDeleteDesignation}
                    showDescription={showDescription}
                    hasDesignationPermission={hasDesignationPermission(
                      user,
                      department.id,
                      1
                    )}
                  />
                );
            })}
          </ul>
        )}
        <div>
          {value &&
            value.key === "department_designation" &&
            value.parent_id === department.id &&
            department.isOpen && (
              <div className="new-designation-block">
                <div
                  className={
                    validationError?.adding &&
                    validationError?.key === "department_designation"
                      ? "new-designation-input-wrapper error-border-bottom"
                      : "new-designation-input-wrapper"
                  }
                >
                  <textarea
                    ref={radnoMestoRef}
                    autoFocus
                    rows="1"
                    placeholder="naziv radnog mesta"
                    type="text"
                    className="add-to-list-input"
                    value={value.name}
                    onChange={(e) => handleChangeNewUnit(e, "name")}
                    onKeyDown={(e) =>
                      handleKeyDown(e, handleCreateDesignation, value)
                    }
                    style={{ resize: "none", overflow: "hidden" }}
                  ></textarea>

                  <div className="edit-department-buttons-block">
                    {(value?.adding || value?.editing) && value.name && (
                      <button onClick={() => handleCreateDesignation(value)}>
                        <img src="/images/check1.jpg" />
                      </button>
                    )}
                    {!value?.adding && !value.editing && value.name && (
                      <button
                      // onClick={(e) => handleSetEdit(e, department.id)}
                      >
                        <img src="/images/edit.png" />
                      </button>
                    )}
                    <button onClick={() => handleDeleteNewUnit()}>
                      <img src="/images/trash.png" />
                    </button>
                    <button
                      onClick={() =>
                        setValue((value) => {
                          return {
                            ...value,
                            openDescription: !value.openDescription,
                          };
                        })
                      }
                    >
                      <img
                        alt=""
                        src={
                          value?.openDescription
                            ? "/images/iks.png"
                            : "/images/plus.png"
                        }
                      />
                    </button>
                  </div>
                </div>
                {value.openDescription && (
                  <textarea
                    rows="4"
                    cols="50"
                    className="position-description"
                    placeholder="dodajte opis"
                    value={value.description}
                    onChange={(e) => handleChangeNewUnit(e, "description")}
                  />
                )}
              </div>
            )}
        </div>

        {!department.typing &&
          department.isOpen &&
          hasDesignationPermission(user, department.id, 1) && (
            <button
              disabled={activeUnit}
              className="add-position-button"
              onClick={() =>
                handleAddNewUnit({
                  name: "",
                  key: "department_designation",
                  description: "",
                  level: 1,
                  parent_id: department.id,
                  adding: true,
                  openDescription: true,
                })
              }
              // disabled={
              //   odsek?.positions?.length && odsek.positions.some((pos) => pos.typing === true)
              // }
            >
              <span>
                <img
                  alt=""
                  className="plus-circle-icon"
                  src="/images/pluscircle.png"
                />
              </span>
              <span>Dodajte radno mesto</span>
            </button>
          )}
      </div>
      {department.isOpen && (
        <div className="add-to-list-block">
          {divisions?.length > 0 && (
            <ul className="odseci-list">
              {divisions.map((division) => {
                if (!division.del)
                  return (
                    <Division
                      division={division}
                      key={`Division${division.id}`}
                      value={value}
                      handleCreateDesignation={handleCreateDesignation}
                      handleKeyDown={handleKeyDown}
                      setValue={setValue}
                      handleAddNewUnit={handleAddNewUnit}
                      department={department}
                      handleCreateUnit={handleCreateUnit}
                      subdivisions={subdivisions}
                      handleSetEditUnit={handleSetEditUnit}
                      handleChangeUnitName={handleChangeUnitName}
                      handleUpdateUnit={handleUpdateUnit}
                      handleSetEditDesignation={handleSetEditDesignation}
                      handleChangeDesignationName={handleChangeDesignationName}
                      handleUpdateDesignation={handleUpdateDesignation}
                      handleDeleteDesignation={handleDeleteDesignation}
                      handleDeleteUnit={handleDeleteUnit}
                      handleDeleteNewUnit={handleDeleteNewUnit}
                      handleChangeNewUnit={handleChangeNewUnit}
                      validationError={validationError}
                      handleSetUnitOpen={handleSetUnitOpen}
                      handleShowDescription={handleShowDescription}
                      showDescription={showDescription}
                      setShowDescription={setShowDescription}
                      activeUnit={activeUnit}
                      hasOrganizationUnitPermission={
                        hasOrganizationUnitPermission
                      }
                    />
                  );
              })}
            </ul>
          )}
          <div>
            {value &&
              value.key === "division" &&
              value.parent_id === department.id && (
                <div
                  className={
                    validationError?.adding &&
                    validationError?.key === "division"
                      ? "input-wrapper unit-error-border"
                      : "input-wrapper"
                  }
                  style={{ marginLeft: "20px" }}
                >
                  <img
                    alt=""
                    className="department-icon"
                    src="/images/odsekicon.png"
                  />
                  <textarea
                    ref={newOdelenjeRef}
                    autoFocus
                    rows="1"
                    style={{ resize: "none", overflow: "hidden" }}
                    type="text"
                    className="department-title-input"
                    value={value.name}
                    onChange={(e) => handleChangeNewUnit(e, "name")}
                    onKeyDown={(e) => handleKeyDown(e, handleCreateUnit, value)}
                  ></textarea>

                  <div className="edit-department-buttons-block">
                    {(value?.adding || value?.editing) && value.name && (
                      <button onClick={() => handleCreateUnit(value)}>
                        <img src="/images/check1.jpg" />
                      </button>
                    )}
                    {!value?.adding && !value.editing && value.name && (
                      <button
                      // onClick={(e) => handleSetEdit(e, department.id)}
                      >
                        <img src="/images/edit.png" />
                      </button>
                    )}
                    <button onClick={() => handleDeleteNewUnit()}>
                      <img src="/images/trash.png" />
                    </button>
                  </div>
                </div>
              )}
          </div>

          {hasOrganizationUnitPermission && (
            <button
              className="add-odsek-button"
              onClick={() =>
                handleAddNewUnit({
                  name: "",
                  key: "division",
                  adding: true,
                  parent_id: department.id,
                  level: 2,
                })
              }
              //disabled={departments?.length && departments.some((pos) => pos.typing === true)}
            >
              + Dodajte organizacionu jedinicu
            </button>
          )}
        </div>
      )}
    </div>
  );
}
