import React from "react";

import ForgottenPassword from "../../components/forgottenPassword";

export default function ForgottenPasswordPage() {
  return (
    <div className="login-page">
      <ForgottenPassword />
    </div>
  );
}
