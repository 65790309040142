import React from "react";
import Autorizacija from "../../components/admin/autorizacija";

function AdminAuthorizationPage(props) {
  return (
    <div className="admin-page-child-wrapper">
      <Autorizacija />
    </div>
  );
}

export default AdminAuthorizationPage;
