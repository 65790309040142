import React, { useState, useEffect, useRef } from "react";

import DropDown from "../elements/dropDown";
import DynamicTextarea from "../elements/DynamicTextarea";
import NoInputCalendar from "../DatePicker/noinputdatepicker";

import LinkPopup from "../LinkPopup";

export default function NewProcedureRow({
  designations,
  handleChangeNewProcedureRow,
  name,
  description,
  handleSelectDueDate,
  due_date,
  handleSelectNewProcedureResponsibleDesignations,
  responsibles,
  handleAddLink,
  handleChangeLink,
  links,
  handleSaveNewProcedureRow,
  handleDeleteNewProcedureRow,
  newProcedureRowValidationsError,
  handleAddNewMapaDocumentList,
  handleRejectMapaDukument,
  handleChangeNewMapaDocument,
  handleSaveNewMapaDocument,
  setActivityToDelete,
  newProcedureRow,
}) {
  const [openLinkPopup, setOpenLinkPopup] = useState(false);

  const formatDateToObject = (date) => {
    const dateStringArray = date.split(" ");
    const dateWithoutTime = dateStringArray[0].split("-");

    return new Date(
      dateWithoutTime[0],
      +dateWithoutTime[1] - 1,
      dateWithoutTime[2]
    );
  };

  const taRef1 = useRef();
  const taRef2 = useRef();

  useEffect(() => {
    if (taRef1.current && taRef2.current) {
      const scrollHeight1 = taRef1.current.scrollHeight;

      const height2 = +taRef2.current.style.height.split("p")[0];

      if (scrollHeight1 > height2) {
        taRef2.current.style.height = `${scrollHeight1}px`;
      }
      taRef1.current.style.height = `${scrollHeight1}px`;
    }
  }, [name]);

  useEffect(() => {
    if (taRef1.current && taRef2.current) {
      const scrollHeight2 = taRef2.current.scrollHeight;

      const height1 = +taRef1.current.style.height.split("p")[0];

      if (scrollHeight2 > height1) {
        taRef1.current.style.height = `${scrollHeight2}px`;
      }
      taRef2.current.style.height = `${scrollHeight2}px`;
    }
  }, [description]);

  return (
    <ul
      className="poslovni-procesi-table-list new-procedure-row"
      style={{ border: "2px solid var(--mainColor)" }}
    >
      <li
        className="poslovni-procesi-table-item"
        style={{ width: "21%", alignItems: "flex-start" }}
      >
        <textarea
          rows="1"
          onChange={(e) => {
            handleChangeNewProcedureRow(e, "name");
          }}
          value={name}
          className="dynamic-textarea-auto"
          ref={taRef1}
        />
        {newProcedureRowValidationsError &&
          newProcedureRowValidationsError["name"] && (
            <div className="input-error-message">Obavezno polje</div>
          )}
      </li>
      <li
        className="poslovni-procesi-table-item"
        style={{
          width: "30%",
          alignItems: "flex-start",
        }}
      >
        <textarea
          rows="1"
          ref={taRef2}
          className="dynamic-textarea-auto"
          onChange={(e) => {
            handleChangeNewProcedureRow(e, "description");
          }}
          value={description}
        />
        {newProcedureRowValidationsError &&
          newProcedureRowValidationsError["description"] && (
            <div className="input-error-message">Obavezno polje</div>
          )}
      </li>
      <li
        className="poslovni-procesi-table-item"
        style={{ width: "30%", display: "flex", alignItems: "flex-start" }}
      >
        <div
          style={{
            width: "55%",
            borderRight: "1px solid var(--mainColor)",
            height: "100%",
            display: "flex",
            alignItems: "flex-start",
            paddingTop: "2px",
          }}
        >
          <DropDown
            isMulti={true}
            list={designations}
            onChange={handleSelectNewProcedureResponsibleDesignations}
            selectedValues={responsibles}
            fontSize={12}
            paddingLeft={0}
            flexDirection="column"
            whiteSpace="wrap !important"
            valuePadding="0"
            control={{ alignItems: "flex-start" }}
          />
          {newProcedureRowValidationsError &&
            newProcedureRowValidationsError["activities_designations"] && (
              <div className="input-error-message">Obavezno polje</div>
            )}
        </div>
        <div
          style={{
            width: "45%",
            position: "relative",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          {/* <p
            style={{
              position: "absolute",
              top: "10px",
              left: "5px",

              fontSize: "16px",
            }}
          >
            {due_date}
          </p>
          <NoInputCalendar
            handleSelectDate={handleSelectDueDate}
            parentStartDate={due_date ? formatDateToObject(due_date) : ""}
          /> */}
          <textarea
            rows="1"
            onChange={(e) => {
              handleChangeNewProcedureRow(e, "due_date");
            }}
            value={due_date ? due_date : ""}
            className="dynamic-textarea-auto"
          />
        </div>
      </li>
      <li
        className="poslovni-procesi-table-item"
        style={{
          width: "15%",
          position: "relative",
          alignItems: "flex-start",
        }}
      >
        <ul
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {links &&
            links.length > 0 &&
            links.map((link) => {
              if (link.saved)
                return (
                  <a
                    className="document-path-link"
                    key={link.id}
                    href={link?.path}
                    target="_blank"
                  >
                    {link?.name}
                  </a>
                );
            })}
        </ul>

        <div
          style={{
            display: "flex",
            paddingTop: "12px",
            paddingRight: "5px",
            flex: "1",
          }}
        >
          <button className="mapedokumentacijaitem-button">
            <img src="/images/doc.png" />
          </button>
          <button
            className="mapedokumentacijaitem-button"
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setOpenLinkPopup((state) => !state);
              handleAddNewMapaDocumentList(true);
            }}
          >
            <img src="/images/link.png" />
          </button>
        </div>
      </li>
      <li className="poslovni-procesi-table-item" style={{ width: "4%" }}>
        <div className="new-procedure-options-block">
          <button
            type="button"
            className="employee-item-options-button"
            style={{ width: "40%" }}
            onClick={handleSaveNewProcedureRow}
          >
            <img src="/images/check.png" alt="im" />
          </button>
          <button
            type="button"
            className="employee-item-options-button"
            style={{ width: "40%" }}
            onClick={() => setActivityToDelete(newProcedureRow)}
          >
            <img src="/images/trash.png" />
          </button>
        </div>
      </li>
      {openLinkPopup && (
        <>
          <div className="loading"></div>
          <LinkPopup
            handleAddLink={handleAddLink}
            handleChangeNewMapaDocument={handleChangeNewMapaDocument}
            handleRejectMapaDukument={handleRejectMapaDukument}
            setOpenLinkPopup={setOpenLinkPopup}
            handleChangeLink={handleChangeLink}
            link={links[links.length - 1]}
            handleSaveNewMapaDocument={handleSaveNewMapaDocument}
            isNewRow={true}
          />
        </>
      )}
    </ul>
  );
}
