import React, { useState } from "react";

import Button from "../elements/button";
import Loading from "../loading";

export default function DeleteEmployeePopup({
  handleConfirmDeleteEmployee,
  setEmployeeToDelete,
  employeeToDelete,

  loading,

  setUnitToDelete,
}) {
  return (
    <div className="link-popup">
      <p style={{ marginBottom: "20px" }}>Sigurno zelite da obrisete radnika?</p>
      <div className="link-popup-buttons">
        <Button
          onClick={() => {
            handleConfirmDeleteEmployee(employeeToDelete);
          }}
          title="Da"
          style={{
            backgroundColor: "white",
            color: "var(--mainColor)",
            border: "1px solid var(--mainColor)",
            fontWeight: "700",
            width: "120px",
          }}
        />
        <Button
          title="Ne"
          style={{ width: "120px" }}
          onClick={() => {
            setEmployeeToDelete(null);
          }}
        />
      </div>
      <Loading isLoading={loading} />
    </div>
  );
}
