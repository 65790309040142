import React, { useEffect, useState } from "react";

import SelectOrganizationsDropdown from "../elements/nestedDropDown";
import { baseRequest } from "../../axiosconfig";
import SelectDesignationDropdown from "../selectDesignationDropdown";
import DropDown from "../elements/dropDown";

export default function NewEmployee({
  employee,
  setNewEmployee,
  handleCreateEmployee,
  handleSetEditEmployee,
  emptyFieldsError,
  deleteNewEmployee,
  handleSelectUnit,
  organization_units,
  designations,
  handleChangeNewEmployee,
  setEmptyFieldsError,
  style,
  hasEditEmployeePermission,
  user,
}) {
  const [showOrganizationsDropdown, setshowOrganizationsDropdown] =
    useState(false);
  const [showDesignationsDropdown, setshowDesignationsDropdown] =
    useState(false);

  const filterUnits = (list) => {
    return list
      .filter((item) => {
        return item.del == 0;
      })
      .map((item) => {
        if (
          !item.has_designations ||
          hasEditEmployeePermission(user, item.id, item.level) === false
        ) {
          return { ...item, isDisabled: true };
        }
        return item;
      });
  };

  const filterDesignations = (list, employee) => {
    if (employee.level === 0) {
      return list.filter((item) => {
        return (item.del == 0 || item.del === false) && item.level === 0;
      });
    } else {
      return list.filter((item) => {
        return (
          item.level === employee.level &&
          item.parent_id === employee.parent_id &&
          (item.del == 0 || item.del === false)
        );
      });
    }
  };

  const handleSelectDesignation = ({ value }) => {
    if (emptyFieldsError) {
      setEmptyFieldsError((list) => {
        return {
          ...list,
          emptyFields: list.emptyFields.filter(
            (item) => item !== "designation_id"
          ),
        };
      });
    }
    setNewEmployee((employee) => {
      return {
        ...employee,
        description: value.description,
        designation_id: value.id,
        designation_name: value.name,
      };
    });
  };

  return (
    <form className="employees-list" style={style}>
      <div
        className="employees-list-item"
        style={{
          border:
            emptyFieldsError &&
            emptyFieldsError?.emptyFields.includes("first_name")
              ? "3px solid red"
              : "none",
          borderRight:
            emptyFieldsError &&
            emptyFieldsError?.emptyFields.includes("first_name")
              ? "3px solid red"
              : "1px solid var(--secondaryColor)",
        }}
      >
        <input
          type="text"
          readOnly={!employee?.adding}
          className="employees-list-input"
          onChange={(e) => handleChangeNewEmployee(e, "first_name")}
          value={employee.first_name}
        />
      </div>
      <div
        className="employees-list-item"
        style={{
          border:
            emptyFieldsError &&
            emptyFieldsError?.emptyFields.includes("last_name")
              ? "3px solid red"
              : "none",
          borderRight:
            emptyFieldsError &&
            emptyFieldsError.emptyFields.includes("last_name")
              ? "3px solid red"
              : "1px solid var(--secondaryColor)",
        }}
      >
        <input
          type="text"
          readOnly={!employee.adding}
          className="employees-list-input"
          onChange={(e) => handleChangeNewEmployee(e, "last_name")}
          value={employee.last_name}
        />
      </div>
      <div
        className="employees-list-item"
        style={{
          border:
            emptyFieldsError &&
            emptyFieldsError.emptyFields.includes("parent_id")
              ? "3px solid red"
              : "none",
          borderRight:
            emptyFieldsError &&
            emptyFieldsError.emptyFields.includes("parent_id")
              ? "3px solid red"
              : "1px solid var(--secondaryColor)",
        }}
        id="employees-dropdown"
        onClick={
          employee?.typing && organization_units.length > 0
            ? () => {
                setshowOrganizationsDropdown((showDropdown) => !showDropdown);
                setshowDesignationsDropdown(false);
              }
            : undefined
        }
      >
        {/* {employee.organization_unit && <p className="selected-unit">{employee.organization_unit}</p>} */}
        {/* <SelectOrganizationsDropdown
          handleSelectUnit={handleSelectUnit}
          showOrganizationsDropdown={showOrganizationsDropdown}
          organization_units={organization_units}
        />
        <div className="dropdown-arrow">
          <img src={showOrganizationsDropdown ? "/images/dropdownup.png" : "/images/dropdowndown.png"} />
        </div> */}
        <DropDown
          list={filterUnits(organization_units)}
          height={50}
          onChange={handleSelectUnit(employee)}
          selectedValue={employee.organization_unit_name}
          disabled={!employee?.adding}
        />
      </div>
      <div
        className="employees-list-item"
        style={{
          border:
            emptyFieldsError &&
            emptyFieldsError?.emptyFields.includes("designation_id")
              ? "3px solid red"
              : "none",
          borderRight:
            emptyFieldsError &&
            emptyFieldsError.emptyFields.includes("designation_id")
              ? "3px solid red"
              : "1px solid var(--secondaryColor)",
        }}
        id="employees-dropdown"
        onClick={
          employee?.typing && designations.length > 0
            ? () => {
                setshowDesignationsDropdown((showDropdown) => !showDropdown);
                setshowOrganizationsDropdown(false);
              }
            : undefined
        }
      >
        {/* <p className="selected-unit">{employee.designation_name}</p>
        <SelectDesignationDropdown
          designations={designations}
          showDesignationsDropdown={showDesignationsDropdown}
          handleSelectDesignation={handleSelectDesignation}
        />
        <div className="dropdown-arrow">
          <img src={showDesignationsDropdown ? "/images/dropdownup.png" : "/images/dropdowndown.png"} />
        </div> */}
        <DropDown
          list={filterDesignations(designations, employee)}
          height={50}
          onChange={handleSelectDesignation}
          selectedValue={employee.designation_name}
          disabled={!employee?.adding}
        />
      </div>
      <div
        className="employees-list-item designation-description"
        style={{ height: "auto", borderRight: "none" }}
      >
        {employee.description}
      </div>
      <div
        className="employees-list-item options-block"
        style={{ borderLeft: "1px solid var(--secondaryColor)" }}
      >
        <button
          type="button"
          className="employee-item-options-button"
          onClick={
            employee?.adding
              ? () => {
                  setshowOrganizationsDropdown(false);
                  setshowDesignationsDropdown(false);
                  handleCreateEmployee(employee);
                }
              : () => handleSetEditEmployee(employee.id)
          }
        >
          <img
            src={employee?.adding ? "/images/check1.jpg" : "/images/edit.png"}
          />
        </button>
        <button
          type="button"
          className="employee-item-options-button"
          onClick={() => {
            deleteNewEmployee();
          }}
        >
          <img src="/images/trash.png" />
        </button>
      </div>
    </form>
  );
}
