import React, { useContext } from "react";

import Button from "../../elements/button";
import { useNavigate } from "react-router-dom";
import { UnsavedDataContext } from "../../../context";

export default function UnsavedDataPopup({ url, setShowNavigatePopup }) {
  const navigate = useNavigate();
  const handleConfirmNavigate = (url) => {
    navigate(url);
  };

  const { setUnsavedData } = useContext(UnsavedDataContext);
  return (
    <div className="link-popup">
      <p style={{ marginBottom: "20px", width: "100%", textAlign: "center" }}>
        Imate nesacuvane podatke. Sigurno zelite da napustite stranicu?
      </p>
      <div className="link-popup-buttons">
        <Button
          onClick={() => {
            handleConfirmNavigate(url);
            setUnsavedData(false);
            setShowNavigatePopup(false);
          }}
          title="Da"
          style={{
            backgroundColor: "white",
            color: "var(--mainColor)",
            border: "1px solid var(--mainColor)",
            fontWeight: "700",
            width: "120px",
          }}
        />
        <Button
          title="Ne"
          style={{ width: "120px" }}
          onClick={() => {
            setShowNavigatePopup(false);
          }}
        />
      </div>
    </div>
  );
}
