import React from "react";
import MapeProcesa from "../../components/mapeProcesa";
import { Link } from "react-router-dom";
import SectionHeader from "../../components/elements/sectionHeader";

export default function MapeProcesaPage() {
  return (
    <div className="page">
      <Link to="/admin/mape-procesa">vrati se</Link>
      <SectionHeader
        title="Mape procesa"
        description="Molimo vas da ne ulazite u proces registracije bez pomoći stručnog lica. Naš tim Vam stoji na raspolaganju."
      />
      <MapeProcesa />
    </div>
  );
}
