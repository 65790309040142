import React, { useContext, useEffect, useRef } from "react";
import Subdivision from "../Subdivision";
import { UserContext } from "../../userContext";
import { hasDesignationPermission } from "../admin/adminStrukturaPreduzeca";
import DivisionDesignation from "../admin/divisionDesignation";

export default function Division({
  division,
  setValue,
  subdivisions = {},
  handleDeleteNewUnit,
  handleAddNewUnit,
  value,
  handleKeyDown,
  handleCreateDesignation,
  department,
  handleCreateUnit,
  handleSetEditUnit,
  handleChangeUnitName,
  handleUpdateUnit,
  handleSetEditDesignation,
  handleChangeDesignationName,
  handleUpdateDesignation,
  handleDeleteDesignation,
  handleDeleteUnit,
  handleChangeNewUnit,
  validationError,
  handleSetUnitOpen,
  handleShowDescription,
  showDescription,
  setShowDescription,
  activeUnit,
  hasOrganizationUnitPermission,
}) {
  // const [showDescription, setShowDescription] = useState();
  function objectNotEmpty(object = {}) {
    return Object.keys(object).length > 0;
  }

  const { user } = useContext(UserContext);

  const filterSubdivisions = (division) => {
    const filteredSubdivisions = [];
    Object.keys(subdivisions).forEach((key) => {
      if (subdivisions[key].division_id === division.id) {
        filteredSubdivisions.push(subdivisions[key]);
      }
    });

    return filteredSubdivisions;
  };

  const divisionRef = useRef();
  const designationRef = useRef();
  const newDesignationRef = useRef();
  const newOdelenjeRef = useRef();

  useEffect(() => {
    if (divisionRef.current) {
      divisionRef.current.style.height = "100%";
      const scrollHeight = divisionRef.current.scrollHeight;
      divisionRef.current.style.height = scrollHeight + "px";
    }
    if (designationRef.current) {
      designationRef.current.style.height = "100%";
      const scrollHeight = designationRef.current.scrollHeight;
      designationRef.current.style.height = scrollHeight + "px";
    }
  }, [division]);

  useEffect(() => {
    if (newDesignationRef.current && value.key === "division_designation") {
      newDesignationRef.current.style.height = "100%";
      const scrollHeight = newDesignationRef.current.scrollHeight;
      newDesignationRef.current.style.height = scrollHeight + "px";
    }

    if (newOdelenjeRef.current && value.key === "subdivision") {
      newOdelenjeRef.current.style.height = "100%";
      const scrollHeight = newOdelenjeRef.current.scrollHeight;
      newOdelenjeRef.current.style.height = scrollHeight + "px";
    }
  }, [value]);

  return (
    <div>
      <div className="organization-wrapper">
        <div
          className={
            division.id === validationError?.id && validationError?.level === 2
              ? "input-wrapper unit-error-border"
              : "input-wrapper"
          }
        >
          <img className="department-icon" src="/images/odsekicon.png" />
          <textarea
            ref={divisionRef}
            autoFocus
            rows="1"
            style={{ resize: "none", overflow: "hidden" }}
            readOnly={!division.editing && !division.typing}
            type="text"
            className="odsek-title-input"
            value={division.name}
            onChange={(e) => handleChangeUnitName(e, division.id, "divisions")}
            //  onKeyDown={(e) => handleKeyDown(e, handleConfirmAddDepartment, department.id)}
            // onBlur={() =>
            //   department.name
            //     ? handleConfirmAddDepartment(department.id)
            //     : handleDeleteDepartment(department.id)
            // }
          ></textarea>

          {hasOrganizationUnitPermission && (
            <div className="edit-department-buttons-block">
              {division.editing && (
                <button onClick={() => handleUpdateUnit(division, 2)}>
                  <img src="/images/check1.jpg" />
                </button>
              )}
              {!division?.editing && (
                <button
                  onClick={() => handleSetEditUnit(division.id, "divisions", 2)}
                >
                  <img src="/images/edit.png" />
                </button>
              )}
              <button
                onClick={() =>
                  handleDeleteUnit({ id: division.id, level: 2 }, "unit")
                }
              >
                <img src="/images/trash.png" />
              </button>
              <div
                className="unfold-list-arrow"
                onClick={() => handleSetUnitOpen(division.id, "divisions")}
              >
                <img
                  alt=""
                  src={
                    division.isOpen
                      ? "/images/dropdownup.png"
                      : "/images/dropdowndown.png"
                  }
                ></img>
              </div>
            </div>
          )}
          {/* <div
          className="unfold-list-arrow"
          onClick={() =>
            setOpenDepartments((deps) => {
              if (!deps.find((el) => el === department.id)) {
                return [...deps, department.id];
              } else {
                return deps.filter((el) => el !== department.id);
              }
            })
          }
        >
          <img
            src={openDepartments.includes(department.id) ? "/images/dropdownup.png" : "/images/dropdowndown.png"}
          ></img>
        </div> */}
        </div>

        {objectNotEmpty(division.designations) && division.isOpen && (
          <ul className="positions-list">
            {Object.keys(division.designations).map((key) => {
              const position = division.designations[key];
              if (!position.del)
                return (
                  <DivisionDesignation
                    key={`designation${position.id}`}
                    position={position}
                    validationError={validationError}
                    handleChangeDesignationName={handleChangeDesignationName}
                    division={division}
                    setShowDescription={setShowDescription}
                    handleUpdateDesignation={handleUpdateDesignation}
                    handleShowDescription={handleShowDescription}
                    handleSetEditDesignation={handleSetEditDesignation}
                    handleDeleteDesignation={handleDeleteDesignation}
                    showDescription={showDescription}
                    hasDesignationPermission={hasDesignationPermission(
                      user,
                      division.id,
                      2
                    )}
                  />
                  // <li key={`designation${position.id}`}>
                  //   <div
                  //     className={
                  //       !validationError?.level &&
                  //       validationError?.id === position.id
                  //         ? "add-to-list-input-wrapper unit-error-border"
                  //         : "add-to-list-input-wrapper"
                  //     }
                  //   >
                  //     <textarea
                  //       ref={designationRef}
                  //       autoFocus
                  //       rows="1"
                  //       style={{ resize: "none", overflow: "hidden" }}
                  //       readOnly={!position.typing && !position.editing}
                  //       type="text"
                  //       className="add-to-list-input"
                  //       value={position?.name}
                  //       onChange={(e) =>
                  //         handleChangeDesignationName(
                  //           e,
                  //           position.id,
                  //           division.id,
                  //           "divisions",
                  //           "name"
                  //         )
                  //       }
                  //       // onKeyDown={(e) =>
                  //       //   handleKeyDown(e, handleAddConfirmPositionToDepartment, department.id, position.id)
                  //       // }
                  //       // onBlur={() => {
                  //       //   position.name
                  //       //     ? handleAddConfirmPositionToDepartment(department.id, position.id)
                  //       //     : handleDeletePositionFromDepartment(department.id, position.id);
                  //       // }}
                  //     ></textarea>

                  //     <div className="edit-buttons-block">
                  //       {(position?.typing || position.editing) &&
                  //         position?.name && (
                  //           <button
                  //             onClick={() => {
                  //               setShowDescription(null);
                  //               handleUpdateDesignation({
                  //                 ...position,
                  //                 level: 2,
                  //               });
                  //             }}
                  //           >
                  //             <img src="/images/check1.jpg" />
                  //           </button>
                  //         )}
                  //       {!position?.typing &&
                  //         !position.editing &&
                  //         position?.name && (
                  //           <button
                  //             onClick={() => {
                  //               handleShowDescription(position.id, 2, true);
                  //               handleSetEditDesignation(
                  //                 position.id,
                  //                 division.id,
                  //                 "divisions",
                  //                 2
                  //               );
                  //             }}
                  //           >
                  //             <img src="/images/edit.png" />
                  //           </button>
                  //         )}
                  //       <button
                  //         onClick={() =>
                  //           handleDeleteDesignation(
                  //             { id: position.id, level: 2 },
                  //             "designation"
                  //           )
                  //         }
                  //       >
                  //         <img src="/images/trash.png" />
                  //       </button>
                  //       {!position.adding && (
                  //         <button
                  //           onClick={() =>
                  //             handleShowDescription(position.id, 2)
                  //           }
                  //         >
                  //           <img
                  //             alt=""
                  //             src={
                  //               showDescription &&
                  //               showDescription.id === position.id
                  //                 ? "/images/iks.png"
                  //                 : "/images/plus.png"
                  //             }
                  //           />
                  //         </button>
                  //       )}
                  //     </div>
                  //   </div>
                  //   {showDescription && showDescription.id === position.id && (
                  //     <textarea
                  //       rows="4"
                  //       cols="50"
                  //       className="position-description"
                  //       value={position.description || ""}
                  //       readOnly={!position.editing}
                  //       onChange={(e) =>
                  //         handleChangeDesignationName(
                  //           e,
                  //           position.id,
                  //           division.id,
                  //           "divisions",
                  //           "description"
                  //         )
                  //       }
                  //     />
                  //   )}
                  // </li>
                );
            })}
          </ul>
        )}
        <div>
          {value &&
            value.key === "division_designation" &&
            value.parent_id === division.id &&
            value.department_id === department.id && (
              <div className="new-designation-block">
                <div
                  className={
                    validationError?.adding &&
                    validationError?.key === "division_designation"
                      ? "new-designation-input-wrapper error-border-bottom"
                      : "new-designation-input-wrapper"
                  }
                >
                  <textarea
                    ref={newDesignationRef}
                    autoFocus
                    rows="1"
                    style={{ resize: "none", overflow: "hidden" }}
                    placeholder="naziv radnog mesta"
                    type="text"
                    className="add-to-list-input"
                    value={value.name}
                    onChange={(e) => handleChangeNewUnit(e, "name")}
                    onKeyDown={(e) =>
                      handleKeyDown(e, handleCreateDesignation, value)
                    }
                    // onBlur={() =>
                    //   department.name
                    //     ? handleConfirmAddDepartment(department.id)
                    //     : handleDeleteDepartment(department.id)
                    // }
                  ></textarea>

                  <div className="edit-department-buttons-block">
                    {(value?.adding || value?.editing) && value.name && (
                      <button onClick={() => handleCreateDesignation(value)}>
                        <img src="/images/check1.jpg" />
                      </button>
                    )}
                    {!value?.adding && !value.editing && value.name && (
                      <button
                      // onClick={(e) => handleSetEdit(e, department.id)}
                      >
                        <img src="/images/edit.png" />
                      </button>
                    )}
                    <button onClick={() => handleDeleteNewUnit}>
                      <img src="/images/trash.png" />
                    </button>
                    <button
                      onClick={() =>
                        setValue((value) => {
                          return {
                            ...value,
                            openDescription: !value.openDescription,
                          };
                        })
                      }
                    >
                      <img
                        alt=""
                        src={
                          value?.openDescription
                            ? "/images/iks.png"
                            : "/images/plus.png"
                        }
                      />
                    </button>
                  </div>
                </div>
                {value.openDescription && (
                  <textarea
                    rows="4"
                    cols="50"
                    className="position-description"
                    placeholder="dodajte opis"
                    value={value.description}
                    onChange={(e) => handleChangeNewUnit(e, "description")}
                  />
                )}
              </div>
            )}
        </div>

        {!division.typing &&
          division.isOpen &&
          hasDesignationPermission(user, division.id, 2) && (
            <button
              disabled={activeUnit}
              className="add-position-button"
              onClick={() =>
                handleAddNewUnit({
                  name: "",
                  key: "division_designation",
                  description: "",
                  level: 2,
                  parent_id: division.id,
                  adding: true,
                  department_id: department.id,
                  openDescription: true,
                })
              }
              // disabled={
              //   odsek?.positions?.length && odsek.positions.some((pos) => pos.typing === true)
              // }
            >
              <span>
                <img
                  alt=""
                  className="plus-circle-icon"
                  src="/images/pluscircle.png"
                />
              </span>
              <span>Dodajte radno mesto</span>
            </button>
          )}
        <div className="add-to-list-block">
          {filterSubdivisions(division)?.length > 0 && division.isOpen && (
            <ul className="odseci-list">
              {filterSubdivisions(division).map((subdivision) => {
                if (!subdivision.del)
                  return (
                    <Subdivision
                      subdivision={subdivision}
                      key={`subdivision${subdivision.id}`}
                      value={value}
                      handleCreateDesignation={handleCreateDesignation}
                      handleKeyDown={handleKeyDown}
                      setValue={setValue}
                      handleAddNewUnit={handleAddNewUnit}
                      division={division}
                      department={department}
                      handleSetEditUnit={handleSetEditUnit}
                      handleChangeUnitName={handleChangeUnitName}
                      handleUpdateUnit={handleUpdateUnit}
                      handleSetEditDesignation={handleSetEditDesignation}
                      handleChangeDesignationName={handleChangeDesignationName}
                      handleUpdateDesignation={handleUpdateDesignation}
                      handleDeleteDesignation={handleDeleteDesignation}
                      handleDeleteUnit={handleDeleteUnit}
                      handleDeleteNewUnit={handleDeleteNewUnit}
                      handleChangeNewUnit={handleChangeNewUnit}
                      validationError={validationError}
                      handleSetUnitOpen={handleSetUnitOpen}
                      handleShowDescription={handleShowDescription}
                      showDescription={showDescription}
                      setShowDescription={setShowDescription}
                      hasOrganizationUnitPermission={
                        hasOrganizationUnitPermission
                      }
                    />
                  );
              })}
            </ul>
          )}
          <div>
            {value &&
              value.key === "subdivision" &&
              value.parent_id === division.id &&
              value.department_id === department.id && (
                <div
                  className={
                    validationError?.adding &&
                    validationError?.key === "subdivision"
                      ? "input-wrapper unit-error-border"
                      : "input-wrapper"
                  }
                  style={{ marginLeft: "20px" }}
                >
                  <img
                    alt=""
                    className="department-icon"
                    src="/images/serviceicon.png"
                  />
                  <textarea
                    ref={newOdelenjeRef}
                    autoFocus
                    rows="1"
                    style={{ resize: "none", overflow: "hidden" }}
                    type="text"
                    className={
                      division.id === validationError?.id &&
                      validationError?.level === 2
                        ? "service-title-input unit-error-border"
                        : "service-title-input"
                    }
                    value={value.name}
                    onChange={(e) => handleChangeNewUnit(e, "name")}
                    onKeyDown={(e) => handleKeyDown(e, handleCreateUnit, value)}
                    // onBlur={() =>
                    //   department.name
                    //     ? handleConfirmAddDepartment(department.id)
                    //     : handleDeleteDepartment(department.id)
                    // }
                  ></textarea>

                  <div className="edit-department-buttons-block">
                    {(value?.adding || value?.editing) && value.name && (
                      <button onClick={() => handleCreateUnit(value)}>
                        <img src="/images/check1.jpg" />
                      </button>
                    )}
                    {!value?.adding && !value.editing && value.name && (
                      <button
                      // onClick={(e) => handleSetEdit(e, department.id)}
                      >
                        <img src="/images/edit.png" />
                      </button>
                    )}
                    <button onClick={() => handleDeleteNewUnit()}>
                      <img src="/images/trash.png" />
                    </button>
                  </div>
                </div>
              )}
          </div>

          {division.isOpen && hasOrganizationUnitPermission && (
            <button
              className="add-odsek-button"
              onClick={() =>
                handleAddNewUnit({
                  name: "",
                  key: "subdivision",
                  adding: true,
                  parent_id: division.id,
                  department_id: department.id,
                  level: 3,
                })
              }
              //disabled={departments?.length && departments.some((pos) => pos.typing === true)}
            >
              + Dodajte organizacionu jedinicu
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
