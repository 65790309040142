import React from "react";
import SingleMapeProcesa from "../../components/admin/singleMapaProcesa";

function AdminSingeMapaProcesaPage(props) {
  return (
    <div className="admin-page-child-wrapper">
      <SingleMapeProcesa />
    </div>
  );
}

export default AdminSingeMapaProcesaPage;
