import React from "react";
import CustomTextArea from "../customTextarea";

function NewActivity({
  handleChangeNewActivity,
  activity,
  handleSaveNewActivity,
  setActivityToDelete,
  newActivityValidationErrors,
  handleDeleteNewActivity,
}) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        border: activity ? "2px solid var(--mainColor)" : "none",
      }}
    >
      <CustomTextArea
        handleChangeNewActivity={handleChangeNewActivity}
        nameValue={activity.name}
        activity={activity}
        descriptionValue={activity.description}
        style={{
          borderRight: "var(--mainBorder)",
          borderLeft: "var(--mainBorder)",
        }}
        handleSaveNewActivity={handleSaveNewActivity}
        // handleDeleteNewActivity={handleDeleteNewActivity}
        newActivityValidationErrors={newActivityValidationErrors}
        setActivityToDelete={handleDeleteNewActivity}
      />
    </div>
  );
}

export default NewActivity;
