import React, { useEffect, useState } from "react";
import "./employeesList.css";
import EmployeesListItem from "../employeesListItem";
import Button from "../elements/button";
import { baseRequest, deleteUrl } from "../../axiosconfig";
import Loading from "../loading";
import { useNavigate } from "react-router-dom";
import NewEmployee from "../newEmployee";
import DeleteEmployeePopup from "../deleteEmployeePopup";
import { ErrorMessage } from "formik";
import ErrorMessagePopup from "../errorMessagePopup";
import axios from "axios";
import EmployeelistErrorPopup from "../employeeListErrorPopup";

export default function EmployeesList() {
  const [newEmployee, setNewEmployee] = useState();
  const [employeesList, setEmployeesList] = useState([]);
  const [errorMessages, setErrorMessages] = useState("");
  const [organization_units, setOrganization_units] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [employeeToDelete, setEmployeeToDelete] = useState();
  const [activeEmployee, setActiveEmployee] = useState();
  const [loading, setLoading] = useState(false);
  const [emptyFieldsError, setEmptyFieldsError] = useState("");

  const navigate = useNavigate();

  const employeesListTitles = [
    { id: 1, title: "Ime*" },
    { id: 2, title: "Prezime*" },
    { id: 3, title: "Organizaciona jedinica*" },
    { id: 4, title: "Naziv radnog mesta*" },
    { id: 5, title: "Opis radnog mesta" },
    { id: 6, title: "Opcije" },
  ];

  useEffect(() => {
    setLoading(true);

    baseRequest
      .get("/organization_unit/0", { withCredentials: true })
      .then((res) => {
        setOrganization_units(res.data.data);
      })
      .catch((error) => console.log(error));
    baseRequest
      .get(`/designations/0/0`, { withCredentials: true })
      .then((res) => {
        setDesignations(res.data.designations);
      })
      .catch((error) => console.log(error));
    baseRequest
      .get("/employee", { withCredentials: true })
      .then((res) => {
        setEmployeesList(res.data.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const addEmployeeToList = () => {
    setNewEmployee({
      first_name: "",
      last_name: "",
      organization_unit_name: "",
      designation_name: "",
      designation_id: "",
      description: "",
      adding: true,
      level: "",
      parent_id: "",
    });

    setActiveEmployee({
      activeUnit: {
        first_name: "",
        last_name: "",
        organization_unit: "",
        designation_name: "",
        designation_id: "",
        designation_description: "",
        adding: true,
        parent_id: "",
      },
      prevState: {
        first_name: "",
        last_name: "",
        organization_unit: "",
        designation_name: "",
        designation_id: "",
        designation_description: "",
        adding: true,
        parent_id: "",
      },
    });
  };

  const handleCreateEmployee = (payload) => {
    if (validateAllFields(payload)) {
      return true;
    }
    setLoading(true);
    baseRequest
      .post("/employee", payload, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        setEmployeesList((list) => {
          return [...list, res.data.data];
        });
        setNewEmployee(null);
        setActiveEmployee(null);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  function handleChangeEmployee(e, field, id) {
    setEmptyFieldsError(null);
    setActiveEmployee((employee) => {
      return {
        ...employee,
        activeUnit: {
          ...employee.activeUnit,
          [field]: e.target.value.trimStart(),
        },
      };
    });
    setEmployeesList((employees) => {
      const newEmployees = employees.map((employee) => {
        if (employee.id === id) {
          return { ...employee, [field]: e.target.value.trimStart() };
        }
        return employee;
      });
      return [...newEmployees];
    });
  }

  const handleChangeNewEmployee = (e, field) => {
    if (emptyFieldsError) {
      setEmptyFieldsError((list) => {
        return {
          ...list,
          emptyFields: list.emptyFields.filter((item) => item !== field),
        };
      });
    }

    setNewEmployee((employee) => {
      return { ...employee, [field]: e.target.value.trimStart() };
    });
    setActiveEmployee((employee) => {
      return {
        ...employee,
        activeUnit: {
          ...employee.activeUnit,
          [field]: e.target.value.trimStart(),
        },
      };
    });
  };

  // function handleConfirmAddEmployee(id) {
  //   setEmployeesList((employees) => {
  //     const newEmployees = employees.map((employee) => {
  //       if (employee.id === id && !validateAllFields(employee)) {
  //         const { typing, ...rest } = employee;
  //         return rest;
  //       }
  //       return employee;
  //     });
  //     return [...newEmployees];
  //   });
  // }

  function handleSetEditEmployee(payload) {
    if (validate()) {
      return;
    }
    setActiveEmployee({
      activeUnit: { ...payload, typing: true },
      prevState: payload,
    });
    setEmployeesList((employees) => {
      const newEmployees = employees.map((employee) => {
        if (employee.id === payload.id) {
          return { ...employee, typing: true };
        }
        const { typing, ...rest } = employee;
        return rest;
      });
      return [...newEmployees];
    });
  }

  const handleUpdateEmployee = (payload) => {
    if (payload.typing) {
      if (validateAllFields(payload)) {
        return;
      }
      setLoading(true);
      baseRequest
        .put("/employee", payload, { withCredentials: true })
        .then((res) => {
          setEmployeesList((employees) => {
            const newEmployees = employees.map((employee) => {
              if (employee.id === payload.id) {
                return res.data.data;
              }
              return employee;
            });
            return [...newEmployees];
          });
          setLoading(false);
          setActiveEmployee(null);
          setErrorMessages(null);
        });
    }
    if (payload.adding) {
      handleCreateEmployee(payload);
    }
  };

  const handleAddUnitToList = (employeeId, unit) => {
    setEmployeesList((employees) => {
      const newEmployees = employees.map((employee) => {
        if (employee.id === employeeId) {
          return {
            ...employee,
            organization_unit: unit.name,
            designation_name: "",
            designation_id: "",
            designation_description: "",
          };
        }
        return employee;
      });
      return [...newEmployees];
    });
  };

  const handleAddDesignationToList = (employeeId, designation) => {
    setEmployeesList((employees) => {
      const newEmployees = employees.map((employee) => {
        if (employee.id === employeeId) {
          return {
            ...employee,
            designation_name: designation.name,
            designation_id: designation.id,
            designation_description: designation.description,
          };
        }
        return employee;
      });
      return [...newEmployees];
    });
  };

  const disableAddButton = () => {
    return (
      employeesList.some((employee) => employee.typing || employee.editing) ||
      newEmployee
    );
  };

  const validateAllFields = (employee) => {
    setErrorMessages(null);
    const fieldsToValidate = {
      first_name: 1,
      last_name: 2,
      parent_id: 3,
      designation_id: 4,
    };
    const emptyFields = Object.keys(employee).filter((key) => {
      if (fieldsToValidate[key]) {
        if (!employee[key]) {
          return key;
        }
      }
    });

    setEmptyFieldsError(
      emptyFields.length ? { id: employee.id, emptyFields } : null
    );
    if (emptyFields.length) {
      return true;
    }
    return false;
  };

  const deleteEmployee = (payload) => {
    if (validate()) {
      return;
    }
    setEmployeeToDelete(payload);
  };

  const handleConfirmDeleteEmployee = (payload) => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}employee`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: payload,
        withCredentials: true,
      })
      .then((res) => {
        setEmployeesList((employees) => {
          const newEmployees = employees.map((employee) => {
            if (employee.id === res.data.data.id) {
              return { ...employee, del: 1 };
            }
            return employee;
          });
          return newEmployees;
        });
        setLoading(false);
        setErrorMessages("");
        setEmployeeToDelete(null);
      });
  };

  const handleSubmit = () => {
    if (employeesList.length && !disableAddButton()) {
      setLoading(true);
      baseRequest
        .post(
          "/employee",
          { employees: employeesList },
          { withCredentials: true }
        )
        .then((res) => {
          setLoading(false);
          navigate("/register/poslovni-procesi");
        });
    }
  };

  const calculateDisbledButton = () => {
    return !employeesList.length || disableAddButton() || errorMessages;
  };

  const handleSelectUnit = (employee) => {
    return function (value) {
      if (emptyFieldsError) {
        setEmptyFieldsError((list) => {
          return {
            ...list,
            emptyFields: list.emptyFields.filter(
              (item) => item !== "parent_id"
            ),
          };
        });
      }
      if (employee.adding) {
        setNewEmployee((obj) => {
          return {
            ...obj,
            organization_unit_name: value.value.name,
            parent_id: value.value.id,
            level: value.value.level,
            description: "",
            designation_id: "",
            designation_name: "",
          };
        });
        setActiveEmployee((obj) => {
          return {
            ...obj,
            activeUnit: {
              ...obj.activeUnit,
              organization_unit_name: value.value.name,
              parent_id: value.value.id,
              level: value.value.level,
              description: "",
              designation_id: "",
              designation_name: "",
            },
          };
        });
      }
      if (employee.typing) {
        setActiveEmployee((obj) => {
          return {
            ...obj,
            activeUnit: {
              ...obj.activeUnit,
              organization_unit_name: value.value.name,
              parent_id: value.value.id,
              level: value.value.level,
              description: "",
              designation_id: "",
              designation_name: "",
            },
          };
        });
        setEmployeesList((list) => {
          const newList = list.map((item) => {
            if (item.id === employee.id) {
              return {
                ...item,
                organization_unit_name: value.value.name,
                parent_id: value.value.id,
                level: value.value.level,
                description: "",
                designation_id: "",
                designation_name: "",
              };
            }
            return item;
          });
          return newList;
        });
      }
    };
  };

  const validate = () => {
    if (newEmployee || activeEmployee) {
      setErrorMessages("Imate nesacuvane podaci. Zelite da sacuvate?");
      return true;
    }
  };

  const resetActiveEmployee = () => {
    setEmployeesList((list) => {
      const newList = list.map((item) => {
        if (item.id === activeEmployee.prevState.id) {
          const { typing, ...rest } = activeEmployee.prevState;

          return rest;
        }
        return item;
      });
      return newList;
    });
    setActiveEmployee(null);
    setNewEmployee(null);
  };

  const handleSelectDesignation = (employee) => {
    return function ({ value }) {
      if (emptyFieldsError) {
        setEmptyFieldsError((list) => {
          return {
            ...list,
            emptyFields: list.emptyFields.filter(
              (item) => item !== "designation_id"
            ),
          };
        });
      }
      setEmployeesList((list) => {
        const newList = list.map((item) => {
          if (item.id === employee.id) {
            return {
              ...item,
              description: value.description,
              designation_id: value.id,
              designation_name: value.name,
            };
          }
          return item;
        });
        return newList;
      });
    };
  };

  const deleteNewEmployee = () => {
    setNewEmployee(null);
    setActiveEmployee(null);
    setEmptyFieldsError(null);
    setErrorMessages(null);
    setEmployeeToDelete(null);
  };

  return (
    <>
      <div className="employees-list-section">
        <ul className="employees-list-header">
          {employeesListTitles.map((item) => {
            return (
              <li key={item.id} className="employees-list-title">
                {item.title}
              </li>
            );
          })}
        </ul>
        <div className="employees-list-wrapper">
          {employeesList.map((employee) => {
            if (!employee.del)
              return (
                <EmployeesListItem
                  key={employee.id}
                  employee={employee}
                  organization_units={organization_units}
                  designations={designations}
                  handleChangeEmployee={handleChangeEmployee}
                  handleSetEditEmployee={handleSetEditEmployee}
                  handleAddUnitToList={handleAddUnitToList}
                  handleAddDesignationToList={handleAddDesignationToList}
                  deleteEmployee={deleteEmployee}
                  errorMessages={errorMessages}
                  validateAllFields={validateAllFields}
                  handleUpdateEmployee={handleUpdateEmployee}
                  emptyFieldsError={emptyFieldsError}
                  handleSelectUnit={handleSelectUnit}
                  handleSelectDesignation={handleSelectDesignation}
                />
              );
          })}
        </div>
        {newEmployee && (
          <NewEmployee
            employee={newEmployee}
            organization_units={organization_units}
            designations={designations}
            handleChangeNewEmployee={handleChangeNewEmployee}
            setNewEmployee={setNewEmployee}
            setActiveEmployee={setActiveEmployee}
            handleCreateEmployee={handleCreateEmployee}
            emptyFieldsError={emptyFieldsError}
            deleteNewEmployee={deleteNewEmployee}
            handleSelectUnit={handleSelectUnit}
            setEmptyFieldsError={setEmptyFieldsError}
            style={{
              borderTop:
                employeesList.length > 0
                  ? "none"
                  : "2px solid var(--mainColor)",
              border: "2px solid var(--mainColor)",
            }}
          />
        )}

        <button
          className={
            !disableAddButton()
              ? "add-employee-button"
              : "add-employee-button add-employee-button-disabled"
          }
          onClick={addEmployeeToList}
          disabled={disableAddButton()}
        >
          <span>
            <img
              src="/images/plus.png"
              className={disableAddButton() ? "disabled-button" : ""}
            />
          </span>
          Dodajte zaposlenog
        </button>
      </div>
      <div className="buttons-block">
        <Button
          disabledColor={calculateDisbledButton()}
          title="Dalje"
          onClick={() => navigate("/register/poslovni-procesi")}
          style={{ marginRight: "20px" }}
          disabled={activeEmployee}
        />
        <Button
          title="Nazad"
          disabledColor={true}
          onClick={() => navigate("/register/struktura-preduzeca")}
        />
      </div>

      {errorMessages && (
        <>
          <div className="loading"></div>
          <EmployeelistErrorPopup
            message={errorMessages}
            setErrorMessage={setErrorMessages}
            resetActiveEmployee={resetActiveEmployee}
            activeEmployee={activeEmployee}
            handleUpdateEmployee={handleUpdateEmployee}
            handleCreateEmployee={handleCreateEmployee}
            newEmployee={newEmployee}
          />
        </>
      )}
      {employeeToDelete && (
        <>
          <div className="loading"></div>
          <DeleteEmployeePopup
            employeeToDelete={employeeToDelete}
            setEmployeeToDelete={setEmployeeToDelete}
            handleConfirmDeleteEmployee={handleConfirmDeleteEmployee}
            loading={loading}
          />
        </>
      )}
      {loading && (
        <>
          <div className="loading"></div>
          <Loading isLoading={loading} />
        </>
      )}
    </>
  );
}
