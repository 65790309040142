import React, { useEffect, useRef, useState } from "react";
import "./customtextarea.css";

export default function CustomTextArea({
  nameValue,
  descriptionValue,
  style,
  handleChangeNewActivity,
  handleSaveNewActivity,
  activity,
  handleDeleteNewActivity,
  handleSetEditActivity,
  handleSaveActivity,
  handleChangeActivity,
  newActivityValidationErrors,
  readonly,
  handleUpdateActivity,
  setActivityToDelete,
  activeProcedure,
  activitiesList,
}) {
  //   const [text1, setText1] = useState("");
  //   const [text2, setText2] = useState("");
  const [height, setHeight] = useState("");

  const taRef1 = useRef();
  const taRef2 = useRef();

  useEffect(() => {
    if (taRef1.current && taRef2.current) {
      const scrollHeight1 = taRef1.current.scrollHeight;

      const height2 = +taRef2.current.style.height.split("p")[0];

      if (scrollHeight1 > height2) {
        taRef2.current.style.height = `${scrollHeight1}px`;
      }
      taRef1.current.style.height = `${scrollHeight1}px`;
    }
  }, [nameValue]);

  useEffect(() => {
    if (taRef1.current && taRef2.current) {
      const scrollHeight2 = taRef2.current.scrollHeight;

      const height1 = +taRef1.current.style.height.split("p")[0];

      if (scrollHeight2 > height1) {
        taRef1.current.style.height = `${scrollHeight2}px`;
      }
      taRef2.current.style.height = `${scrollHeight2}px`;
    }
  }, [descriptionValue]);

  return (
    <div className="custom-text-area-wrapper" style={style}>
      <textarea
        readOnly={!activity.editing && !activity.adding}
        onChange={
          activity.adding
            ? (e) => {
                handleChangeNewActivity(e, "name");
              }
            : (e) => handleChangeActivity(e, activity.id, "name")
        }
        className="custom-text-area"
        rows={1}
        ref={taRef1}
        value={nameValue || ""}
        style={{
          width: "35%",
          borderRight: "1px solid var(--mainColor)",
          // height: `${height}px`,
        }}
      ></textarea>

      {newActivityValidationErrors && newActivityValidationErrors.name && (
        <div className="custom-text-area-name-error">obavezno polje</div>
      )}
      <textarea
        readOnly={!activity.editing && !activity.adding}
        onChange={
          activity.adding
            ? (e) => {
                handleChangeNewActivity(e, "description");
              }
            : (e) => handleChangeActivity(e, activity.id, "description")
        }
        className="custom-text-area"
        rows={1}
        ref={taRef2}
        value={descriptionValue || ""}
        style={{
          width: "58%",
          // height: `${height}px`
        }}
      ></textarea>
      {newActivityValidationErrors &&
        newActivityValidationErrors.description && (
          <div className="custom-text-area-description-error">
            obavezno polje
          </div>
        )}
      <div className="options-block" style={{ width: "7%" }}>
        <button
          type="button"
          className="employee-item-options-button"
          // disabled={readonly}
          onClick={
            activity.adding
              ? handleSaveNewActivity
              : activity.editing
              ? () => handleUpdateActivity(activity)
              : () => handleSetEditActivity(activity.id)
          }
        >
          <img
            src={
              activity?.editing || activity.adding
                ? "/images/check1.jpg"
                : "/images/edit.png"
            }
          />
        </button>
        <button
          type="button"
          className="employee-item-options-button"
          onClick={() => setActivityToDelete(activity)}
        >
          <img src="/images/trash.png" />
        </button>
      </div>
    </div>
  );
}
