import React from "react";

export default function FormulareRizikaUnitsList({
  addNewFormular,
  formularList,
  setFormular,
  activeFormular,
  designations,
  setErrorMessages,
  setErrors,
  handleDeleteFormular,
}) {
  const formatEmployees = (list, des) => {
    const workers = list.map((item) => {
      const worker = des.find((el) => el.id === item.employee_id);

      if (worker) {
        return { label: worker.name, value: worker, isDisabled: undefined };
      }
    });

    return workers;
  };

  const handleSetActiveFormular = (formular) => {
    setErrorMessages(null);
    setErrors(null);
    setFormular({
      ...formular,
      risk_interviewees: formatEmployees(
        formular.risk_interviewees,
        designations
      ),
      risk_owners: formatEmployees(formular.risk_owners, designations),
      risk_correspondents: formatEmployees(
        formular.risk_correspondents,
        designations
      ),
    });
  };
  return (
    <div className="poslovni-procesi-unit-list">
      <button
        className="add-position-button"
        onClick={addNewFormular}
        disabled={!activeFormular.id}
      >
        <span>
          <img className="plus-circle-icon" src="/images/pluscircle.png" />
        </span>
        <span
          className={
            !activeFormular.id ? "add-unit-btn-disabled" : "add-unit-btn"
          }
        >
          Dodajte formular rizika
        </span>
      </button>
      <h3 className="poslovni-procesi-unit-list-title">
        {activeFormular.name ? activeFormular.name : "Formular rizika"}
      </h3>
      {Object.keys(formularList).length > 0 && (
        <ul>
          {Object.keys(formularList).map((key) => {
            const { name } = formularList[key];
            if (key != activeFormular.id && !formularList[key].del)
              return (
                <li
                  key={key}
                  className={
                    key == activeFormular.id
                      ? "poslovni-procesi-unit-list-item active-unit"
                      : "poslovni-procesi-unit-list-item"
                  }
                >
                  <p
                    className="unit-name"
                    style={{ width: "90%" }}
                    onClick={() => handleSetActiveFormular(formularList[key])}
                  >
                    {name}
                  </p>

                  <span
                    className="poslovni-procesi-unit-list-delete-button"
                    onClick={() => handleDeleteFormular(key)}
                  >
                    <img src="/images/trash.png" />
                  </span>
                </li>
              );
          })}
        </ul>
      )}
    </div>
  );
}
