import React, { useEffect, useState } from "react";
import "./newstrukturapreduzeca.css";

import { baseRequest, deleteUrl } from "../../axiosconfig";
import Button from "../elements/button";
import { useNavigate } from "react-router-dom";
import Department from "../Department";
import Loading from "../loading";
import axios from "axios";
import ErrorMessagePopup from "../errorMessagePopup";
import DeletePopup from "../deletePupup";

export default function NewStrukturaPreduzeca({}) {
  const navigate = useNavigate();
  const [structure, setStructure] = useState({});
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [showDescription, setShowDescription] = useState();
  const [errorMessages, setErrorMessage] = useState(null);
  const [activeUnit, setActiveUnit] = useState(null);
  const [unitToDelete, setUnitToDelete] = useState();
  const [validationError, setValidationError] = useState(null);
  const [saved, setSaved] = useState(false);

  const levels = {
    1: "departments",
    2: "divisions",
    3: "subdivisions",
  };

  const formatListToMap = (list) => {
    const map = {};
    list.forEach((item) => {
      map[item.id] = item;
    });
    return map;
  };

  const filterDivisions = (department) => {
    const divisions = [];
    if (structure.divisions) {
      Object.keys(structure.divisions).forEach((key) => {
        if (structure.divisions[key].department_id === department.id) {
          divisions.push(structure.divisions[key]);
        }
      });

      return divisions;
    } else {
      return [];
    }
  };
  function objectNotEmpty(object = {}) {
    return Object.keys(object).length > 0;
  }

  const formatUnitDesignations = (list) => {
    return list.map((item) => {
      return {
        ...item,
        designations: item.designations
          ? formatListToMap(item.designations)
          : [],
      };
    });
  };

  const formatStructure = (structure) => {
    const newStructure = {};
    Object.keys(structure).forEach((key) => {
      if (Array.isArray(structure[key])) {
        newStructure[key] = formatListToMap(
          formatUnitDesignations(structure[key])
        );
      }
    });
    return { ...structure, ...newStructure, isOpen: true };
  };

  useEffect(() => {
    setLoading(true);
    baseRequest
      .get("/structure", { withCredentials: true })
      .then((res) => {
        setStructure(formatStructure(res.data.data));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        navigate("/login");
      });
  }, []);

  const handleAddNewUnit = (obj, key) => {
    if (validate()) {
      return;
    }
    setActiveUnit({ activeUnit: obj, prevState: obj, key });
    setValue(obj);
  };

  const handleCreateUnit = (payload) => {
    if (validateData(payload)) {
      return;
    }

    setLoading(true);
    baseRequest
      .post("/organization_unit", payload, { withCredentials: true })
      .then((res) => {
        setErrorMessage(null);
        setActiveUnit(null);
        setStructure((structure) => {
          return {
            ...structure,
            [levels[res.data.data.level]]: {
              ...structure[levels[res.data.data.level]],
              [res.data.data.id]: { ...res.data.data, isOpen: true },
            },
          };
        });
        setLoading(false);
        setValue(null);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleShowDescription = (id, level, clickedFromEdit) => {
    // if (validate()) {
    //   return;
    // }
    setShowDescription((prevState) => {
      if (prevState && !clickedFromEdit && prevState.id === id) {
        return null;
      } else {
        return { id, level };
      }
    });
  };

  const handleCreateDesignation = (payload) => {
    if (validateData(payload)) {
      return;
    }
    setLoading(true);
    baseRequest
      .post("/designation", payload, { withCredentials: true })
      .then((res) => {
        setShowDescription(null);
        setErrorMessage(null);
        setActiveUnit(null);
        setStructure((structure) => {
          if (payload.level === 0) {
            return {
              ...structure,
              designations: {
                ...structure.designations,
                [res.data.data.id]: res.data.data,
              },
            };
          } else {
            return {
              ...structure,
              [levels[res.data.data.level]]: {
                ...structure[levels[res.data.data.level]],
                [res.data.data.parent_id]: {
                  ...structure[levels[res.data.data.level]][
                    res.data.data.parent_id
                  ],
                  designations: {
                    ...structure[levels[res.data.data.level]][
                      res.data.data.parent_id
                    ].designations,
                    [res.data.data.id]: res.data.data,
                  },
                },
              },
            };
          }
        });
        setLoading(false);
        setValue(null);
      });
  };

  const handleSetEditDesignation = (id, parent_id, key, level) => {
    if (validate()) {
      return;
    } else {
      setStructure((structure) => {
        if (key) {
          setActiveUnit({
            activeUnit: {
              ...structure[key][parent_id]?.designations[id],
              editing: true,
              key: "designation",
              level: level,
            },
            prevState: {
              designation: structure[key][parent_id]?.designations[id],
              parent_id,
              key,
              level,
            },
          });
          return {
            ...structure,
            [key]: {
              ...structure[key],
              [parent_id]: {
                ...structure[key][parent_id],
                designations: {
                  ...structure[key][parent_id].designations,
                  [id]: {
                    ...structure[key][parent_id].designations[id],
                    editing: true,
                  },
                },
              },
            },
          };
        } else {
          setActiveUnit({
            activeUnit: {
              ...structure.designations[id],
              editing: true,
              key: "designation",
              level: level,
            },
            prevState: {
              designation: structure.designations[id],
              parent_id,
              key,
              level,
            },
          });
          return {
            ...structure,
            designations: {
              ...structure.designations,
              [id]: { ...structure.designations[id], editing: true },
            },
          };
        }
      });
    }
  };

  const handleChangeDesignationName = (e, id, parent_id, key, property) => {
    if (e.target.value.trimStart()) {
      setValidationError(null);
    }
    setActiveUnit((unit) => {
      return {
        ...unit,
        activeUnit: {
          ...unit.activeUnit,
          [property]:
            property === "description"
              ? e.target.value
              : e.target.value.trimStart(),
        },
      };
    });
    setStructure((structure) => {
      if (parent_id && key) {
        return {
          ...structure,
          [key]: {
            ...structure[key],
            [parent_id]: {
              ...structure[key][parent_id],
              designations: {
                ...structure[key][parent_id].designations,
                [id]: {
                  ...structure[key][parent_id].designations[id],
                  [property]:
                    property === "description"
                      ? e.target.value
                      : e.target.value.trimStart(),
                },
              },
            },
          },
        };
      } else {
        return {
          ...structure,
          designations: {
            ...structure.designations,
            [id]: {
              ...structure.designations[id],
              [property]:
                property === "description"
                  ? e.target.value
                  : e.target.value.trimStart(),
            },
          },
        };
      }
    });
  };

  const handleUpdateDesignation = (payload) => {
    if (validateData(payload)) {
      setErrorMessage(null);
      return;
    }
    setLoading(true);
    setShowDescription(null);
    baseRequest
      .put("/designation", payload, { withCredentials: true })
      .then((res) => {
        setErrorMessage(null);
        setActiveUnit(null);
        setShowDescription(null);
        setStructure((structure) => {
          if (payload.level === 0) {
            return {
              ...structure,
              designations: {
                ...structure.designations,
                [res.data.data.id]: res.data.data,
              },
            };
          } else {
            return {
              ...structure,
              [levels[res.data.data.level]]: {
                ...structure[levels[res.data.data.level]],
                [res.data.data.parent_id]: {
                  ...structure[levels[res.data.data.level]][
                    res.data.data.parent_id
                  ],
                  designations: {
                    ...structure[levels[res.data.data.level]][
                      res.data.data.parent_id
                    ].designations,
                    [res.data.data.id]: res.data.data,
                  },
                },
              },
            };
          }
        });
        setLoading(false);
        setValue(null);
      });
  };

  const handleSetEditUnit = (id, level, number, name) => {
    if (validate()) {
      return;
    }
    setStructure((structure) => {
      return {
        ...structure,
        [level]: {
          ...structure[level],
          [id]: { ...structure[level][id], editing: true },
        },
      };
    });
    setActiveUnit({
      activeUnit: { ...structure[level][id], edit: true },
      level: number,
      prevState: { unit: structure[level][id], level },
    });
  };

  const handleSetUnitOpen = (id, level) => {
    setStructure((structure) => {
      if (!id) {
        return {
          ...structure,
          isOpen: !structure.isOpen,
        };
      } else {
        return {
          ...structure,
          [level]: {
            ...structure[level],
            [id]: {
              ...structure[level][id],
              isOpen: !structure[level][id].isOpen,
            },
          },
        };
      }
    });
  };

  const handleChangeUnitName = (e, id, level) => {
    if (e.target.value.trimStart()) {
      setValidationError(null);
    }

    setActiveUnit((unit) => {
      return {
        ...unit,
        activeUnit: { ...unit.activeUnit, name: e.target.value.trimStart() },
      };
    });
    setStructure((structure) => {
      return {
        ...structure,
        [level]: {
          ...structure[level],
          [id]: { ...structure[level][id], name: e.target.value.trimStart() },
        },
      };
    });
  };

  const handleKeyDown = (e, callback, ...params) => {
    if (e.key === "Enter" && activeUnit) {
      e.target.blur();
      callback(...params);
    }
  };

  const handleUpdateUnit = (payload, level) => {
    if (validateData(payload, level)) {
      setErrorMessage(null);
      return;
    }
    setLoading(true);
    baseRequest
      .put(
        "/organization_unit",
        { ...payload, level },
        { withCredentials: true }
      )
      .then((res) => {
        setShowDescription(null);
        setActiveUnit(null);
        setErrorMessage(null);
        setStructure((structure) => {
          const { editing, ...rest } =
            structure[levels[res.data.data.level]][res.data.data.id];
          return {
            ...structure,
            [levels[res.data.data.level]]: {
              ...structure[levels[res.data.data.level]],
              [res.data.data.id]: { ...rest, ...res.data.data },
            },
          };
        });
        setLoading(false);
      });
  };

  const handleDeleteDesignation = (payload, key) => {
    if (validate()) {
      return;
    }
    setUnitToDelete({ payload, key });
  };

  const handleConfirmDeleteDesignation = (payload) => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}designation`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: payload,
        withCredentials: true,
      })
      .then((res) => {
        setActiveUnit(null);
        setErrorMessage(null);
        setUnitToDelete(null);
        setStructure((structure) => {
          if (payload.level === 0) {
            return {
              ...structure,
              designations: {
                ...structure.designations,
                [res.data.data.id]: res.data.data,
              },
            };
          } else {
            return {
              ...structure,
              [levels[res.data.data.level]]: {
                ...structure[levels[res.data.data.level]],
                [res.data.data.parent_id]: {
                  ...structure[levels[res.data.data.level]][
                    res.data.data.parent_id
                  ],
                  designations: {
                    ...structure[levels[res.data.data.level]][
                      res.data.data.parent_id
                    ].designations,
                    [res.data.data.id]: res.data.data,
                  },
                },
              },
            };
          }
        });
        setLoading(false);
      });
  };

  const handleDeleteUnit = (payload, key) => {
    setUnitToDelete({ payload, key });
  };

  const handleConfirmDeleteUnit = (payload) => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}organization_unit`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: payload,
        withCredentials: true,
      })
      .then((res) => {
        setActiveUnit(null);
        setErrorMessage(null);
        setUnitToDelete(null);
        const { departments, divisions, subdivisions } = res.data.data;

        setStructure((structure) => {
          const deletedDepartmentDesignations = objectNotEmpty(
            structure.departments[departments[0]?.id]?.designations
          )
            ? formatListToMap(
                Object.keys(
                  structure.departments[departments[0].id]?.designations
                ).map((key) => {
                  return {
                    ...structure.departments[departments[0].id]?.designations[
                      key
                    ],
                    del: 1,
                  };
                })
              )
            : null;

          const deletedDepartment = departments.length
            ? {
                ...structure.departments,
                [departments[0].id]: {
                  ...structure.departments[departments[0].id],
                  del: 1,
                  designations: deletedDepartmentDesignations
                    ? deletedDepartmentDesignations
                    : [],
                },
              }
            : null;

          const deletedDivisions = objectNotEmpty(divisions)
            ? formatListToMap(
                Object.keys(structure.divisions).map((key) => {
                  const foundDivisionId = divisions.find(
                    (div) => div.id === structure.divisions[key].id
                  )?.id;

                  if (structure.divisions[key].id === foundDivisionId) {
                    return {
                      ...structure.divisions[key],
                      del: 1,
                      designations: objectNotEmpty(
                        structure.divisions[key].designations
                      )
                        ? formatListToMap(
                            Object.keys(
                              structure.divisions[key].designations
                            ).map((desKey) => {
                              return {
                                ...structure.divisions[key].designations[
                                  desKey
                                ],
                                del: 1,
                              };
                            })
                          )
                        : [],
                    };
                  }
                  return structure.divisions[key];
                })
              )
            : null;

          const deletedSubdivisions = objectNotEmpty(subdivisions)
            ? formatListToMap(
                Object.keys(structure.subdivisions).map((key) => {
                  const foundDivisionId = subdivisions.find(
                    (div) => div.id === structure.subdivisions[key].id
                  )?.id;

                  if (structure.subdivisions[key].id === foundDivisionId) {
                    return {
                      ...structure.subdivisions[key],
                      del: 1,
                      designations: objectNotEmpty(
                        structure.subdivisions[key].designations
                      )
                        ? formatListToMap(
                            Object.keys(
                              structure.subdivisions[key].designations
                            ).map((desKey) => {
                              return {
                                ...structure.subdivisions[key].designations[
                                  desKey
                                ],
                                del: 1,
                              };
                            })
                          )
                        : [],
                    };
                  }
                  return structure.subdivisions[key];
                })
              )
            : null;

          return {
            ...structure,
            departments: deletedDepartment
              ? deletedDepartment
              : structure.departments,
            divisions: deletedDivisions
              ? deletedDivisions
              : structure.divisions,
            subdivisions: deletedSubdivisions
              ? deletedSubdivisions
              : structure.subdivisions,
          };
        });
        setLoading(false);
      });
  };

  const validate = () => {
    if (value || activeUnit) {
      setErrorMessage("Imate nesacuvane podatke. Zelite da sacuvate?");
      return true;
    }
  };

  const validateData = (data, level) => {
    if (data.name === "") {
      setValidationError({ ...data, level });
      setErrorMessage(null);
      return true;
    }
  };

  const handleDeleteNewUnit = () => {
    setValue(null);
    setActiveUnit(null);
    setErrorMessage(null);
  };

  const handleChangeNewUnit = (e, prop) => {
    if (e.target.value.trimStart()) {
      setValidationError(null);
    }
    setValue((value) => {
      return { ...value, [prop]: e.target.value.trimStart() };
    });

    setActiveUnit((unit) => {
      return {
        ...unit,
        activeUnit: { ...unit.activeUnit, [prop]: e.target.value.trimStart() },
      };
    });
  };

  const handleRouteNextPage = () => {
    if (validate()) {
      return;
    } else {
      navigate("/register/spisak-zaposlenih");
    }
  };

  const handleResetActiveUnit = () => {
    if (value) {
      setValue(null);
      setActiveUnit(null);
    } else {
      if (activeUnit.prevState.designation) {
        if (activeUnit.prevState.key) {
          setStructure((structure) => {
            return {
              ...structure,
              [activeUnit.prevState.key]: {
                ...structure[activeUnit.prevState.key],
                [activeUnit.prevState.parent_id]: {
                  ...structure[activeUnit.prevState.key][
                    activeUnit.prevState.parent_id
                  ],
                  designations: {
                    ...structure[activeUnit.prevState.key][
                      activeUnit.prevState.parent_id
                    ].designations,
                    [activeUnit.prevState.designation.id]:
                      activeUnit.prevState.designation,
                  },
                },
              },
            };
          });
          setActiveUnit(null);
          setShowDescription(null);
        } else {
          setStructure((structure) => {
            return {
              ...structure,
              designations: {
                ...structure.designations,
                [activeUnit.prevState.designation.id]:
                  activeUnit.prevState.designation,
              },
            };
          });
          setActiveUnit(null);
          setShowDescription(null);
        }
      } else {
        setStructure((structure) => {
          return {
            ...structure,
            [activeUnit.prevState.level]: {
              ...structure[activeUnit.prevState.level],
              [activeUnit.prevState.unit.id]: activeUnit.prevState.unit,
            },
          };
        });
        setActiveUnit(null);
      }
    }
  };

  return (
    <div className="page">
      <div className="page-title">Struktura preduzeca</div>
      <p className="startpage-description">
        Molimo vas da ne ulazite u proces registracije bez pomoći stručnog lica.
        Naš tim Vam stoji na raspolaganju.
      </p>

      <section className="page-main flex-start">
        <div className="list-wrapper">
          <div className="list-title-wrapper">
            <img src="/images/globe.png" />
            <h3 className="list-title">Uprava</h3>
            <div
              className="unfold-list-arrow"
              onClick={() => handleSetUnitOpen(null, "uprava")}
            >
              <img
                alt="icon"
                src={
                  structure.isOpen
                    ? "/images/dropdownup.png"
                    : "/images/dropdowndown.png"
                }
              ></img>
            </div>
          </div>

          {Object.keys(structure).length > 0 &&
            Object.keys(structure?.designations).length > 0 &&
            structure.isOpen && (
              <ul className="positions-list">
                {Object.keys(structure?.designations).map((key) => {
                  const position = structure.designations[key];
                  if (!position.del)
                    return (
                      <li
                        key={`designation${position.id}`}
                        style={{ marginBottom: "10px" }}
                      >
                        <div
                          className={
                            validationError?.editing &&
                            validationError?.id === position?.id &&
                            !validationError?.level
                              ? "add-to-list-input-wrapper error-border-bottom"
                              : "add-to-list-input-wrapper"
                          }
                        >
                          <input
                            type="text"
                            className="add-to-list-input"
                            value={position.name}
                            readOnly={!position.editing}
                            onChange={(e) => {
                              handleChangeDesignationName(
                                e,
                                position.id,
                                null,
                                null,
                                "name"
                              );
                            }}
                            onKeyDown={(e) =>
                              handleKeyDown(
                                e,
                                handleUpdateDesignation,
                                activeUnit.activeUnit
                              )
                            }
                          ></input>

                          <div className="edit-buttons-block">
                            {position.editing && (
                              <button
                                onClick={() => {
                                  setShowDescription(null);
                                  handleUpdateDesignation({
                                    ...position,
                                    level: 0,
                                  });
                                }}
                              >
                                <img src="/images/check1.jpg" />
                              </button>
                            )}
                            {!position.editing && (
                              <button
                                onClick={(e) => {
                                  if (!activeUnit) {
                                    handleShowDescription(position.id, 0, true);
                                  }

                                  handleSetEditDesignation(
                                    position.id,
                                    null,
                                    null,
                                    0
                                  );
                                }}
                              >
                                <img src="/images/edit.png" />
                              </button>
                            )}
                            <button
                              onClick={() =>
                                handleDeleteDesignation(
                                  { id: position.id, level: 0 },
                                  "designation"
                                )
                              }
                            >
                              <img src="/images/trash.png" />
                            </button>
                            {!position.adding && (
                              <button
                                onClick={() =>
                                  handleShowDescription(position.id, 0)
                                }
                              >
                                <img
                                  alt="icon"
                                  src={
                                    showDescription &&
                                    showDescription.id === position.id &&
                                    showDescription.level === 0
                                      ? "/images/iks.png"
                                      : "/images/plus.png"
                                  }
                                />
                              </button>
                            )}
                          </div>
                        </div>
                        {showDescription &&
                          showDescription.id === position.id &&
                          showDescription.level === 0 && (
                            <textarea
                              rows="4"
                              cols="50"
                              className="position-description"
                              value={position?.description || ""}
                              readOnly={!position.editing}
                              onChange={(e) => {
                                handleChangeDesignationName(
                                  e,
                                  position.id,
                                  null,
                                  null,
                                  "description"
                                );
                              }}
                            />
                          )}
                      </li>
                    );
                })}
              </ul>
            )}
          <div>
            {value &&
              value.key === "administration_designation" &&
              value.administration_id === structure.id && (
                <div className="new-designation-block">
                  <div
                    className={
                      validationError?.adding &&
                      validationError?.key === "administration_designation"
                        ? "new-designation-input-wrapper error-border-bottom"
                        : "new-designation-input-wrapper"
                    }
                  >
                    <input
                      //  readOnly={!department.editing && !department.typing}
                      placeholder="naziv radnog mesta"
                      type="text"
                      className="add-to-list-input"
                      value={value.name}
                      onChange={(e) => {
                        handleChangeNewUnit(e, "name");
                      }}
                      onKeyDown={(e) =>
                        handleKeyDown(e, handleCreateDesignation, value)
                      }
                      // onBlur={() =>
                      //   department.name
                      //     ? handleConfirmAddDepartment(department.id)
                      //     : handleDeleteDepartment(department.id)
                      // }
                    ></input>

                    <div className="edit-buttons-block">
                      {(value?.adding || value?.editing) && value.name && (
                        <button onClick={() => handleCreateDesignation(value)}>
                          <img src="/images/check1.jpg" />
                        </button>
                      )}
                      {!value?.adding && !value.editing && value.name && (
                        <button
                        // onClick={(e) => handleSetEdit(e, department.id)}
                        >
                          <img src="/images/edit.png" />
                        </button>
                      )}
                      <button onClick={() => handleDeleteNewUnit()}>
                        <img src="/images/trash.png" />
                      </button>

                      <button
                        onClick={() =>
                          setValue((value) => {
                            return {
                              ...value,
                              openDescription: !value.openDescription,
                            };
                          })
                        }
                      >
                        <img
                          alt="icon"
                          src={
                            value?.openDescription
                              ? "/images/iks.png"
                              : "/images/plus.png"
                          }
                        />
                      </button>
                    </div>
                  </div>

                  {value.openDescription && (
                    <textarea
                      rows="4"
                      cols="50"
                      className="position-description"
                      placeholder="dodajte opis"
                      value={value.description}
                      onChange={(e) => {
                        handleChangeNewUnit(e, "description");
                      }}
                    />
                  )}
                </div>
              )}
          </div>

          {structure.isOpen && (
            <div className="add-to-list-block">
              <button
                className={
                  activeUnit
                    ? "add-list-button add-list-button-disabled"
                    : "add-list-button"
                }
                disabled={activeUnit}
                onClick={() =>
                  handleAddNewUnit({
                    name: "",
                    administration_id: structure.id,
                    adding: true,
                    key: "administration_designation",
                    parent_id: structure.id,
                    level: 0,
                    description: "",
                    openDescription: true,
                    isOpen: true,
                  })
                }
                //disabled={positions.length && positions.some((pos) => pos.typing === true)}
              >
                <span>
                  <img
                    className="plus-circle-icon"
                    src="/images/pluscircle.png"
                  />
                </span>
                <span>Dodajte radno mesto</span>
              </button>
            </div>
          )}
          <Button
            title="Dalje"
            style={{ marginTop: "20px" }}
            onClick={() => handleRouteNextPage()}
            disabledColor={errorMessages || activeUnit}
          />
        </div>

        <div className="list-wrapper">
          {structure?.departments && (
            <ul className="strukcture-list">
              {Object.keys(structure.departments).map((key) => {
                if (!structure.departments[key].del)
                  return (
                    <Department
                      key={`Department${key}`}
                      department={structure.departments[key]}
                      handleAddNewUnit={handleAddNewUnit}
                      value={value}
                      setValue={setValue}
                      divisions={filterDivisions(structure.departments[key])}
                      handleCreateUnit={handleCreateUnit}
                      handleCreateDesignation={handleCreateDesignation}
                      handleKeyDown={handleKeyDown}
                      subdivisions={structure.subdivisions}
                      handleSetEditUnit={handleSetEditUnit}
                      handleChangeUnitName={handleChangeUnitName}
                      handleUpdateUnit={handleUpdateUnit}
                      handleSetEditDesignation={handleSetEditDesignation}
                      handleChangeDesignationName={handleChangeDesignationName}
                      handleUpdateDesignation={handleUpdateDesignation}
                      handleDeleteDesignation={handleDeleteDesignation}
                      handleSetUnitOpen={handleSetUnitOpen}
                      handleDeleteUnit={handleDeleteUnit}
                      handleDeleteNewUnit={handleDeleteNewUnit}
                      handleChangeNewUnit={handleChangeNewUnit}
                      validationError={validationError}
                      activeUnit={activeUnit}
                      handleShowDescription={handleShowDescription}
                      showDescription={showDescription}
                      setShowDescription={setShowDescription}
                    />
                  );
              })}
            </ul>
          )}

          <div className="add-to-list-block">
            {value && value.key === "department" && value.adding && (
              <div
                className={
                  validationError?.adding &&
                  validationError?.key === "department"
                    ? "input-wrapper unit-error-border"
                    : "input-wrapper"
                }
              >
                <img
                  className="department-icon"
                  src="/images/departmenticon.png"
                />
                <input
                  autoFocus
                  //  readOnly={!department.editing && !department.typing}
                  type="text"
                  className="department-title-input"
                  value={value.name}
                  onChange={(e) => handleChangeNewUnit(e, "name")}
                  onKeyDown={(e) => handleKeyDown(e, handleCreateUnit, value)}
                  // onBlur={() =>
                  //   department.name
                  //     ? handleConfirmAddDepartment(department.id)
                  //     : handleDeleteDepartment(department.id)
                  // }
                ></input>

                <div className="edit-department-buttons-block">
                  {(value?.adding || value?.editing) && value.name && (
                    <button onClick={() => handleCreateUnit(value)}>
                      <img src="/images/check1.jpg" />
                    </button>
                  )}
                  {!value?.adding && !value.editing && value.name && (
                    <button
                    // onClick={(e) => handleSetEdit(e, department.id)}
                    >
                      <img src="/images/edit.png" />
                    </button>
                  )}
                  <button
                    onClick={() => {
                      handleDeleteNewUnit();
                    }}
                  >
                    <img src="/images/trash.png" />
                  </button>
                </div>
              </div>
            )}
            <button
              className="add-department-button"
              onClick={() =>
                handleAddNewUnit({
                  name: "",
                  key: "department",
                  adding: true,
                  parent_id: null,
                  level: 1,
                })
              }
              // disabled={departments?.length && departments.some((pos) => pos.typing === true)}
            >
              + Dodajte organizacionu jedinicu
            </button>
          </div>
        </div>
      </section>

      <Loading isLoading={loading} />
      {errorMessages && (
        <>
          <div className="loading"></div>
          <ErrorMessagePopup
            message={errorMessages}
            setErrorMessage={setErrorMessage}
            setActiveUnit={setActiveUnit}
            handleUpdateDesignation={handleUpdateDesignation}
            activeUnit={activeUnit}
            handleCreateDesignation={handleCreateDesignation}
            handleCreateUnit={handleCreateUnit}
            loading={loading}
            handleUpdateUnit={handleUpdateUnit}
            handler={handleResetActiveUnit}
            validationError={validationError}
            setValidationError={setValidationError}
          />
        </>
      )}
      {unitToDelete && (
        <>
          <div className="loading"></div>
          <DeletePopup
            setUnitToDelete={setUnitToDelete}
            handleConfirmDeleteDesignation={handleConfirmDeleteDesignation}
            handleConfirmDeleteUnit={handleConfirmDeleteUnit}
            unitToDelete={unitToDelete}
            loading={loading}
          />
        </>
      )}
    </div>
  );
}
