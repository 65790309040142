import React from "react";
import AdminFormulariRizika from "../../components/admin/adminFormulariRizika";

function AdminFormulariRizikaPage(props) {
  return (
    <div className="admin-page-child-wrapper">
      <AdminFormulariRizika />
    </div>
  );
}

export default AdminFormulariRizikaPage;
