import React from "react";
import "./mapeprocesaaktivnostilist.css";

import CustomTextArea from "../customTextarea";
import NewActivity from "../newActivity";

export default function MapeProcesaAktivnostiList({
  activitiesList,
  handleAddNewActivitiItem,
  handleChangeActivitiName,
  handleChangeActivitiDescription,
  newActivity,
  handleChangeNewActivity,
  handleSaveNewActivity,
  handleDeleteNewActivity,
  handleSetEditActivity,
  handleSaveActivity,
  handleChangeActivity,
  newActivityValidationErrors,
  mapa,
  handleUpdateActivity,
  setActivityToDelete,
  activeProcedure,
}) {
  return (
    <div style={{ marginBottom: "30px", marginTop: "40px" }}>
      <div
        style={{
          border:
            mapa.edit || mapa.adding ? "2px solid var(--mainColor)" : "none",
          marginBottom: "20px",
        }}
      >
        <div className="mape-procesa-aktivnost-list-header">
          <p
            style={{
              width: "35%",
              borderRight: "1px solid var(--mainColor)",
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
              color: "var(--mainColor)",
              textTransform: "uppercase",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            AKTIVNOSTI U PROCESU
          </p>
          <p
            style={{
              width: "65%",
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
              color: "var(--mainColor)",
              textTransform: "uppercase",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            OPIS AKTIVNOSTI
          </p>
        </div>
        <ul className="mape-procesa-aktivnosti-list">
          {activitiesList?.length > 0 &&
            activitiesList.map((item, index) => {
              return (
                <li
                  key={item.id}
                  style={{
                    display: "flex",
                    width: "100%",
                    border: item.editing
                      ? "2px solid var(--mainColor)"
                      : "none",
                  }}
                >
                  <CustomTextArea
                    handleChangeActivitiName={handleChangeActivitiName(index)}
                    handleChangeActivitiDescription={handleChangeActivitiDescription(
                      index
                    )}
                    nameValue={item.name}
                    descriptionValue={item.description}
                    activity={item}
                    handleSetEditActivity={handleSetEditActivity}
                    handleSaveActivity={handleSaveActivity}
                    handleChangeActivity={handleChangeActivity}
                    readonly={!mapa.edit && !mapa.adding}
                    handleUpdateActivity={handleUpdateActivity}
                    setActivityToDelete={setActivityToDelete}
                    activeProcedure={activeProcedure}
                    activitiesList={activitiesList}
                  />
                </li>
              );
            })}
        </ul>
        {newActivity && (
          <NewActivity
            handleChangeNewActivity={handleChangeNewActivity}
            activity={newActivity}
            handleSaveNewActivity={handleSaveNewActivity}
            newActivityValidationErrors={newActivityValidationErrors}
            // setActivityToDelete={setActivityToDelete}
            handleDeleteNewActivity={handleDeleteNewActivity}
          />
        )}
      </div>
      <div className="add-activity-button-wrapper">
        {newActivityValidationErrors &&
          newActivityValidationErrors.no_activity && (
            <div className="empty-activity-error">Dodajte aktivnost</div>
          )}
        <button
          className={
            newActivity ||
            activitiesList?.some((activity) => activity.editing) ||
            !mapa.business_process_list_id
              ? "add-poslovni-proces-btn add-button-disabled"
              : "add-poslovni-proces-btn"
          }
          onClick={handleAddNewActivitiItem}
          disabled={
            newActivity ||
            activitiesList?.some((activity) => activity.editing) ||
            !mapa.business_process_list_id
          }
        >
          <img src="/images/plus.png" />
          <span>Dodajte aktivnost</span>
        </button>
      </div>
    </div>
  );
}
