import axios from "axios";

export const baseRequest = axios.create({
  //baseURL: "http://localhost:8000",
  baseURL: "https://fuksrv.harmonity.com",
  // baseURL: "https://king-prawn-app-k4oq8.ondigitalocean.app/",

  headers: {
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

//export const deleteUrl = "http://localhost:8000/";
export const deleteUrl = "https://fuksrv.harmonity.com/";

//export const csrfUrl = "http://localhost:8000/";

export const csrfUrl = "https://fuksrv.harmonity.com/";
