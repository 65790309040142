import React, { useContext } from "react";
import "./header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { baseRequest } from "../../axiosconfig";
import { UserContext } from "../../userContext";

export default function Header({ isLoggedin, setIsloggedin, setLoading }) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  function handleLogout() {
    setLoading(true);
    baseRequest
      .post("/logout", {}, { withCredentials: true })
      .then((res) => {
        navigate("/login");
        setIsloggedin(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  return (
    <div className="header">
      <div>
        <h1 className="logo">fuk</h1>
        <p>Finansijsko upravljanje i kontrola</p>
      </div>

      <nav className="navigation">
        {!isLoggedin && (
          <Link className="navigation-item" to="/login">
            Prijavite se
          </Link>
        )}
        {!isLoggedin && (
          <Link className="navigation-item" to="/register">
            Registrujte se
          </Link>
        )}
        {isLoggedin && user && (
          <p style={{ marginRight: "20px" }}>{user.email}</p>
        )}

        {isLoggedin && (
          <p
            className="navigation-item"
            onClick={() => {
              navigate("/admin/contact");
            }}
          >
            Kontaktirajte nas
          </p>
        )}
        {isLoggedin && (
          <Link
            onClick={() => {
              handleLogout();
            }}
            className="navigation-item"
          >
            Odjavite se
          </Link>
        )}
      </nav>
    </div>
  );
}
