import React, { useContext, useEffect, useRef } from "react";
import SubdivisionDesignation from "../admin/subdivisionDesignation";
import { hasDesignationPermission } from "../admin/adminStrukturaPreduzeca";
import { UserContext } from "../../userContext";

export default function Subdivision({
  department,
  division,
  activeUnit,
  subdivision,
  handleCreateDesignation,
  handleDeleteNewUnit,
  value,
  handleAddNewUnit,
  setValue,
  handleChangeNewUnit,
  handleKeyDown,
  handleSetEditUnit,
  handleChangeUnitName,
  handleUpdateUnit,
  handleSetEditDesignation,
  handleChangeDesignationName,
  handleUpdateDesignation,
  handleDeleteDesignation,
  handleDeleteUnit,
  validationError,
  handleSetUnitOpen,
  handleShowDescription,
  showDescription,
  setShowDescription,
  hasOrganizationUnitPermission,
}) {
  //const [showDescription, setShowDescription] = useState();
  function objectNotEmpty(object = {}) {
    return Object.keys(object).length > 0;
  }

  const subDivisionRef = useRef();
  //const designationRef = useRef();
  const newDesignationRef = useRef();

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (subDivisionRef.current) {
      subDivisionRef.current.style.height = "100%";
      const scrollHeight = subDivisionRef.current.scrollHeight;
      subDivisionRef.current.style.height = scrollHeight + "px";
    }
    // if (designationRef.current) {
    //   designationRef.current.style.height = "100%";
    //   const scrollHeight = designationRef.current.scrollHeight;
    //   designationRef.current.style.height = scrollHeight + "px";
    // }
  }, [subdivision]);

  useEffect(() => {
    if (newDesignationRef.current && value.key === "subdivision_designation") {
      newDesignationRef.current.style.height = "100%";
      const scrollHeight = newDesignationRef.current.scrollHeight;
      newDesignationRef.current.style.height = scrollHeight + "px";
    }
  }, [value]);

  return (
    <div>
      <div className="organization-wrapper">
        <div
          className={
            subdivision.id === validationError?.id &&
            validationError?.level === 3
              ? "input-wrapper unit-error-border"
              : "input-wrapper"
          }
        >
          <img className="department-icon" src="/images/serviceicon.png" />
          <textarea
            ref={subDivisionRef}
            autoFocus
            rows="1"
            style={{ resize: "none", overflow: "hidden", marginBottom: "3px" }}
            readOnly={!subdivision.editing && !subdivision.typing}
            type="text"
            className="service-title-input"
            value={subdivision.name}
            onChange={(e) =>
              handleChangeUnitName(e, subdivision.id, "subdivisions")
            }
            //  onKeyDown={(e) => handleKeyDown(e, handleConfirmAddDepartment, department.id)}
            // onBlur={() =>
            //   department.name
            //     ? handleConfirmAddDepartment(department.id)
            //     : handleDeleteDepartment(department.id)
            // }
          ></textarea>

          {hasOrganizationUnitPermission && (
            <div className="edit-department-buttons-block">
              {subdivision.editing && (
                <button onClick={() => handleUpdateUnit(subdivision, 3)}>
                  <img src="/images/check1.jpg" />
                </button>
              )}
              {!subdivision.editing && (
                <button
                  onClick={() =>
                    handleSetEditUnit(subdivision.id, "subdivisions", 3)
                  }
                >
                  <img src="/images/edit.png" />
                </button>
              )}
              <button
                onClick={() =>
                  handleDeleteUnit({ id: subdivision.id, level: 3 }, "unit")
                }
              >
                <img src="/images/trash.png" />
              </button>
              <div
                className="unfold-list-arrow"
                onClick={() =>
                  handleSetUnitOpen(subdivision.id, "subdivisions")
                }
              >
                <img
                  alt=""
                  src={
                    subdivision.isOpen
                      ? "/images/dropdownup.png"
                      : "/images/dropdowndown.png"
                  }
                ></img>
              </div>
            </div>
          )}
          {/* <div
          className="unfold-list-arrow"
          onClick={() =>
            setOpenDepartments((deps) => {
              if (!deps.find((el) => el === department.id)) {
                return [...deps, department.id];
              } else {
                return deps.filter((el) => el !== department.id);
              }
            })
          }
        >
          <img
            src={openDepartments.includes(department.id) ? "/images/dropdownup.png" : "/images/dropdowndown.png"}
          ></img>
        </div> */}
        </div>

        {objectNotEmpty(subdivision.designations) && subdivision.isOpen && (
          <ul className="positions-list">
            {Object.keys(subdivision.designations).map((key) => {
              const position = subdivision.designations[key];
              if (!position.del)
                return (
                  <SubdivisionDesignation
                    key={`designation${position.id}`}
                    position={position}
                    validationError={validationError}
                    handleChangeDesignationName={handleChangeDesignationName}
                    subdivision={subdivision}
                    setShowDescription={setShowDescription}
                    handleUpdateDesignation={handleUpdateDesignation}
                    handleShowDescription={handleShowDescription}
                    handleSetEditDesignation={handleSetEditDesignation}
                    handleDeleteDesignation={handleDeleteDesignation}
                    showDescription={showDescription}
                    hasDesignationPermission={hasDesignationPermission(
                      user,
                      subdivision.id,
                      3
                    )}
                  />
                );
            })}
          </ul>
        )}
        <div>
          {value &&
            value.key === "subdivision_designation" &&
            value.parent_id === subdivision.id &&
            value.department_id === department.id &&
            value.division_id === division.id && (
              <div className="new-designation-block">
                <div
                  className={
                    validationError?.adding &&
                    validationError?.key === "subdivision_designation"
                      ? "new-designation-input-wrapper error-border-bottom"
                      : "new-designation-input-wrapper"
                  }
                >
                  <textarea
                    ref={newDesignationRef}
                    rows="1"
                    autoFocus
                    style={{ resize: "none", overflow: "hidden" }}
                    placeholder="naziv radnog mesta"
                    type="text"
                    className="add-to-list-input"
                    value={value.name}
                    onChange={(e) => handleChangeNewUnit(e, "name")}
                    onKeyDown={(e) =>
                      handleKeyDown(e, handleCreateDesignation, value)
                    }
                    // onBlur={() =>
                    //   department.name
                    //     ? handleConfirmAddDepartment(department.id)
                    //     : handleDeleteDepartment(department.id)
                    // }
                  ></textarea>

                  <div className="edit-department-buttons-block">
                    {(value?.adding || value?.editing) && value.name && (
                      <button onClick={() => handleCreateDesignation(value)}>
                        <img src="/images/check1.jpg" />
                      </button>
                    )}
                    {!value?.adding && !value.editing && value.name && (
                      <button
                      // onClick={(e) => handleSetEdit(e, department.id)}
                      >
                        <img src="/images/edit.png" />
                      </button>
                    )}
                    <button onClick={() => handleDeleteNewUnit()}>
                      <img src="/images/trash.png" />
                    </button>
                    <button
                      onClick={() =>
                        setValue((value) => {
                          return {
                            ...value,
                            openDescription: !value.openDescription,
                          };
                        })
                      }
                    >
                      <img
                        alt=""
                        src={
                          value?.openDescription
                            ? "/images/iks.png"
                            : "/images/plus.png"
                        }
                      />
                    </button>
                  </div>
                </div>
                {value.openDescription && (
                  <textarea
                    rows="4"
                    cols="50"
                    className="position-description"
                    placeholder="dodajte opis"
                    value={value.description}
                    onChange={(e) => handleChangeNewUnit(e, "description")}
                  />
                )}
              </div>
            )}
        </div>

        {!subdivision.typing &&
          hasDesignationPermission(user, subdivision.id, 3) && (
            <button
              disabled={activeUnit}
              className="add-position-button"
              onClick={() =>
                handleAddNewUnit({
                  name: "",
                  key: "subdivision_designation",
                  description: "",
                  level: 3,
                  parent_id: subdivision.id,
                  adding: true,
                  division_id: division.id,
                  department_id: department.id,
                  openDescription: true,
                })
              }
              // disabled={
              //   odsek?.positions?.length && odsek.positions.some((pos) => pos.typing === true)
              // }
            >
              <span>
                <img
                  className="plus-circle-icon"
                  src="/images/pluscircle.png"
                />
              </span>
              <span>Dodajte radno mesto</span>
            </button>
          )}
      </div>
    </div>
  );
}
