import React, { useEffect, useRef } from "react";

function AdministrationDesignation({
  position,
  validationError,
  handleChangeDesignationName,
  handleKeyDown,
  handleUpdateDesignation,
  activeUnit,
  setShowDescription,
  handleShowDescription,
  handleSetEditDesignation,
  handleDeleteDesignation,
  showDescription,
  hasDesignationPermission,
}) {
  const designationRef = useRef();

  useEffect(() => {
    if (designationRef.current) {
      designationRef.current.style.height = "100%";
      const scrollHeight = designationRef.current.scrollHeight;
      designationRef.current.style.height = scrollHeight + "px";
    }
  }, [position]);

  return (
    <li style={{ marginBottom: "10px" }}>
      <div
        className={
          validationError?.editing &&
          validationError?.id === position?.id &&
          !validationError?.level
            ? "input-wrapper error-border-bottom"
            : "input-wrapper"
        }
        style={{ marginBottom: "0" }}
      >
        <textarea
          ref={designationRef}
          rows="1"
          style={{ resize: "none", overflow: "hidden" }}
          type="text"
          className="add-to-list-input"
          value={position.name}
          readOnly={!position.editing}
          onChange={(e) => {
            handleChangeDesignationName(e, position.id, null, null, "name");
          }}
          onKeyDown={(e) =>
            handleKeyDown(e, handleUpdateDesignation, activeUnit?.activeUnit)
          }
        ></textarea>

        <div className="edit-buttons-block">
          {position.editing && (
            <button
              onClick={() => {
                setShowDescription(null);
                handleUpdateDesignation({
                  ...position,
                  level: 0,
                });
              }}
            >
              <img src="/images/check1.jpg" />
            </button>
          )}
          {!position.editing && hasDesignationPermission && (
            <button
              onClick={(e) => {
                if (!activeUnit) {
                  handleShowDescription(position.id, 0, true);
                }

                handleSetEditDesignation(position.id, null, null, 0);
              }}
            >
              <img src="/images/edit.png" />
            </button>
          )}
          {hasDesignationPermission && (
            <button
              onClick={() =>
                handleDeleteDesignation(
                  { id: position.id, level: 0 },
                  "designation"
                )
              }
            >
              <img src="/images/trash.png" />
            </button>
          )}
          {!position.adding && (
            <button onClick={() => handleShowDescription(position.id, 0)}>
              <img
                alt="icon"
                src={
                  showDescription &&
                  showDescription.id === position.id &&
                  showDescription.level === 0
                    ? "/images/iks.png"
                    : "/images/plus.png"
                }
              />
            </button>
          )}
        </div>
      </div>
      {showDescription &&
        showDescription.id === position.id &&
        showDescription.level === 0 && (
          <textarea
            rows="4"
            cols="50"
            className="position-description"
            value={position?.description || ""}
            readOnly={!position.editing}
            onChange={(e) => {
              handleChangeDesignationName(
                e,
                position.id,
                null,
                null,
                "description"
              );
            }}
          />
        )}
    </li>
  );
}

export default AdministrationDesignation;
