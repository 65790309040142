import React from "react";
import DynamicTextarea from "../../elements/DynamicTextarea";

function NewRisk({
  handleChangeNewRiskName,
  name,
  handleSaveNewRisk,

  handleDeleteNewProcedure,
  newProcedureValidationErrors,
  setProcedureToDelete,
  newProcedure,
  newRiskValidationError,
  handleDeleteNewRisk,
}) {
  return (
    <div
      className="new-procedure-list-item"
      style={{ border: "2px solid var(--mainColor)" }}
    >
      <DynamicTextarea
        style={{
          paddingTop: "24px",
          borderRight: "1px solid var(--mainColor)",
        }}
        onChange={handleChangeNewRiskName}
        textAreaWrapperStyle={{
          width: "calc(90% + 1px)",
        }}
        //   handleChangeActivitiName={handleChangeActivitiName(index)}
        //   handleChangeActivitiDescription={handleChangeActivitiDescription(
        //     index
        //   )}
        value={name}
        hasErrors={newRiskValidationError}
      />
      <div style={{ flex: "1" }}>
        <div className="options-block">
          <button
            type="button"
            className="employee-item-options-button"
            onClick={handleSaveNewRisk}
          >
            <img src="/images/check.png" alt="im" />
          </button>
          <button
            type="button"
            className="employee-item-options-button"
            onClick={() => {
              handleDeleteNewRisk();
            }}
          >
            <img src="/images/trash.png" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewRisk;
