import React from "react";

import DropDown from "../../elements/dropDown";
import DropdownWrapper from "../../elements/dropdownWrapper";
import FormulareRizikaUnitsList from "../../formulareRizikaUnitsList";
import Button from "../../elements/button";
import TextareaAutosize from "react-textarea-autosize";
import DynamicTextarea from "../../elements/DynamicTextarea";
import Loading from "../../loading";

import Calendar from "../../DatePicker";
import { useEffect, useState } from "react";
import { baseRequest, deleteUrl } from "../../../axiosconfig";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

export default function NewFormularRizika() {
  const [designations, setDesignations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formular, setFormular] = useState({});
  const [processes, setProcesses] = useState([]);
  const [riskGroups, setRiskGroups] = useState([]);
  const [riskLevels, setRisksLevels] = useState([]);
  const [controlInfluence, setControlInfluence] = useState("");
  const [preostaliRizik, setPreostaliRizik] = useState("");
  const [formularList, setFormularList] = useState({});
  const [errors, setErrors] = useState();
  const [errorMessages, setErrorMessages] = useState();
  const [influenceValidationError, setInfluenceValidationError] = useState();

  const navigate = useNavigate();

  const wrapperStyle = { borderBottom: "none" };
  const multiWrapperStyle = { borderBottom: "none", borderTop: "none" };
  const style = { borderRight: "1px solid var(--mainColor)" };
  const textAreaStyle = {
    borderBottom: "1px solid var(--mainColor)",
    paddingTop: "15px",
  };
  const border = "1px solid var(--mainColor)";
  const disabledBackground = "#eee";

  const errorStyle = { border: "2px solid red", width: "100%" };

  const riskColors = {
    nizak: "#B0E0E6",
    srednji: "#FFD700",
    visok: "#FF5C5C",
  };

  function formatProcesses(list = []) {
    const newList = [];

    list.forEach((item) => {
      if (!item.del) {
        item.business_process_lists.forEach((element) => {
          if (!element.del) {
            newList.push(element);
          }
        });
      }
    });

    return newList;
  }

  const formatListToMap = (list) => {
    const map = {};
    list.forEach((item) => {
      map[item.id] = item;
    });
    return map;
  };

  const formatEmployees = (list, des) => {
    const workers = list.map((item) => {
      const worker = des.find((el) => el.id === item.employee_id);

      if (worker) {
        return { label: worker.name, value: worker, isDisabled: undefined };
      }
    });

    return workers;
  };

  useEffect(() => {
    setLoading(true);
    const urls = [
      "/risk_forms",
      "/organization_unit/0",
      "/employee/id/0/level/0",
      "/designations/0/0",
      "/business_processes",
      "/risk_levels",
      "/risk_groups",
    ];

    Promise.all(
      urls.map((url) => baseRequest.get(url, { withCredentials: true }))
    )
      .then((res) => {
        setLoading(false);
        const responseObj = {};
        res.forEach((item) => {
          if (item.data.data) {
            if (item.data.data[0].first_name) {
              responseObj["designations"] = formatDesignations(item.data.data);
            } else {
              responseObj["organization_units"] = item.data.data;
            }
          } else if (item.data.business_processes) {
            responseObj["business_processes"] = item.data.business_processes;
          } else if (item.data.designations) {
            responseObj["designationsList"] = item.data.designations;
          } else if (item.data.risk_groups) {
            responseObj["risk_groups"] = item.data.risk_groups;
          } else if (item.data.risk_levels) {
            responseObj["risk_levels"] = item.data.risk_levels;
          } else if (item.data.risk_forms) {
            responseObj["risk_forms"] = item.data.risk_forms;
          }
        });
        setDesignations(responseObj["designations"]);
        setRiskGroups(responseObj["risk_groups"]);
        setProcesses(responseObj["business_processes"]);
        setRisksLevels(formatRiskLevels(responseObj["risk_levels"]));
        setFormularList(formatListToMap(responseObj["risk_forms"]));
        addNewFormular();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (formular.selectedProcess) {
      setFormular((formular) => {
        return { ...formular, ["process_code"]: formular.selectedProcess.code };
      });
    }
  }, [formular.selectedProcess]);

  useEffect(() => {
    const cInf = calculateControlInfluence(
      formular.probability_id,
      formular.precaution_influence_percentage
    );
    setControlInfluence(cInf);

    const tot = calculateTotalValue(
      formular.probability_id,
      formular.influence_id
    ).total;
    if (tot && controlInfluence) {
      const preostaliRizik = (tot - controlInfluence) * 10;
      const stringValue =
        preostaliRizik < 50
          ? "nizak"
          : preostaliRizik > 59
          ? "visok"
          : "srednji";
      setPreostaliRizik({ preostaliRizik: `${preostaliRizik}%`, stringValue });
    } else if (tot && controlInfluence === 0) {
      const preostaliRizik = (tot - controlInfluence) * 10;
      const stringValue =
        preostaliRizik < 50
          ? "nizak"
          : preostaliRizik > 59
          ? "visok"
          : "srednji";
      setPreostaliRizik({ preostaliRizik: `${preostaliRizik}%`, stringValue });
    } else {
      setPreostaliRizik("");
    }
  }, [formular, controlInfluence]);

  function formatDesignations(list) {
    return list.map((item) => {
      return {
        id: item.id,
        first_name: item.first_name,
        last_name: item.last_name,
        name: `${item.first_name} ${item.last_name} - ${item.designation_name}`,
      };
    });
  }

  const formatRiskLevels = (list) => {
    return list.map((item) => {
      return {
        name: `${item.id}. ${item.name}`,
        id: item.id,
        basic_name: item.name,
      };
    });
  };

  function setInterviewedPersons(value) {
    if (value.length) {
      validateField("risk_interviewees");
    }
    setFormular((formular) => {
      return { ...formular, risk_interviewees: value };
    });
  }

  function handleSelectInterviewDate(date) {
    if (date) {
      validateField("interview_date");
    }
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let currentDate = `${year}-${month}-${day}`;
    setFormular((formular) => {
      return {
        ...formular,
        ["interview_date"]: currentDate,
      };
    });
  }

  const handleSetProcessName = (value) => {
    if (value) {
      validateField("business_process_list_id");
    }
    const relativeFormulars = Object.values(formularList).filter(
      (element) => element.business_process_list_id === value.value.id
    );

    const formularNumber =
      relativeFormulars.length > 0 ? relativeFormulars.length + 1 : 1;
    setFormular((formular) => {
      return {
        ...formular,
        business_process_list: value.value,
        business_process_list_id: value.value.id,
        number: `${value.value.code}-${formularNumber}`,
      };
    });
  };

  const getProcessOwners = (list, desList) => {
    return list.map((owner) => {
      const worker = desList.find((el) => el.id === owner.employee_id).name;
      return `${worker}`;
    });
  };

  const selectRistOwners = (value) => {
    if (value.length) {
      validateField("risk_owners");
    }
    setFormular((formular) => {
      return { ...formular, ["risk_owners"]: value };
    });
  };

  const handleChange = (key, isNumeric) => {
    return function (e) {
      if (e.target.value.trimStart() && !isNumeric) {
        setFormular((formular) => {
          return { ...formular, [key]: e.target.value.trimStart() };
        });
        validateField(key);
      } else {
        const validNumber = new RegExp(/^\d*\.?\d*$/);
        const value = e.target.value.trimStart();
        if (validNumber.test(value)) {
          validateField(key);
          setInfluenceValidationError(null);
          setFormular((formular) => {
            return { ...formular, [key]: value };
          });
        } else {
          setInfluenceValidationError("Samo brojevi");
        }
      }
    };
  };

  function setGroupRisk(value) {
    if (value) {
      validateField("risk_group_id");
    }
    setFormular((formular) => {
      return {
        ...formular,
        ["risk_group"]: value.value,
        risk_group_id: value.value.id,
      };
    });
  }

  function setRiskProbability(value) {
    if (value) {
      validateField("probability_id");
    }
    setFormular((formular) => {
      return {
        ...formular,
        ["probability_id"]: value.value.id,
        selectedRiskProbability: value.value,
      };
    });
  }

  function setRiskInfluence(value) {
    if (value) {
      validateField("influence_id");
    }
    setFormular((formular) => {
      return {
        ...formular,
        ["influence_id"]: value.value.id,
        selectedRiskEffect: value.value,
      };
    });
  }

  function handleSelectPrecautionDate(date) {
    if (date) {
      validateField("precaution_date");
    }
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let currentDate = `${year}-${month}-${day}`;
    setFormular((formular) => {
      return {
        ...formular,
        ["precaution_date"]: currentDate,
      };
    });
  }

  const calculateTotalValue = (probability, influence) => {
    if (probability && influence) {
      const percentage = (probability + influence) * 10;
      const stringValue =
        percentage < 50 ? "nizak" : percentage > 59 ? "visok" : "srednji";
      return {
        total: probability + influence,
        percentage: `${percentage}%`,
        stringValue,
      };
    }
    return "";
  };

  const calculateControlInfluence = (probability, estimatedInfluence) => {
    if (probability && estimatedInfluence) {
      const value = (probability * estimatedInfluence) / 100;

      if (Number.isInteger(value)) {
        return value;
      } else {
        return value.toFixed(1);
      }
    }

    return "";
  };

  function setCorrespondentEmployees(value) {
    if (value.length) {
      validateField("risk_correspondents");
    }
    setFormular((formular) => {
      return { ...formular, ["risk_correspondents"]: value };
    });
  }

  const handleSubmitFormular = () => {
    if (validateAllFields(formular)) {
      return;
    }
    const { risk_correspondents, risk_interviewees, risk_owners } = formular;
    const formatted_risk_correspondent_employees = risk_correspondents
      ? risk_correspondents.map((item) => {
          return { id: item.value.id };
        })
      : null;
    const formatted_risk_interviewee_employees = risk_interviewees.map(
      (item) => {
        return { id: item.value.id };
      }
    );

    const formatted_risk_owner_employees = risk_owners.map((item) => {
      return { id: item.value.id };
    });
    const formattedFormular = {
      ...formular,
      risk_correspondent_employees: formatted_risk_correspondent_employees,
      risk_interviewee_employees: formatted_risk_interviewee_employees,
      risk_owner_employees: formatted_risk_owner_employees,
    };
    setLoading(true);

    baseRequest
      .post("risk_form", formattedFormular, { withCredentials: true })
      .then((res) => {
        setLoading(false);
        setErrorMessages(null);
        setErrors(null);

        navigate(`/admin/formulari-rizika/${res.data.data.id}`);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const addNewFormular = () => {
    setFormular({});
    setControlInfluence("");
    setPreostaliRizik("");
  };

  const formatDateToObject = (date) => {
    const dateStringArray = date.split("-");

    return new Date(
      dateStringArray[0],
      +dateStringArray[1] - 1,
      dateStringArray[2]
    );
  };

  const validateAllFields = (formular) => {
    const requiredFields = [
      "interview_date",
      "business_process_list_id",
      "name",
      "description",
      "cause",
      "effect",
      "risk_group_id",
      "probability_id",
      "influence_id",
      "management_method",

      "precaution_influence_percentage",

      "risk_interviewees",
      "risk_owners",

      "business_process_list",
      "risk_group",
    ];
    const {
      created_at,
      del,
      id,
      is_archived,
      last_updated,
      number,
      tenant_id,
      updated_at,
      user_id_last_updated,
      ...rest
    } = formular;
    const errors = [];
    const isEmpty = Object.keys(formular).length === 0;
    if (isEmpty) {
      setErrorMessages("Popunite sva polja");
      return true;
    } else {
      requiredFields.forEach((field) => {
        if (rest[field] && Array.isArray(rest[field]) && !rest[field].length) {
          errors.push(field);
        } else if (!rest[field]) {
          errors.push(field);
        }
      });
      if (errors.length) {
        setErrorMessages("Popunite sva polja");
        setErrors(errors);
        return true;
      }
      return false;
    }
  };

  const validateField = (field) => {
    if (errors && errors.includes(field)) {
      setErrors((errors) => {
        if (errors.length === 1) {
          setErrorMessages(null);
        }

        const filteredErrors = errors.filter((error) => error !== field);
        return filteredErrors;
      });
    }
  };

  const handleDeleteFormular = (id) => {
    setLoading(true);
    axios
      .delete(`${deleteUrl}risk_form`, {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        data: { id: id },
        withCredentials: true,
      })
      .then((res) => {
        setLoading(false);

        setFormularList((list) => {
          return {
            ...list,
            [res.data.data.id]: { ...list[res.data.data.id], del: 1 },
          };
        });

        if (res.data.data.id === formular.id) {
          const activeFormulars = Object.values(formularList).filter(
            (value) => !value.del
          );
          if (activeFormulars.length > 1) {
            setFormular(
              activeFormulars.filter((form) => form.id !== res.data.data.id)[0]
            );
          } else {
            addNewFormular();
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleDeleteNewFormular = () => {
    setFormular({});
    setErrorMessages(null);
    setErrors(null);
    setInfluenceValidationError(null);
  };

  return (
    <>
      <div
        className="processes-header"
        style={{ padding: "15px 0", justifyContent: "space-between" }}
      >
        <div className="back-arrow">
          <img src="/images/backarrow.png" />
          <Link to="/admin/formulari-rizika">Nazad</Link>
        </div>
        <div className="sort-options-dropdown" style={{ width: "200px" }}>
          <div
            className="sort-options-dropdown-selected"
            onClick={() => {
              // setShowDropdown((state) => !state);
            }}
          >
            <span style={{ marginRight: "7px", paddingLeft: "10px" }}>
              Opcije
            </span>

            <img
              alt=""
              src="/images/sidebararrow.png"
              className="sidebar-navigation-item-arrow"
            ></img>
          </div>
          {/* {showDropdown && (
            <ul
              className="sort-options-dropdown-options"
              style={{ left: "0", zIndex: "100", flex: 1 }}
            >
              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setShowDropdown(false);
                }}
              >
                Izmenite
              </li>

              <li
                className="sort-options-dropdown-options-item"
                onClick={() => {
                  setShowDropdown(false);
                }}
              >
                Odštampaj
              </li>
            </ul>
          )} */}
        </div>
        <div>
          {formular && (
            <button className="add-btn-large">
              <img alt="" src="/images/check2.png"></img>
            </button>
          )}
        </div>
      </div>

      <div
        className="poslovni-procesi"
        style={{
          width: "auto",
          marginLeft: "50px",
          marginTop: "30px",
        }}
      >
        <div
          className="poslovni-procesi-tables"
          style={{
            width: "100%",
          }}
        >
          <DropdownWrapper
            title="INTERVJUISANO LICE I NJEGOVA FUNKCIJA"
            wrapperStyle={wrapperStyle}
            style={style}
          >
            <DropDown
              list={designations}
              isMulti={true}
              onChange={setInterviewedPersons}
              selectedValues={
                formular?.risk_interviewees ? formular?.risk_interviewees : ""
              }
              dropDownStyle={
                errors && errors.includes("risk_interviewees") ? errorStyle : {}
              }
            />
          </DropdownWrapper>
          <DropdownWrapper
            title="DATUM INTERVJUA"
            wrapperStyle={wrapperStyle}
            style={style}
          >
            <div
              style={
                errors && errors.includes("interview_date") ? errorStyle : {}
              }
            >
              <Calendar
                handleSelectDate={handleSelectInterviewDate}
                parentStartDate={
                  formular.interview_date
                    ? formatDateToObject(formular.interview_date)
                    : ""
                }
              />
            </div>
          </DropdownWrapper>
          <DropdownWrapper
            title="IME PROCESA"
            wrapperStyle={wrapperStyle}
            style={style}
          >
            <DropDown
              isMulti={false}
              list={formatProcesses(processes) || []}
              onChange={handleSetProcessName}
              selectedValue={formular?.business_process_list?.name || ""}
              dropDownStyle={
                errors && errors.includes("business_process_list_id")
                  ? errorStyle
                  : {}
              }
            />
          </DropdownWrapper>
          <DropdownWrapper
            title="VLASNIK PROCESA"
            wrapperStyle={wrapperStyle}
            style={style}
          >
            {formular.business_process_list && (
              <ul className="static-list">
                {getProcessOwners(
                  formular.business_process_list.business_process_owners,
                  designations
                ).map((el) => {
                  return <li key={el}>{el}</li>;
                })}
              </ul>
            )}
          </DropdownWrapper>
          <DropdownWrapper
            title="BROJ"
            wrapperStyle={wrapperStyle}
            style={style}
          >
            <div className="broj">{formular?.number}</div>
          </DropdownWrapper>
          <DropdownWrapper title="FUNKCIJA/VLASNIK RIZIKA" style={style}>
            <DropDown
              list={designations}
              isMulti={true}
              onChange={selectRistOwners}
              selectedValues={formular.risk_owners ? formular.risk_owners : ""}
              dropDownStyle={
                errors && errors.includes("risk_owners") ? errorStyle : {}
              }
            />
          </DropdownWrapper>
          <div className="empty-cell"></div>
          <DropdownWrapper
            title="ime rizika"
            wrapperStyle={wrapperStyle}
            style={style}
          >
            <DynamicTextarea
              onChange={handleChange("name")}
              value={formular.name ? formular.name : ""}
              style={errors && errors.includes("name") ? errorStyle : {}}
            />
          </DropdownWrapper>
          <DropdownWrapper
            title="opis rizika"
            wrapperStyle={wrapperStyle}
            style={style}
          >
            <DynamicTextarea
              style={
                errors && errors.includes("description")
                  ? errorStyle
                  : textAreaStyle
              }
              autoFocus
              placeHolder="*opis rizika"
              onChange={handleChange("description")}
              value={formular.description ? formular.description : ""}
            />
            <DynamicTextarea
              style={
                errors && errors.includes("cause") ? errorStyle : textAreaStyle
              }
              autoFocus
              placeHolder="*uzroci"
              onChange={handleChange("cause")}
              value={formular.cause ? formular.cause : ""}
            />
            <DynamicTextarea
              placeHolder="*posledice"
              onChange={handleChange("effect")}
              value={formular.effect ? formular.effect : ""}
              style={
                errors && errors.includes("effect")
                  ? errorStyle
                  : { paddingTop: "15px" }
              }
            />
          </DropdownWrapper>

          <DropdownWrapper
            title="grupa rizika"
            wrapperStyle={wrapperStyle}
            style={style}
          >
            <DropDown
              isMulti={false}
              list={riskGroups}
              onChange={setGroupRisk}
              selectedValue={
                formular?.risk_group?.name ? formular.risk_group.name : ""
              }
              dropDownStyle={
                errors && errors.includes("risk_group_id") ? errorStyle : {}
              }
            />
          </DropdownWrapper>
          <DropdownWrapper
            title="verovatnoca (1-5)"
            wrapperStyle={wrapperStyle}
            style={style}
          >
            <DropDown
              isMulti={false}
              list={riskLevels}
              onChange={setRiskProbability}
              selectedValue={formular.probability_id}
              dropDownStyle={
                errors && errors.includes("probability_id")
                  ? { ...errorStyle, height: "auto" }
                  : { height: "auto" }
              }
            />
            <DynamicTextarea
              readOnly={true}
              style={{ borderTop: border }}
              value={
                riskLevels && formular.probability_id
                  ? riskLevels.find(
                      (item) => item.id === formular.probability_id
                    ).basic_name
                  : ""
              }
            />
          </DropdownWrapper>

          <DropdownWrapper
            title="uticaj (1-5)"
            wrapperStyle={wrapperStyle}
            style={style}
          >
            <DropDown
              isMulti={false}
              list={riskLevels}
              onChange={setRiskInfluence}
              selectedValue={formular.influence_id}
              dropDownStyle={
                errors && errors.includes("influence_id")
                  ? { ...errorStyle, height: "auto" }
                  : { height: "auto" }
              }
            />
            <DynamicTextarea
              style={{ borderTop: border }}
              readOnly={true}
              value={
                riskLevels && formular.influence_id
                  ? riskLevels.find((item) => item.id === formular.influence_id)
                      .basic_name
                  : ""
              }
            />
          </DropdownWrapper>

          <DropdownWrapper
            title="UKUPNA VREDNOST (verovatnoća + uticaj)"
            style={style}
          >
            <DynamicTextarea
              style={{
                borderBottom: border,
                backgroundColor: disabledBackground,
                padding: "10px",
              }}
              readOnly={true}
              value={
                formular.probability_id
                  ? calculateTotalValue(
                      formular.probability_id,
                      formular.influence_id
                    ).total
                  : ""
              }
            />
            <DynamicTextarea
              style={{
                borderBottom: border,
                backgroundColor: disabledBackground,
                padding: "10px",
              }}
              readOnly={true}
              value={
                formular.probability_id
                  ? calculateTotalValue(
                      formular.probability_id,
                      formular.influence_id
                    ).percentage
                  : ""
              }
            />
            <DynamicTextarea
              style={{
                padding: "10px",
                backgroundColor: calculateTotalValue(
                  formular.probability_id,
                  formular.influence_id
                ).stringValue
                  ? riskColors[
                      calculateTotalValue(
                        formular.probability_id,
                        formular.influence_id
                      ).stringValue
                    ]
                  : disabledBackground,
              }}
              readOnly={true}
              value={
                formular.probability_id
                  ? calculateTotalValue(
                      formular.probability_id,
                      formular.influence_id
                    ).stringValue
                  : ""
              }
            />
          </DropdownWrapper>
          <DropdownWrapper
            title="NAČIN UPRAVLJANJA RIZIKOM"
            wrapperStyle={multiWrapperStyle}
            style={style}
          >
            <DynamicTextarea
              onChange={handleChange("management_method")}
              value={
                formular.management_method ? formular.management_method : ""
              }
              style={
                errors && errors.includes("management_method") ? errorStyle : {}
              }
            />
          </DropdownWrapper>
          <DropdownWrapper
            title="OPIS KONTROLA/MERA ZA UPRAVLJANJE RIZICIMA"
            wrapperStyle={wrapperStyle}
            style={style}
          >
            <DynamicTextarea
              onChange={handleChange(
                "management_method_precaution_description"
              )}
              value={
                formular.management_method_precaution_description
                  ? formular.management_method_precaution_description
                  : ""
              }
              style={
                errors &&
                errors.includes("management_method_precaution_description")
                  ? errorStyle
                  : {}
              }
            />
          </DropdownWrapper>
          <DropdownWrapper
            title="PROCENJENI UTICAJ KONTROLA U %"
            wrapperStyle={wrapperStyle}
            style={style}
          >
            <DynamicTextarea
              onChange={handleChange("precaution_influence_percentage", true)}
              hasErrors={influenceValidationError}
              value={
                formular.precaution_influence_percentage
                  ? formular.precaution_influence_percentage
                  : ""
              }
              style={
                errors && errors.includes("precaution_influence_percentage")
                  ? errorStyle
                  : {}
              }
            />
          </DropdownWrapper>
          <DropdownWrapper
            title="UTICAJ KONTROLA (0-5)"
            wrapperStyle={{ borderBottom: "none" }}
            style={style}
          >
            <DynamicTextarea
              readOnly={true}
              value={controlInfluence}
              style={{ backgroundColor: disabledBackground, height: "100%" }}
            />
          </DropdownWrapper>
          <DropdownWrapper title="PREOSTALI RIZIK" style={style}>
            <DynamicTextarea
              style={{
                borderBottom: border,
                backgroundColor: disabledBackground,
                padding: "10px",
              }}
              readOnly={true}
              value={
                preostaliRizik.preostaliRizik
                  ? preostaliRizik.preostaliRizik
                  : ""
              }
            />
            <DynamicTextarea
              style={{
                backgroundColor: preostaliRizik.stringValue
                  ? riskColors[preostaliRizik.stringValue]
                  : disabledBackground,
                padding: "10px",
              }}
              readOnly={true}
              value={
                preostaliRizik.stringValue ? preostaliRizik.stringValue : ""
              }
            />
          </DropdownWrapper>
          <div className="empty-cell"></div>
          <DropdownWrapper
            title="ROK ZA IZVRŠENJE KONTROLNIH MERA"
            wrapperStyle={wrapperStyle}
            style={style}
          >
            <div
              style={
                errors && errors.includes("precaution_date") ? errorStyle : {}
              }
            >
              <Calendar
                handleSelectDate={handleSelectPrecautionDate}
                parentStartDate={
                  formular.precaution_date
                    ? formatDateToObject(formular.precaution_date)
                    : ""
                }
              />
            </div>
          </DropdownWrapper>
          <DropdownWrapper
            title="ODGOVORNA OSOBA ZA SPROVOĐENJE MERA"
            wrapperStyle={wrapperStyle}
            style={style}
          >
            <DropDown
              isMulti={true}
              list={designations}
              onChange={setCorrespondentEmployees}
              selectedValues={
                formular.risk_correspondents ? formular.risk_correspondents : ""
              }
              dropDownStyle={
                errors && errors.includes("risk_correspondents")
                  ? errorStyle
                  : {}
              }
            />
          </DropdownWrapper>
          <DropdownWrapper
            title="STATUS IZVRŠENJA MERA"
            wrapperStyle={wrapperStyle}
            style={style}
          >
            <DynamicTextarea
              onChange={handleChange("precaution_status")}
              value={
                formular.precaution_status ? formular.precaution_status : ""
              }
              style={
                errors && errors.includes("precaution_status") ? errorStyle : {}
              }
            />
          </DropdownWrapper>
          <DropdownWrapper
            title="POSLEDNJE IZMENE UNEO"
            style={style}
            wrapperStyle={wrapperStyle}
          >
            <DynamicTextarea
              style={{ backgroundColor: disabledBackground, padding: 0 }}
            />
          </DropdownWrapper>
          <DropdownWrapper title="DATUM POSLEDNJE IZMENE" style={style}>
            <DynamicTextarea
              style={{ backgroundColor: disabledBackground, padding: 0 }}
            />
          </DropdownWrapper>
          {errorMessages && <div className="errormessage">{errorMessages}</div>}
          <div
            style={{
              padding: "20px 0",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              className="add-poslovni-proces-btn"
              onClick={handleSubmitFormular}
            >
              <img src="/images/save.png" />
              <span>Sačuvajte formular rizika</span>
            </button>

            <button
              className="add-poslovni-proces-btn"
              onClick={handleDeleteNewFormular}
            >
              <img src="/images/trash.png" />
              <span>Izbrisite formular rizika</span>
            </button>
          </div>
        </div>
        <Loading isLoading={loading} />
      </div>
    </>
  );
}
