import React from "react";

import DropDown from "../../elements/dropDown";

function NewUser({
  employee,
  users,
  organization_units,
  designations,
  handleSelectOrganizationUnit,
  handleSelectDesignation,
  handleSelectRole,
  handleChangeRoleInput,
  handleSaveNewUser,
  handleDeleteNewUser,
  newUser,
  roles,
  userValidationErrors,
}) {
  const options = roles
    ? [
        { id: 1, icon: "/images/bigupravaicon.png" },
        { id: 2, icon: "/images/odsekicon.png" },
        { id: 3, icon: "/images/smallzaposleniicon.png" },
      ].map((item) => {
        const match = roles.find((r) => r.id === item.id);
        return { ...item, ...match };
      })
    : [];

  const height = "49px";
  return (
    <div className="authorization-employee-item">
      <div
        className="authorization-employee-item-cell"
        style={{ width: "20%" }}
      >
        <DropDown
          height={height}
          list={organization_units}
          onChange={handleSelectOrganizationUnit}
          selectedValue={newUser?.organization_unit?.name}
        />
        {userValidationErrors &&
          userValidationErrors["organization_unit_id"] && (
            <p
              style={{
                position: "absolute",
                left: "4px",
                bottom: "0",
                fontSize: "11px",
                color: "red",
              }}
            >
              {userValidationErrors["organization_unit_id"]}
            </p>
          )}
      </div>
      <div
        className="authorization-employee-item-cell"
        style={{ width: "20%" }}
      >
        <DropDown
          height={height}
          list={designations}
          onChange={handleSelectDesignation}
          selectedValue={
            newUser?.designation?.name ? newUser?.designation?.name : ""
          }
        />
        {userValidationErrors && userValidationErrors["employee_id"] && (
          <p
            style={{
              position: "absolute",
              left: "4px",
              bottom: "0",
              fontSize: "11px",
              color: "red",
            }}
          >
            {userValidationErrors["employee_id"]}
          </p>
        )}
      </div>
      <div
        className="authorization-employee-item-cell"
        style={{ width: "19%" }}
      >
        <DropDown
          list={options}
          height={height}
          withIcon={true}
          onChange={handleSelectRole}
          selectedValue={newUser?.role?.name}
        />
        {userValidationErrors && userValidationErrors["role_id"] && (
          <p
            style={{
              position: "absolute",
              left: "4px",
              bottom: "0",
              fontSize: "11px",
              color: "red",
            }}
          >
            {userValidationErrors["role_id"]}
          </p>
        )}
      </div>
      <div
        className="authorization-employee-item-cell"
        style={{ width: "18%" }}
      >
        <input
          className="authorization-employee-item-input"
          onChange={(e) => handleChangeRoleInput(e, "email")}
          value={newUser?.email ? newUser?.email : ""}
        />
        {userValidationErrors &&
          userValidationErrors["email"] &&
          !Array.isArray(userValidationErrors["email"]) && (
            <p
              style={{
                position: "absolute",
                left: "4px",
                bottom: "0",
                fontSize: "11px",
                color: "red",
              }}
            >
              {userValidationErrors["email"]}
            </p>
          )}
        {userValidationErrors &&
          userValidationErrors["email"] &&
          Array.isArray(userValidationErrors["email"]) && (
            <ul>
              {userValidationErrors["email"].map((er) => {
                return (
                  <li
                    key={er}
                    style={{
                      position: "absolute",
                      left: "4px",
                      bottom: "0",
                      fontSize: "11px",
                      color: "red",
                    }}
                  >
                    {er}
                  </li>
                );
              })}
            </ul>
          )}
      </div>
      <div
        className="authorization-employee-item-cell"
        style={{ width: "18%" }}
      >
        <input
          className="authorization-employee-item-input"
          onChange={(e) => handleChangeRoleInput(e, "password")}
          value={newUser?.password ? newUser.password : ""}
        />
        {userValidationErrors &&
          userValidationErrors["password"] &&
          !Array.isArray(userValidationErrors["password"]) && (
            <p
              style={{
                position: "absolute",
                left: "4px",
                bottom: "0",
                fontSize: "11px",
                color: "red",
              }}
            >
              {userValidationErrors["password"]}
            </p>
          )}
        {userValidationErrors &&
          userValidationErrors["password"] &&
          Array.isArray(userValidationErrors["password"]) && (
            <ul>
              {userValidationErrors["password"].map((er) => {
                return (
                  <li
                    key={er}
                    style={{
                      position: "absolute",
                      left: "4px",
                      bottom: "0",
                      fontSize: "11px",
                      color: "red",
                    }}
                  >
                    {er}
                  </li>
                );
              })}
            </ul>
          )}
      </div>
      <div className="authorization-employee-item-cell" style={{ width: "5%" }}>
        <div className=" options-block">
          <button
            type="button"
            className="employee-item-options-button"
            onClick={
              newUser?.adding
                ? () => {
                    handleSaveNewUser(newUser);
                  }
                : () => {}
            }
          >
            <img
              alt=""
              src={newUser?.adding ? "/images/check.png" : "images/edit.png"}
              // src={employee?.typing ? "/images/check1.jpg" : "/images/edit.png"}
            />
          </button>
          <button
            type="button"
            className="employee-item-options-button"
            onClick={() => {
              handleDeleteNewUser();
            }}
          >
            <img alt="" src="/images/trash.png" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default NewUser;
